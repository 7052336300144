import { pluckSynth } from "../tones/tone-synthesizers";

/**
 * Play two tones to indicate that the model is ready
 */
export function playModelReadyTones() {
  try {
    pluckSynth.triggerAttackRelease("C5", "16n");
    pluckSynth.triggerAttackRelease("E5", "4n", "+0.1s");
  } catch (error) {
    console.error("Error playing note:", error);
  }
}
