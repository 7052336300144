import { setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* import '@ionic/react/css/palettes/dark.always.css'; */
/* import '@ionic/react/css/palettes/dark.class.css'; */
import "@ionic/react/css/palettes/dark.system.css";

import { Capacitor } from "@capacitor/core";
import {
  Box,
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  Flex,
  StyleFunctionProps,
} from "@chakra-ui/react";
import { NativeAudio } from "@scottjgilroy/native-audio";
import { useEffect, useState } from "react";
import "./App.css";
import AppContent from "./AppContent";
import { AuthProvider } from "./components/login/AuthProvider";
import { useAuthStateObserver } from "./components/login/useAuthStateObserver";
import { SettingsProvider } from "./components/settings/SettingsProvider";
import { SubscriptionProvider } from "./components/subscribe/SubscriptionContext";
import { SpeechQueueProvider } from "./components/tts/useSpeechQueue";
import DetailedErrorBoundary from "./DetailedErrorBoundary";
import { firebaseService } from "./services/FirebaseService";
import "./theme/fonts.css";
import "./theme/variables.css";

setupIonicReact();

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};

const theme = extendTheme({
  config: {
    initialColorMode: "system",
    useSystemColorMode: true,
  },
  components: {
    Form: {
      variants: {
        floating: (props: StyleFunctionProps) => ({
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, .chakra-numberinput + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor:
                props.colorMode === "dark"
                  ? "var(--ion-background-color)"
                  : "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        }),
      },
    },
    Link: {
      variants: {
        conventional: (props: StyleFunctionProps) => ({
          color: props.colorMode === "dark" ? "blue.300" : "blue.600",
          textDecor: "underline",
        }),
      },
    },
  },
});

const App: React.FC = () => {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      NativeAudio.configure({
        focus: true,
        background: true,
      });
    }
  }, []);

  useEffect(() => {
    firebaseService.initialize().then(() => {
      setFirebaseInitialized(true);
    });
  }, []);

  useAuthStateObserver();

  if (!firebaseInitialized) {
    return (
      <Flex
        height="100vh"
        width="100vw"
        alignItems="center"
        justifyContent="center"
      >
        <Box>Loading...</Box>
      </Flex>
    );
  }

  return (
    <DetailedErrorBoundary>
      <ColorModeScript initialColorMode="system" />
      <ChakraProvider theme={theme}>
        <SpeechQueueProvider>
          <SettingsProvider>
            <AuthProvider>
              <SubscriptionProvider>
                <AppContent />
              </SubscriptionProvider>
            </AuthProvider>
          </SettingsProvider>
        </SpeechQueueProvider>
      </ChakraProvider>
    </DetailedErrorBoundary>
  );
};

export default App;
