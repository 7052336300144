import { Capacitor } from "@capacitor/core";
import { Box, BoxProps, Icon, IconButton, Select } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { MdFlipCameraIos, MdOutlineFlipCameraAndroid } from "react-icons/md";
import { useCameraPermissionDetector } from "../pose-detection/useCameraPermissionDetector";

const FORCE_FLIP_BUTTON = false;

interface VideoDevice {
  deviceId: string;
  label: string;
  isBackFacing?: boolean;
}

export type SourceType = "camera" | "image" | "webcam" | "video";

interface VideoDevicePickerProps extends BoxProps {
  forceSelect?: boolean;
  forceFlipButton?: boolean;
  videoDeviceId: string | undefined;
  setVideoDeviceId: (
    deviceId: string | undefined,
    type: SourceType,
    isBackFacing?: boolean
  ) => void;
}

const VideoDevicePicker: React.FC<VideoDevicePickerProps> = ({
  forceSelect,
  forceFlipButton,
  videoDeviceId,
  setVideoDeviceId,
  ...props
}) => {
  const permission = useCameraPermissionDetector();
  const [devices, setDevices] = useState<VideoDevice[]>([]);
  const platform = Capacitor.getPlatform();
  const CameraIcon =
    platform === "ios" ? MdFlipCameraIos : MdOutlineFlipCameraAndroid;

  const handleDevices = useCallback((mediaDevices: MediaDeviceInfo[]) => {
    console.log("handleDevices", mediaDevices);
    return setDevices(
      mediaDevices
        .filter(({ kind }) => kind === "videoinput")
        .map(({ deviceId, label }) => {
          const isBackFacing = isBackFacingDevice(label);

          return {
            deviceId,
            label,
            isBackFacing,
          };
        })
    );
  }, []);

  // Ensure the videoDeviceId and isBackFacing are set in the parent when first loading
  useEffect(() => {
    const currentDevice = devices.find(
      (device) => device.deviceId === videoDeviceId
    );
    setVideoDeviceId(videoDeviceId, "webcam", currentDevice?.isBackFacing);
  }, [devices]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices, permission]);

  useEffect(() => {
    if (
      devices.length > 0 &&
      !videoDeviceId &&
      devices[0].deviceId &&
      devices[0].deviceId !== ""
    ) {
      setVideoDeviceId(devices[0].deviceId, "webcam", devices[0].isBackFacing);
    }
  }, [devices, videoDeviceId, setVideoDeviceId]);

  const handleDeviceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDevice = devices.find(
      (device) => device.deviceId === event.target.value
    );
    setVideoDeviceId(
      event.target.value,
      "webcam",
      selectedDevice?.isBackFacing
    );
  };

  const toggleCamera = () => {
    const currentIndex = devices.findIndex(
      (device) => device.deviceId === videoDeviceId
    );
    const nextIndex = (currentIndex + 1) % devices.length;
    const nextDevice = devices[nextIndex];
    setVideoDeviceId(nextDevice.deviceId, "webcam", nextDevice.isBackFacing);
  };

  if (devices.length === 0) {
    return null;
  }

  return (
    <Box {...props}>
      {(forceFlipButton ||
        FORCE_FLIP_BUTTON ||
        (isMobile && devices.length === 2)) &&
      !forceSelect ? (
        <IconButton
          variant="ghost"
          color="white"
          aria-label="Toggle camera"
          icon={<Icon as={CameraIcon} boxSize={8} />}
          onClick={toggleCamera}
          borderRadius={props.borderRadius}
        />
      ) : (
        <Select
          value={videoDeviceId}
          onChange={handleDeviceChange}
          onClick={(e) => e.stopPropagation()}
          borderRadius={props.borderRadius}
        >
          {devices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Camera ${devices.indexOf(device) + 1}`}
            </option>
          ))}
        </Select>
      )}
    </Box>
  );
};

export default VideoDevicePicker;
function isBackFacingDevice(label: string) {
  return (
    label.toLowerCase().includes("back") ||
    // Common patterns in different devices/browsers
    label.toLowerCase().includes("rear") ||
    label.toLowerCase().includes("environment")
  );
}
