/* eslint-disable */
/**
 * This file was automatically generated by PoseDefinitionLoaderGenerator.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source generator file,
 * and run it to regenerate this file.
 */

import Ajv from "ajv";
import { PoseDetectionFunction } from "../pose-detection/PoseDetectionFunction";
import poseDefinitionSchema from "../pose-detection/PoseDefinition.json";
import { PoseDefinitionInterpreter } from "../pose-detection/PoseDefinitionInterpreter";
import { poseIdToFileName } from "../pose-detection/poseIdToFileName";
import allFoursDefinition from "../pose-detection/poses/all-fours.json";
import atLeastOneHandUpDefinition from "../pose-detection/poses/at-least-one-hand-up.json";
import bearDefinition from "../pose-detection/poses/bear.json";
import boatDefinition from "../pose-detection/poses/boat.json";
import butterflyDefinition from "../pose-detection/poses/butterfly.json";
import cactusArmsDefinition from "../pose-detection/poses/cactus-arms.json";
import chairHandstandCompressionAlternatingLegsDefinition from "../pose-detection/poses/chair-handstand-compression-alternating-legs.json";
import childsPoseDefinition from "../pose-detection/poses/childs-pose.json";
import corpseDefinition from "../pose-detection/poses/corpse.json";
import couchStretchDefinition from "../pose-detection/poses/couch-stretch.json";
import crowDefinition from "../pose-detection/poses/crow.json";
import deepSquatWithArmsRaisedDefinition from "../pose-detection/poses/deep-squat-with-arms-raised.json";
import deepSquatDefinition from "../pose-detection/poses/deep-squat.json";
import dolphinDefinition from "../pose-detection/poses/dolphin.json";
import downwardDogDefinition from "../pose-detection/poses/downward-dog.json";
import eagleDefinition from "../pose-detection/poses/eagle.json";
import easyPoseDefinition from "../pose-detection/poses/easy-pose.json";
import extendedChildsPoseDefinition from "../pose-detection/poses/extended-childs-pose.json";
import feetElevatedDefinition from "../pose-detection/poses/feet-elevated.json";
import forwardFoldDefinition from "../pose-detection/poses/forward-fold.json";
import frontSplitsDefinition from "../pose-detection/poses/front-splits.json";
import fullHandstandDefinition from "../pose-detection/poses/full-handstand.json";
import goddessWithCactusArmsDefinition from "../pose-detection/poses/goddess-with-cactus-arms.json";
import halfHandstandCompressionWithFootOnTableDefinition from "../pose-detection/poses/half-handstand-compression-with-foot-on-table.json";
import halfPikeHandstandWithFootOnTableDefinition from "../pose-detection/poses/half-pike-handstand-with-foot-on-table.json";
import halfStraddleHandstandWithFootOnTableDefinition from "../pose-detection/poses/half-straddle-handstand-with-foot-on-table.json";
import halfTuckHandstandDefinition from "../pose-detection/poses/half-tuck-handstand.json";
import handsAtHeartCenterDefinition from "../pose-detection/poses/hands-at-heart-center.json";
import handsDownDefinition from "../pose-detection/poses/hands-down.json";
import handsOnEarsDefinition from "../pose-detection/poses/hands-on-ears.json";
import handsOnGroundDefinition from "../pose-detection/poses/hands-on-ground.json";
import handsOnHipsDefinition from "../pose-detection/poses/hands-on-hips.json";
import handsOnKneesDefinition from "../pose-detection/poses/hands-on-knees.json";
import handsOnShouldersDefinition from "../pose-detection/poses/hands-on-shoulders.json";
import handsOnToesDefinition from "../pose-detection/poses/hands-on-toes.json";
import handsUpDefinition from "../pose-detection/poses/hands-up.json";
import handstandAtTheWallDefinition from "../pose-detection/poses/handstand-at-the-wall.json";
import handstandChestToWallDefinition from "../pose-detection/poses/handstand-chest-to-wall.json";
import handstandKickUpPrepDefinition from "../pose-detection/poses/handstand-kick-up-prep.json";
import handstandPrepDefinition from "../pose-detection/poses/handstand-prep.json";
import handstandDefinition from "../pose-detection/poses/handstand.json";
import happyBabyDefinition from "../pose-detection/poses/happy-baby.json";
import headLevelDefinition from "../pose-detection/poses/head-level.json";
import highVDefinition from "../pose-detection/poses/high-v.json";
import horseDefinition from "../pose-detection/poses/horse.json";
import kneePlankDefinition from "../pose-detection/poses/knee-plank.json";
import kneelingWithArmsRaisedDefinition from "../pose-detection/poses/kneeling-with-arms-raised.json";
import layingOnBackWithStraightLegsSlightlyRaisedDefinition from "../pose-detection/poses/laying-on-back-with-straight-legs-slightly-raised.json";
import layingWithStraightLegsLiftedTo90DegreesDefinition from "../pose-detection/poses/laying-with-straight-legs-lifted-to-90-degrees.json";
import lowDownwardDogPushUpDefinition from "../pose-detection/poses/low-downward-dog-push-up.json";
import lowKneePushUpDefinition from "../pose-detection/poses/low-knee-push-up.json";
import lowPushUpDefinition from "../pose-detection/poses/low-push-up.json";
import lungeLeftDefinition from "../pose-detection/poses/lunge-left.json";
import lungeRightDefinition from "../pose-detection/poses/lunge-right.json";
import modifiedLowPushUpDefinition from "../pose-detection/poses/modified-low-push-up.json";
import modifiedPushUpDefinition from "../pose-detection/poses/modified-push-up.json";
import mountainDefinition from "../pose-detection/poses/mountain.json";
import narrowDownwardDogDefinition from "../pose-detection/poses/narrow-downward-dog.json";
import pancakeOnTheWallDefinition from "../pose-detection/poses/pancake-on-the-wall.json";
import partialRangeSquatWithArmsRaisedDefinition from "../pose-detection/poses/partial-range-squat-with-arms-raised.json";
import partialRangeSquatDefinition from "../pose-detection/poses/partial-range-squat.json";
import pikeHandstandLeftHandToShoulderWithFeetOnChairDefinition from "../pose-detection/poses/pike-handstand-left-hand-to-shoulder-with-feet-on-chair.json";
import pikeHandstandRightHandToShoulderWithFeetOnChairDefinition from "../pose-detection/poses/pike-handstand-right-hand-to-shoulder-with-feet-on-chair.json";
import pikeHandstandWithBentKneesAndFeetOnWallDefinition from "../pose-detection/poses/pike-handstand-with-bent-knees-and-feet-on-wall.json";
import pikeHandstandWithFeetOnChairDefinition from "../pose-detection/poses/pike-handstand-with-feet-on-chair.json";
import pikeHandstandWithFeetOnTableDefinition from "../pose-detection/poses/pike-handstand-with-feet-on-table.json";
import plankWithFeetElevatedDefinition from "../pose-detection/poses/plank-with-feet-elevated.json";
import plankWithHipsHighDefinition from "../pose-detection/poses/plank-with-hips-high.json";
import plankWithLeftHandToShoulderDefinition from "../pose-detection/poses/plank-with-left-hand-to-shoulder.json";
import plankWithLeftKneeToChestDefinition from "../pose-detection/poses/plank-with-left-knee-to-chest.json";
import plankWithRightHandToShoulderDefinition from "../pose-detection/poses/plank-with-right-hand-to-shoulder.json";
import plankWithRightKneeToChestDefinition from "../pose-detection/poses/plank-with-right-knee-to-chest.json";
import plankDefinition from "../pose-detection/poses/plank.json";
import singleLegStanceDefinition from "../pose-detection/poses/single-leg-stance.json";
import sissySquatDefinition from "../pose-detection/poses/sissy-squat.json";
import sphinxDefinition from "../pose-detection/poses/sphinx.json";
import squatWithArmsRaisedDefinition from "../pose-detection/poses/squat-with-arms-raised.json";
import squatDefinition from "../pose-detection/poses/squat.json";
import standWithArmsRaisedDefinition from "../pose-detection/poses/stand-with-arms-raised.json";
import standWithLeftKneeRaisedDefinition from "../pose-detection/poses/stand-with-left-knee-raised.json";
import standWithRightKneeRaisedDefinition from "../pose-detection/poses/stand-with-right-knee-raised.json";
import standDefinition from "../pose-detection/poses/stand.json";
import standingCrabWalkLeftDefinition from "../pose-detection/poses/standing-crab-walk-left.json";
import standingCrabWalkRightDefinition from "../pose-detection/poses/standing-crab-walk-right.json";
import standingForwardFoldDefinition from "../pose-detection/poses/standing-forward-fold.json";
import standingShoulderOpenerWithHandsOnTableDefinition from "../pose-detection/poses/standing-shoulder-opener-with-hands-on-table.json";
import straddleForwardFoldDefinition from "../pose-detection/poses/straddle-forward-fold.json";
import straddleHandstandDefinition from "../pose-detection/poses/straddle-handstand.json";
import tPoseDefinition from "../pose-detection/poses/t-pose.json";
import takeAKneeDefinition from "../pose-detection/poses/take-a-knee.json";
import threeBlocksChestExerciseDefinition from "../pose-detection/poses/three-blocks-chest-exercise.json";
import treeDefinition from "../pose-detection/poses/tree.json";
import tuckHandstandDefinition from "../pose-detection/poses/tuck-handstand.json";
import tuckPlancheDefinition from "../pose-detection/poses/tuck-planche.json";
import upwardDogDefinition from "../pose-detection/poses/upward-dog.json";
import warrior1Definition from "../pose-detection/poses/warrior-1.json";
import warrior2Definition from "../pose-detection/poses/warrior-2.json";
import wheelDefinition from "../pose-detection/poses/wheel.json";

import { PoseDefinition } from "./PoseDefinition";

const interpreter = new PoseDefinitionInterpreter();

// validate the JSON definition
const ajv = new Ajv();
const validate = ajv.compile(poseDefinitionSchema);

const definitions = [
  allFoursDefinition,
  atLeastOneHandUpDefinition,
  bearDefinition,
  boatDefinition,
  butterflyDefinition,
  cactusArmsDefinition,
  chairHandstandCompressionAlternatingLegsDefinition,
  childsPoseDefinition,
  corpseDefinition,
  couchStretchDefinition,
  crowDefinition,
  deepSquatWithArmsRaisedDefinition,
  deepSquatDefinition,
  dolphinDefinition,
  downwardDogDefinition,
  eagleDefinition,
  easyPoseDefinition,
  extendedChildsPoseDefinition,
  feetElevatedDefinition,
  forwardFoldDefinition,
  frontSplitsDefinition,
  fullHandstandDefinition,
  goddessWithCactusArmsDefinition,
  halfHandstandCompressionWithFootOnTableDefinition,
  halfPikeHandstandWithFootOnTableDefinition,
  halfStraddleHandstandWithFootOnTableDefinition,
  halfTuckHandstandDefinition,
  handsAtHeartCenterDefinition,
  handsDownDefinition,
  handsOnEarsDefinition,
  handsOnGroundDefinition,
  handsOnHipsDefinition,
  handsOnKneesDefinition,
  handsOnShouldersDefinition,
  handsOnToesDefinition,
  handsUpDefinition,
  handstandAtTheWallDefinition,
  handstandChestToWallDefinition,
  handstandKickUpPrepDefinition,
  handstandPrepDefinition,
  handstandDefinition,
  happyBabyDefinition,
  headLevelDefinition,
  highVDefinition,
  horseDefinition,
  kneePlankDefinition,
  kneelingWithArmsRaisedDefinition,
  layingOnBackWithStraightLegsSlightlyRaisedDefinition,
  layingWithStraightLegsLiftedTo90DegreesDefinition,
  lowDownwardDogPushUpDefinition,
  lowKneePushUpDefinition,
  lowPushUpDefinition,
  lungeLeftDefinition,
  lungeRightDefinition,
  modifiedLowPushUpDefinition,
  modifiedPushUpDefinition,
  mountainDefinition,
  narrowDownwardDogDefinition,
  pancakeOnTheWallDefinition,
  partialRangeSquatWithArmsRaisedDefinition,
  partialRangeSquatDefinition,
  pikeHandstandLeftHandToShoulderWithFeetOnChairDefinition,
  pikeHandstandRightHandToShoulderWithFeetOnChairDefinition,
  pikeHandstandWithBentKneesAndFeetOnWallDefinition,
  pikeHandstandWithFeetOnChairDefinition,
  pikeHandstandWithFeetOnTableDefinition,
  plankWithFeetElevatedDefinition,
  plankWithHipsHighDefinition,
  plankWithLeftHandToShoulderDefinition,
  plankWithLeftKneeToChestDefinition,
  plankWithRightHandToShoulderDefinition,
  plankWithRightKneeToChestDefinition,
  plankDefinition,
  singleLegStanceDefinition,
  sissySquatDefinition,
  sphinxDefinition,
  squatWithArmsRaisedDefinition,
  squatDefinition,
  standWithArmsRaisedDefinition,
  standWithLeftKneeRaisedDefinition,
  standWithRightKneeRaisedDefinition,
  standDefinition,
  standingCrabWalkLeftDefinition,
  standingCrabWalkRightDefinition,
  standingForwardFoldDefinition,
  standingShoulderOpenerWithHandsOnTableDefinition,
  straddleForwardFoldDefinition,
  straddleHandstandDefinition,
  tPoseDefinition,
  takeAKneeDefinition,
  threeBlocksChestExerciseDefinition,
  treeDefinition,
  tuckHandstandDefinition,
  tuckPlancheDefinition,
  upwardDogDefinition,
  warrior1Definition,
  warrior2Definition,
  wheelDefinition,
];
const poseIconsMap = new Map<string, boolean>([
  ["all-fours", true],
  ["at-least-one-hand-up", true],
  ["bear", true],
  ["boat", true],
  ["butterfly", true],
  ["cactus-arms", true],
  ["chair-handstand-compression-alternating-legs", true],
  ["childs-pose", true],
  ["corpse", true],
  ["couch-stretch", true],
  ["crow", true],
  ["deep-squat-with-arms-raised", true],
  ["deep-squat", true],
  ["dolphin", true],
  ["downward-dog", true],
  ["eagle", true],
  ["easy-pose", true],
  ["extended-childs-pose", true],
  ["feet-elevated", true],
  ["forward-fold", true],
  ["front-splits", true],
  ["full-handstand", true],
  ["goddess-with-cactus-arms", true],
  ["half-handstand-compression-with-foot-on-table", true],
  ["half-pike-handstand-with-foot-on-table", true],
  ["half-straddle-handstand-with-foot-on-table", true],
  ["half-tuck-handstand", true],
  ["hands-at-heart-center", true],
  ["hands-down", true],
  ["hands-on-ears", true],
  ["hands-on-ground", true],
  ["hands-on-hips", true],
  ["hands-on-knees", true],
  ["hands-on-shoulders", true],
  ["hands-on-toes", true],
  ["hands-up", true],
  ["handstand-at-the-wall", true],
  ["handstand-chest-to-wall", true],
  ["handstand-kick-up-prep", true],
  ["handstand-prep", true],
  ["handstand", true],
  ["happy-baby", true],
  ["head-level", true],
  ["high-v", true],
  ["horse", true],
  ["knee-plank", true],
  ["kneeling-with-arms-raised", true],
  ["laying-on-back-with-straight-legs-slightly-raised", true],
  ["laying-with-straight-legs-lifted-to-90-degrees", true],
  ["low-downward-dog-push-up", true],
  ["low-knee-push-up", true],
  ["low-push-up", true],
  ["lunge-left", true],
  ["lunge-right", true],
  ["modified-low-push-up", true],
  ["modified-push-up", true],
  ["mountain", true],
  ["narrow-downward-dog", true],
  ["pancake-on-the-wall", true],
  ["partial-range-squat-with-arms-raised", true],
  ["partial-range-squat", true],
  ["pike-handstand-left-hand-to-shoulder-with-feet-on-chair", true],
  ["pike-handstand-right-hand-to-shoulder-with-feet-on-chair", true],
  ["pike-handstand-with-bent-knees-and-feet-on-wall", true],
  ["pike-handstand-with-feet-on-chair", true],
  ["pike-handstand-with-feet-on-table", true],
  ["plank-with-feet-elevated", true],
  ["plank-with-left-hand-to-shoulder", true],
  ["plank-with-left-knee-to-chest", true],
  ["plank-with-right-hand-to-shoulder", true],
  ["plank-with-right-knee-to-chest", true],
  ["plank", true],
  ["single-leg-stance", true],
  ["sissy-squat", true],
  ["sphinx", true],
  ["squat-with-arms-raised", true],
  ["squat", true],
  ["stand-with-arms-raised", true],
  ["stand-with-left-knee-raised", true],
  ["stand-with-right-knee-raised", true],
  ["stand", true],
  ["standing-crab-walk-left", true],
  ["standing-crab-walk-right", true],
  ["standing-forward-fold", true],
  ["standing-shoulder-opener-with-hands-on-table", true],
  ["straddle-forward-fold", true],
  ["straddle-handstand", true],
  ["t-pose", true],
  ["take-a-knee", true],
  ["three-blocks-chest-exercise", true],
  ["tree", true],
  ["tuck-handstand", true],
  ["tuck-planche", true],
  ["upward-dog", true],
  ["warrior-1", true],
  ["warrior-2", true],
  ["wheel", true],
]);
const poseDefinitionsMap = new Map<string, PoseDefinition>();
const poseEvaluationFunctionsMap = new Map<string, PoseDetectionFunction>();

for (const definition of definitions) {
  const validationResult = validate(definition);
  if (!validationResult) {
    console.error(
      "Pose Definition failed validation",
      (definition as PoseDefinition).name,
      validate.errors
    );
  }
  const definitionTyped = definition as PoseDefinition;

  // modify the definition to include the icon if not already specified and it exists
  const iconFile = poseIdToFileName(definitionTyped.name);
  if (!definitionTyped.icons && poseIconsMap.has(iconFile)) {
    definitionTyped.icons = [
      {
        src: `/icons/poses/${iconFile}.svg`,
        type: "image/svg+xml",
      },
    ];
  }
  poseDefinitionsMap.set(definitionTyped.name, definitionTyped);
  const poseEvaluationFunction =
    interpreter.interpretDefinition(definitionTyped);
  poseEvaluationFunctionsMap.set(definitionTyped.name, poseEvaluationFunction);
}

export const getPoseDefinition = (poseId: string): PoseDefinition => {
  const poseDefinition = poseDefinitionsMap.get(poseId);
  if (!poseDefinition) {
    throw new Error(`Pose ${poseId} not found`);
  }
  return poseDefinition;
};

export const getPoseEvaluationFunction = (
  poseId: string
): PoseDetectionFunction => {
  const poseEvaluationFunction = poseEvaluationFunctionsMap.get(poseId);
  if (!poseEvaluationFunction) {
    throw new Error(`Pose ${poseId} not found`);
  }
  return poseEvaluationFunction;
};
