import { Capacitor } from "@capacitor/core";
import { useToast } from "@chakra-ui/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../login/useAuthContext";

// Define interfaces for the response structure
interface Tag {
  id: number;
  name: string;
  created_at: string;
}

interface SubscribeResponse {
  tags: Tag[];
}

const getPlatformTag = (): number | null => {
  switch (Capacitor.getPlatform()) {
    case "ios":
      return 5313384;
    case "android":
      return 5313385;
    case "web":
      return 5313383;
    default:
      return null;
  }
};

// Get value of named cookie with TypeScript safety
function getCookie(name: string): string | undefined {
  const cookieArr = document.cookie.match(
    new RegExp("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")
  );
  return cookieArr ? cookieArr[2] : undefined;
}

// Get all subscription fields
const getFields = () => {
  const fields: Record<string, string | undefined> = {
    // Platform/App specific fields
    client_platform: Capacitor.getPlatform(),
    app_name: "handstand_timer",
    content_type: "in_app",

    // Current page info
    current_url: window.location.href,
    pathname: window.location.pathname,
    referrer_url: document.referrer || undefined,
  };

  // Add all query parameters from source_query cookie
  const sourceQuery = getCookie("source_query");
  if (sourceQuery) {
    const params = new URLSearchParams(sourceQuery);
    params.forEach((value, key) => {
      fields[key] = value;
    });
  }

  return fields;
};

export const useSubscriptionsState = () => {
  const [currentTags, setCurrentTags] = useState<number[]>([]);
  const [pendingTags, setPendingTags] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuthContext();
  const toast = useToast();
  const functions = getFunctions();

  useEffect(() => {
    // Set default tag based on current platform
    const platformTag = getPlatformTag();
    if (platformTag) {
      setPendingTags([platformTag]);
    }
  }, []);

  const fetchCurrentSubscriptions = useCallback(async () => {
    if (!user) {
      // setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      const getSubscribedEmailTags = httpsCallable(
        functions,
        "getSubscribedEmailTags"
      );
      const result = await getSubscribedEmailTags();
      if (result?.data) {
        const responseData = result.data as SubscribeResponse;
        const tagIds = responseData.tags?.map((tag: Tag) => tag.id);
        setCurrentTags(tagIds);
        // add the tagIds to the set of pending tags (if any)
        setPendingTags((prevTags) => [...new Set([...prevTags, ...tagIds])]);
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      toast({
        title: "Error fetching subscriptions",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [user, functions, toast]);

  useEffect(() => {
    fetchCurrentSubscriptions();
  }, [fetchCurrentSubscriptions]);

  const handleCurrentTagToggle = async (tagId: number) => {
    setIsLoading(true);
    try {
      if (currentTags.includes(tagId)) {
        const unsubscribeEmailTag = httpsCallable(
          functions,
          "unsubscribeEmailTag"
        );
        await unsubscribeEmailTag({ tagId });
        setCurrentTags(currentTags.filter((id) => id !== tagId));
      } else {
        const subscribeEmailTag = httpsCallable(functions, "subscribeEmailTag");
        await subscribeEmailTag({ tagId, fields: getFields() });
        setCurrentTags([...currentTags, tagId]);
      }
      toast({
        title: "Subscription updated",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating subscription:", error);
      toast({
        title: "Error updating subscription",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePendingTagToggle = async (tagId: number) => {
    // For pending mode, just update the pendingTags state
    setPendingTags((prev) =>
      prev.includes(tagId)
        ? prev.filter((id) => id !== tagId)
        : [...prev, tagId]
    );
  };

  const applyPendingChanges = async () => {
    setIsLoading(true);
    try {
      const tagsToAdd = pendingTags.filter((tag) => !currentTags.includes(tag));
      const tagsToRemove = currentTags.filter(
        (tag) => !pendingTags.includes(tag)
      );

      const subscribeEmailTag = httpsCallable(functions, "subscribeEmailTag");
      const unsubscribeEmailTag = httpsCallable(
        functions,
        "unsubscribeEmailTag"
      );

      const fields = getFields();

      for (const tagId of tagsToAdd) {
        await subscribeEmailTag({ tagId, fields });
      }

      for (const tagId of tagsToRemove) {
        await unsubscribeEmailTag({ tagId });
      }

      setCurrentTags(pendingTags);
      toast({
        title: "Subscriptions updated",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error applying subscription changes:", error);
      toast({
        title: "Error updating subscriptions",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    currentTags,
    pendingTags,
    isLoading,
    setIsLoading,
    handleCurrentTagToggle,
    handlePendingTagToggle,
    fetchCurrentSubscriptions,
    applyPendingChanges,
  };
};
