import {
  ButtonGroup,
  Button,
  Flex,
  Input,
  useFormControl,
  FormLabel,
  ContainerProps,
} from "@chakra-ui/react";
import { Key, useEffect, useState } from "react";

export type PickerWithCustomProps<T> = {
  standardValues: { label: string; value: T }[];
  initialValue: T;
  initialCustomValue: T;
  onValueChange: (value: T) => void;
  unit: string;
  convert: (value: string) => T;
  isDisabled?: boolean;
} & ContainerProps;

export function PickerWithCustom<T extends string | number | null | undefined>({
  standardValues,
  initialValue,
  initialCustomValue,
  onValueChange,
  unit,
  convert,
  isDisabled,
  ...props
}: PickerWithCustomProps<T>) {
  const [value, setValue] = useState<T>(initialValue);
  const initialUseCustomValue = !standardValues.some(
    ({ value }: { value: T }) => value === initialValue
  );
  const [customValue, setCustomValue] = useState(
    initialUseCustomValue ? initialValue : initialCustomValue
  );
  const [useCustomValue, setUseCustomValue] = useState(initialUseCustomValue);
  const formControl = useFormControl(props);

  useEffect(() => {
    const newValue = useCustomValue ? customValue : value;
    if (newValue !== initialValue) {
      onValueChange(newValue);
    }
  }, [value, customValue, useCustomValue]);

  return (
    <>
      <ButtonGroup
        isAttached={true}
        size="sm"
        variant="outline"
        flexWrap="wrap"
        {...props}
      >
        {standardValues.map(({ label, value }: { label: string; value: T }) => (
          <Button
            isDisabled={formControl.disabled}
            key={value}
            onClick={() => {
              setValue(value);
              setUseCustomValue(false);
            }}
            variant={
              value !== initialValue || useCustomValue ? "outline" : "solid"
            }
            colorScheme={
              value !== initialValue || useCustomValue ? "gray" : "blue"
            }
          >
            {label}
          </Button>
        ))}
        <Button
          isDisabled={formControl.disabled || isDisabled}
          variant={useCustomValue ? "solid" : "outline"}
          colorScheme={useCustomValue ? "blue" : "gray"}
          onClick={() => setUseCustomValue(true)}
        >
          custom
        </Button>
      </ButtonGroup>
      {useCustomValue && (
        <Flex alignItems="center" gap={3} marginTop={3}>
          <Input
            w="120px"
            type="number"
            value={customValue || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCustomValue(convert(e.target.value))
            }
            isDisabled={formControl.disabled || isDisabled}
            {...props}
          />
          <FormLabel>{unit}</FormLabel>
        </Flex>
      )}
    </>
  );
}
