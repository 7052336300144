import {
  Box,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  IconButtonProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  VStack,
  keyframes,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RiChatVoiceFill } from "react-icons/ri";
import { ControlsExpander } from "../../common/ControlsExpander";
import { SettingsContext } from "../../settings/SettingsContext";
import NonPoseIcon from "../icons/non-pose.svg?react";
import SpeechDisabled from "../icons/speech-disabled.svg?react";
import { GuidanceState } from "./DetectionGuidanceStateVisualization";
import { useDetectionGuidanceMuteInterval } from "./useDetectionGuidanceMuteInterval";

type DetectionGuidanceMuteControlProps = {
  expanded?: boolean;
  guidanceState?: GuidanceState;
  effectiveGuidanceInterval?: number;
} & Partial<IconButtonProps>;

/**
 * DetectionGuidanceMuteControl component renders a button to mute/unmute detection
 * guidance and a modal to select the mute duration.
 * @param props - The component props
 * @param [props.expanded] - Indicates if the mute control should be forced expanded (overrides the expanded setting)
 * @param [props.guidanceState] - The current guidance state
 * @param [props.effectiveGuidanceInterval] - The effective guidance interval in milliseconds
 * @returns The rendered DetectionGuidanceMuteControl component
 */
const DetectionGuidanceMuteControl: React.FC<
  DetectionGuidanceMuteControlProps
> = ({ expanded, guidanceState, effectiveGuidanceInterval, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("300000");

  const {
    setDetectionGuidanceMutedUntil,
    isPoseDetectionGuidanceEnabled,
    setIsPoseDetectionGuidanceEnabled,
    poseDetectionGuidanceControlsExpanded,
  } = useContext(SettingsContext);

  const isMuteIntervalActive = useDetectionGuidanceMuteInterval();

  const isGuidanceMuted = useMemo(() => {
    return isMuteIntervalActive || !isPoseDetectionGuidanceEnabled;
  }, [isMuteIntervalActive, isPoseDetectionGuidanceEnabled]);

  const handleOk = () => {
    if (selectedOption === "always") {
      setIsPoseDetectionGuidanceEnabled(false);
      setDetectionGuidanceMutedUntil(0);
    } else {
      setIsPoseDetectionGuidanceEnabled(true);
      const duration = parseInt(selectedOption, 10);
      setDetectionGuidanceMutedUntil(Date.now() + duration);
    }
    setIsOpen(false);
  };

  const handleMuteButtonClick = () => {
    if (isGuidanceMuted) {
      setIsPoseDetectionGuidanceEnabled(true);
      setDetectionGuidanceMutedUntil(0);
    } else {
      setIsOpen(true);
    }
  };

  const pulseAnimation = keyframes`
  0% { height: 0%; }
  3% { height: 20%; }
  7% { height: 10%; }
  12% { height: 40%; }
  18% { height: 0%; }
  25% { height: 50%; }
  30% { height: 20%; }
  34% { height: 60%; }
  40% { height: 0%; }
  48% { height: 70%; }
  55% { height: 30%; }
  62% { height: 80%; }
  68% { height: 50%; }
  75% { height: 90%; }
  80% { height: 60%; }
  86% { height: 0%; }
  92% { height: 40%; }
  97% { height: 20%; }
  100% { height: 0%; }
`;

  const [progress, setProgress] = useState(0);
  const guidanceIntervalStartAtRef = useRef<number | undefined>();

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (guidanceState === "warming" && effectiveGuidanceInterval) {
      guidanceIntervalStartAtRef.current = Date.now();
      setProgress(0);
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          const elapsed =
            Date.now() - (guidanceIntervalStartAtRef.current || 0);
          const newProgress = elapsed + 300;
          return newProgress >= effectiveGuidanceInterval
            ? effectiveGuidanceInterval
            : newProgress;
        });
      }, 100);
    } else {
      setProgress(0);
    }

    return () => {
      clearInterval(interval);
    };
  }, [guidanceState, effectiveGuidanceInterval]);

  return (
    <>
      <IconButton
        aria-label={
          isGuidanceMuted
            ? "Unmute detection guidance"
            : "Mute detection guidance"
        }
        paddingInline={1}
        variant="ghost"
        width="fit-content"
        icon={
          <>
            <Box position="relative" boxSize={6}>
              {(guidanceState === "warming" ||
                guidanceState === "preparing") && (
                <CircularProgress
                  value={progress}
                  max={effectiveGuidanceInterval}
                  size="28px"
                  thickness="17px"
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  color={guidanceState === "warming" ? "orange" : "yellow"}
                  isIndeterminate={guidanceState === "preparing"}
                  trackColor="transparent"
                />
              )}
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="80%"
                height={guidanceState === "speaking" ? "60%" : "0%"}
                backgroundColor="gray.100"
                borderRadius="full"
              />
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="80%"
                height="0%"
                backgroundColor="blue"
                borderRadius="full"
                animation={
                  guidanceState === "speaking"
                    ? `${pulseAnimation} 2s ease-in-out infinite`
                    : "none"
                }
              />
              <Icon
                as={isGuidanceMuted ? SpeechDisabled : RiChatVoiceFill}
                boxSize={6}
                position="relative"
                color="gray.100"
              />
            </Box>
            <Icon
              as={NonPoseIcon}
              boxSize={6}
              opacity={isGuidanceMuted ? 0.5 : 1}
              color="gray.100"
            />
          </>
        }
        onClick={handleMuteButtonClick}
        {...props}
      />
      <ControlsExpander
        expanded={expanded || poseDetectionGuidanceControlsExpanded}
      >
        <Button pl={1} variant="ghost" onClick={handleMuteButtonClick}>
          {isGuidanceMuted ? "Unmute guidance" : "Mute guidance"}
        </Button>
      </ControlsExpander>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <Box color="var(--chakra-colors-whiteAlpha-900)">
            <ModalHeader>Mute Detection Guidance</ModalHeader>
            <ModalBody>
              <RadioGroup value={selectedOption} onChange={setSelectedOption}>
                <VStack align="start">
                  <Radio value="5000">5 seconds</Radio>
                  <Radio value="60000">1 minute</Radio>
                  <Radio value="300000">5 minutes</Radio>
                  <Radio value="3600000">1 hour</Radio>
                  <Radio value="always">Always</Radio>
                </VStack>
              </RadioGroup>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleOk}>
                OK
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DetectionGuidanceMuteControl;
