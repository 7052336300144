import React, { useState, useCallback, useMemo } from "react";
import { SettingsContext, SettingsContextType } from "./SettingsContext";
import { Settings, defaultSettings } from "./settingsConfig";

type SettingsProviderProps = {
  children: React.ReactNode;
};

export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children,
}) => {
  const [settings, setSettingsState] = useState<Settings>(() => {
    const storedSettings: Partial<Settings> = {};
    for (const key in defaultSettings) {
      try {
        const storedValue = localStorage.getItem(key);
        if (storedValue !== null) {
          const parsedValue = JSON.parse(storedValue);
          // Only store value if it matches the type in defaultSettings
          if (
            typeof parsedValue === typeof defaultSettings[key as keyof Settings]
          ) {
            storedSettings[key as keyof Settings] = parsedValue;
          } else {
            console.warn(`Invalid type for setting "${key}" in localStorage`);
            localStorage.removeItem(key);
          }
        }
      } catch (error) {
        console.warn(
          `Error parsing setting "${key}" from localStorage:`,
          error
        );
        localStorage.removeItem(key);
      }
    }
    return { ...defaultSettings, ...storedSettings };
  });

  const setSettings = useCallback(
    <K extends keyof Settings>(key: K, value: Settings[K]) => {
      setSettingsState((prev) => {
        const newSettings = { ...prev, [key]: value };
        localStorage.setItem(key, JSON.stringify(value));
        return newSettings;
      });
    },
    []
  );

  const resetAllSettings = useCallback(() => {
    setSettingsState(defaultSettings);
    for (const key in defaultSettings) {
      localStorage.removeItem(key);
    }
  }, []);

  const contextValue = useMemo(() => {
    const setters = {} as Pick<
      SettingsContextType,
      `set${Capitalize<keyof Settings & string>}`
    >;

    for (const key in settings) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      const setterKey = `set${capitalizedKey}` as `set${Capitalize<
        keyof Settings & string
      >}`;
      setters[setterKey] = (value: any) =>
        setSettings(key as keyof Settings, value);
    }

    return {
      ...settings,
      ...setters,
      resetAllSettings,
    } as SettingsContextType;
  }, [settings, setSettings, resetAllSettings]);

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};
