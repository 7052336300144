import { PoseDetectionFunction } from "./PoseDetectionFunction";
import {
  Criterion,
  CriterionJointPair,
  CriterionLandmarkPair,
  CriterionLandmarkPairs,
  CriterionOneJoint,
  CriterionOneLandmark,
  PoseDefinition,
} from "../generated/PoseDefinition";
import { JointAngle } from "./pose-utils";
import {
  POSE_LANDMARKS_LEFT,
  POSE_LANDMARKS_RIGHT,
  POSE_LANDMARKS,
} from "./landmarks";
import { PoseCriteriaEvaluator } from "./pose-criteria-evaluator";
import { PoseDetectionFeedback } from "./pose-detection-feedback";
import { NormalizedLandmark } from "@mediapipe/tasks-vision";

export type CriterionForEvaluator = {
  criterion: Criterion;
  type: string;
  feedbackKey: string;
  side?: "left" | "right" | undefined;
  jointPair?: [number, number];
  jointPairVisible?: [[number, number, number], [number, number, number]];
  landmarkPair?: [number, number];
  landmarkPairs?: [[number, number], [number, number]];
  thresholdAngle?: number;
  thresholdDistance?: number;
  thresholdDelta?: number;
  onlyRequireIfVisible?: boolean;
};

type JointPairMap = {
  [key: string]: [number, number];
};

const jointPairMap: JointPairMap = {
  ankles: [POSE_LANDMARKS_LEFT.LEFT_ANKLE, POSE_LANDMARKS_RIGHT.RIGHT_ANKLE],
  knees: [POSE_LANDMARKS_LEFT.LEFT_KNEE, POSE_LANDMARKS_RIGHT.RIGHT_KNEE],
  hips: [POSE_LANDMARKS.LEFT_HIP, POSE_LANDMARKS.RIGHT_HIP],
  shoulders: [POSE_LANDMARKS.LEFT_SHOULDER, POSE_LANDMARKS.RIGHT_SHOULDER],
  elbows: [POSE_LANDMARKS_LEFT.LEFT_ELBOW, POSE_LANDMARKS_RIGHT.RIGHT_ELBOW],
  wrists: [POSE_LANDMARKS_LEFT.LEFT_WRIST, POSE_LANDMARKS_RIGHT.RIGHT_WRIST],
  pinkies: [POSE_LANDMARKS_LEFT.LEFT_PINKY, POSE_LANDMARKS_RIGHT.RIGHT_PINKY],
};

type JointPairVisibleMap = {
  [key: string]: [[number, number, number], [number, number, number]];
};

const jointPairVisibleMap: JointPairVisibleMap = {
  ankles: [
    [
      POSE_LANDMARKS_LEFT.LEFT_FOOT_INDEX,
      POSE_LANDMARKS_LEFT.LEFT_ANKLE,
      POSE_LANDMARKS_LEFT.LEFT_KNEE,
    ],
    [
      POSE_LANDMARKS_RIGHT.RIGHT_FOOT_INDEX,
      POSE_LANDMARKS_RIGHT.RIGHT_ANKLE,
      POSE_LANDMARKS_RIGHT.RIGHT_KNEE,
    ],
  ],
  knees: [
    [
      POSE_LANDMARKS_LEFT.LEFT_ANKLE,
      POSE_LANDMARKS_LEFT.LEFT_KNEE,
      POSE_LANDMARKS_LEFT.LEFT_HIP,
    ],
    [
      POSE_LANDMARKS_RIGHT.RIGHT_ANKLE,
      POSE_LANDMARKS_RIGHT.RIGHT_KNEE,
      POSE_LANDMARKS_RIGHT.RIGHT_HIP,
    ],
  ],
  hips: [
    [
      POSE_LANDMARKS_LEFT.LEFT_KNEE,
      POSE_LANDMARKS.LEFT_HIP,
      POSE_LANDMARKS.LEFT_SHOULDER,
    ],
    [
      POSE_LANDMARKS_RIGHT.RIGHT_KNEE,
      POSE_LANDMARKS.RIGHT_HIP,
      POSE_LANDMARKS.RIGHT_SHOULDER,
    ],
  ],
  shoulders: [
    [
      POSE_LANDMARKS.LEFT_HIP,
      POSE_LANDMARKS.LEFT_SHOULDER,
      POSE_LANDMARKS.LEFT_ELBOW,
    ],
    [
      POSE_LANDMARKS.RIGHT_HIP,
      POSE_LANDMARKS.RIGHT_SHOULDER,
      POSE_LANDMARKS.RIGHT_ELBOW,
    ],
  ],
  elbows: [
    [
      POSE_LANDMARKS_LEFT.LEFT_SHOULDER,
      POSE_LANDMARKS_LEFT.LEFT_ELBOW,
      POSE_LANDMARKS_LEFT.LEFT_WRIST,
    ],
    [
      POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER,
      POSE_LANDMARKS_RIGHT.RIGHT_ELBOW,
      POSE_LANDMARKS_RIGHT.RIGHT_WRIST,
    ],
  ],
  wrists: [
    [
      POSE_LANDMARKS_LEFT.LEFT_ELBOW,
      POSE_LANDMARKS_LEFT.LEFT_WRIST,
      POSE_LANDMARKS_LEFT.LEFT_INDEX,
    ],
    [
      POSE_LANDMARKS_RIGHT.RIGHT_ELBOW,
      POSE_LANDMARKS_RIGHT.RIGHT_WRIST,
      POSE_LANDMARKS_RIGHT.RIGHT_INDEX,
    ],
  ],
};

const landmarkPairMap: JointPairMap = {
  ...jointPairMap,

  eyeInners: [
    POSE_LANDMARKS_LEFT.LEFT_EYE_INNER,
    POSE_LANDMARKS_RIGHT.RIGHT_EYE_INNER,
  ],
  eyes: [POSE_LANDMARKS_LEFT.LEFT_EYE, POSE_LANDMARKS_RIGHT.RIGHT_EYE],
  eyeOuters: [
    POSE_LANDMARKS_LEFT.LEFT_EYE_OUTER,
    POSE_LANDMARKS_RIGHT.RIGHT_EYE_OUTER,
  ],
  ears: [POSE_LANDMARKS_LEFT.LEFT_EAR, POSE_LANDMARKS_RIGHT.RIGHT_EAR],
  mouth: [POSE_LANDMARKS.MOUTH_LEFT, POSE_LANDMARKS.MOUTH_RIGHT],
  shoulders: [
    POSE_LANDMARKS_LEFT.LEFT_SHOULDER,
    POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER,
  ],
  elbows: [POSE_LANDMARKS_LEFT.LEFT_ELBOW, POSE_LANDMARKS_RIGHT.RIGHT_ELBOW],
  wrists: [POSE_LANDMARKS_LEFT.LEFT_WRIST, POSE_LANDMARKS_RIGHT.RIGHT_WRIST],
  pinkies: [POSE_LANDMARKS_LEFT.LEFT_PINKY, POSE_LANDMARKS_RIGHT.RIGHT_PINKY],
  indexes: [POSE_LANDMARKS_LEFT.LEFT_INDEX, POSE_LANDMARKS_RIGHT.RIGHT_INDEX],
  indexs: [POSE_LANDMARKS_LEFT.LEFT_INDEX, POSE_LANDMARKS_RIGHT.RIGHT_INDEX],
  indices: [POSE_LANDMARKS_LEFT.LEFT_INDEX, POSE_LANDMARKS_RIGHT.RIGHT_INDEX],
  hands: [POSE_LANDMARKS_LEFT.LEFT_INDEX, POSE_LANDMARKS_RIGHT.RIGHT_INDEX],
  nose: [POSE_LANDMARKS.NOSE, POSE_LANDMARKS.NOSE],
  thumbs: [POSE_LANDMARKS_LEFT.LEFT_THUMB, POSE_LANDMARKS_RIGHT.RIGHT_THUMB],
  hips: [POSE_LANDMARKS_LEFT.LEFT_HIP, POSE_LANDMARKS_RIGHT.RIGHT_HIP],
  knees: [POSE_LANDMARKS_LEFT.LEFT_KNEE, POSE_LANDMARKS_RIGHT.RIGHT_KNEE],
  ankles: [POSE_LANDMARKS_LEFT.LEFT_ANKLE, POSE_LANDMARKS_RIGHT.RIGHT_ANKLE],
  heels: [POSE_LANDMARKS_LEFT.LEFT_HEEL, POSE_LANDMARKS_RIGHT.RIGHT_HEEL],
  feet: [
    POSE_LANDMARKS_LEFT.LEFT_FOOT_INDEX,
    POSE_LANDMARKS_RIGHT.RIGHT_FOOT_INDEX,
  ],
  toes: [
    POSE_LANDMARKS_LEFT.LEFT_FOOT_INDEX,
    POSE_LANDMARKS_RIGHT.RIGHT_FOOT_INDEX,
  ],
};
export class PoseDefinitionInterpreter {
  interpretDefinition(poseDefinition: PoseDefinition): PoseDetectionFunction {
    // parse the criteria from the definition, performing necessary conversions
    const criteriaForEvaluator: CriterionForEvaluator[] =
      poseDefinition.criteria.map((criterion) => {
        const criterionJointPair = criterion as CriterionJointPair;
        const criterionOneJoint = criterion as CriterionOneJoint;
        const criterionLandmarkPair = criterion as CriterionLandmarkPair;
        const criterionOneLandmark = criterion as CriterionOneLandmark;
        const criterionLandmarkPairs = criterion as CriterionLandmarkPairs;
        return {
          type: criterion.type,
          feedbackKey: criterion.feedbackKey,
          criterion,
          side: criterionOneJoint.side || criterionOneLandmark.side,
          jointPair: criterionJointPair.jointPair
            ? jointPairMap[criterionJointPair.jointPair]
            : undefined,
          jointPairVisible: criterionJointPair.jointPair
            ? jointPairVisibleMap[criterionJointPair.jointPair]
            : undefined,
          landmarkPair: criterionLandmarkPair.landmarkPair
            ? landmarkPairMap[criterionLandmarkPair.landmarkPair]
            : undefined,
          landmarkPairs: criterionLandmarkPairs.landmarkPairs
            ? [
                landmarkPairMap[criterionLandmarkPairs.landmarkPairs[0]],
                landmarkPairMap[criterionLandmarkPairs.landmarkPairs[1]],
              ]
            : undefined,
          thresholdAngle: criterionJointPair.thresholdAngle,
          thresholdDistance:
            criterionLandmarkPair.thresholdDistance ||
            criterionLandmarkPairs.thresholdDistance,
          thresholdDelta: criterion.thresholdDelta,
          onlyRequireIfVisible: criterion.onlyRequireIfVisible,
        };
      });

    const evaluatePoseLandmarks = (
      isInPose: boolean,
      poseLandmarks: NormalizedLandmark[],
      jointAnglesMap: { [key: number]: JointAngle },
      poseDetectionFeedback: PoseDetectionFeedback,
      visibilityThreshold: number,
      configuration: Record<string, number>
    ): boolean => {
      if (!poseLandmarks) return false;

      const poseCriteriaEvaluator = new PoseCriteriaEvaluator(
        isInPose,
        poseLandmarks,
        jointAnglesMap,
        poseDetectionFeedback,
        visibilityThreshold
      );

      return criteriaForEvaluator.reduce((isMet, criterion) => {
        let result = false;
        // For now, the only configurable parameter is the threshold distance for handstand and hands up
        const configuredStartThreshold =
          (poseDefinition.name === "handstand" ||
            poseDefinition.name === "hands up") &&
          criterion.type === "LandmarksAbove"
            ? configuration.raisedPoseStartThreshold
            : undefined;
        const startThreshold = criterion.jointPair
          ? criterion.thresholdAngle !== undefined
            ? Math.PI * criterion.thresholdAngle
            : undefined
          : criterion.thresholdDistance === undefined
          ? configuredStartThreshold
          : criterion.thresholdDistance;

        const thresholdDelta =
          criterion.thresholdDelta !== undefined
            ? criterion.thresholdDelta
            : criterion.jointPair
            ? Math.PI * 0.1
            : 0.1;
        const endThreshold =
          startThreshold === undefined
            ? undefined
            : startThreshold - thresholdDelta;
        switch (criterion.type) {
          case "JointsStraight":
            if (!criterion.jointPairVisible)
              throw new Error("jointPairVisible not defined");
            result = poseCriteriaEvaluator.checkJointsStraight(
              criterion,
              criterion.feedbackKey,
              criterion.jointPairVisible[0],
              criterion.jointPairVisible[1],
              startThreshold,
              endThreshold
            );
            break;
          case "JointsBent":
            if (!criterion.jointPairVisible)
              throw new Error("jointPairVisible not defined");
            result = poseCriteriaEvaluator.checkJointsBent(
              criterion,
              criterion.feedbackKey,
              criterion.jointPairVisible[0],
              criterion.jointPairVisible[1],
              startThreshold,
              endThreshold
            );
            break;
          case "OneJointStraight":
            if (!criterion.jointPairVisible)
              throw new Error("jointPairVisible not defined");
            result = poseCriteriaEvaluator.checkOneJointStraight(
              criterion,
              criterion.feedbackKey,
              criterion.side,
              criterion.jointPairVisible[0],
              criterion.jointPairVisible[1],
              startThreshold,
              endThreshold
            );
            break;
          case "OneJointBent":
            if (!criterion.jointPairVisible)
              throw new Error("jointPairVisible not defined");
            result = poseCriteriaEvaluator.checkOneJointBent(
              criterion,
              criterion.feedbackKey,
              criterion.side,
              criterion.jointPairVisible[0],
              criterion.jointPairVisible[1],
              startThreshold,
              endThreshold
            );
            break;
          case "LandmarksApart":
            if (!criterion.landmarkPair)
              throw new Error("Landmark pair not defined");
            result = poseCriteriaEvaluator.checkLandmarksApart(
              criterion,
              criterion.feedbackKey,
              criterion.landmarkPair,
              startThreshold,
              endThreshold
            );
            break;
          case "LandmarksTogether":
            if (!criterion.landmarkPair)
              throw new Error("Landmark pair not defined");
            result = poseCriteriaEvaluator.checkLandmarksTogether(
              criterion,
              criterion.feedbackKey,
              criterion.landmarkPair,
              startThreshold,
              endThreshold
            );
            break;
          case "MassCenteredOn":
            if (!criterion.landmarkPair)
              throw new Error("Landmark pair not defined");
            result = poseCriteriaEvaluator.checkMassCenteredOn(
              criterion,
              criterion.feedbackKey,
              criterion.landmarkPair,
              startThreshold,
              endThreshold
            );
            break;
          case "OneLandmarkHigher":
            if (!criterion.landmarkPair)
              throw new Error("Landmark pair not defined");
            result = poseCriteriaEvaluator.checkOneLandmarkHigher(
              criterion,
              criterion.feedbackKey,
              criterion.side,
              criterion.landmarkPair,
              startThreshold,
              endThreshold
            );
            break;
          case "OneLandmarkInFront":
            if (!criterion.landmarkPair)
              throw new Error("Landmark pair not defined");
            result = poseCriteriaEvaluator.checkOneLandmarkInFront(
              criterion,
              criterion.feedbackKey,
              criterion.side,
              criterion.landmarkPair,
              startThreshold,
              endThreshold
            );
            break;
          case "LandmarksStacked":
            if (!criterion.landmarkPairs)
              throw new Error("Landmark pairs not defined");
            result = poseCriteriaEvaluator.checkLandmarksStacked(
              criterion,
              criterion.feedbackKey,
              // checkLandmarksStacked expects left, right
              [criterion.landmarkPairs[0][0], criterion.landmarkPairs[1][0]],
              [criterion.landmarkPairs[0][1], criterion.landmarkPairs[1][1]],
              startThreshold,
              endThreshold
            );
            break;
          case "LandmarksAbove":
            if (!criterion.landmarkPairs)
              throw new Error("Landmark pairs not defined");
            result = poseCriteriaEvaluator.checkLandmarksAbove(
              criterion,
              criterion.feedbackKey,
              criterion.landmarkPairs[1],
              criterion.landmarkPairs[0],
              startThreshold,
              endThreshold
            );
            break;
          case "LandmarksAboveLowest":
            if (!criterion.landmarkPairs)
              throw new Error("Landmark pairs not defined");
            result = poseCriteriaEvaluator.checkLandmarksAboveLowest(
              criterion,
              criterion.feedbackKey,
              criterion.landmarkPairs[1],
              criterion.landmarkPairs[0],
              startThreshold,
              endThreshold
            );
            break;
          case "LandmarksBelowHighest":
            if (!criterion.landmarkPairs)
              throw new Error("Landmark pairs not defined");
            result = poseCriteriaEvaluator.checkLandmarksBelowHighest(
              criterion,
              criterion.feedbackKey,
              criterion.landmarkPairs[0],
              criterion.landmarkPairs[1],
              startThreshold,
              endThreshold
            );
            break;
          case "LandmarksSameHeight":
            if (!criterion.landmarkPairs)
              throw new Error("Landmark pairs not defined");
            result = poseCriteriaEvaluator.checkLandmarksSameHeight(
              criterion,
              criterion.feedbackKey,
              criterion.landmarkPairs[0],
              criterion.landmarkPairs[1],
              startThreshold,
              endThreshold
            );
            break;
          case "LandmarkPairSameHeight":
            if (!criterion.landmarkPair)
              throw new Error("Landmark pair not defined");
            result = poseCriteriaEvaluator.checkLandmarkPairSameHeight(
              criterion,
              criterion.feedbackKey,
              criterion.landmarkPair,
              startThreshold,
              endThreshold
            );
            break;
          case "LandmarksTouching":
            if (!criterion.landmarkPairs)
              throw new Error("Landmark pairs not defined");
            result = poseCriteriaEvaluator.checkLandmarksTouching(
              criterion,
              criterion.feedbackKey,
              criterion.landmarkPairs[0],
              criterion.landmarkPairs[1],
              startThreshold,
              endThreshold
            );
            break;
          default:
            console.warn(`Unknown criterion type: ${criterion.type}`);
            break;
        }
        return isMet && result;
      }, true);
    };

    return evaluatePoseLandmarks;
  }
}
