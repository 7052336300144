import { useCallback } from "react";
import { TextToSpeechUtterancesDao } from "../../services/firestore/TextToSpeechUtterancesDao";
import { TextToSpeechUtteranceRequest, getUtteranceId } from "./TextToSpeech";

export const DELAY_BETWEEN_UTTERANCE_CREATION = 2000;

/**
 * The return type of the useUtteranceCreator hook.
 */
type UseUtteranceCreatorResult = {
  /**
   * Creates a new TextToSpeechUtteranceRequest object.
   *
   * @param text - The text of the utterance.
   * @param contextProps - Additional context properties to be included in the utterance request.
   * @returns The created TextToSpeechUtteranceRequest object.
   */
  createUtterance: (
    text: string,
    contextProps?: { [key: string]: any }
  ) => TextToSpeechUtteranceRequest;

  /**
   * Saves an array of TextToSpeechUtteranceRequest objects to Firestore.
   *
   * @param utterances - The array of utterances to be saved.
   * @returns A promise that resolves to an array of utterance hashes.
   */
  saveUtterances: (
    utterances: TextToSpeechUtteranceRequest[]
  ) => Promise<string[]>;
};

/**
 * Hook that provides callbacks for creating and saving TextToSpeechUtteranceRequest objects.
 *
 * @param context - The context object to be included in the utterance request.
 * @param voice - The optional voice to be used for the utterance.
 * @returns An object containing `createUtterance` and `saveUtterances` callbacks.
 */
export function useUtteranceCreator<
  T extends { type: string; [key: string]: any }
>(context: T, voice?: string): UseUtteranceCreatorResult {
  const createUtterance = useCallback(
    (
      text: string,
      contextProps?: { [key: string]: any }
    ): TextToSpeechUtteranceRequest => {
      return {
        text,
        ...(voice ? { voice } : {}),
        context: {
          ...context,
          ...(contextProps || {}),
        },
      };
    },
    [context, voice]
  );

  const saveUtterances = useCallback(
    async (utterances: TextToSpeechUtteranceRequest[]) => {
      const textToSpeechUtterancesDao = new TextToSpeechUtterancesDao();
      const newUtteranceHashes = utterances.map((utterance) =>
        getUtteranceId(utterance)
      );

      for (const utterance of utterances) {
        await textToSpeechUtterancesDao.saveTextToSpeechUtterance(utterance);
        await new Promise((resolve) =>
          setTimeout(resolve, DELAY_BETWEEN_UTTERANCE_CREATION)
        );
      }

      return newUtteranceHashes;
    },
    []
  );

  return { createUtterance, saveUtterances };
}
