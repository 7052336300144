import { GuidanceStyle } from "./GuidanceStyle";
import { GuidanceTemplateType } from "./GuidanceTemplateType";

export const guidanceTemplates: Record<
  GuidanceStyle,
  Record<GuidanceTemplateType, string[]>
> = {
  direct: {
    [GuidanceTemplateType.Visibility]: [
      "Ensure that your {landmarksPhrase} are visible",
    ],
    [GuidanceTemplateType.JointsBent]: ["Bend your {jointPair}."],
    [GuidanceTemplateType.JointsStraight]: ["Straighten your {jointPair}."],
    [GuidanceTemplateType.OneJointBent]: ["Bend {sidedPartPhrase}."],
    [GuidanceTemplateType.OneJointStraight]: ["Straighten {sidedPartPhrase}."],
    [GuidanceTemplateType.LandmarksApart]: ["Move your {landmarkPair} apart."],
    [GuidanceTemplateType.LandmarksTogether]: [
      "Bring your {landmarkPair} together.",
    ],
    [GuidanceTemplateType.LandmarkPairSameHeight]: [
      "Align your {landmarkPair} at the same height.",
    ],
    [GuidanceTemplateType.MassCenteredOn]: [
      "Center your body over your {landmarkPair}.",
    ],
    [GuidanceTemplateType.OneLandmarkHigher]: ["Raise {sidedPartPhrase}."],
    [GuidanceTemplateType.OneLandmarkInFront]: [
      "Move {sidedPartPhrase} forward.",
    ],
    [GuidanceTemplateType.LandmarksAbove]: [
      "Position your {pair1} {aboveText}above your {pair2}.",
    ],
    [GuidanceTemplateType.LandmarksAboveLowest]: [
      "Position your {pair1} {aboveText}above your {pair2}.",
    ],
    [GuidanceTemplateType.LandmarksStacked]: [
      "Stack your {pair1} over your {pair2}.",
    ],
    [GuidanceTemplateType.LandmarksBelowHighest]: [
      "Position a {singularPair2} above your {pair1}.",
    ],
    [GuidanceTemplateType.LandmarksSameHeight]: [
      "Position your {pair1} at the same height as your {pair2}.",
    ],
    [GuidanceTemplateType.LandmarksTouching]: [
      "Position your {pair1} touching your {pair2}.",
    ],
  },
  concise: {
    [GuidanceTemplateType.Visibility]: ["{landmarksPhrase} not visible"],
    [GuidanceTemplateType.JointsBent]: ["Bend {jointPair}"],
    [GuidanceTemplateType.JointsStraight]: ["Straighten {jointPair}"],
    [GuidanceTemplateType.OneJointBent]: ["Bend {sidedPartPhrase}"],
    [GuidanceTemplateType.OneJointStraight]: ["Straighten {sidedPartPhrase}"],
    [GuidanceTemplateType.LandmarksApart]: ["{landmarkPair} apart"],
    [GuidanceTemplateType.LandmarksTogether]: ["{landmarkPair} together"],
    [GuidanceTemplateType.LandmarkPairSameHeight]: [
      "{landmarkPair} same height",
    ],
    [GuidanceTemplateType.MassCenteredOn]: ["Center over {landmarkPair}"],
    [GuidanceTemplateType.OneLandmarkHigher]: ["Raise {sidedPartPhrase}"],
    [GuidanceTemplateType.OneLandmarkInFront]: ["{sidedPartPhrase} forward"],
    [GuidanceTemplateType.LandmarksAbove]: ["{pair1} {aboveText}above {pair2}"],
    [GuidanceTemplateType.LandmarksAboveLowest]: [
      "{pair1} {aboveText}above {pair2}",
    ],
    [GuidanceTemplateType.LandmarksStacked]: ["{pair1} over {pair2}"],
    [GuidanceTemplateType.LandmarksBelowHighest]: [
      "{singularPair2} above {pair1}",
    ],
    [GuidanceTemplateType.LandmarksSameHeight]: [
      "{pair1} same height as {pair2}",
    ],
    [GuidanceTemplateType.LandmarksTouching]: ["{pair1} touching {pair2}"],
  },
  questioning: {
    [GuidanceTemplateType.Visibility]: [
      "Could you make your {landmarksPhrase} visible?",
    ],
    [GuidanceTemplateType.JointsBent]: ["Could you bend your {jointPair}?"],
    [GuidanceTemplateType.JointsStraight]: [
      "Could you straighten your {jointPair}?",
    ],
    [GuidanceTemplateType.OneJointBent]: ["Could you bend {sidedPartPhrase}?"],
    [GuidanceTemplateType.OneJointStraight]: [
      "Could you straighten {sidedPartPhrase}?",
    ],
    [GuidanceTemplateType.LandmarksApart]: [
      "Could you move your {landmarkPair} apart?",
    ],
    [GuidanceTemplateType.LandmarksTogether]: [
      "Could you bring your {landmarkPair} together?",
    ],
    [GuidanceTemplateType.LandmarkPairSameHeight]: [
      "Could you align your {landmarkPair} at the same height?",
    ],
    [GuidanceTemplateType.MassCenteredOn]: [
      "Could you center your body over your {landmarkPair}?",
    ],
    [GuidanceTemplateType.OneLandmarkHigher]: [
      "Could you raise {sidedPartPhrase}?",
    ],
    [GuidanceTemplateType.OneLandmarkInFront]: [
      "Could you move {sidedPartPhrase} forward?",
    ],
    [GuidanceTemplateType.LandmarksAbove]: [
      "Could you position your {pair1} {aboveText}above your {pair2}?",
    ],
    [GuidanceTemplateType.LandmarksAboveLowest]: [
      "Could you position your {pair1} {aboveText}above your {pair2}?",
    ],
    [GuidanceTemplateType.LandmarksStacked]: [
      "Could you stack your {pair1} over your {pair2}?",
    ],
    [GuidanceTemplateType.LandmarksBelowHighest]: [
      "Could you position a {singularPair2} above your {pair1}?",
    ],
    [GuidanceTemplateType.LandmarksSameHeight]: [
      "Could you position your {pair1} at the same height as your {pair2}?",
    ],
    [GuidanceTemplateType.LandmarksTouching]: [
      "Could you position your {pair1} touching your {pair2}?",
    ],
  },
  friendly: {
    [GuidanceTemplateType.Visibility]: [
      "Hey there, could you please make sure your {landmarksPhrase} are visible?",
      "Your {landmarksPhrase} aren't sufficiently visible yet.",
      "Try moving to ensure that the camera can see your {landmarksPhrase}",
      "Sorry, but I can't see your {landmarksPhrase} clearly. Could you please try moving your body or the camera?",
      "We need your {landmarksPhrase} to be visible to give you feedback.",
    ],
    [GuidanceTemplateType.JointsBent]: [
      "Hey, try bending your {jointPair} for me!",
      "It seems that your {jointPair} are not bent enough.",
      "Now please bend your {jointPair}.",
      "Would you please try to bend your {jointPair} further?",
      "For me to detect this pose, I need to see more bend in your {jointPair}.",
    ],
    [GuidanceTemplateType.JointsStraight]: [
      "Hey, could you straighten your {jointPair} for me?",
      "It looks like your {jointPair} are not fully straightened.",
      "Now let's focus on straightening your {jointPair}.",
      "Would you mind straightening your {jointPair} a bit more?",
      "To achieve this pose, your {jointPair} should be nice and straight.",
    ],
    [GuidanceTemplateType.OneJointBent]: [
      "Hey, could you bend {sidedPartPhrase} for me?",
      "It seems that {sidedPartPhrase} is not bent enough.",
      "Now please focus on bending {sidedPartPhrase}.",
      "Would you mind bending {sidedPartPhrase} a bit further?",
      "To nail this pose, I need to see more bend in {sidedPartPhrase}.",
    ],
    [GuidanceTemplateType.OneJointStraight]: [
      "Hey, could you straighten {sidedPartPhrase} for me?",
      "It looks like {sidedPartPhrase} is not fully straightened.",
      "Now let's work on straightening {sidedPartPhrase}.",
      "Would you please try to straighten {sidedPartPhrase} a bit more?",
      "For this pose, {sidedPartPhrase} should be straight and aligned.",
    ],
    [GuidanceTemplateType.LandmarksApart]: [
      "Hey, could you move your {landmarkPair} further apart?",
      "It seems that your {landmarkPair} are a bit too close together.",
      "Now let's focus on separating your {landmarkPair}.",
      "Would you mind moving your {landmarkPair} a bit further apart?",
      "To achieve this pose, your {landmarkPair} should be comfortably apart.",
    ],
    [GuidanceTemplateType.LandmarksTogether]: [
      "Hey, could you bring your {landmarkPair} closer together?",
      "It looks like your {landmarkPair} are a bit too far apart.",
      "Now please focus on bringing your {landmarkPair} together.",
      "Would you mind moving your {landmarkPair} a bit closer?",
      "For this pose, your {landmarkPair} should be comfortably close together.",
    ],
    [GuidanceTemplateType.LandmarkPairSameHeight]: [
      "Hey, could you align your {landmarkPair} at the same height?",
      "It seems that your {landmarkPair} are not quite at the same height.",
      "Now let's work on aligning your {landmarkPair} at the same level.",
      "Would you please try to adjust your {landmarkPair} to be at the same height?",
      "To nail this pose, your {landmarkPair} should be at the same height.",
    ],
    [GuidanceTemplateType.MassCenteredOn]: [
      "Hey, could you center your body over your {landmarkPair}?",
      "It looks like your body is not quite centered over your {landmarkPair}.",
      "Now please focus on centering your body over your {landmarkPair}.",
      "Would you mind adjusting your position to center your body over your {landmarkPair}?",
      "For this pose, your body should be nicely centered over your {landmarkPair}.",
    ],
    [GuidanceTemplateType.OneLandmarkHigher]: [
      "Hey, could you raise {sidedPartPhrase} a bit higher?",
      "It seems that {sidedPartPhrase} is not quite high enough.",
      "Now let's focus on raising {sidedPartPhrase}.",
      "Would you please try to lift {sidedPartPhrase} a bit more?",
      "To achieve this pose, {sidedPartPhrase} should be raised higher.",
    ],
    [GuidanceTemplateType.OneLandmarkInFront]: [
      "Hey, could you move {sidedPartPhrase} a bit further forward?",
      "It looks like {sidedPartPhrase} is not quite far enough forward.",
      "Now please focus on moving {sidedPartPhrase} forward.",
      "Would you mind shifting {sidedPartPhrase} a bit more to the front?",
      "For this pose, {sidedPartPhrase} should be positioned further forward.",
    ],
    [GuidanceTemplateType.LandmarksAbove]: [
      "Hey, could you position your {pair1} {aboveText}above your {pair2}?",
      "It seems that your {pair1} are not quite {aboveText}above your {pair2}.",
      "Now let's work on positioning your {pair1} {aboveText}above your {pair2}.",
      "Would you please try to adjust your {pair1} to be {aboveText}above your {pair2}?",
      "To nail this pose, your {pair1} should be comfortably {aboveText}above your {pair2}.",
    ],
    [GuidanceTemplateType.LandmarksAboveLowest]: [
      "Hey, could you position your {pair1} {aboveText}above your {pair2}?",
      "It looks like your {pair1} are not quite {aboveText}above your {pair2}.",
      "Now please focus on positioning your {pair1} {aboveText}above your {pair2}.",
      "Would you mind adjusting your {pair1} to be {aboveText}above your {pair2}?",
      "For this pose, your {pair1} should be comfortably {aboveText}above your {pair2}.",
    ],
    [GuidanceTemplateType.LandmarksStacked]: [
      "Hey, could you stack your {pair1} over your {pair2}?",
      "It seems that your {pair1} are not quite stacked over your {pair2}.",
      "Now let's work on stacking your {pair1} over your {pair2}.",
      "Would you please try to align your {pair1} to be stacked over your {pair2}?",
      "To achieve this pose, your {pair1} should be nicely stacked over your {pair2}.",
    ],
    [GuidanceTemplateType.LandmarksBelowHighest]: [
      "Hey, could you position a {singularPair2} above your {pair1}?",
      "It looks like neither {singularPair2} is quite high enough above your {pair1}.",
      "Now please focus on positioning a {singularPair2} above your {pair1}.",
      "Would you mind adjusting a {singularPair2} to be above your {pair1}?",
      "For this pose, a {singularPair2} should be comfortably above your {pair1}.",
    ],
    [GuidanceTemplateType.LandmarksSameHeight]: [
      "Hey, could you position your {pair1} at the same height as your {pair2}?",
      "It seems that your {pair1} are not quite at the same height as your {pair2}.",
      "Now let's work on aligning your {pair1} at the same height as your {pair2}.",
      "Would you please try to adjust your {pair1} to be at the same height as your {pair2}?",
      "To nail this pose, your {pair1} should be at the same height as your {pair2}.",
    ],
    [GuidanceTemplateType.LandmarksTouching]: [
      "Hey, could you position your {pair1} to touch your {pair2}?",
      "It looks like your {pair1} are not quite touching your {pair2}.",
      "Now please focus on bringing your {pair1} to touch your {pair2}.",
      "Would you mind adjusting your {pair1} to make contact with your {pair2}?",
      "For this pose, your {pair1} should be comfortably touching your {pair2}.",
    ],
  },
  aggressive: {
    [GuidanceTemplateType.Visibility]: [
      "Show me your {landmarksPhrase} now!",
      "I can't see your {landmarksPhrase}. Fix it!",
      "Move so I can see your {landmarksPhrase}!",
      "Make your {landmarksPhrase} visible, pronto!",
      "Your {landmarksPhrase} better be visible, or else!",
    ],
    [GuidanceTemplateType.JointsBent]: [
      "Bend your {jointPair} now!",
      "Your {jointPair} aren't bent enough. Do it right!",
      "Put some effort into bending your {jointPair}!",
      "Is that all you've got?! Bend your {jointPair} more!",
      "I need to see those {jointPair} bent, understood?!",
    ],
    [GuidanceTemplateType.JointsStraight]: [
      "Straighten your {jointPair}, now!",
      "Your {jointPair} are still bent. Straighten them out!",
      "Put some effort into straightening your {jointPair}!",
      "You call that straight?! Straighten your {jointPair} more!",
      "I want to see those {jointPair} straight as an arrow!",
    ],
    [GuidanceTemplateType.OneJointBent]: [
      "Bend {sidedPartPhrase}, now!",
      "{sidedPartPhrase} isn't bent enough. Fix it!",
      "Put some effort into bending {sidedPartPhrase}!",
      "Is that all you've got?! Bend {sidedPartPhrase} more!",
      "I need to see {sidedPartPhrase} bent properly!",
    ],
    [GuidanceTemplateType.OneJointStraight]: [
      "Straighten {sidedPartPhrase}, now!",
      "{sidedPartPhrase} is still bent. Straighten it out!",
      "Put some effort into straightening {sidedPartPhrase}!",
      "You call that straight?! Straighten {sidedPartPhrase} more!",
      "I want to see {sidedPartPhrase} straight as an arrow!",
    ],
    [GuidanceTemplateType.LandmarksApart]: [
      "Move your {landmarkPair} further apart, now!",
      "Your {landmarkPair} are too close. Separate them!",
      "Put some distance between your {landmarkPair}!",
      "Is that as far as you can move your {landmarkPair}?!",
      "I want to see those {landmarkPair} far apart!",
    ],
    [GuidanceTemplateType.LandmarksTogether]: [
      "Bring your {landmarkPair} together, now!",
      "Your {landmarkPair} are too far apart. Close the gap!",
      "Put some effort into bringing your {landmarkPair} together!",
      "You call that close?! Bring your {landmarkPair} closer!",
      "I want to see those {landmarkPair} touching!",
    ],
    [GuidanceTemplateType.LandmarkPairSameHeight]: [
      "Align your {landmarkPair} at the same height, now!",
      "Your {landmarkPair} aren't at the same height. Fix it!",
      "Put some effort into aligning your {landmarkPair}!",
      "Is that your best attempt?! Align your {landmarkPair} properly!",
      "I want to see those {landmarkPair} perfectly aligned!",
    ],
    [GuidanceTemplateType.MassCenteredOn]: [
      "Center your body over your {landmarkPair}, now!",
      "Your body isn't centered over your {landmarkPair}. Fix it!",
      "Put some effort into centering your body!",
      "You call that centered?! Adjust your position!",
      "I want to see your body perfectly centered over your {landmarkPair}!",
    ],
    [GuidanceTemplateType.OneLandmarkHigher]: [
      "Raise {sidedPartPhrase} higher, now!",
      "{sidedPartPhrase} isn't high enough. Lift it!",
      "Put some effort into raising {sidedPartPhrase}!",
      "Is that as high as you can lift {sidedPartPhrase}?!",
      "I want to see {sidedPartPhrase} sky-high!",
    ],
    [GuidanceTemplateType.OneLandmarkInFront]: [
      "Move {sidedPartPhrase} further forward, now!",
      "{sidedPartPhrase} isn't far enough forward. Fix it!",
      "Put some effort into moving {sidedPartPhrase} forward!",
      "You call that forward?! Move {sidedPartPhrase} further!",
      "I want to see {sidedPartPhrase} way out in front!",
    ],
    [GuidanceTemplateType.LandmarksAbove]: [
      "Position your {pair1} {aboveText}above your {pair2}, now!",
      "Your {pair1} aren't {aboveText}above your {pair2}. Fix it!",
      "Put some effort into positioning your {pair1} {aboveText}above your {pair2}!",
      "Is that your best attempt?! Move your {pair1} {aboveText}above your {pair2}!",
      "I want to see those {pair1} way {aboveText}above your {pair2}!",
      "Get your {pair1} {aboveText}above your {pair2}! Do it now!",
    ],
    [GuidanceTemplateType.LandmarksAboveLowest]: [
      "Position your {pair1} {aboveText}above your {pair2}, now!",
      "Your {pair1} aren't {aboveText}above your {pair2}. Fix it!",
      "Put some effort into positioning your {pair1} {aboveText}above your {pair2}!",
      "Is that your best attempt?! Move your {pair1} {aboveText}above your {pair2}!",
      "I want to see those {pair1} way {aboveText}above your {pair2}!",
      "Get your {pair1} {aboveText}above your {pair2}! Do it now!",
    ],
    [GuidanceTemplateType.LandmarksStacked]: [
      "Stack your {pair1} over your {pair2}, now!",
      "Your {pair1} aren't stacked over your {pair2}. Fix it!",
      "Put some effort into stacking your {pair1} over your {pair2}!",
      "You call that stacked?! Align your {pair1} over your {pair2}!",
      "I want to see those {pair1} perfectly stacked over your {pair2}!",
    ],
    [GuidanceTemplateType.LandmarksBelowHighest]: [
      "Position a {singularPair2} above your {pair1}, now!",
      "Neither {singularPair2} is high enough above your {pair1}. Fix it!",
      "Put some effort into positioning a {singularPair2} above your {pair1}!",
      "Is that your best attempt?! Raise a {singularPair2} higher! It must be above your {pair1}!",
      "I want to see a {singularPair2} way above your {pair1}!",
    ],
    [GuidanceTemplateType.LandmarksSameHeight]: [
      "Align your {pair1} at the same height as your {pair2}, now!",
      "Your {pair1} aren't at the same height as your {pair2}. Fix it!",
      "Put some effort into aligning your {pair1} and your {pair2}!",
      "Is that your best attempt?! Align your {pair1} and your {pair2} properly!",
      "I want to see those {pair1} perfectly level with your {pair2}!",
    ],
    [GuidanceTemplateType.LandmarksTouching]: [
      "Make your {pair1} touch your {pair2}, now!",
      "Your {pair1} aren't touching your {pair2}. Fix it!",
      "Put some effort into bringing your {pair1} and {pair2} together!",
      "You call that touching?! Make your {pair1} contact your {pair2}!",
      "I want to see those {pair1} firmly touching your {pair2}!",
    ],
  },
  silly: {
    [GuidanceTemplateType.Visibility]: [
      "Peek-a-boo! I can't see your {landmarksPhrase}. Where did they go?",
      "Oopsie, your {landmarksPhrase} are playing hide-and-seek with me!",
      "Hocus pocus, let's focus! Make sure your {landmarksPhrase} are visible, pretty please!",
      "Abracadabra, alakazam! Your {landmarksPhrase} have vanished like a magic trick!",
      "Knock knock, who's there? Hopefully your {landmarksPhrase}, so we can have some fun!",
    ],
    [GuidanceTemplateType.JointsBent]: [
      "Bend it like Beckham! Let's see those {jointPair} get their yoga on!",
      "Ooh, looks like your {jointPair} are feeling a bit too relaxed. Time to give them a little workout!",
      "Alright, let's get this party started! Bend those {jointPair} like there's no tomorrow!",
      "Hmmm, your {jointPair} seem to be on a break. Let's get them back to work, shall we?",
      "Bend, baby, bend! Your {jointPair} are ready for their close-up!",
    ],
    [GuidanceTemplateType.JointsStraight]: [
      "Get on the straight narrow, make each of your {jointPair} like an arrow!",
      "Uh-oh, looks like your {jointPair} are feeling a bit too wiggly. Time to straighten them out!",
      "Alrighty, let's get those {jointPair} in line! No more silly curves allowed!",
      "Hmmm, your {jointPair} seem to be doing the wave. Let's get them back to being straight and brave!",
      "Straighten up and fly right, your {jointPair} are ready for takeoff!",
    ],
    [GuidanceTemplateType.OneJointBent]: [
      "Bend it like a banana! Let's see {sidedPartPhrase} get its curve on!",
      "Oopsie, looks like {sidedPartPhrase} is feeling a bit too lazy. Time to give it a little nudge!",
      "Alright, let's get this show on the road! Bend {sidedPartPhrase} like it's tying a shoe!",
      "Hmmm, {sidedPartPhrase} seems to be taking a siesta. Let's wake it up with a little bend!",
      "Bend, {sidedPartPhrase}, bend! It's your time to shine!",
    ],
    [GuidanceTemplateType.OneJointStraight]: [
      "Straight as a ruler, let's make {sidedPartPhrase} super duper!",
      "Uh-oh, looks like {sidedPartPhrase} is feeling a bit too squiggly. Time to straighten it out!",
      "Alrighty, let's get {sidedPartPhrase} in line! No more silly bends allowed!",
      "Hmmm, {sidedPartPhrase} seems to be doing the wiggle. Let's get it back to being straight and giggle!",
      "Straighten up and stand tall, {sidedPartPhrase} is ready to have a ball!",
    ],
    [GuidanceTemplateType.LandmarksApart]: [
      "Alright, let's give your {landmarkPair} some space! They need room to groove!",
      "Oopsie, looks like your {landmarkPair} are having a little too much togetherness. Time to spread the love!",
      "Hocus pocus, let's focus! Move those {landmarkPair} apart like they're doing the splits!",
      "Hmmm, your {landmarkPair} seem to be playing a game of 'stuck like glue'. Let's unstick them, shall we?",
      "Abracadabra, alakazam! Make your {landmarkPair} disappear from each other's side!",
    ],
    [GuidanceTemplateType.LandmarksTogether]: [
      "Alright, let's have a {landmarkPair} reunion! Bring them in for a hug!",
      "Oopsie, looks like your {landmarkPair} are feeling a bit too independent. Time to bring them back together!",
      "Hocus pocus, let's focus! Move those {landmarkPair} closer like they're best buddies!",
      "Hmmm, your {landmarkPair} seem to be playing a game of 'long-distance relationship'. Let's end the game, shall we?",
      "Abracadabra, alakazam! Make your {landmarkPair} magically attract each other!",
    ],
    [GuidanceTemplateType.LandmarkPairSameHeight]: [
      "Alright, let's make your {landmarkPair} see eye to eye! They should be at the same party level!",
      "Oopsie, looks like one of your {landmarkPair} is feeling a bit superior. Time to even the playing field!",
      "Hocus pocus, let's focus! Align those {landmarkPair} like they're twins!",
      "Hmmm, your {landmarkPair} seem to be playing a game of 'who's taller?'. Let's call it a tie, shall we?",
      "Abracadabra, alakazam! Make your {landmarkPair} match heights like a perfect pair!",
    ],
    [GuidanceTemplateType.MassCenteredOn]: [
      "Alright, let's make your body the center of attention! Put it right over your {landmarkPair}!",
      "Oopsie, looks like your body is feeling a bit off-center. Time to give it a nudge back to the middle!",
      "Hocus pocus, let's focus! Center your body over your {landmarkPair} like a tightrope walker!",
      "Hmmm, your body seems to be playing a game of 'lean to the side'. Let's bring it back to center stage, shall we?",
      "Abracadabra, alakazam! Make your body balance perfectly over your {landmarkPair}!",
    ],
    [GuidanceTemplateType.OneLandmarkHigher]: [
      "Alright, let's give {sidedPartPhrase} a boost! Raise it up like it's reaching for the stars!",
      "Oopsie, looks like {sidedPartPhrase} is feeling a bit shy. Time to give it a little pep talk and lift it higher!",
      "Hocus pocus, let's focus! Elevate {sidedPartPhrase} like it's on a magic carpet ride!",
      "Hmmm, {sidedPartPhrase} seems to be playing limbo. Let's raise the bar and get it up there, shall we?",
      "Abracadabra, alakazam! Make {sidedPartPhrase} levitate like a magician's assistant!",
    ],
    [GuidanceTemplateType.OneLandmarkInFront]: [
      "Alright, let's give {sidedPartPhrase} a VIP pass! Move it to the front of the line!",
      "Oopsie, looks like {sidedPartPhrase} is feeling a bit behind the times. Time to bring it to the forefront!",
      "Hocus pocus, let's focus! Move {sidedPartPhrase} forward like it's leading a parade!",
      "Hmmm, {sidedPartPhrase} seems to be playing a game of 'back of the bus'. Let's give it a front-row seat, shall we?",
      "Abracadabra, alakazam! Make {sidedPartPhrase} step forward like it's walking the red carpet!",
    ],
    [GuidanceTemplateType.LandmarksAbove]: [
      "Alright, let's give your {pair1} a promotion! They should be the boss of your {pair2}!",
      "Oopsie, looks like your {pair1} are feeling a bit too humble. Time to give them a boost {aboveText}above your {pair2}!",
      "Hocus pocus, let's focus! Move those {pair1} {aboveText}above your {pair2} like they're on a pedestal!",
      "Hmmm, your {pair1} seem to be playing a game of 'limbo' with your {pair2}. Let's get them {aboveText}above, shall we?",
      "Abracadabra, alakazam! Make your {pair1} float {aboveText}above your {pair2} like magic!",
    ],
    [GuidanceTemplateType.LandmarksAboveLowest]: [
      "Alright, let's give your {pair1} a little boost! They should be the king of the hill {aboveText}above your {pair2}!",
      "Oopsie, looks like your {pair1} are feeling a bit too down to earth. Time to give them a lift {aboveText}above your {pair2}!",
      "Hocus pocus, let's focus! Move those {pair1} {aboveText}above your {pair2} like they're on a throne!",
      "Hmmm, your {pair1} seem to be playing a game of 'who's the shortest?' with your {pair2}. Let's get them {aboveText}above, shall we?",
      "Abracadabra, alakazam! Make your {pair1} rise {aboveText}above your {pair2} like a hot air balloon!",
    ],
    [GuidanceTemplateType.LandmarksStacked]: [
      "Alright, let's make a {pair1} sandwich with your {pair2} as the bread! Stack 'em up!",
      "Oopsie, looks like your {pair1} and {pair2} are playing 'the floor is lava'. Time to get them stacked up!",
      "Hocus pocus, let's focus! Pile your {pair1} on top of your {pair2} like a game of Jenga!",
      "Hmmm, your {pair1} seem to be avoiding your {pair2}. Let's get them cozied up in a nice stack, shall we?",
      "Abracadabra, alakazam! Make your {pair1} levitate and land on top of your {pair2} like a magic trick!",
    ],
    [GuidanceTemplateType.LandmarksBelowHighest]: [
      "Alright, let's make {singularPair2} the king of the castle! It should be ruling over your {pair1}!",
      "Oopsie, looks like your {pair1} are getting a bit too big for their britches. Time to put {singularPair2} above them!",
      "Hocus pocus, let's focus! Move a {singularPair2} above your {pair1} like it's wearing a crown!",
      "Hmmm, your {pair1} seem to be staging a coup against {singularPair2}. Let's restore the rightful order, shall we?",
      "Abracadabra, alakazam! Make a {singularPair2} rise above your {pair1} like a majestic eagle!",
    ],
    [GuidanceTemplateType.LandmarksSameHeight]: [
      "Alright, let's make your {pair1} and {pair2} best friends forever! They should be side by side at the same height!",
      "Oopsie, looks like your {pair1} and {pair2} are playing 'see-saw'. Time to get them on the same level!",
      "Hocus pocus, let's focus! Align your {pair1} and {pair2} like they're posing for a group photo!",
      "Hmmm, your {pair1} seem to be having a height competition with your {pair2}. Let's call it a draw and make them equal, shall we?",
      "Abracadabra, alakazam! Make your {pair1} and {pair2} line up at the same height like synchronized swimmers!",
    ],
    [GuidanceTemplateType.LandmarksTouching]: [
      "Alright, let's have a {pair1} and {pair2} high-five! They should be touching like best buds!",
      "Oopsie, looks like your {pair1} and {pair2} are playing 'hard to get'. Time to make them meet in the middle!",
      "Hocus pocus, let's focus! Bring your {pair1} and {pair2} together like they're holding hands!",
      "Hmmm, your {pair1} seem to be playing 'keep away' from your {pair2}. Let's end the game and make them touch, shall we?",
      "Abracadabra, alakazam! Make your {pair1} and {pair2} connect like two pieces of a puzzle!",
    ],
  },
};
