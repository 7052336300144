// src/hooks/useAvailablePoses.ts
import { useMemo, useContext } from "react";
import { SettingsContext } from "../components/settings/SettingsContext"; // Adjust the import path as needed
import { poses as allPoses } from "../components/types"; // Adjust the import path as needed

export const useAvailablePoses = () => {
  const { onlyShowHandstandPoses } = useContext(SettingsContext);

  const availablePoses = useMemo(() => {
    return onlyShowHandstandPoses
      ? allPoses.filter(
          (pose) =>
            pose.includes("handstand") &&
            !pose.includes("handstand compression") &&
            !pose.includes("hand to shoulder")
        )
      : allPoses;
  }, [onlyShowHandstandPoses]);

  return availablePoses;
};
