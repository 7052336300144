import { Capacitor } from "@capacitor/core";
import { FilePicker } from "@capawesome/capacitor-file-picker";
import { Box, BoxProps, Icon, IconButton } from "@chakra-ui/react";
import React from "react";
import { MdAddToPhotos } from "react-icons/md";

export type MediaFile = {
  path?: string;
  blob?: Blob;
  mimeType: string;
};

type MediaPickerProps = {
  onMediaSelected: (file: MediaFile) => void;
  onError?: (error: Error) => void;
} & BoxProps;

const MediaPicker: React.FC<MediaPickerProps> = ({
  onMediaSelected,
  onError,
  ...props
}) => {
  const handlePickMedia = async () => {
    if (Capacitor.isNativePlatform()) {
      try {
        const result = await FilePicker.pickMedia({
          limit: 1,
        });

        if (result.files.length > 0) {
          const file = result.files[0];
          if (file.path) {
            try {
              const convertedPath = Capacitor.convertFileSrc(file.path);
              console.log("handlePickMedia", {
                "file.path": file.path,
                convertedPath,
                file,
              });
              try {
                onMediaSelected({
                  path: convertedPath,
                  mimeType: file.mimeType,
                });
              } catch (error) {
                onError?.(
                  new Error(
                    "Error in onMediaSelected callback: " +
                      (error as Error).message
                  )
                );
              }
            } catch (error) {
              onError?.(
                new Error(
                  "Error converting file path: " + (error as Error).message
                )
              );
            }
          }
        }
      } catch (error) {
        onError?.(new Error("Error picking file: " + (error as Error).message));
      }
    } else {
      // Web platform
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*,video/*";

      input.onchange = (e: Event) => {
        const target = e.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
          const file = target.files[0];
          const reader = new FileReader();
          reader.onload = (event) => {
            if (event.target && event.target.result) {
              const blob = new Blob([event.target.result], {
                type: file.type,
              });
              try {
                onMediaSelected({ blob, mimeType: file.type });
              } catch (error) {
                onError?.(
                  new Error(
                    "Error in onMediaSelected callback: " +
                      (error as Error).message
                  )
                );
              }
            }
          };
          reader.onerror = () => {
            onError?.(new Error("Error reading file"));
          };
          reader.readAsArrayBuffer(file);
        }
      };

      input.click();
    }
  };

  return (
    <Box {...props}>
      <IconButton
        aria-label="Pick media"
        icon={<Icon boxSize={6} as={MdAddToPhotos} />}
        onClick={handlePickMedia}
        variant="ghost"
        color="white"
        borderRadius="full"
      />
    </Box>
  );
};

export default MediaPicker;
