import { Capacitor } from "@capacitor/core";
import { initializeAnalytics } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Auth,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions,
} from "firebase/functions";
import { FirebasePerformance, getPerformance } from "firebase/performance";
import { getFirebaseConfig } from "./firebase-config";

type InitializeResultProps = {
  app: FirebaseApp;
  functions: Functions;
  performance: FirebasePerformance;
};

class FirebaseService {
  private static instance: FirebaseService;
  private app: FirebaseApp | null = null;
  private initializationPromise: Promise<InitializeResultProps> | null = null;

  private constructor() {}

  public static getInstance(): FirebaseService {
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
    }
    return FirebaseService.instance;
  }

  public async initialize(): Promise<InitializeResultProps> {
    if (!this.initializationPromise) {
      this.initializationPromise = this.initializeFirebase();
    }
    return this.initializationPromise;
  }

  private async initializeFirebase(): Promise<InitializeResultProps> {
    const firebaseConfig = await getFirebaseConfig();
    this.app = initializeApp(firebaseConfig);
    const functions = getFunctions(this.app);
    const functionsEmulatorHost = import.meta.env.VITE_FUNCTIONS_EMULATOR_HOST;
    if (functionsEmulatorHost) {
      const [host, portString] = functionsEmulatorHost.split(":");
      const port = parseInt(portString);
      connectFunctionsEmulator(functions, host, port);
    }

    let authInstance: Auth | null = null;
    if (Capacitor.isNativePlatform()) {
      authInstance = initializeAuth(this.app, {
        persistence: indexedDBLocalPersistence,
      });
    } else {
      // default initialization with support for sign in via popup
      authInstance = getAuth(this.app);
    }

    // TODO: restore auth emulator; broken on mobile currently0
    // const authEmulatorHost = import.meta.env.VITE_AUTH_EMULATOR_HOST;
    // if (authEmulatorHost) {
    //   const auth = await getFirebaseAuth();
    //   connectAuthEmulator(auth, authEmulatorHost);
    // }

    const performance = getPerformance(this.app);

    initializeAnalytics(this.app, {});
    return { app: this.app, functions, performance };
  }

  public getApp(): FirebaseApp {
    if (!this.app) {
      throw new Error(
        "Firebase has not been initialized. Call initialize() first."
      );
    }
    return this.app;
  }
}

export const firebaseService = FirebaseService.getInstance();
