import { Box, Center, ScaleFade } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useTimeoutEffect } from "react-timing-hooks";

interface CompletionAnimationOverlayProps {
  isOpen: boolean;
  onClose: () => void;
}

const animations = [
  "completion-4JC4vgh9wv.json",
  "done-4z7CzNTCXF.json",
  "done-status-feedback-6fFX87ZZsM.json",
  "high-five-S84Wg3Tr2b.json",
  "laurel-wreath-star-JHBYolFG1f.json",
  "rainbow-checkmark-t34oEIbaHE.json",
  "smile-party-celebration-GHhk794Xlh.json",
  "thumbs-up-birdie-b083O85vXr.json",
  "tick-animation-success-feedback-Cswc47bzP0.json",
  "unicorn-rainbow-2p1FCK99ip.json",
];

export const CompletionAnimationOverlay: React.FC<
  CompletionAnimationOverlayProps
> = ({ isOpen, onClose }) => {
  const [randomAnimation, setRandomAnimation] = useState<string>();
  useEffect(() => {
    if (isOpen) {
      setRandomAnimation(
        animations[Math.floor(Math.random() * animations.length)]
      );
    } else {
      setRandomAnimation(undefined);
    }
  }, [isOpen]);

  useTimeoutEffect(
    (timeout, clearAll) => {
      if (isOpen) {
        timeout(onClose, 5000);
      }
    },
    [isOpen, onClose]
  );

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex="overlay"
      pointerEvents={isOpen ? "auto" : "none"}
    >
      <ScaleFade initialScale={0.1} in={isOpen}>
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          onClick={onClose} // Close the overlay on any click event
        >
          <Center height="100%">
            <Box fontSize="10rem" color="green.500">
              {randomAnimation && (
                <Player
                  autoplay
                  src={`./animations/${randomAnimation}`}
                  style={{
                    height: "800px",
                    width: "800px",
                    maxWidth: "100vw",
                    maxHeight:
                      "calc(var(--vh, 1vh) * 100 - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
                  }}
                />
              )}
            </Box>
            {isOpen && (
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                confettiSource={{
                  x: window.innerWidth / 2,
                  y: window.innerHeight / 2,
                  w: 20,
                  h: 20,
                }}
                recycle={false}
                tweenDuration={1000}
                friction={0.999}
                gravity={0.4}
                initialVelocityY={{ min: -40, max: 0 }}
                initialVelocityX={{ min: -15, max: 15 }}
              />
            )}
          </Center>
        </Box>
      </ScaleFade>
    </Box>
  );
};
