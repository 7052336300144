import { Auth, onAuthStateChanged, User } from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import { AuthContext } from "./AuthContextType";
import { getFirebaseAuth } from "./getFirebaseAuth";
import { useFirebaseAuthProvider } from "./useFirebaseAuthProvider";
import { Flex, Box } from "@chakra-ui/react";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [auth, setAuth] = useState<Auth | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      console.log("initializing auth...");
      const firebaseAuth = await getFirebaseAuth();
      setAuth(firebaseAuth);
      setLoading(false);
      console.log("auth initialized");
    };
    initAuth();
  }, []);

  useEffect(() => {
    if (!auth) return;

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("onAuthStateChanged in AuthProvider", user);
      setUser(user);
    });
    return unsubscribe;
  }, [auth]);

  const firebaseAuthProvider = useFirebaseAuthProvider(auth);

  const signOut = useCallback(async () => {
    if (!auth) return;
    console.log("signout");
    await firebaseAuthProvider.signOut();

    setUser(null);
  }, [auth, firebaseAuthProvider]);

  const value = {
    loading,
    user,
    setUser,
    expectLoggedIn: firebaseAuthProvider.expectLoggedIn,
    hasRegistered: firebaseAuthProvider.hasRegistered,
    signOut,
    signInWithApple: firebaseAuthProvider.signInWithApple,
    signInWithGoogle: firebaseAuthProvider.signInWithGoogle,
    signInWithEmailAndPassword: firebaseAuthProvider.signInWithEmailAndPassword,
    signInWithPhoneNumber: firebaseAuthProvider.signInWithPhoneNumber,
    confirmVerificationCode: firebaseAuthProvider.confirmVerificationCode,
    createUserWithEmailAndPassword:
      firebaseAuthProvider.createUserWithEmailAndPassword,
    applyActionCode: firebaseAuthProvider.applyActionCode,
    confirmPasswordReset: firebaseAuthProvider.confirmPasswordReset,
  };

  if (loading) {
    return (
      <Flex
        height="100vh"
        width="100vw"
        alignItems="center"
        justifyContent="center"
      >
        <Box>Loading...</Box>
      </Flex>
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
