import { limit, where } from "firebase/firestore";
import { PerformanceTrace, trace } from "firebase/performance";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTextToSpeechUtterances } from "../../services/firestore/TextToSpeechUtterancesDao";
import { GuidanceStyle } from "../pose-detection/detection-guidance/GuidanceStyle";
import { GuidanceReason } from "../pose-detection/detection-guidance/feedbackItemToGuidance";
import { TextToSpeechUtterance, getUtteranceId } from "./TextToSpeech";
import {
  SpeechUtteranceContainer,
  useSpeakUtteranceAudio,
} from "./useSpeakUtteranceAudio";
import useSpeechQueue, { SpeechUtteranceWrapper } from "./useSpeechQueue";
import { useUtteranceCreator } from "./useUtteranceCreator";
import { firebaseService } from "../../services/FirebaseService";

const PREPARE_AUDIO_TIMEOUT = 2000;

/**
 * A container for a speech utterance request, including context for
 * creating the utterance and the trace and promise for tracking the preparation
 * of the utterance.
 */
export type SpeechUtteranceRequestContainer = {
  text: string;
  contextProps?: { [key: string]: any };
  preparationTrace?: PerformanceTrace;
  preparationPromise?: Promise<void>;
  preparationPromiseResolve?: () => void;
  unmetCriteriaGuidance: string;
  guidanceReason?: GuidanceReason;
  guidanceStyle: GuidanceStyle;
};

/**
 * The return type of the useSpeechFacade hook.
 */
type UseSpeechFacadeResult = {
  /**
   * Prepare the utterance by saving the utterance document that will initiate
   * creation of the audio via ElevenLabs and add the utterance hash to the set
   * of ids used to fetch and watch for changes (to get the resulting audio path).
   */
  prepareUtterance: (
    speechUtteranceRequestContainer: SpeechUtteranceRequestContainer
  ) => void;

  /**
   * Prepares and queues the utterance for speaking.
   */
  prepareAndQueueUtterance: (
    speechUtteranceRequestContainer: SpeechUtteranceRequestContainer
  ) => Promise<void>;

  /**
   * Aborts the request if it is pending or cancels it if it is already in the queue.
   */
  cancelUtteranceRequest: (
    speechUtteranceRequestContainer: SpeechUtteranceRequestContainer
  ) => Promise<void>;
};

/**
 * Hook which provides a callback prepare/fetch synthesized speech utterances (via ElevenLabs) and
 * then queue the utterances for speaking sequentially. If the audio is not prepared before the timeout
 * elapses, fallback to queuing a local web speech synthesis utterance instead. Also provides a
 * callback to cancel the a request for an utterance.
 *
 * @param utterancesContext
 * @param voice
 * @returns An object with callback functions
 */
export function useSpeechFacade<T extends { type: string; [key: string]: any }>(
  utterancesContext: T,
  voice?: string
): UseSpeechFacadeResult {
  const [utteranceHashes, setUtteranceHashes] = useState<string[]>([]);
  const [utterances, utterancesLoading, utterancesError] =
    useTextToSpeechUtterances(
      utteranceHashes?.length ? where("hash", "in", utteranceHashes) : limit(1)
    );

  const { queueUtterance, clearUtteranceQueue, cancelUtterance } =
    useSpeechQueue();
  const { createUtterance, saveUtterances } = useUtteranceCreator(
    utterancesContext,
    voice
  );

  const { audioContext } = useMemo(() => {
    const audioContext = new AudioContext();
    return { audioContext };
  }, []);

  const stopAllSpeech = () => {
    // stop any ongoing speech
    clearUtteranceQueue();
    waitingForUtteranceRef.current = undefined;
  };

  const doneSpeaking = useCallback((utterance?: SpeechUtteranceWrapper) => {
    // console.log("... doneSpeaking");
  }, []);

  const waitingForUtteranceRef = useRef<string | undefined>();

  const { fetchAndQueueUtterance, cancelUtteranceAudioRequest } =
    useSpeakUtteranceAudio(
      audioContext,
      queueUtterance,
      cancelUtterance,
      doneSpeaking
    );

  const utteranceFallbackTimerRef = useRef<NodeJS.Timeout | undefined>();
  useEffect(() => {
    return () => {
      if (utteranceFallbackTimerRef.current) {
        clearTimeout(utteranceFallbackTimerRef.current);
      }
    };
  }, []);

  const resolveCurrentUtteranceRef = useRef<(() => void) | undefined>();

  // TODO: use a queue instead of a single ref
  const utteranceRequestContainerRef = useRef<
    SpeechUtteranceRequestContainer | undefined
  >();

  const utteranceWrapperRef = useRef<SpeechUtteranceWrapper | undefined>();

  // Keep a reference to the utterance so that it can be aborted via cancelUtteranceAudioRequest
  const utteranceRef = useRef<TextToSpeechUtterance | undefined>();

  const prepareUtterance = useCallback(
    (speechUtteranceRequestContainer: SpeechUtteranceRequestContainer) => {
      const { text, contextProps } = speechUtteranceRequestContainer;
      const utteranceRequest = createUtterance(text, contextProps);

      // add the hash to utteranceHashes
      const hash = getUtteranceId(utteranceRequest);
      speechUtteranceRequestContainer.preparationTrace?.putAttribute(
        "utteranceId",
        hash
      );
      setUtteranceHashes((hashes) => {
        if (hashes.includes(hash)) {
          return hashes;
        }
        return [...hashes.slice(-29), hash];
      });

      // create/update the utterance document in Firestore; don't wait for the promise
      saveUtterances([utteranceRequest]);
    },
    [createUtterance, saveUtterances]
  );

  const prepareAndQueueUtterance = useCallback(
    async (
      speechUtteranceRequestContainer: SpeechUtteranceRequestContainer
    ) => {
      speechUtteranceRequestContainer.preparationPromise = new Promise<void>(
        (resolve) => {
          speechUtteranceRequestContainer.preparationPromiseResolve = resolve;
        }
      );
      const { performance } = await firebaseService.initialize();
      const t = trace(performance, "prepareSpeechAudio");
      t.start();
      speechUtteranceRequestContainer.preparationTrace = t;

      utteranceRequestContainerRef.current = speechUtteranceRequestContainer;

      prepareUtterance(speechUtteranceRequestContainer);

      // just in case, clear any existing fallback timer
      if (utteranceFallbackTimerRef.current) {
        clearTimeout(utteranceFallbackTimerRef.current);
        utteranceFallbackTimerRef.current = undefined;
      }

      const text = speechUtteranceRequestContainer.text;

      // determine if the TTS utterance audio is available yet
      const utterance = utterances?.find((u) => u.text === text);
      if (utterance && utterance.audioPath) {
        t.putAttribute("fromFacadeCache", "true");
        t.stop();
        utteranceRef.current = utterance;
        const utteranceContainer: SpeechUtteranceContainer = {
          utterance,
        };
        const fetchAndQueueUtterancePromise =
          fetchAndQueueUtterance(utteranceContainer);
        utteranceContainer.fetchingPromise?.then(() => {
          // don't resolve the preparation promise until after fetch
          speechUtteranceRequestContainer.preparationPromiseResolve?.();
        });
        await fetchAndQueueUtterancePromise;
      } else {
        t.putAttribute("fromFacadeCache", "false");
        waitingForUtteranceRef.current = text;

        // keep a ref to the timer so we can cancel it
        utteranceFallbackTimerRef.current = setTimeout(() => {
          if (waitingForUtteranceRef.current) {
            // Fallback to queueUtterance after some timeout elapses
            waitingForUtteranceRef.current = undefined;
            const utteranceWrapper = { text };
            utteranceWrapperRef.current = utteranceWrapper;
            queueUtterance(utteranceWrapper).then((done) => {
              resolveCurrentUtteranceRef.current?.();
              resolveCurrentUtteranceRef.current = undefined;
              done && doneSpeaking();
            });
          }
        }, PREPARE_AUDIO_TIMEOUT);

        // Return a new promise that resolves when the utterance is done
        return new Promise<void>((resolve) => {
          resolveCurrentUtteranceRef.current = resolve;
        });
      }
    },
    [
      utterances,
      prepareUtterance,
      fetchAndQueueUtterance,
      queueUtterance,
      doneSpeaking,
    ]
  );

  // wait for the updated utterance and speak it when ready
  useEffect(() => {
    if (waitingForUtteranceRef.current) {
      const utterance = utterances?.find(
        (u) => u.text === waitingForUtteranceRef.current
      );
      if (utterance && utterance.audioPath) {
        if (utteranceRequestContainerRef.current) {
          // Once we detect the audioPath is available, we stop the trace for preparation
          utteranceRequestContainerRef.current.preparationTrace?.stop();
        }
        waitingForUtteranceRef.current = undefined;
        if (utteranceFallbackTimerRef.current) {
          clearTimeout(utteranceFallbackTimerRef.current);
          utteranceFallbackTimerRef.current = undefined;
        }
        utteranceRef.current = utterance;
        const utteranceContainer: SpeechUtteranceContainer = { utterance };

        const fetchAndQueueUtterancePromise =
          fetchAndQueueUtterance(utteranceContainer);
        utteranceContainer.fetchingPromise?.then(() => {
          if (utteranceRequestContainerRef.current) {
            utteranceRequestContainerRef.current.preparationPromiseResolve?.();
            utteranceRequestContainerRef.current = undefined;
          }
        });

        fetchAndQueueUtterancePromise.then(() => {
          resolveCurrentUtteranceRef.current?.();
          resolveCurrentUtteranceRef.current = undefined;
        });
      }
    }
  }, [utterances]);

  const cancelUtteranceRequest = useCallback(
    async (
      speechUtteranceRequestContainer: SpeechUtteranceRequestContainer
    ) => {
      // abort the fallback web speech synthesis utterance, if any
      if (utteranceWrapperRef.current) {
        cancelUtterance(utteranceWrapperRef.current);
        utteranceWrapperRef.current = undefined;
      }

      // abort the audio utterance, if any
      if (utteranceRef.current) {
        cancelUtteranceAudioRequest(utteranceRef.current);
        utteranceRef.current = undefined;
      }

      // cancel the fallback timer
      if (utteranceFallbackTimerRef.current) {
        clearTimeout(utteranceFallbackTimerRef.current);
        utteranceFallbackTimerRef.current = undefined;
      }

      // clear the ref to the fallback utterance ref
      waitingForUtteranceRef.current = undefined;

      // clear the ref to the utterance request container, to avoid having it queued after being prepared
      utteranceRequestContainerRef.current = undefined;

      if (resolveCurrentUtteranceRef.current) {
        resolveCurrentUtteranceRef.current?.();
        resolveCurrentUtteranceRef.current = undefined;
      }

      doneSpeaking();
    },
    []
  );

  useEffect(() => {
    return () => {
      stopAllSpeech();
    };
  }, []);

  return { prepareUtterance, prepareAndQueueUtterance, cancelUtteranceRequest };
}
