import {
  gongSynth,
  kickSynth,
  pluckSynth,
  sharpKickSynth,
  synth,
} from "./tone-synthesizers";
import { useVolumeAwareSynth } from "./useVolumeAwareSynth";

/**
 * Bind the volume of the synthesizers to the volume control options
 */
export function useVolumeAwareInstruments() {
  useVolumeAwareSynth(gongSynth);
  useVolumeAwareSynth(kickSynth);
  useVolumeAwareSynth(pluckSynth);
  useVolumeAwareSynth(sharpKickSynth);
  useVolumeAwareSynth(synth);
}
