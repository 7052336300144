import { Box, Flex } from "@chakra-ui/react";
import { forwardRef, useEffect, useState } from "react";

interface CustomSliderProps {
  min: number;
  max: number;
  onChange: (value: number) => void;
  isDisabled?: boolean;
}

const CustomSlider = forwardRef<HTMLInputElement, CustomSliderProps>(
  ({ min, max, onChange, isDisabled }, ref) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
      if (ref && "current" in ref && ref.current) {
        const updateValue = () => {
          const newValue = parseFloat(ref.current?.value || "0");
          setValue(newValue);
        };
        ref.current.addEventListener("input", updateValue);
        return () => ref.current?.removeEventListener("input", updateValue);
      }
    }, [ref]);

    return (
      <Flex align="center" width="100%" position="relative">
        <Box
          position="absolute"
          top="50%"
          left="0"
          right="0"
          height="4px"
          transform="translateY(-50%)"
          bg="gray.300"
          borderRadius="full"
          zIndex={1}
        />
        <Box
          position="absolute"
          top="50%"
          left="0"
          height="4px"
          width={`${((value - min) / (max - min)) * 100}%`}
          transform="translateY(-50%)"
          bg="blue.500"
          borderRadius="full"
          zIndex={2}
        />
        <input
          ref={ref}
          type="range"
          min={min}
          max={max}
          step="any"
          onChange={(e) => {
            // Check if the event is synthetic (programmatically dispatched)
            if (e.isTrusted) {
              const newValue = parseFloat(e.target.value);
              setValue(newValue);
              onChange(newValue);
            }
          }}
          disabled={isDisabled}
          style={{
            width: "100%",
            appearance: "none",
            background: "transparent",
            cursor: isDisabled ? "not-allowed" : "pointer",
            zIndex: 3,
            position: "relative",
          }}
        />
        <style>{`
          input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: white;
            border: 2px solid #3182ce;
            cursor: pointer;
            box-shadow: 0 1px 3px rgba(0,0,0,0.3);
          }
          input[type='range']::-moz-range-thumb {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: white;
            border: 2px solid #3182ce;
            cursor: pointer;
            box-shadow: 0 1px 3px rgba(0,0,0,0.3);
          }
        `}</style>
      </Flex>
    );
  }
);

export default CustomSlider;
