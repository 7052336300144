import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useIonRouter } from "@ionic/react";
import { formatDistanceToNow } from "date-fns";
import React, { useEffect, useState } from "react";
import { FaSignInAlt } from "react-icons/fa";
import { getPoseEvents } from "../../services/poseEventService";
import { SettingsContext } from "../settings/SettingsContext";
import { useAuthContext } from "./useAuthContext";

interface SignInModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SignInModal: React.FC<SignInModalProps> = ({ isOpen, onClose }) => {
  const [eventCount, setEventCount] = useState(0);
  const [firstEventDate, setFirstEventDate] = useState<Date | null>(null);
  const [dontShowForHour, setDontShowForHour] = useState(false);
  const { hasRegistered } = useAuthContext();
  const router = useIonRouter();
  const toast = useToast();
  const { setSignInPromptSilencedUntil } = React.useContext(SettingsContext);

  useEffect(() => {
    if (isOpen) {
      loadPoseEventsSummary();
    }
  }, [isOpen]);

  const loadPoseEventsSummary = async () => {
    try {
      const events = await getPoseEvents();
      setEventCount(events.length);
      if (events.length > 0) {
        setFirstEventDate(new Date(events[0].startAt));
      }
    } catch (error) {
      console.error("Error loading pose events summary:", error);
      toast({
        title: "Error loading pose events summary",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSignIn = () => {
    router.push("/login");
    onClose();
  };

  const handleCancel = () => {
    if (dontShowForHour) {
      const silenceUntil = Date.now() + 60 * 60 * 1000; // 1 hour from now
      setSignInPromptSilencedUntil(silenceUntil);
      toast({
        title: "Prompt Silenced",
        description: "The sign-in prompt has been silenced for the next hour.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
    onClose();
  };

  const getFirstEventText = () => {
    if (!firstEventDate) return "";
    const isToday = new Date().toDateString() === firstEventDate.toDateString();
    if (isToday) {
      return "since your first pose event <strong>today</strong>";
    } else {
      return `since your first pose event <strong>${formatDistanceToNow(
        firstEventDate,
        { addSuffix: true }
      )}</strong>`;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel}>
      <ModalOverlay />
      <ModalContent color="var(--chakra-colors-chakra-body-text)">
        <ModalHeader>
          {hasRegistered ? "Sign In to Your Account" : "Create an Account"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text>
              You have <strong>{eventCount}</strong> pose event
              {eventCount !== 1 ? "s" : ""} saved locally{" "}
              {firstEventDate && (
                <span
                  dangerouslySetInnerHTML={{ __html: getFirstEventText() }}
                />
              )}
              .
            </Text>
            <Text>
              {hasRegistered
                ? "Sign in to sync your pose events and access them across devices."
                : "Create an account to sync your pose events and access them across devices."}
            </Text>
            <Checkbox
              isChecked={dontShowForHour}
              onChange={(e) => setDontShowForHour(e.target.checked)}
            >
              Don't show this for the next hour
            </Checkbox>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSignIn}
            leftIcon={<FaSignInAlt />}
          >
            {hasRegistered ? "Sign In" : "Sign Up"}
          </Button>
          <Button variant="ghost" onClick={handleCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignInModal;
