import { PoseLandmarkerResult } from "@mediapipe/tasks-vision";
import {
  POSE_LANDMARKS,
  POSE_LANDMARKS_LEFT,
  POSE_LANDMARKS_RIGHT,
} from "./landmarks";
import { calculateDistance2D } from "./pose-utils";

function toFixed3(num: number): string {
  return num.toFixed(3);
}

const weights = {
  [POSE_LANDMARKS_LEFT.LEFT_HIP]: 1.8,
  [POSE_LANDMARKS_LEFT.LEFT_SHOULDER]: 1.4,
  [POSE_LANDMARKS_LEFT.LEFT_KNEE]: 1.4,
  [POSE_LANDMARKS_LEFT.LEFT_ELBOW]: 1.3,
};

function getJointThickness(landmark: number): number {
  const left =
    landmark > 6
      ? landmark - (1 - (landmark % 2))
      : landmark > 3
      ? landmark - 3
      : landmark;

  // console.log("left", left, "from", landmark, weights[left]);
  return weights[left] || 1;
}

// prioritize landmarks which are likely to be visible and far apart from each other
const preferredLandmarks = [
  POSE_LANDMARKS.LEFT_EAR,
  POSE_LANDMARKS.RIGHT_EAR,
  POSE_LANDMARKS.LEFT_HIP,
  POSE_LANDMARKS.RIGHT_HIP,
  POSE_LANDMARKS.LEFT_SHOULDER,
  POSE_LANDMARKS.RIGHT_SHOULDER,
  POSE_LANDMARKS.LEFT_ELBOW,
  POSE_LANDMARKS.RIGHT_ELBOW,
  POSE_LANDMARKS.LEFT_WRIST,
  POSE_LANDMARKS.RIGHT_WRIST,
  POSE_LANDMARKS.LEFT_KNEE,
  POSE_LANDMARKS.RIGHT_KNEE,
  POSE_LANDMARKS.LEFT_ANKLE,
  POSE_LANDMARKS.RIGHT_ANKLE,
];

function getDistance(results: PoseLandmarkerResult): number {
  // Find three visible landmarks to use as candidates for calculating the distance to the object
  const visibleLandmarks = preferredLandmarks.filter((landmark) => {
    return results.landmarks[0][landmark]?.visibility ?? 0 > 0.5;
  });

  const landmarkA = visibleLandmarks[0];

  // get the distance to the next two landmarks
  const distances = [visibleLandmarks[1], visibleLandmarks[2]].map(
    (landmarkB) => {
      return landmarkB === undefined
        ? 0
        : calculateDistance2D(
            results.landmarks[0][landmarkA],
            results.landmarks[0][landmarkB]
          );
    }
  );

  const landmarkB = visibleLandmarks[distances[0] > distances[1] ? 1 : 2];

  // console.log("landmarks", landmarkA, landmarkB);
  const candidates = [
    {
      landmarks: [
        results.landmarks[0][landmarkA],
        results.landmarks[0][landmarkB],
      ],
      worldLandmarks: [
        results.worldLandmarks[0][landmarkA],
        results.worldLandmarks[0][landmarkB],
      ],
    },
  ];

  for (const candidate of candidates) {
    const realObjectSize = calculateDistance2D(
      candidate.worldLandmarks[0],
      candidate.worldLandmarks[1]
    );
    const focalLength = 1.5; // estimated
    const sizeOnSensor = calculateDistance2D(
      candidate.landmarks[0],
      candidate.landmarks[1]
    );
    const distanceToObject = (realObjectSize * focalLength) / sizeOnSensor;
    // console.log(
    //   'ears', candidate.keypoints3D[0].score, candidate.keypoints3D[1].score,
    //   candidate.keypoints3D[0], candidate.keypoints3D[1],
    //   "3D distance", calculateDistance(candidate.keypoints3D[0], candidate.keypoints3D[1]),
    //   "3D xy", realObjectSize,
    //   candidate.keypoints[0], candidate.keypoints[1],
    //   "2D", sizeOnSensor,
    //   "distance to object", distanceToObject);
    /*
    Distance to Object = Real Object size × Focal Length (mm) / Object size on sensor (mm)
    */
    // console.log("distance to object", distanceToObject);
    return distanceToObject;
  }
  return 1;
}

/**
 * Draws a stick figure on a canvas or SVG element based on the provided pose landmarks.
 * @param containerSize - The container size to constrain the stick figure.
 * @param results - The pose landmarks and other results used to draw the stick figure.
 * @param canvasCtx - Optional. The CanvasRenderingContext2D used for drawing on the canvas element.
 * @param svgElement - Optional. The SVGSVGElement used for drawing on the SVG element.
 * @param connectorThickness - Optional. The thickness of the connectors between body parts.
 */
export function drawStickFigure(
  containerSize: { width: number; height: number },
  results: PoseLandmarkerResult,
  canvasCtx?: CanvasRenderingContext2D,
  svgElement?: SVGSVGElement,
  connectorThickness = 1
) {
  if (!results.landmarks[0]) {
    return;
  }
  const svgNS = "http://www.w3.org/2000/svg";

  const leftEar = results.landmarks[0][POSE_LANDMARKS.LEFT_EAR];
  const rightEar = results.landmarks[0][POSE_LANDMARKS.RIGHT_EAR];
  const headCenter = {
    x: ((leftEar.x + rightEar.x) / 2) * containerSize.width,
    y: ((leftEar.y + rightEar.y) / 2) * containerSize.height,
    z: (leftEar.z + rightEar.z) / 2,
  };
  const headWidth =
    (0.6 / getDistance(results)) * containerSize.width * connectorThickness;
  const headRadius = headWidth / 2;
  if (canvasCtx) {
    // draw the head as an elipse
    canvasCtx.fillStyle = "white";
    canvasCtx.ellipse(
      headCenter.x,
      headCenter.y,
      headRadius,
      headRadius,
      0,
      0,
      2 * Math.PI
    );
    canvasCtx.fill();
  }
  if (svgElement) {
    // draw the head as an ellipse
    const head = document.createElementNS(svgNS, "ellipse");
    head.setAttribute("cx", toFixed3(headCenter.x));
    head.setAttribute("cy", toFixed3(headCenter.y));
    head.setAttribute("rx", toFixed3(headRadius));
    head.setAttribute("ry", toFixed3(headRadius));
    head.setAttribute("fill", "currentcolor");
    svgElement.appendChild(head);
  }

  // draw the limbs as rounded rectangles (for left and right: shoulder to elbow, elbow to wrist, hip to knee, knee to ankle)
  const limbs = [
    [POSE_LANDMARKS_LEFT.LEFT_SHOULDER, POSE_LANDMARKS_LEFT.LEFT_ELBOW],
    [POSE_LANDMARKS_LEFT.LEFT_ELBOW, POSE_LANDMARKS_LEFT.LEFT_WRIST],
    [POSE_LANDMARKS_LEFT.LEFT_WRIST, POSE_LANDMARKS_LEFT.LEFT_INDEX],
    [POSE_LANDMARKS_LEFT.LEFT_HIP, POSE_LANDMARKS_LEFT.LEFT_KNEE],
    [POSE_LANDMARKS_LEFT.LEFT_KNEE, POSE_LANDMARKS_LEFT.LEFT_HEEL],
    [POSE_LANDMARKS_LEFT.LEFT_HEEL, POSE_LANDMARKS_LEFT.LEFT_FOOT_INDEX],
    [POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER, POSE_LANDMARKS_RIGHT.RIGHT_ELBOW],
    [POSE_LANDMARKS_RIGHT.RIGHT_ELBOW, POSE_LANDMARKS_RIGHT.RIGHT_WRIST],
    [POSE_LANDMARKS_RIGHT.RIGHT_WRIST, POSE_LANDMARKS.RIGHT_INDEX],
    [POSE_LANDMARKS_RIGHT.RIGHT_HIP, POSE_LANDMARKS_RIGHT.RIGHT_KNEE],
    [POSE_LANDMARKS_RIGHT.RIGHT_KNEE, POSE_LANDMARKS_RIGHT.RIGHT_HEEL],
    [POSE_LANDMARKS_RIGHT.RIGHT_HEEL, POSE_LANDMARKS_RIGHT.RIGHT_FOOT_INDEX],
    [
      // and a box for the torso using shoulders and hips
      POSE_LANDMARKS.LEFT_SHOULDER,
      POSE_LANDMARKS.RIGHT_SHOULDER,
    ],
    [POSE_LANDMARKS.LEFT_HIP, POSE_LANDMARKS.RIGHT_HIP],
    [POSE_LANDMARKS.LEFT_SHOULDER, POSE_LANDMARKS.LEFT_HIP],
    [POSE_LANDMARKS.RIGHT_SHOULDER, POSE_LANDMARKS.RIGHT_HIP],
  ];

  for (const limb of limbs) {
    const start = results.landmarks[0][limb[0]];
    const end = results.landmarks[0][limb[1]];
    const limbWidth = headRadius * 0.8;
    const startThickness = getJointThickness(limb[0]);
    const endThickness = getJointThickness(limb[1]);
    const limbVector = {
      x: (end.x - start.x) * containerSize.width,
      y: (end.y - start.y) * containerSize.height,
    };
    const limbLength = Math.hypot(limbVector.x, limbVector.y);
    const limbRadius = limbWidth / 2;
    const angle = Math.atan2(limbVector.y, limbVector.x);

    if (canvasCtx) {
      canvasCtx.fillStyle = "white";

      // Calculate the points of the polygon
      const points = [
        { x: 0, y: -limbRadius * startThickness },
        { x: limbLength, y: -limbRadius * endThickness },
        { x: limbLength, y: limbRadius * endThickness },
        { x: 0, y: limbRadius * startThickness },
      ];

      canvasCtx.save();
      canvasCtx.translate(
        start.x * containerSize.width,
        start.y * containerSize.height
      );
      canvasCtx.rotate(angle);

      // Draw the polygon and the half-circle end caps as a single path
      canvasCtx.beginPath();
      canvasCtx.arc(
        0,
        0,
        limbRadius * startThickness,
        Math.PI / 2,
        (3 * Math.PI) / 2
      );
      canvasCtx.lineTo(points[0].x, points[0].y);
      for (let i = 1; i < points.length; i++) {
        canvasCtx.lineTo(points[i].x, points[i].y);
      }
      canvasCtx.arc(
        limbLength,
        0,
        limbRadius * endThickness,
        -Math.PI / 2,
        Math.PI / 2
      );
      canvasCtx.closePath();
      canvasCtx.fill();

      canvasCtx.restore();
    }
    if (svgElement) {
      // create a group to apply transformations
      const g = document.createElementNS(svgNS, "g");
      const angleInDegrees = angle * (180 / Math.PI);
      g.setAttribute(
        "transform",
        `translate(${toFixed3(start.x * containerSize.width)} ${toFixed3(
          start.y * containerSize.height
        )}) rotate(${toFixed3(angleInDegrees)})`
      );

      // draw the limb as a path with rounded corners and half-circle end caps
      const d = [
        `M0,${toFixed3(-limbRadius * startThickness)}`,
        `A${toFixed3(limbRadius * startThickness)},${toFixed3(
          limbRadius * startThickness
        )} 0 0,0 0,${toFixed3(limbRadius * startThickness)}`,
        `L${toFixed3(limbLength)},${toFixed3(limbRadius * endThickness)}`,
        `A${toFixed3(limbRadius * endThickness)},${toFixed3(
          limbRadius * endThickness
        )} 0 0,0 ${toFixed3(limbLength)},${toFixed3(
          -limbRadius * endThickness
        )}`,
        "Z",
      ].join(" ");

      const path = document.createElementNS(svgNS, "path");
      path.setAttribute("d", d);
      path.setAttribute("fill", "currentcolor");
      g.appendChild(path);

      svgElement.appendChild(g);
    }
  }

  const torsoLandmarks = [
    POSE_LANDMARKS.LEFT_SHOULDER,
    POSE_LANDMARKS.RIGHT_SHOULDER,
    POSE_LANDMARKS.RIGHT_HIP,
    POSE_LANDMARKS.LEFT_HIP,
  ];
  const torsoPoints = torsoLandmarks.map((landmark) => {
    return {
      x: results.landmarks[0][landmark].x * containerSize.width,
      y: results.landmarks[0][landmark].y * containerSize.height,
    };
  });
  if (canvasCtx) {
    // fill the polygon made by the hips and shoulders
    canvasCtx.fillStyle = "white";
    canvasCtx.beginPath();
    canvasCtx.moveTo(torsoPoints[0].x, torsoPoints[0].y);
    for (const point of torsoPoints) {
      canvasCtx.lineTo(point.x, point.y);
    }
    canvasCtx.closePath();
    canvasCtx.fill();
  }
  if (svgElement) {
    // draw the torso as a polygon
    const torso = document.createElementNS(svgNS, "polygon");
    torso.setAttribute(
      "points",
      torsoPoints.map((p) => `${toFixed3(p.x)},${toFixed3(p.y)}`).join(" ")
    );
    torso.setAttribute("fill", "currentcolor");
    svgElement.appendChild(torso);

    // Create an offscreen SVG element
    const offscreenSvg = document.createElementNS(svgNS, "svg");
    offscreenSvg.style.position = "absolute";
    offscreenSvg.style.top = "-9999px";
    offscreenSvg.style.left = "-9999px";
    document.body.appendChild(offscreenSvg);

    // Append the SVG element to the offscreen SVG
    offscreenSvg.appendChild(svgElement);

    // Calculate the bounding box
    const bbox = svgElement.getBBox();

    // Remove the SVG element from the offscreen SVG
    offscreenSvg.removeChild(svgElement);

    // Remove the offscreen SVG from the body
    document.body.removeChild(offscreenSvg);

    // Set the viewBox attribute to the bounding box of all the elements
    svgElement.setAttribute(
      "viewBox",
      `${toFixed3(bbox.x)} ${toFixed3(bbox.y)} ${toFixed3(
        bbox.width
      )} ${toFixed3(bbox.height)}`
    );
  }
}
