import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  useIonToast,
} from "@ionic/react";
import { FirebaseError } from "firebase/app";
import { User, verifyBeforeUpdateEmail } from "firebase/auth";
import React, { FormEvent, useEffect, useState } from "react";
import ButtonToolbar from "../common/ButtonToolbar";
import { background } from "@chakra-ui/react";

interface EditEmailFormProps {
  user: User | null;
  onCancel: () => void;
  onReauthenticate: () => void;
  onSuccess: (email: string) => void;
}

const EditEmailForm: React.FC<EditEmailFormProps> = ({
  user,
  onCancel,
  onReauthenticate,
  onSuccess,
}) => {
  const [email, setEmail] = useState(user?.email || "");
  const [originalEmail, setOriginalEmail] = useState(user?.email || "");
  const [isLoading, setIsLoading] = useState(false);
  const [requiresReauth, setRequiresReauth] = useState(false);
  const [present] = useIonToast();

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
      setOriginalEmail(user.email);
    }
  }, [user]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setIsLoading(true);
    try {
      await verifyBeforeUpdateEmail(user, email);
      present({
        message: "Please check your email to verify and complete the change",
        duration: 3000,
        color: "success",
      });
      setRequiresReauth(false);
      onSuccess(email);
    } catch (error) {
      const firebaseError = error as FirebaseError;
      if (firebaseError.code?.includes("requires-recent-login")) {
        setRequiresReauth(true);
        present({
          message: "Please reauthenticate to update email",
          duration: 3000,
          color: "warning",
        });
      } else {
        present({
          message: `Error updating email: ${firebaseError.message}`,
          duration: 3000,
          color: "danger",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isChanged = email !== originalEmail;
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  return (
    <form onSubmit={handleSubmit}>
      <IonItem style={{ "--background": "none" }}>
        <IonLabel position="stacked">Email</IonLabel>
        <IonInput
          type="email"
          value={email}
          onIonInput={(e) => setEmail(e.detail.value || "")}
          placeholder="Enter new email"
        />
      </IonItem>
      {isChanged && !isValidEmail && (
        <IonText color="danger">
          <p className="ion-padding-start">
            Please enter a valid email address.
          </p>
        </IonText>
      )}
      <IonText color="medium">
        <p className="ion-padding-start">
          Note: You will need to verify your new email address before the change
          takes effect.
        </p>
      </IonText>
      <ButtonToolbar>
        <IonButton slot="end" color="light" onClick={onCancel}>
          Cancel
        </IonButton>
        {requiresReauth ? (
          <IonButton slot="end" strong={true} onClick={onReauthenticate}>
            Reauthenticate
          </IonButton>
        ) : (
          <IonButton
            slot="end"
            strong={true}
            type="submit"
            disabled={!isChanged || !isValidEmail || isLoading}
          >
            Send Verification
          </IonButton>
        )}
      </ButtonToolbar>
    </form>
  );
};

export default EditEmailForm;
