import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import SubscribeForm from "../components/subscribe/SubscribeForm";
import "./Subscribe.css";

const Subscribe: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Subscribe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Subscribe</IonTitle>
          </IonToolbar>
        </IonHeader>
        <SubscribeForm />
      </IonContent>
    </IonPage>
  );
};

export default Subscribe;
