import { NormalizedLandmark } from "@mediapipe/tasks-vision";
import { JointAngle, calculateDistance } from "./pose-utils";
import {
  PoseDetectionFeedback,
  PoseDetectionFeedbackItem,
  SideFeedbackItem,
  ThresholdOperator,
} from "./pose-detection-feedback";
import { CriterionForEvaluator } from "./PoseDefinitionInterpreter";

export function formatAngleValue(value: number) {
  return isNaN(value) || value === Infinity
    ? ""
    : `${(value / Math.PI).toFixed(2)} (${((value / Math.PI) * 180).toFixed(
        0
      )}°)`;
}
export function formatAngle(jointAngle: JointAngle) {
  return jointAngle && ((jointAngle.angle / Math.PI) * 180).toFixed(0);
}

export function formatLandmark(landmark: NormalizedLandmark): string {
  return `[${landmark.x.toFixed(2)}, ${landmark.y.toFixed(
    2
  )}, ${landmark.z.toFixed(2)}] (${landmark.visibility?.toFixed(2)})`;
}

function getOppositeLandmarkIndex(landmarkIndex: number) {
  const offset = landmarkIndex % 2 === 0 ? -1 : 1;
  return landmarkIndex + offset;
}

/**
 * Calculate the approximate center of mass based on the the pose landmarks
 * @param poseLandmarks
 * @returns coordinate of the center of mass
 */
export function calculateCenterOfMass(
  poseLandmarks: NormalizedLandmark[]
): NormalizedLandmark {
  const weights = [
    // Head
    10, // Nose
    0, // Left eye (inner)
    5, // Left eye
    0, // Left eye (outer)
    0, // Right eye (inner)
    5, // Right eye
    0, // Right eye (outer)
    5, // Left ear
    5, // Right ear
    0, // Mouth (left)
    0, // Mouth (right)
    // Shoulders
    10, // Left shoulder
    10, // Right shoulder
    // Arms
    5, // Left elbow
    5, // Right elbow
    5, // Left wrist
    5, // Right wrist
    0, // Left pinky
    0, // Right pinky
    0, // Left index
    0, // Right index
    0, // Left thumb
    0, // Right thumb
    // Hips
    20, // Left hip
    20, // Right hip
    // Legs
    5, // Left knee
    5, // Right knee
    5, // Left ankle
    5, // Right ankle
    2, // Left heel
    2, // Right heel
    2, // Left foot index
    2, // Right foot index
  ];

  const numLandmarks = poseLandmarks.length;
  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;
  let totalWeight = 0;

  const visibilityThreshold = 0.4;
  for (let i = 0; i < numLandmarks; i++) {
    let landmark = poseLandmarks[i];
    const weight = weights[i];
    // if the landmark is not visible, try to use the opposite landmark
    // to calculate the center of mass
    if (landmark.visibility && landmark.visibility < visibilityThreshold) {
      const oppositeLandmarkIndex = getOppositeLandmarkIndex(i);
      if (oppositeLandmarkIndex >= 0) {
        const oppositeLandmark = poseLandmarks[oppositeLandmarkIndex];
        if (
          oppositeLandmark.visibility &&
          oppositeLandmark.visibility >= visibilityThreshold
        ) {
          // use an imaginary landmark on the other side of the body
          landmark = {
            ...oppositeLandmark,
            z: -oppositeLandmark.z,
          };
          // console.log(
          //   "imaginary landmark",
          //   i,
          //   formatLandmark(landmark),
          //   "weight",
          //   weight,
          //   "->",
          //   oppositeLandmarkIndex,
          //   formatLandmark(oppositeLandmark)
          // );
        }
      }
    }

    if (landmark.visibility && landmark.visibility >= visibilityThreshold) {
      sumX += landmark.x * weight;
      sumY += landmark.y * weight;
      sumZ += landmark.z * weight;
      totalWeight += weight;
    }
  }

  const centerX = sumX / totalWeight;
  const centerY = sumY / totalWeight;
  const centerZ = sumZ / totalWeight;

  return { x: centerX, y: centerY, z: centerZ, visibility: 1 };
}
/**
 * For a given joint on one side of the body, return the side feedback item
 */
export function getSideFeedback(
  sideValue: number,
  valueThreshold: number,
  joints: NormalizedLandmark[]
): SideFeedbackItem {
  return {
    value: sideValue,
    isMet: sideValue > valueThreshold,
    visibility: Math.min(...joints.map((joint) => joint?.visibility || 0)),
  };
}

export function combineFeedbackSides(
  base: {
    criterion: CriterionForEvaluator;
    min: number;
    max: number;
    startThreshold: number;
    endThreshold: number;
    thresholdOperator: ThresholdOperator;
    visibilityThreshold: number;
    format: (value: number) => string;
  },
  left: { value: number; isMet: boolean; visibility: number },
  right: { value: number; isMet: boolean; visibility: number }
): PoseDetectionFeedbackItem {
  const leftVisible = left.visibility > base.visibilityThreshold;
  const rightVisible = right.visibility > base.visibilityThreshold;
  const visibleValues: number[] = [];
  leftVisible && visibleValues.push(left.value);
  rightVisible && visibleValues.push(right.value);
  return {
    ...base,
    sides: {
      left,
      right,
    },
    value: Math.min(...visibleValues),
    isMet:
      // at least one side must be visible
      (base.criterion.onlyRequireIfVisible || leftVisible || rightVisible) &&
      // and for each side, if visible then it must be met
      (!leftVisible || left.isMet) &&
      (!rightVisible || right.isMet),
    visibility: Math.max(left.visibility, right.visibility),
  };
}

/**
 * Combine the feedback from the left and right sides of the body
 * where the condition is met if either side is met
 */
export function combineFeedbackSidesOr(
  base: {
    criterion: CriterionForEvaluator;
    min: number;
    max: number;
    startThreshold: number;
    endThreshold: number;
    thresholdOperator: ThresholdOperator;
    visibilityThreshold: number;
    format: (value: number) => string;
  },
  left: { value: number; isMet: boolean; visibility: number },
  right: { value: number; isMet: boolean; visibility: number },
  side?: "left" | "right"
): PoseDetectionFeedbackItem {
  // to be eligible, a side must be visible and not excluded based on the side parameter
  const leftEligible =
    side !== "right" && left.visibility > base.visibilityThreshold;
  const rightEligible =
    side !== "left" && right.visibility > base.visibilityThreshold;
  const eligibleValues: number[] = [];
  let visibility = 0;
  if (leftEligible) {
    eligibleValues.push(left.value);
    visibility = left.visibility;
  }
  if (rightEligible) {
    eligibleValues.push(right.value);
    visibility = Math.max(visibility, right.visibility);
  }
  return {
    ...base,
    sides: {
      left,
      right,
    },
    value: Math.max(...eligibleValues),
    isMet:
      // at least one side must be eligible
      (leftEligible || rightEligible) &&
      // and one eligible side must be met
      ((leftEligible && left.isMet) || (rightEligible && right.isMet)),
    visibility,
  };
}

export function checkJointsStraight(
  criterion: CriterionForEvaluator,
  isInPose: boolean,
  poseLandmarks: NormalizedLandmark[],
  jointAnglesMap: { [key: number]: JointAngle },
  poseDetectionFeedback: PoseDetectionFeedback,
  visibilityThreshold: number,
  feedbackKey: string,
  landmarksLeft: [number, number, number],
  landmarksRight: [number, number, number],
  startThreshold = Math.PI * 0.85,
  endThreshold = Math.PI * 0.75
): boolean {
  validateThresholds(feedbackKey, startThreshold, endThreshold);
  const base = {
    criterion,
    min: 0,
    max: Math.PI,
    startThreshold,
    endThreshold,
    thresholdOperator: ThresholdOperator.GreaterThan,
    visibilityThreshold,
    format: formatAngleValue,
  };
  const threshold = isInPose ? base.endThreshold : base.startThreshold;
  const jointAngles = [landmarksLeft[1], landmarksRight[1]].map(
    (key) => jointAnglesMap[key]
  );
  const [leftJointAngle, rightJointAngle] = jointAngles;
  poseDetectionFeedback[feedbackKey] = combineFeedbackSides(
    base,
    getSideFeedback(leftJointAngle?.angle, threshold, [
      poseLandmarks[landmarksLeft[0]],
      poseLandmarks[landmarksLeft[1]],
      poseLandmarks[landmarksLeft[2]],
    ]),
    getSideFeedback(rightJointAngle?.angle, threshold, [
      poseLandmarks[landmarksRight[0]],
      poseLandmarks[landmarksRight[1]],
      poseLandmarks[landmarksRight[2]],
    ])
  );
  return poseDetectionFeedback[feedbackKey].isMet;
}

export function checkJointsBent(
  criterion: CriterionForEvaluator,
  isInPose: boolean,
  poseLandmarks: NormalizedLandmark[],
  jointAnglesMap: { [key: number]: JointAngle },
  poseDetectionFeedback: PoseDetectionFeedback,
  visibilityThreshold: number,
  feedbackKey: string,
  landmarksLeft: [number, number, number],
  landmarksRight: [number, number, number],
  startThreshold = Math.PI * 0.6,
  endThreshold = Math.PI * 0.5
) {
  validateThresholds(feedbackKey, startThreshold, endThreshold);
  const base = {
    criterion,
    min: 0,
    max: Math.PI,
    startThreshold,
    endThreshold,
    thresholdOperator: ThresholdOperator.GreaterThan,
    visibilityThreshold,
    format: formatAngleValue,
  };

  const threshold = isInPose ? base.endThreshold : base.startThreshold;
  const jointAngles = [landmarksLeft[1], landmarksRight[1]].map(
    (key) => jointAnglesMap[key]
  );
  const [leftJointAngle, rightJointAngle] = jointAngles;
  poseDetectionFeedback[feedbackKey] = combineFeedbackSides(
    base,
    getSideFeedback(Math.PI - leftJointAngle?.angle, threshold, [
      poseLandmarks[landmarksLeft[0]],
      poseLandmarks[landmarksLeft[1]],
      poseLandmarks[landmarksLeft[2]],
    ]),
    getSideFeedback(Math.PI - rightJointAngle?.angle, threshold, [
      poseLandmarks[landmarksRight[0]],
      poseLandmarks[landmarksRight[1]],
      poseLandmarks[landmarksRight[2]],
    ])
  );
  return poseDetectionFeedback[feedbackKey].isMet;
}

export function checkLandmarksStacked(
  criterion: CriterionForEvaluator,
  isInPose: boolean,
  poseLandmarks: NormalizedLandmark[],
  poseDetectionFeedback: PoseDetectionFeedback,
  visibilityThreshold: number,
  feedbackKey: string,
  landmarksLeft: [number, number],
  landmarksRight: [number, number],
  startThreshold = -0.25,
  endThreshold = -0.3
): boolean {
  validateThresholds(feedbackKey, startThreshold, endThreshold);
  const landmarksStackedBase = {
    criterion,
    min: -0.5,
    max: 0,
    startThreshold,
    endThreshold,
    thresholdOperator: ThresholdOperator.GreaterThan,
    visibilityThreshold,
    format: (value: number) => value.toFixed(2),
  };
  const stackedThreshold = isInPose
    ? landmarksStackedBase.endThreshold
    : landmarksStackedBase.startThreshold;
  const leftLandmark0 = poseLandmarks[landmarksLeft[0]];
  const leftLandmark1 = poseLandmarks[landmarksLeft[1]];
  const rightLandmark0 = poseLandmarks[landmarksRight[0]];
  const rightLandmark1 = poseLandmarks[landmarksRight[1]];
  poseDetectionFeedback[feedbackKey] = combineFeedbackSides(
    landmarksStackedBase,
    getSideFeedback(
      -Math.hypot(
        leftLandmark0.x - leftLandmark1.x,
        leftLandmark0.z - leftLandmark1.z
      ),
      stackedThreshold,
      [leftLandmark0, leftLandmark1]
    ),
    getSideFeedback(
      -Math.hypot(
        rightLandmark0.x - rightLandmark1.x,
        rightLandmark0.z - rightLandmark1.z
      ),
      stackedThreshold,
      [rightLandmark0, rightLandmark1]
    )
  );
  return poseDetectionFeedback[feedbackKey].isMet;
}

export function checkLandmarksAbove(
  criterion: CriterionForEvaluator,
  isInPose: boolean,
  poseLandmarks: NormalizedLandmark[],
  poseDetectionFeedback: PoseDetectionFeedback,
  visibilityThreshold: number,
  feedbackKey: string,
  landmarksLower: [number, number],
  landmarksUpper: [number, number],
  startThreshold = 0,
  endThreshold = -0.1
): boolean {
  validateThresholds(feedbackKey, startThreshold, endThreshold);
  if (!poseLandmarks) return false;

  const leftLower = poseLandmarks[landmarksLower[0]];
  const rightLower = poseLandmarks[landmarksLower[1]];
  const leftUpper = poseLandmarks[landmarksUpper[0]];
  const rightUpper = poseLandmarks[landmarksUpper[1]];

  const base = {
    criterion,
    min: -0.5,
    max: 0.5,
    startThreshold: startThreshold,
    endThreshold: endThreshold,
    thresholdOperator: ThresholdOperator.GreaterThan,
    visibilityThreshold,
    format: (value: number) => value.toFixed(2),
    activeLandmarkIndexes: [
      landmarksLower[0],
      landmarksLower[1],
      landmarksUpper[0],
      landmarksUpper[1],
    ],
  };
  const threshold = isInPose ? base.endThreshold : base.startThreshold;
  poseDetectionFeedback[feedbackKey] = combineFeedbackSides(
    base,
    getSideFeedback(leftLower.y - leftUpper.y, threshold, [
      leftUpper,
      leftLower,
    ]),
    getSideFeedback(rightLower.y - rightUpper.y, threshold, [
      rightUpper,
      rightLower,
    ])
  );

  return poseDetectionFeedback[feedbackKey].isMet;
}

export function checkLandmarksApart(
  criterion: CriterionForEvaluator,
  isInPose: boolean,
  poseLandmarks: NormalizedLandmark[],
  poseDetectionFeedback: PoseDetectionFeedback,
  visibilityThreshold: number,
  feedbackKey: string,
  landmarkIndexes: [number, number],
  startThreshold = 0.25,
  endThreshold = 0.15
) {
  validateThresholds(feedbackKey, startThreshold, endThreshold);

  const landmark0 = poseLandmarks[landmarkIndexes[0]];
  const landmark1 = poseLandmarks[landmarkIndexes[1]];
  const base = {
    criterion,
    min: 0,
    max: 1,
    startThreshold,
    endThreshold,
    thresholdOperator: ThresholdOperator.GreaterThan,
    visibilityThreshold,
    format: (value: number) => value.toFixed(2),
  };
  const threshold = isInPose ? base.endThreshold : base.startThreshold;
  const apartDistance = calculateDistance(landmark0, landmark1);
  const visibility = Math.min(
    landmark0.visibility || 0,
    landmark1.visibility || 0
  );
  poseDetectionFeedback[feedbackKey] = {
    ...base,
    value: apartDistance,
    isMet: visibility > visibilityThreshold && apartDistance > threshold,
    visibility,
  };
  return poseDetectionFeedback[feedbackKey].isMet;
}

export function checkLandmarksSameHeight(
  criterion: CriterionForEvaluator,
  isInPose: boolean,
  poseLandmarks: NormalizedLandmark[],
  poseDetectionFeedback: PoseDetectionFeedback,
  visibilityThreshold: number,
  feedbackKey: string,
  landmarks0: [number, number],
  landmarks1: [number, number],
  startThreshold = -0.2,
  endThreshold = -0.25
) {
  validateThresholds(feedbackKey, startThreshold, endThreshold);
  const left0 = poseLandmarks[landmarks0[0]];
  const right0 = poseLandmarks[landmarks0[1]];
  const left1 = poseLandmarks[landmarks1[0]];
  const right1 = poseLandmarks[landmarks1[1]];

  const base = {
    criterion,
    min: -0.5,
    max: 0,
    startThreshold,
    endThreshold,
    thresholdOperator: ThresholdOperator.GreaterThan,
    visibilityThreshold,
    format: (value: number) => value.toFixed(2),
  };
  const threshold = isInPose ? base.endThreshold : base.startThreshold;
  poseDetectionFeedback[feedbackKey] = combineFeedbackSides(
    base,
    getSideFeedback(-Math.abs(left0.y - left1.y), threshold, [left0, left1]),
    getSideFeedback(-Math.abs(right0.y - right1.y), threshold, [right0, right1])
  );

  return poseDetectionFeedback[feedbackKey].isMet;
}

export function validateThresholds(
  feedbackKey: string,
  startThreshold: number,
  endThreshold: number
) {
  if (startThreshold < endThreshold) {
    throw new Error(
      `Invalid thresholds for ${feedbackKey}: startThreshold (${startThreshold}) must be greater than endThreshold (${endThreshold})`
    );
  }
}
