import { Capacitor } from "@capacitor/core";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  Auth,
} from "firebase/auth";
import { firebaseService } from "../../services/FirebaseService";

let authInstance: Auth | null = null;

export const getFirebaseAuth = async (): Promise<Auth> => {
  await firebaseService.initialize();

  if (!authInstance) {
    if (Capacitor.isNativePlatform()) {
      authInstance = initializeAuth(firebaseService.getApp(), {
        persistence: indexedDBLocalPersistence,
      });
    } else {
      authInstance = getAuth(firebaseService.getApp());
    }
  }

  return authInstance;
};
