import { FirebaseOptions } from "firebase/app";
import { FirebaseApp } from "@capacitor-firebase/app";
import { Capacitor } from "@capacitor/core";

const defaultConfig: FirebaseOptions = {
  apiKey: "AIzaSyCI6yZEKwpmawg21rrYUojEPNqsXdQCN8M",
  authDomain: "movejourney.app",
  databaseURL: "https://pose-challenges-default-rtdb.firebaseio.com",
  projectId: "pose-challenges",
  storageBucket: "pose-challenges.appspot.com",
  messagingSenderId: "392734389374",
  appId: "1:392734389374:web:11a533695efa8b4d18142f",
  measurementId: "G-RV5EH9C3YH",
};

export async function getFirebaseConfig(): Promise<FirebaseOptions> {
  try {
    const capacitorOptions = Capacitor.isNativePlatform()
      ? await FirebaseApp.getOptions()
      : { applicationId: defaultConfig.appId };
    console.log("[LM-01] Got Firebase options", {
      defaultAppId: defaultConfig.appId,
      ...(capacitorOptions.applicationId === defaultConfig.appId
        ? {}
        : {
            capacitorAppId: capacitorOptions.applicationId,
          }),
    });
    return {
      ...defaultConfig,
      appId: capacitorOptions.applicationId,
    };
  } catch (error) {
    console.error("Error fetching Firebase options:", error);
    return defaultConfig;
  }
}
