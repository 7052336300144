import {
  DocumentReference,
  Firestore,
  FirestoreDataConverter,
  QueryConstraint,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import {
  FirebaseUserMetadata,
  MoveJourneyUserMetadata,
  User,
} from "../user/User";

// export the User type from the database module
export { type User, type MoveJourneyUserMetadata, type FirebaseUserMetadata };
export class UsersDao {
  firestore: Firestore;
  constructor() {
    this.firestore = getFirestore();
  }
  async createUser(user: User): Promise<string> {
    const userDoc = doc(this.firestore, `users/${user.id}`).withConverter(
      userConverter
    );
    await setDoc(userDoc, user);
    return user.id;
  }
  async getUsers(): Promise<{ [key: string]: User }> {
    const usersCollection = collection(this.firestore, `users`).withConverter(
      userConverter
    );
    const userSnapshot = await getDocs(usersCollection);
    const users: { [key: string]: User } = {};
    userSnapshot.forEach((doc) => {
      users[doc.id] = doc.data() as User;
    });
    return users;
  }
  async getUsersById(userIds: string[]): Promise<User[]> {
    const users: User[] = [];
    for (const id of userIds) {
      const user = await this.getUser(id);
      if (user) {
        users.push(user);
      }
    }
    return users;
  }
  async getUser(userId: string): Promise<User> {
    const userDoc = doc(this.firestore, `users/${userId}`).withConverter(
      userConverter
    );
    const userSnapshot = await getDoc(userDoc);
    return userSnapshot.data() as User;
  }
  async saveUser(user: User): Promise<void> {
    const userDoc = doc(this.firestore, `users/${user.id}`).withConverter(
      userConverter
    );
    await setDoc(userDoc, user);
  }
  async updateUser(userId: string, user: Partial<User>): Promise<void> {
    const userDoc = doc(this.firestore, `users/${userId}`).withConverter(
      userConverter
    );
    await updateDoc(userDoc, user);
  }
  async acknowledgeAnnouncement(userId: string, key: string): Promise<void> {
    const userDoc = doc(this.firestore, `users/${userId}`).withConverter(
      userConverter
    );
    await updateDoc(userDoc, {
      announcementsAcknowledged: arrayUnion(key),
    });
  }
  async unacknowledgeAnnouncement(userId: string, key: string): Promise<void> {
    const userDoc = doc(this.firestore, `users/${userId}`).withConverter(
      userConverter
    );
    await updateDoc(userDoc, {
      announcementsAcknowledged: arrayRemove(key),
    });
  }
  async grantConsent(userId: string, key: string): Promise<void> {
    const userDoc = doc(this.firestore, `users/${userId}`).withConverter(
      userConverter
    );
    await updateDoc(userDoc, {
      consentItemsGranted: arrayUnion(key),
    });
  }
  async revokeConsent(userId: string, key: string): Promise<void> {
    const userDoc = doc(this.firestore, `users/${userId}`).withConverter(
      userConverter
    );
    await updateDoc(userDoc, {
      consentItemsGranted: arrayRemove(key),
    });
  }
}

const userConverter: FirestoreDataConverter<User> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data() as User;
    data.id = snapshot.id;
    // Perform any necessary data transformations here
    if (data.lastOnline) {
      data.lastOnline = new Date(data.lastOnline);
    }
    return data;
  },
  toFirestore: (user) => {
    // Perform any necessary data transformations here
    const { id, ...userWithoutId } = user;
    if (userWithoutId.lastOnline && userWithoutId.lastOnline instanceof Date) {
      userWithoutId.lastOnline = userWithoutId.lastOnline.getTime();
    }
    return userWithoutId;
  },
};

export type UseUserResult = [User | undefined, boolean, Error | undefined];

export const useUser = (userId: string): UseUserResult => {
  if (!userId || userId.length === 0 || userId === "bot") {
    useDocumentData<User>();
    return [undefined, false, undefined];
  }
  const userRef: DocumentReference<User> = doc(
    getFirestore(),
    `users/${userId}`
  ).withConverter(userConverter);
  const [user, loading, error] = useDocumentData<User>(userRef);
  if (error) {
    // ensure that the error is at least visible in the console
    console.error(error);
  }
  return [user, loading, error];
};

export const useUsers = (
  ...constraints: QueryConstraint[]
): [User[] | undefined, boolean, Error | undefined] => {
  const usersRef = query(
    collection(getFirestore(), `users`).withConverter(userConverter),
    ...constraints
  );
  const [users, loading, error] = useCollectionData<User>(usersRef);
  if (error) {
    // ensure that the error is at least visible in the console
    console.error(error);
  }
  return [users, loading, error];
};
