import { useColorModeValue, useToken } from "@chakra-ui/react";
import {
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
} from "react-select";

export function useColorModeAwareSelectStyles<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>() {
  const [gray100, gray300, gray700, gray800] = useToken("colors", [
    "gray.100",
    "gray.300",
    "gray.700",
    "gray.800",
  ]);
  const bgColor = useColorModeValue("white", gray700);
  const textColor = useColorModeValue("black", "white");
  const focusBgColor = useColorModeValue(gray100, gray800);
  const focusTextColor = useColorModeValue("black", "white");
  const indicatorHoverColor = useColorModeValue(gray800, gray300);

  return {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      backgroundColor: bgColor,
      color: textColor,
    }),
    option: (
      provided: CSSObjectWithLabel,
      state: OptionProps<Option, IsMulti, Group>
    ) => ({
      ...provided,
      backgroundColor: state.isFocused ? focusBgColor : bgColor,
      color: state.isFocused ? focusTextColor : "inherit",
    }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      backgroundColor: bgColor,
      color: textColor,
      zIndex: 2, // ensure the menu is above any sliders or similar controls
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: textColor,
    }),
    input: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: textColor,
    }),
    dropdownIndicator: (
      provided: CSSObjectWithLabel,
      state: DropdownIndicatorProps<Option, IsMulti, Group>
    ) => ({
      ...provided,
      color: state.isFocused ? focusTextColor : "inherit",
      "&:hover": {
        color: indicatorHoverColor,
      },
    }),
  };
}
