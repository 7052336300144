import { openDB, DBSchema } from "idb";
import { PoseEvent } from "./pose-events/PoseEvent";

interface MyDB extends DBSchema {
  poseEvents: {
    key: string;
    value: PoseEvent;
    indexes: { "by-start": number };
  };
}

const dbPromise = openDB<MyDB>("handstand-timer-db", 1, {
  upgrade(db) {
    const store = db.createObjectStore("poseEvents", {
      keyPath: "id",
      autoIncrement: true,
    });
    store.createIndex("by-start", "startAt");
  },
});

export async function addPoseEvent(poseEvent: PoseEvent): Promise<string> {
  const db = await dbPromise;
  return db.add("poseEvents", poseEvent);
}

export async function getPoseEvents(): Promise<PoseEvent[]> {
  const db = await dbPromise;
  return db.getAllFromIndex("poseEvents", "by-start");
}

export async function getPoseEvent(id: string): Promise<PoseEvent | undefined> {
  const db = await dbPromise;
  return db.get("poseEvents", id);
}

// export async function updatePoseEvent(
//   id: string,
//   poseEvent: PoseEvent
// ): Promise<void> {
//   const db = await dbPromise;
//   return db.put("poseEvents", { ...poseEvent, id });
// }

export async function deletePoseEvent(id: string): Promise<void> {
  const db = await dbPromise;
  return db.delete("poseEvents", id);
}

export async function clearAllPoseEvents(): Promise<void> {
  const db = await dbPromise;
  return db.clear("poseEvents");
}

export async function getRecentPoseEvents(
  limit: number = 10
): Promise<PoseEvent[]> {
  const db = await dbPromise;
  const tx = db.transaction("poseEvents", "readonly");
  const index = tx.store.index("by-start");

  let cursor = await index.openCursor(null, "prev");
  const events: PoseEvent[] = [];

  while (cursor && events.length < limit) {
    events.push(cursor.value);
    cursor = await cursor.continue();
  }

  return events;
}
