import { useCallback, useContext, useState } from "react";
import { useTimeoutEffect } from "react-timing-hooks";
import { SettingsContext } from "../../settings/SettingsContext";

export const useDetectionGuidanceMuteInterval = () => {
  const { detectionGuidanceMutedUntil } = useContext(SettingsContext);

  const [isMuteIntervalActive, setIsMuteIntervalActive] = useState(false);

  const evaluateMuteInterval = useCallback(() => {
    setIsMuteIntervalActive(detectionGuidanceMutedUntil > Date.now());
  }, [detectionGuidanceMutedUntil]);

  useTimeoutEffect(
    (timeout, clearAll) => {
      evaluateMuteInterval();
      clearAll();
      if (detectionGuidanceMutedUntil > Date.now()) {
        timeout(() => {
          evaluateMuteInterval();
        }, detectionGuidanceMutedUntil - Date.now());
      }
    },
    [detectionGuidanceMutedUntil]
  );

  return isMuteIntervalActive;
};
