import { MembraneSynth, MetalSynth, PluckSynth, PolySynth, Synth } from "tone";

export const synth = new Synth().toDestination();
export const pluckSynth = new PluckSynth({
  attackNoise: 0.5,
  dampening: 200,
  resonance: 0.96,
  release: 500,
}).toDestination();
export const gongSynth = new MetalSynth().toDestination();
export const kickSynth = new MembraneSynth().toDestination();
export const sharpKickSynth = new MembraneSynth({
  pitchDecay: 0.05, // set pitch decay to 0.05 for a shorter decay time
  octaves: 4,
  oscillator: {
    type: "sine",
  },
  envelope: {
    attack: 0.001,
    decay: 0.1, // set decay to 0.1 for a shorter decay time
    sustain: 0,
    release: 0.1,
  },
}).toDestination();
