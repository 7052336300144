import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";

type ActiveTabContextType = {
  activeTab: string;
};

const ActiveTabContext = createContext<ActiveTabContextType | undefined>(
  undefined
);

export const ActiveTabProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeTab, setActiveTab] = useState("/tab1");
  const location = useLocation();

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  return (
    <ActiveTabContext.Provider value={{ activeTab }}>
      {children}
    </ActiveTabContext.Provider>
  );
};

export const useActiveTab = () => {
  const context = useContext(ActiveTabContext);
  if (context === undefined) {
    throw new Error("useActiveTab must be used within an ActiveTabProvider");
  }
  return context;
};
