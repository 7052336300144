import { getAnalytics, logEvent } from "firebase/analytics";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { UsersDao } from "../../services/firestore/UsersDao";
import {
  FirebaseUserMetadata,
  MoveJourneyUserMetadata,
} from "../../services/user/User";
import { usePersistentStorageValue } from "../settings/usePersistentStorageValue";
import { firebaseService } from "../../services/FirebaseService";

const expectAutomaticSignInKey = "expectAutomaticSignIn";

export const useAuthStateObserver = () => {
  const [expectLoggedIn, setExpectLoggedIn] = usePersistentStorageValue(
    "expectLoggedIn",
    false
  );
  const [hasRegistered, setHasRegistered] = usePersistentStorageValue(
    "hasRegistered",
    false
  );
  const [userId, setUserId] = useState<string>();
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(true);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const initAuth = async () => {
      await firebaseService.initialize();
      const auth = getAuth(firebaseService.getApp());
      const analytics = getAnalytics(firebaseService.getApp());

      unsubscribe = onAuthStateChanged(auth, async (user) => {
        console.log("onAuthStateChanged in useAuthStateObserver", user);

        if (user?.uid == null) {
          localStorage.removeItem(expectAutomaticSignInKey);
          setIsLoggingIn(false);
          setUserId(undefined);
          setExpectLoggedIn(false);
          return;
        }

        localStorage.setItem(expectAutomaticSignInKey, "1");
        setExpectLoggedIn(true);

        logEvent(analytics, "login", {
          method: user?.providerData[0]?.providerId,
        });

        const usersDao = new UsersDao();
        await usersDao.getUser(user?.uid).then(async (existingUser) => {
          let metadata: MoveJourneyUserMetadata | undefined;

          if (user?.metadata) {
            const userMetadata = user.metadata as FirebaseUserMetadata;
            metadata = {
              creationTime: user.metadata.creationTime,
              lastSignInTime: user.metadata.lastSignInTime,
            };

            if (userMetadata.createdAt) {
              try {
                metadata.createdAt = parseInt(
                  userMetadata.createdAt.toString()
                );
              } catch (e) {
                console.error(
                  "error parsing createdAt",
                  userMetadata.createdAt
                );
              }
            }

            if (userMetadata.lastLoginAt) {
              try {
                metadata.lastLoginAt = parseInt(
                  userMetadata.lastLoginAt.toString()
                );
              } catch (e) {
                console.error(
                  "error parsing lastLoginAt",
                  userMetadata.lastLoginAt
                );
              }
            }
          }

          if (!existingUser) {
            await usersDao.createUser({
              id: user?.uid,
              name: user.displayName || "Anonymous",
              isAnonymous: user?.isAnonymous,
              ...(metadata && { metadata }),
              previousPoseDuration: 0,
              useProfilePhoto: user?.photoURL != null,
              photoURL: user?.photoURL,
              connections: [],
              announcementsAcknowledged: [],
              consentItemsGranted: [],
            });
            setHasRegistered(true);
          } else {
            await usersDao.updateUser(user?.uid, {
              photoURL: user?.photoURL,
              ...(metadata && { metadata }),
            });
          }

          if (user?.uid != null) {
            setUserId(user?.uid);
          }
          setIsLoggingIn(false);
          console.log("[LM-a01] User logged in");
        });
      });
    };

    initAuth();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return { userId, isLoggingIn, expectLoggedIn, hasRegistered };
};
