import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React from "react";
import { PoseEvent } from "../services/pose-events/PoseEvent";
import PoseIcon from "./pose-detection/PoseIcon";
import { CurrentPoseAggregateSummary } from "./pose-events/CurrentPoseAggregateSummary";
import { PoseIds } from "./types";

interface PoseEventSummaryProps {
  events: PoseEvent[];
  onDetectPose: (poseId: PoseIds) => void;
  aggregateDurations: Record<
    string,
    {
      range0: number;
      range1: number;
      range2: number;
    }
  > | null;
  aggregateLoading: boolean;
  aggregateError: Error | null;
}

const formatAggregateTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${hours}h ${minutes}m ${remainingSeconds}s`;
};

export const PoseEventSummary: React.FC<PoseEventSummaryProps> = ({
  events,
  onDetectPose,
  aggregateDurations,
  aggregateLoading,
  aggregateError,
}) => {
  const formatDuration = (duration: number) =>
    `${(duration / 1000).toFixed(1)}s`;

  const formatDate = (timestamp: number) => {
    return new Date(timestamp).toLocaleDateString();
  };

  const groupedEvents = events.reduce((acc, event) => {
    if (!acc[event.poseId]) {
      acc[event.poseId] = [];
    }
    acc[event.poseId].push(event);
    return acc;
  }, {} as Record<string, PoseEvent[]>);

  console.log("Render PoseEventSummary", { events, aggregateDurations });
  return (
    <Box>
      <Heading size="md" mb={4}>
        Recent Pose Events
      </Heading>
      {Object.keys(groupedEvents).length === 0 ? (
        <Text>No recent pose events found.</Text>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
          {Object.entries(groupedEvents).map(([poseId, poseEvents]) => (
            <Box
              key={poseId}
              borderWidth="1px"
              borderRadius="md"
              p={4}
              boxShadow="md"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                gap={3}
                wrap="wrap"
              >
                <Flex alignItems="center">
                  <PoseIcon pose={poseId as PoseIds} boxSize={8} mr={2} />
                  <Badge colorScheme="blue">{poseId}</Badge>
                </Flex>
                <Button
                  colorScheme="teal"
                  onClick={() => onDetectPose(poseId as PoseIds)}
                >
                  Detect Again
                </Button>
              </Flex>
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton paddingInline={0}>
                        <Box flex="1" textAlign="left">
                          {isExpanded ? (
                            <Text>
                              {poseEvents.length}{" "}
                              {pluralize("event", poseEvents.length)}
                            </Text>
                          ) : (
                            <Text>
                              {pluralize("Duration", poseEvents.length)}:{" "}
                              {poseEvents
                                .map((event) => formatDuration(event.duration))
                                .join(", ")}
                            </Text>
                          )}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel paddingInline={0}>
                        <VStack align="stretch" spacing={2}>
                          {poseEvents.map((event, index) => (
                            <Flex key={index} justifyContent="space-between">
                              <Text fontSize="sm">
                                Duration: {formatDuration(event.duration)}
                              </Text>
                              <Text fontSize="sm" color="gray.500">
                                {formatDate(event.startAt)}
                              </Text>
                            </Flex>
                          ))}
                          {aggregateDurations && aggregateDurations[poseId] && (
                            <CurrentPoseAggregateSummary
                              aggregateDurations={aggregateDurations[poseId]}
                              aggregateLoading={aggregateLoading}
                              aggregateError={aggregateError}
                            />
                          )}
                        </VStack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
};
