import { FaGlobe, FaApple, FaAndroid } from "react-icons/fa";

export const waitlistTags = [
  {
    id: 5313383,
    name: "Web App Waitlist",
    description: "Updates for the web version of Move Journey",
    icon: FaGlobe,
  },
  {
    id: 5313384,
    name: "iOS App Waitlist",
    description: "Updates for the iOS version of Move Journey",
    icon: FaApple,
  },
  {
    id: 5313385,
    name: "Android App Waitlist",
    description: "Updates for the Android version of Move Journey",
    icon: FaAndroid,
  },
];
