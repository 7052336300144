export function poseIdToFileName(poseId: string) {
  return poseId
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/'/g, "")
    .replace(/"/g, "")
    .replace(/,/g, "")
    .replace(/:/g, "")
    .replace(/;/g, "")
    .replace(/\./g, "")
    .replace(/\?/g, "")
    .replace(/\!/g, "")
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(/\[/g, "")
    .replace(/\]/g, "")
    .replace(/\{/g, "")
    .replace(/\}/g, "");
}
