import { Camera, CameraPermissionState } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { isInAppBrowser } from "../isInAppBrowser";

const POLLING_INTERVAL = 1000;
const PROMPT_DELAY = 500; // Delay in ms to consider 'prompt' state as valid

function mapCameraPermissionToPermissionState(
  state: CameraPermissionState
): PermissionState {
  switch (state) {
    case "granted":
      return "granted";
    case "denied":
      return "denied";
    case "prompt":
    case "prompt-with-rationale":
      return "prompt";
    default:
      return "prompt"; // Or whatever default you want to use
  }
}
export const useCameraPermissionDetector = () => {
  const [cameraPermission, setCameraPermission] = useState<string>();
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const promptTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  function clearIntervalAndReset() {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    if (promptTimeoutRef.current) {
      clearTimeout(promptTimeoutRef.current);
      promptTimeoutRef.current = null;
    }
  }

  const setPermissionWithDelay = useCallback((state: PermissionState) => {
    if (state === "prompt") {
      if (promptTimeoutRef.current) {
        clearTimeout(promptTimeoutRef.current);
      }
      promptTimeoutRef.current = setTimeout(() => {
        setCameraPermission(state);
      }, PROMPT_DELAY);
    } else {
      if (promptTimeoutRef.current) {
        clearTimeout(promptTimeoutRef.current);
      }
      setCameraPermission(state);
    }
  }, []);

  /**
   * Query camera permission status.
   *
   * For native platforms, it first checks the permission using Capacitor's Camera plugin,
   * then queries using navigator.permissions to return a PermissionStatus object.
   *
   * For web platforms, it directly uses navigator.permissions.query.
   *
   * @returns {Promise<PermissionStatus>} A promise that resolves to a PermissionStatus object,
   * which is an event emitter that allows listening for subsequent permission changes.
   */
  const queryPermission = useCallback(async () => {
    if (Capacitor.isNativePlatform()) {
      try {
        const permissionStatus = await Camera.checkPermissions();
        console.log("permission query", { state: permissionStatus.camera });
        const mappedPermissionState = mapCameraPermissionToPermissionState(
          permissionStatus.camera
        );
        setPermissionWithDelay(mappedPermissionState);
        if (permissionStatus.camera === "granted") {
          clearIntervalAndReset();
          console.log("[LM-03] Camera permission granted (queryPermission)");
        }
        return navigator.permissions.query({
          name: "camera" as PermissionName,
        });
      } catch (error) {
        console.error("Error checking camera permissions:", error);
        throw error;
      }
    } else {
      return navigator.permissions
        .query({ name: "camera" as PermissionName })
        .then((permissionStatus) => {
          console.log("permission query", { state: permissionStatus.state });
          setPermissionWithDelay(permissionStatus.state);
          if (permissionStatus.state === "granted") {
            clearIntervalAndReset();
            console.log("[LM-03] Camera permission granted (queryPermission)");
          }
          return permissionStatus;
        });
    }
  }, [setPermissionWithDelay]);

  useEffect(() => {
    console.log("useCameraPermissionDetector useEffect []");
    if (navigator.permissions) {
      // Use the Permissions API
      queryPermission().then((permissionStatus) => {
        // Listen for changes to the permission status
        permissionStatus.addEventListener("change", () => {
          console.log("permission change", { state: permissionStatus.state });

          setPermissionWithDelay(permissionStatus.state);
          if (permissionStatus.state === "granted") {
            clearIntervalAndReset();
            console.log("[LM-03] Camera permission granted (listener)");
          }
        });

        if (!isInAppBrowser()) {
          // check the permission again immediately to workaround iOS issue
          queryPermission();

          // Also use polling as a fallback
          const intervalId = setInterval(() => {
            queryPermission();
          }, POLLING_INTERVAL);
          intervalIdRef.current = intervalId;

          // Clear the interval when the component is unmounted
          return () => clearInterval(intervalId);
        }
      });
    } else {
      console.log(
        "Checking permission via fallback navigator.mediaDevices.getUserMedia"
      );
      // Use the MediaDevices API as a fallback
      const checkCameraPermission = () => {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(() => {
            // If it succeeds, the user has granted camera permissions
            setPermissionWithDelay("granted");

            clearIntervalAndReset();
            console.log("[LM-03] Camera permission granted (fallback)");
          })
          .catch(() => {
            // If it fails, the user has not granted camera permissions
            setPermissionWithDelay("denied");
          });
      };

      // Check the camera permission immediately
      checkCameraPermission();

      if (!isInAppBrowser()) {
        // Re-check the camera permission periodically
        const intervalId = setInterval(checkCameraPermission, POLLING_INTERVAL);
        intervalIdRef.current = intervalId;

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
      }
    }
  }, [queryPermission, setPermissionWithDelay]);

  return cameraPermission;
};
