import { motion } from "framer-motion";

interface ControlsExpanderProps {
  expanded: boolean;
  children: React.ReactNode;
}

export const ControlsExpander: React.FC<ControlsExpanderProps> = ({
  expanded,
  children,
}) => (
  <motion.div
    initial={false}
    animate={{
      width: expanded ? "auto" : "0",
      flexGrow: expanded ? 1 : 0,
    }}
    transition={{ duration: 0.5 }}
    style={{
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      flexWrap: expanded ? "wrap" : "nowrap",
    }}
  >
    {children}
  </motion.div>
);
