import { NormalizedLandmark } from "@mediapipe/tasks-vision";

export type Point3D = {
  x: number;
  y: number;
  z: number;
};

export function calculateDistance(p1: Point3D, p2: Point3D) {
  if (!p1 || !p2) return Infinity;
  const a = p2.x - p1.x;
  const b = p2.y - p1.y;
  const c = p2.z - p1.z;

  return Math.hypot(a, b, c);
}

type Point2D = {
  x: number;
  y: number;
};

export function calculateDistance2D(p1: Point2D, p2: Point2D) {
  const a = p2.x - p1.x;
  const b = p2.y - p1.y;

  return Math.hypot(a, b);
}

/** Calculate an angle in radians made by three points */
function calculateAngle(p1: Point3D, p2: Point3D, p3: Point3D) {
  const d12 = calculateDistance(p1, p2);
  const d23 = calculateDistance(p2, p3);
  const d13 = calculateDistance(p1, p3);

  const angle = (d12 ** 2 + d23 ** 2 - d13 ** 2) / (2 * d12 * d23);
  return Math.acos(angle);
}

export interface JointAngle {
  /** Angle represented in a range [0..1] where 0 is completely bent and 1 is fully straight */
  angle: number;
  confidence: number;
}

export type JointAngles = JointAngle[];

/**
 * For the specified joints pair (such as left and right shoulders) determine the angle made by the
 * landmarks for each joint.
 * @param joints Two dimensional array where the first index is for left and right and the second is for the 3 landmarks indices for the joint
 * @param poseLandmarks The list of landmarks for the pose
 * @param landmarkPoseVisibilityThreshold The minimum visibility for a landmark to be considered
 * @param jointAnglesMap The map to store the individual joint angles in
 * @returns The list of joint angles for the specified joints pair. Joints are only included if all 3 associated landmarks are visible.
 */
export function getAnglesForJointsPair(
  joints: number[][],
  poseLandmarks: NormalizedLandmark[],
  landmarkPoseVisibilityThreshold: number,
  jointAnglesMap: { [key: number]: JointAngle }
): JointAngles {
  const angles: JointAngles = [];
  if (poseLandmarks) {
    for (const points of joints) {
      // const points = [POSE_LANDMARKS.RIGHT_SHOULDER, POSE_LANDMARKS.RIGHT_ELBOW, POSE_LANDMARKS.RIGHT_WRIST]; // right elboow
      const jointPoseLandmarks = [
        poseLandmarks[points[0]],
        poseLandmarks[points[1]],
        poseLandmarks[points[2]],
      ];

      if (
        jointPoseLandmarks[0].visibility !== undefined &&
        jointPoseLandmarks[0].visibility >= landmarkPoseVisibilityThreshold &&
        jointPoseLandmarks[1].visibility !== undefined &&
        jointPoseLandmarks[1].visibility >= landmarkPoseVisibilityThreshold &&
        jointPoseLandmarks[2].visibility !== undefined &&
        jointPoseLandmarks[2].visibility >= landmarkPoseVisibilityThreshold
      ) {
        const angle = calculateAngle(
          jointPoseLandmarks[0],
          jointPoseLandmarks[1],
          jointPoseLandmarks[2]
        );
        const jointAngleDetails = {
          angle,
          confidence:
            jointPoseLandmarks.reduce(
              (a, b) => a + (b.visibility === undefined ? 0 : b.visibility),
              0
            ) / jointPoseLandmarks.length || 0,
        };
        jointAnglesMap[points[1]] = jointAngleDetails;
        angles.push(jointAngleDetails);
      }
    }
  }
  return angles;
}
