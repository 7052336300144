import { createRoot } from "react-dom/client";
import App from "./App";

window.onerror = function (msg, url, lineNo, columnNo, error) {
  console.error("Window Error: ", {
    message: msg,
    url: url,
    lineNo: lineNo,
    columnNo: columnNo,
    error: error,
  });
  return false;
};

window.addEventListener("unhandledrejection", function (event) {
  console.error("Unhandled Promise Rejection: ", event.reason);
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
