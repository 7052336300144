import { Alert, AlertIcon } from "@chakra-ui/alert";
import { CheckIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Badge,
  Flex,
  Heading,
  ListItem,
  UnorderedList,
} from "@chakra-ui/layout";
import { Box, Spinner, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useTimeoutEffect } from "react-timing-hooks";
import { CompletionAnimationOverlay } from "../challenge/completion-animation-overlay";
import { PoseDetector } from "../pose-detection/PoseDetector";
import { useCameraPermissionDetector } from "../pose-detection/useCameraPermissionDetector";
import { synth } from "../tones/tone-synthesizers";
import { PoseIds } from "../types";

const CameraDemo = ({}: {}) => {
  const cameraPermission = useCameraPermissionDetector();

  const [poseStartAt, setPoseStartAt] = useState<number>(0);
  const [isInPoseState, setIsInPoseState] = useState(false);
  const [currentPoseDuration, setCurrentPoseDuration] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState<string>();

  const [objectiveComplete, setObjectiveComplete] = useState(false);
  const [isCompletionOverlayOpen, setIsCompletionOverlayOpen] =
    useState<boolean>(false);

  useTimeoutEffect(
    (timeout, clearAllTimers) => {
      clearAllTimers();
      if (isInPoseState && !objectiveComplete) {
        timeout(() => {
          if (!objectiveComplete) {
            setObjectiveComplete(true);
            setIsCompletionOverlayOpen(true);

            // play a short jaunty celebration tune using Tone.js
            try {
              synth.triggerAttackRelease("D4", "8n");
              synth.triggerAttackRelease("D4", "8n", "+0.1");
              synth.triggerAttackRelease("A4", "8n", "+0.3");
              synth.triggerAttackRelease("C5", "8n", "+0.4");
              synth.triggerAttackRelease("A4", "8n", "+0.6");
              synth.triggerAttackRelease("D5", "8n", "+0.8");
              synth.triggerAttackRelease("F5", "4n", "+1.0");
            } catch (error) {
              console.error("Error triggering synth:", error);
            }
          }
        }, 3000);
      }
    },
    [isInPoseState, objectiveComplete]
  );

  return (
    <Flex width="100%" direction="column" gap={3}>
      {objectiveComplete ? (
        <Text>
          You have <b>completed</b> the objective of this demo! You can{" "}
          {isInPoseState ? "put" : "keep"} your hands down now.
        </Text>
      ) : (
        <Text>
          Objective: have Handstand Timer detect the "at least one hand up" pose
          for <b>three seconds</b>. Current status:{" "}
          <b>{isInPoseState ? "in pose" : "pose not detected"}</b>.
        </Text>
      )}
      <Box
        width="100%"
        borderRadius={5}
        overflow="hidden"
        position="relative"
        border="10px solid"
        borderColor={isInPoseState ? "blue.500" : "transparent"}
      >
        <PoseDetector
          detectingPoseId={PoseIds.AtLeastOneHandUp}
          setPoseStartAt={setPoseStartAt}
          setIsInPoseState={setIsInPoseState}
          setCurrentPoseDuration={setCurrentPoseDuration}
          hideChrome={true}
          onLoadingStatusChange={setLoadingStatus}
          fillWidth={true}
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isInPoseState ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <Text
            pointerEvents="none"
            fontSize="6xl"
            position="absolute"
            top="0"
            width="100%"
            textAlign="center"
            color="white"
            textShadow="1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000, 1px 0px 0 #000" // set black text shadow
          >
            {(currentPoseDuration / 1000).toFixed(1)} s
          </Text>
        </motion.div>
      </Box>
      <Heading size="md">Tips</Heading>
      <UnorderedList spacing={3}>
        <ListItem>
          <Flex gap={3} direction="column">
            <Text>
              <b>Camera Access:</b> Please allow the app to access your camera.
              Current permission status:{" "}
              <Badge
                border="1px solid "
                colorScheme={
                  cameraPermission === "granted"
                    ? "green"
                    : cameraPermission === "denied"
                    ? "red"
                    : "grey"
                }
              >
                {cameraPermission === "granted" ? (
                  <CheckIcon mr={2} />
                ) : (
                  <WarningIcon mr={2} />
                )}
                {cameraPermission}
              </Badge>
            </Text>
            <Alert status="info">
              <AlertIcon />
              Handstand Timer does not transmit your video or images. Pose
              detection is performed locally on your device. We do not capture
              or store your videos or images.
            </Alert>
          </Flex>
        </ListItem>
        <ListItem>
          <Text>
            <b>Position:</b> Position yourself so that the camera can see your
            whole body.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>Hand Up:</b> Raise a hand above your shoulders.
          </Text>
        </ListItem>
        <ListItem>
          <Box>
            <b>Loading can be slow:</b> Please be patient. Downloading and
            initializing the pose detection model can sometimes take a minute or
            two, especially if bandwidth is limited or you are on an older
            device. Under ideal conditions, the loading time is about 20 seconds
            initially, and then a second or two once the files are cached.
            Loading status:
            <Badge
              border="1px solid "
              colorScheme={
                loadingStatus === "loaded"
                  ? "green"
                  : cameraPermission === "loading"
                  ? "grey"
                  : "red"
              }
              alignItems="center"
              flexDirection="row"
              display="inline-flex"
              ml={2}
            >
              {loadingStatus === "loaded" ? (
                <CheckIcon mr={2} />
              ) : loadingStatus === "loading" ? (
                <Spinner mr={2} mt={1} mb={1} size="xs" speed="0.7s" />
              ) : (
                <WarningIcon mr={2} />
              )}
              <Text>{loadingStatus}</Text>
            </Badge>
          </Box>
        </ListItem>
      </UnorderedList>
      <CompletionAnimationOverlay
        isOpen={isCompletionOverlayOpen}
        onClose={() => setIsCompletionOverlayOpen(false)}
      />
    </Flex>
  );
};
export default CameraDemo;
