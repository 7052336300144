import { motion } from "framer-motion";
import { Box, Flex, Image } from "@chakra-ui/react";

const imageVariants = {
  hidden: { opacity: 0, scale: 0.8, rotate: 0 },
  visible: (rotate: number) => ({ opacity: 1, scale: 1, rotate: rotate }),
};

const ImageContainer = ({
  src,
  alt,
  rotate,
  delay,
  top,
  left,
  aspectRatio,
  width,
}: {
  src: string;
  alt: string;
  rotate: number;
  delay: number;
  top: string;
  left: string;
  aspectRatio: string;
  width: string;
}) => (
  <motion.div
    style={{
      position: "absolute",
      width,
      height: "auto",
      aspectRatio,
      top,
      left,
    }}
    initial="hidden"
    animate="visible"
    variants={imageVariants}
    custom={rotate}
    transition={{ duration: 1, delay }}
  >
    <Box
      width="100%"
      height="100%"
      padding="15px" // Consistent border width
      bg="white"
      boxShadow="lg"
    >
      <Box width="100%" height="100%" overflow="hidden">
        <Image
          src={src}
          alt={alt}
          objectFit="cover"
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  </motion.div>
);

const Collage = () => {
  return (
    <Flex
      position="relative"
      width="100%"
      height="0"
      paddingBottom="75%" // 4:3 aspect ratio for the container
      bg="gray.100"
      borderRadius="lg"
      overflow="hidden"
    >
      <ImageContainer
        src="/images/scott-handstand-diamond.jpg"
        alt="Scott Handstand Diamond"
        rotate={-5}
        delay={0}
        top="2%"
        left="2%"
        aspectRatio="9/16"
        width="33.75%" // Increased by 50% from previous 22.5%
      />
      <ImageContainer
        src="/images/scott-handstand-straddle.jpg"
        alt="Scott Handstand Straddle"
        rotate={5}
        delay={0.5}
        top="15%"
        left="55%"
        aspectRatio="1/1"
        width="40%"
      />
      <ImageContainer
        src="/images/scott-headshot.jpg"
        alt="Scott Headshot"
        rotate={-3}
        delay={1}
        top="50%"
        left="30%"
        aspectRatio="1/1"
        width="40%"
      />
    </Flex>
  );
};

export default Collage;
