import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import {
  AspectRatio,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { FC, useEffect, useRef, useState } from "react";
import { FaApple, FaGoogle } from "react-icons/fa";
import { MdEmail, MdPhone } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import { PasswordInput } from "./PasswordInput";
import { useAuthContext } from "./useAuthContext";
import "./Login.css";

const logoUrl = "/images/logo-maskable.png";

export const Login: FC<{
  setLoggingIn: (value: boolean) => void;
  loggingIn: boolean;
}> = ({ setLoggingIn, loggingIn }) => {
  const router = useIonRouter();
  const routerHistory = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const authContext = useAuthContext();
  const [tabIndex, setTabIndex] = useState(0);
  const [isRegisterMode, setIsRegisterMode] = useState(
    !authContext.hasRegistered
  );
  const [isForgotPasswordMode, setIsForgotPasswordMode] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);

  const from = location.state?.from?.pathname || "/";

  const [confirmPassword, setConfirmPassword] = useState("");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const recaptchaContainerRef = useRef<HTMLDivElement>(null);

  const [phoneError, setPhoneError] = useState<string | null>(null);

  console.log("Login", { from, loggingIn });
  const validatePhoneNumber = (number: string) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(value);

    if (value && !validatePhoneNumber(value)) {
      setPhoneError(
        "Please enter a valid phone number starting with + and country code"
      );
    } else {
      setPhoneError("");
    }
  };

  useEffect(() => {
    const setupListeners = async () => {
      await FirebaseAuthentication.addListener(
        "phoneVerificationCompleted",
        ({ credential }) => {
          console.log("Phone verification completed:", credential);
          // Handle instant verification or auto-retrieval
          router.push(from, "forward", "replace");
        }
      );

      await FirebaseAuthentication.addListener(
        "phoneVerificationFailed",
        ({ message }) => {
          console.error("Phone verification failed:", message);
          setPhoneError(message);
          setLoggingIn(false);
        }
      );

      await FirebaseAuthentication.addListener(
        "phoneCodeSent",
        ({ verificationId }) => {
          console.log("Phone code sent:", verificationId);
          setCodeSent(true);
          setPhoneError(null);
          setLoggingIn(false);
        }
      );
    };

    setupListeners();

    return () => {
      FirebaseAuthentication.removeAllListeners();
    };
  }, []);

  const redirectAfterSuccess = () => {
    router.push(from, "forward", "replace");
    setLoggingIn(false);
  };

  const toast = useToast();

  const handleSignIn = async (
    provider: "google" | "apple" | "email" | "phone"
  ) => {
    setLoggingIn(true);
    setPhoneError(null);
    try {
      switch (provider) {
        case "google":
          await authContext.signInWithGoogle();
          redirectAfterSuccess();
          break;
        case "apple":
          await authContext.signInWithApple();
          redirectAfterSuccess();
          break;
        case "email":
          if (isRegisterMode) {
            await authContext.createUserWithEmailAndPassword(email, password);
            // we need to redirect to the /verify-email page to await verification (or re-send verification email)
            routerHistory.push("/verify-email", {
              verificationEmailSent: true,
            });
          } else {
            await authContext.signInWithEmailAndPassword(email, password);
            redirectAfterSuccess();
          }
          break;
        case "phone":
          if (!codeSent) {
            if (recaptchaContainerRef.current) {
              await authContext.signInWithPhoneNumber(
                phoneNumber,
                "recaptcha-container"
              );
              redirectAfterSuccess();
            }
          } else {
            await authContext.confirmVerificationCode(verificationCode);
          }
          break;
      }
    } catch (error) {
      console.error("Authentication error:", error);
      // Show error to user using Toast
      toast({
        title: "Authentication Error",
        description:
          (error as Error)?.message || "An error occurred. Please try again.",
        position: "bottom",
      });
      setLoggingIn(false);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await FirebaseAuthentication.sendPasswordResetEmail({
        email: email,
      });
      toast({ title: "Password reset email sent. Please check your inbox." });
    } catch (error) {
      console.error("Error sending password reset email:", error);
      toast({
        status: "error",
        title: "Failed to send password reset email. Please try again.",
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isForgotPasswordMode) {
      handleForgotPassword();
    } else {
      handleSignIn(tabIndex === 0 ? "email" : "phone");
    }
  };

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={from} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <Flex
          padding={[3, 5]}
          w="calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right))"
          minHeight="calc(var(--vh, 1vh) * 100 - env(safe-area-inset-top) - env(safe-area-inset-bottom))"
          gap={3}
          direction="column"
          overflowY="scroll"
          alignItems="center"
        >
          {/* Logo */}
          <AspectRatio
            ratio={1}
            minWidth="80px"
            maxWidth="250px"
            width="20vh"
            marginTop="calc(env(safe-area-inset-top))"
          >
            <Box borderRadius="full" overflow="hidden">
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <Image src={logoUrl} boxSize="125%" objectFit="cover" />
              </Flex>
            </Box>
          </AspectRatio>
          <Flex direction="column">
            <Heading marginTop={0}>Handstand Timer</Heading>
            <Text size="sm" color="chakra-subtle-text">
              Brought to you by Move Journey
            </Text>
          </Flex>{" "}
          {/* Authentication Actions */}
          <VStack spacing={4} align="stretch" width="100%" maxWidth="400px">
            {authContext.user ? (
              <Button onClick={() => authContext.signOut()} colorScheme="red">
                Sign Out
              </Button>
            ) : (
              <>
                <Heading size="md">
                  {isRegisterMode
                    ? "Create an account"
                    : "Sign in to your account"}
                </Heading>
                <VStack spacing={4}>
                  <Button
                    isLoading={loggingIn}
                    colorScheme="blue"
                    leftIcon={<FaGoogle />}
                    onClick={() => handleSignIn("google")}
                    width="100%"
                  >
                    {isRegisterMode
                      ? "Sign up with Google"
                      : "Sign in with Google"}
                  </Button>
                  <Button
                    isLoading={loggingIn}
                    colorScheme="gray"
                    leftIcon={<FaApple />}
                    onClick={() => handleSignIn("apple")}
                    width="100%"
                  >
                    {isRegisterMode
                      ? "Sign up with Apple"
                      : "Sign in with Apple"}
                  </Button>
                </VStack>
                <Flex align="center" width="100%" my={4}>
                  <Box flex={1} height="1px" bg="gray.300" />
                  <Text px={3} color="gray.500" fontWeight="medium">
                    or
                  </Text>
                  <Box flex={1} height="1px" bg="gray.300" />
                </Flex>
                <Tabs
                  isFitted
                  variant="enclosed"
                  onChange={(index) => setTabIndex(index)}
                >
                  <TabList mb="1em">
                    <Tab>
                      <Icon as={MdEmail} mr={2} />
                      Email/Password
                    </Tab>
                    <Tab>
                      <Icon as={MdPhone} mr={2} />
                      Phone
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <form onSubmit={handleSubmit}>
                        <VStack spacing={4}>
                          <FormControl variant="floating">
                            <Input
                              placeholder=" "
                              type="email"
                              autoComplete="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <FormLabel>Email</FormLabel>
                          </FormControl>
                          {!isForgotPasswordMode && (
                            <PasswordInput
                              value={password}
                              onChange={setPassword}
                              label="Password"
                              showValidation={isRegisterMode}
                            />
                          )}
                          {isRegisterMode && !isForgotPasswordMode && (
                            <PasswordInput
                              value={confirmPassword}
                              onChange={setConfirmPassword}
                              label="Confirm Password"
                              showValidation={false}
                              customError={
                                password !== confirmPassword
                                  ? "Passwords do not match"
                                  : undefined
                              }
                            />
                          )}
                          <Button
                            type="submit"
                            isLoading={loggingIn}
                            colorScheme="blue"
                            width="100%"
                            isDisabled={
                              (isRegisterMode &&
                                password !== confirmPassword) ||
                              !email ||
                              !password
                            }
                            leftIcon={<MdEmail />}
                          >
                            {isForgotPasswordMode
                              ? "Send Password Reset Email"
                              : isRegisterMode
                              ? "Sign up"
                              : "Sign in"}
                          </Button>
                          {!isRegisterMode && !isForgotPasswordMode && (
                            <Link
                              color="blue.500"
                              onClick={() => setIsForgotPasswordMode(true)}
                              fontSize="sm"
                            >
                              Forgot password?
                            </Link>
                          )}
                          {isForgotPasswordMode && (
                            <Link
                              color="blue.500"
                              onClick={() => setIsForgotPasswordMode(false)}
                              fontSize="sm"
                            >
                              Back to Sign In
                            </Link>
                          )}
                        </VStack>
                      </form>
                    </TabPanel>
                    <TabPanel>
                      <form onSubmit={handleSubmit}>
                        <VStack spacing={4}>
                          <FormControl
                            variant="floating"
                            isInvalid={!!phoneError}
                          >
                            <Input
                              placeholder=" "
                              type="tel"
                              value={phoneNumber}
                              onChange={handlePhoneChange}
                            />
                            <FormLabel>Phone Number</FormLabel>
                            {phoneError && (
                              <FormErrorMessage>{phoneError}</FormErrorMessage>
                            )}
                            <FormHelperText>
                              Start with + followed by country code and number
                            </FormHelperText>
                          </FormControl>
                          {codeSent && (
                            <FormControl variant="floating">
                              <Input
                                placeholder=" "
                                type="number"
                                value={verificationCode}
                                onChange={(e) =>
                                  setVerificationCode(e.target.value)
                                }
                              />
                              <FormLabel>Verification Code</FormLabel>
                            </FormControl>
                          )}
                          <Button
                            type="submit"
                            ref={buttonRef}
                            isLoading={loggingIn}
                            colorScheme="blue"
                            width="100%"
                            leftIcon={<MdPhone />}
                            isDisabled={!!phoneError || !phoneNumber}
                          >
                            {codeSent ? "Verify Code" : "Send Code"}
                          </Button>
                          <div
                            id="recaptcha-container"
                            ref={recaptchaContainerRef}
                          />
                        </VStack>
                      </form>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <Button
                  variant="link"
                  onClick={() => setIsRegisterMode(!isRegisterMode)}
                >
                  {isRegisterMode
                    ? "Already have an account? Sign in"
                    : "Need an account? Sign up"}
                </Button>
              </>
            )}
          </VStack>
          {/* Additional information */}
          <Divider my={3} />
          <Text
            fontSize="sm"
            textAlign="center"
            marginBottom="calc(env(safe-area-inset-bottom))"
          >
            By continuing, you agree to our{" "}
            <Link
              href="https://handstandtimer.com/terms/"
              isExternal
              color="blue.500"
              textDecoration="underline"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              href="https://handstandtimer.com/privacy/"
              isExternal
              color="blue.500"
              textDecoration="underline"
            >
              Privacy Policy
            </Link>
            .
          </Text>
        </Flex>
      </IonContent>
    </IonPage>
  );
};
