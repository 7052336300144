import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { home, notifications, person } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Login } from "./components/login/Login";
import { useAuthContext } from "./components/login/useAuthContext";
import IonPoseIcon from "./components/pose-detection/IonPoseIcon";
import { useTitleCase } from "./components/pose-detection/useTitleCase";
import AccountProfile from "./components/settings/AccountProfile";
import AccountSettings from "./components/settings/AccountSettings";
import DeleteAccount from "./components/settings/DeleteAccount";
import EditDisplayName from "./components/settings/EditDisplayName";
import EditEmail from "./components/settings/EditEmail";
import EditPassword from "./components/settings/EditPassword";
import EditPhotoUrl from "./components/settings/EditPhotoUrl";
import LinkAccounts from "./components/settings/LinkAccounts";
import { SettingsContext } from "./components/settings/SettingsContext";
import { useSubscriptions } from "./components/subscribe/SubscriptionContext";
import WaitlistModal from "./components/subscribe/WaitlistModal";
import { waitlistTags } from "./components/subscribe/waitlistTags";
import { useVolumeAwareInstruments } from "./components/tones/useVolumeAwareInstruments";
import { ActiveTabProvider } from "./contexts/ActiveTabContext";
import Home from "./pages/Home";
import Onboarding from "./pages/Onboarding";
import PosePage from "./pages/PosePage";
import { RequireVerifiedEmailRoute } from "./pages/RequireVerifiedEmailRoute";
import Subscribe from "./pages/Subscribe";
import VerifyEmail from "./pages/VerifyEmail";
import usePageTracking from "./services/analytics/usePageTracking";

const TabsContent: React.FC = () => {
  const { detectingPoseId } = useContext(SettingsContext);
  const poseTitle = useTitleCase(detectingPoseId);
  const [initialTabLoaded, setInitialTabLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const savedTab = localStorage.getItem("currentTab") || "/home";
    localStorage.setItem("currentTab", savedTab);
    setInitialTabLoaded(true);
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      localStorage.setItem("currentTab", location.pathname);
    }
  }, [location]);

  if (!initialTabLoaded) {
    return null;
  }

  return (
    <IonTabs>
      <IonRouterOutlet animated={false}>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/pose">
          <PosePage />
        </Route>
        <Route path="/subscribe">
          <Subscribe />
        </Route>
        <Route path="/account">
          <AccountSettings />
        </Route>
        <Route exact path="/">
          <Redirect to={localStorage.getItem("currentTab") || "/home"} />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/home">
          <IonIcon aria-hidden="true" icon={home} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="pose" href="/pose">
          <IonPoseIcon pose={detectingPoseId} />
          <IonLabel>{poseTitle}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="subscribe" href="/subscribe">
          <IonIcon aria-hidden="true" icon={notifications} />
          <IonLabel>Subscribe</IonLabel>
        </IonTabButton>
        <IonTabButton tab="account" href="/account">
          <IonIcon aria-hidden="true" icon={person} />
          <IonLabel>Account</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

const PageTracking = () => {
  usePageTracking();
  return null;
};

const AppContent: React.FC = () => {
  const { currentTags, isLoading } = useSubscriptions();
  const { dontShowWaitlistModal } = useContext(SettingsContext);
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const { user } = useAuthContext();
  const [loggingIn, setLoggingIn] = useState(false);
  useVolumeAwareInstruments();

  useEffect(() => {
    const checkSubscription = () => {
      const waitlistTagIds = waitlistTags.map((tag) => tag.id);
      const isSubscribed = currentTags.some((tagId) =>
        waitlistTagIds.includes(tagId)
      );

      return isSubscribed;
    };

    const checkAndShowModal = async () => {
      if (user && !dontShowWaitlistModal && !isLoading) {
        const isSubscribed = checkSubscription();
        if (!isSubscribed) {
          setShowWaitlistModal(true);
        }
      }
    };

    checkAndShowModal();
  }, [user, user?.email, isLoading, currentTags]);

  return (
    <IonApp>
      <IonReactRouter>
        <PageTracking />
        <ActiveTabProvider>
          <Switch>
            <Route path="/login">
              <Login setLoggingIn={setLoggingIn} loggingIn={loggingIn} />
            </Route>
            <Route path="/onboarding/:step" component={Onboarding} />
            <Route path="/verify-email" component={VerifyEmail} />
            <Route path="/account/link-accounts" exact={true}>
              <LinkAccounts />
            </Route>
            <Route path="/account/profile" exact={true}>
              <AccountProfile />
            </Route>
            <Route path="/account/edit-display-name" exact={true}>
              <EditDisplayName />
            </Route>
            <Route path="/account/edit-photo-url" exact={true}>
              <EditPhotoUrl />
            </Route>
            <Route path="/account/edit-email" exact={true}>
              <EditEmail />
            </Route>
            <Route path="/account/edit-password" exact={true}>
              <EditPassword />
            </Route>
            <Route path="/account/delete" exact={true}>
              <DeleteAccount />
            </Route>
            <Route path="/">
              <RequireVerifiedEmailRoute>
                <TabsContent />
              </RequireVerifiedEmailRoute>
            </Route>
          </Switch>
        </ActiveTabProvider>
        <WaitlistModal
          isOpen={showWaitlistModal}
          onClose={() => setShowWaitlistModal(false)}
        />
      </IonReactRouter>
    </IonApp>
  );
};

export default AppContent;
