import React, { createContext, useContext } from "react";
import { useSubscriptionsState } from "./useSubscriptionsState";

interface SubscriptionContextType {
  currentTags: number[];
  pendingTags: number[];
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleCurrentTagToggle: (tagId: number) => Promise<void>;
  handlePendingTagToggle: (tagId: number) => Promise<void>;
  fetchCurrentSubscriptions: () => Promise<void>;
  applyPendingChanges: () => Promise<void>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(
  undefined
);

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const subscriptions = useSubscriptionsState();

  return (
    <SubscriptionContext.Provider value={subscriptions}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptions = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error(
      "useSubscriptions must be used within a SubscriptionProvider"
    );
  }
  return context;
};
