import { NativeAudio } from "@scottjgilroy/native-audio";
import { trace } from "firebase/performance";
import { useCallback, useRef } from "react";
import { firebaseService } from "../../services/FirebaseService";
import { TextToSpeechUtterancesDao } from "../../services/firestore/TextToSpeechUtterancesDao";
import { TextToSpeechUtterance } from "./TextToSpeech";
import { SpeechUtteranceWrapper } from "./useSpeechQueue";

/**
 * Represents a container for a speech utterance, including the utterance itself,
 * a fetching promise, and a function to resolve the fetching promise.
 * The fetching promise is used to track the asynchronous process of fetching the
 * audio data for the utterance, and the resolve function is called when the fetching
 * process is completed.
 */
export type SpeechUtteranceContainer = {
  utterance: TextToSpeechUtterance;
  fetchingPromise?: Promise<void>;
  fetchingPromiseResolve?: () => void;
};

/**
 * Type representing the callbacks returned by the `useSpeakUtteranceAudio` hook.
 */
type UseSpeakUtteranceAudioResult = {
  /**
   * Fetches the speech audio for an utterance document, creates a buffer with the audio data,
   * and then queues the corresponding utterance wrapper to be played in the audio context.
   * @param utteranceContainer - The container object holding the utterance and related data
   */
  fetchAndQueueUtterance: (
    utteranceContainer: SpeechUtteranceContainer
  ) => Promise<void>;

  /**
   * Cancels the audio request for a specific utterance.
   * Clears the utterance ref to abort queuing the utterance and cancels the utterance wrapper
   * to stop it in progress or remove it from the queue.
   * @param utterance - The utterance for which to cancel the audio request
   */
  cancelUtteranceAudioRequest: (utterance: TextToSpeechUtterance) => void;
};

/**
 * Hook to provide a callback which will fetch the speech audio for an utterance document,
 * create a buffer with the audio data, and then queue the corresponding utterance wrapper
 * to be played in the audio context.
 * @param audioContext - The audio context
 * @param queueUtterance - Function to queue an utterance wrapper
 * @param cancelUtterance - Function to cancel an utterance wrapper
 * @param doneSpeaking - Callback function to be called when speaking is done
 * @returns An object containing the `fetchAndQueueUtterance` and `cancelUtteranceAudioRequest` callbacks
 */
export function useSpeakUtteranceAudio(
  audioContext: AudioContext,
  queueUtterance: (utterance: SpeechUtteranceWrapper) => Promise<boolean>,
  cancelUtterance: (utterance: SpeechUtteranceWrapper) => void,
  doneSpeaking: (utterance?: SpeechUtteranceWrapper) => void
): UseSpeakUtteranceAudioResult {
  const utteranceRef = useRef<TextToSpeechUtterance | undefined>();
  const utteranceWrapperRef = useRef<SpeechUtteranceWrapper | undefined>();

  const fetchAndQueueUtterance = useCallback(
    async (utteranceContainer: SpeechUtteranceContainer) => {
      const utterance = utteranceContainer.utterance;
      utteranceRef.current = utterance;

      utteranceContainer.fetchingPromise = new Promise<void>((resolve) => {
        utteranceContainer.fetchingPromiseResolve = resolve;
      });
      const { performance } = await firebaseService.initialize();
      const t = trace(performance, "fetchAndDecodeSpeechAudio");
      t.start();
      t.putAttribute("utteranceId", utterance.id);
      const audioUrl =
        await new TextToSpeechUtterancesDao().getAudioDownloadUrl(utterance);
      if (audioUrl) {
        try {
          const preloadOptions = {
            assetId: utterance.id,
            assetPath: audioUrl,
            audioChannelNum: 1,
            isUrl: true,
          };

          // Check if the asset is already preloaded
          const isAlreadyPreloaded = await NativeAudio.isPreloaded(
            preloadOptions
          );

          console.log("NativeAudio.isPreloaded result", { isAlreadyPreloaded });
          const isPreloaded =
            typeof isAlreadyPreloaded === "boolean"
              ? isAlreadyPreloaded
              : isAlreadyPreloaded?.found;

          if (!isPreloaded) {
            // Only preload if not already loaded
            await NativeAudio.preload(preloadOptions);
          }

          t.stop();
          utteranceContainer.fetchingPromiseResolve?.();

          if (!utteranceRef.current) {
            // abort because cancel was invoked during the asynchronous steps to preload the audio data
            return;
          }

          const utteranceWrapper: SpeechUtteranceWrapper = { id: utterance.id };
          utteranceWrapperRef.current = utteranceWrapper;
          await queueUtterance(utteranceWrapper).then(
            (done) => done && doneSpeaking()
          );
        } catch (error) {
          console.error("Error checking or preloading audio:", error);
          t.stop();
          utteranceContainer.fetchingPromiseResolve?.();
        }
      } else {
        console.warn("No audio URL found for utterance:", utterance);
        t.stop();
        utteranceContainer.fetchingPromiseResolve?.();
      }
    },
    [doneSpeaking]
  );

  const cancelUtteranceAudioRequest = useCallback(
    (utterance: TextToSpeechUtterance) => {
      // clear the utterance ref to abort queuing the utterance
      if (utteranceRef.current === utterance) {
        utteranceRef.current = undefined;
      }

      // cancel the utterance wrapper to stop it in progress or remove it from the queue
      if (utteranceWrapperRef.current) {
        cancelUtterance(utteranceWrapperRef.current);
        utteranceWrapperRef.current = undefined;
      }
    },
    []
  );

  return { fetchAndQueueUtterance, cancelUtteranceAudioRequest };
}
