import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { FirebaseError } from "firebase/app";
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useAuthContext } from "../login/useAuthContext";
import AccountIdentityProviders from "./AccountIdentityProviders";

const DeleteAccount: React.FC = () => {
  const { user, signOut } = useAuthContext();
  const routerHistory = useHistory();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [needsReauth, setNeedsReauth] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef(null);

  const handleDeleteAccount = async () => {
    if (!user) {
      throw new Error("User is not authenticated");
    }
    setIsLoading(true);
    try {
      await FirebaseAuthentication.deleteUser();
      await signOut();
      toast({
        title: "Account deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      routerHistory.push("/");
    } catch (error) {
      const errorCode = (error as FirebaseError).code;
      if (errorCode?.includes("requires-recent-login")) {
        setNeedsReauth(true);
        toast({
          title: "Please reauthenticate to delete your account",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error deleting account",
          description: (error as Error).message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };

  const handleReauth = async () => {
    await signOut();
    routerHistory.push("/login", { from: "/account/delete" });
  };

  if (!user) {
    console.log("redirecting to home after");
    return <Redirect to="/home" />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account" />
          </IonButtons>
          <IonTitle>Delete Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Box padding={[3, 5]}>
          <VStack spacing={6} align="stretch">
            <AccountIdentityProviders
              providerData={user?.providerData || []}
              isUnlinkVisible={false}
            />
            <Text fontSize="xl" fontWeight="bold">
              Delete Your Account
            </Text>
            <Text>
              Warning: This action is irreversible. All your data will be
              permanently deleted.
            </Text>
            {needsReauth ? (
              <Button
                colorScheme="blue"
                onClick={handleReauth}
                isLoading={isLoading}
              >
                Sign In Again
              </Button>
            ) : (
              <Button
                colorScheme="red"
                onClick={() => setIsOpen(true)}
                isLoading={isLoading}
                isDisabled={!user}
              >
                Delete Account
              </Button>
            )}

            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Account
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        onClose();
                        handleDeleteAccount();
                      }}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </VStack>
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default DeleteAccount;
