import { synth } from "../tones/tone-synthesizers";

export function playPoseStateTransitionTones(
  isInPose: boolean,
  isAutoRecording?: boolean
) {
  const highTone = "D4";
  const higherTone = "E4";
  const lowTone = "C4";

  const playTones = (tones: string[]) => {
    tones.forEach((tone, index) => {
      synth.triggerAttackRelease(tone, "8n", `+${index * 0.1}`);
    });
  };

  try {
    if (isInPose && isAutoRecording) {
      // Pose + Start Recording
      playTones([lowTone, highTone, higherTone]);
    } else if (!isInPose && isAutoRecording) {
      // Pose Exit + Stop Recording
      playTones([higherTone, highTone, lowTone]);
    } else if (isInPose) {
      // Entering Pose
      playTones([lowTone, highTone]);
    } else {
      // Exiting Pose
      playTones([highTone, lowTone]);
    }
  } catch (error) {
    console.error("Error triggering synth:", error);
  }
}
