import { useCallback, useState } from "react";

import { persistentStorage } from "./PersistentStorage";

/**
 * A hook which returns a value from persistent storage and updates it when the value is changed.
 * Basd on code from https://radzion.com/blog/hook-storage
 * @param key
 * @param initialValue
 * @param convertFromStorage Optional function to convert the value from storage to the desired type
 * @returns
 */
export function usePersistentStorageValue<T>(
  key: string,
  initialValue?: T,
  convertFromStorage?: (value: T) => T
) {
  const [value, setValue] = useState<T>(() => {
    const valueFromStorage = persistentStorage.getItem(key);

    if (convertFromStorage) {
      return convertFromStorage(valueFromStorage);
    } else if (
      typeof initialValue === "object" &&
      !Array.isArray(initialValue) &&
      initialValue !== null
    ) {
      return {
        ...initialValue,
        ...valueFromStorage,
      };
    }

    return valueFromStorage === undefined ? initialValue : valueFromStorage;
  });

  const setValueWrapper: React.Dispatch<React.SetStateAction<T>> = useCallback(
    (newValue: React.SetStateAction<T>) => {
      if (typeof newValue === "function") {
        const newValueFunction = newValue as (oldValue: T) => T;
        setValue((oldValue) => {
          const value = newValueFunction(oldValue);
          persistentStorage.setItem(key, value);
          return value;
        });
      } else {
        persistentStorage.setItem(key, newValue);
        setValue(newValue);
      }
    },
    [key, setValue]
  );

  return [value, setValueWrapper] as const;
}
