import { WarningIcon } from "@chakra-ui/icons";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { CameraPermissionInstructions } from "./CameraPermissionInstructions";

export const CameraPermissionPopover: React.FC<{
  cameraPermission: unknown;
}> = ({ cameraPermission }) => (
  <Popover>
    <PopoverTrigger>
      <Button leftIcon={<WarningIcon />} variant="ghost" whiteSpace="normal">
        {cameraPermission === "prompt"
          ? "Asking for Permission..."
          : "Permission Denied"}
      </Button>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader>
        Camera Permission{" "}
        {cameraPermission === "denied" ? "Denied" : "Not Granted"}
      </PopoverHeader>
      <PopoverBody display="flex" flexDirection="column" gap={3}>
        <Text>
          To use this feature, please enable camera access in your browser
          settings.
        </Text>
        <CameraPermissionInstructions />
      </PopoverBody>
    </PopoverContent>
  </Popover>
);
