import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";
import { Chart, ChartOptions, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { PoseEvent } from "../../services/pose-events/PoseEvent";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

interface CurrentPoseHistoryProps {
  currentPoseId: string;
  events: PoseEvent[];
}

export const CurrentPoseHistory: React.FC<
  CurrentPoseHistoryProps & BoxProps
> = ({ currentPoseId, events, ...props }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [maxEvents, setMaxEvents] = useState(5);
  const [isSimplifiedMode, setIsSimplifiedMode] = useState(true);
  const displayedEventsRef = useRef<PoseEvent[]>([]);

  const barColor = useColorModeValue(
    "rgba(75, 192, 192, 0.6)",
    "rgba(102, 205, 170, 0.6)"
  );
  const barBorderColor = useColorModeValue(
    "rgba(75, 192, 192, 1)",
    "rgba(102, 205, 170, 1)"
  );
  const textColor = useColorModeValue("black", "white");
  const gridColor = useColorModeValue(
    "rgba(0, 0, 0, 0.1)",
    "rgba(255, 255, 255, 0.1)"
  );

  const toggleMode = () => {
    setIsSimplifiedMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const width = entry.contentRect.width;
          let newMaxEvents = 5;
          if (width > 768) {
            newMaxEvents = Math.floor(width / 50);
          } else if (width > 480) {
            newMaxEvents = 8;
          }
          setMaxEvents(newMaxEvents);
        }
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  function formatDateTime(date: Date): string[] {
    const today = new Date();
    const isToday = date.toDateString() === today.toDateString();

    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    if (isToday) {
      return [timeString];
    } else {
      const dateString = date.toLocaleDateString([], {
        month: "short",
        day: "numeric",
      });
      return [dateString, timeString];
    }
  }

  function addData(chart: Chart, event: PoseEvent) {
    displayedEventsRef.current.push(event);
    const formattedDate = formatDateTime(new Date(event.startAt));
    chart.data.labels!.push(formattedDate);
    chart.data.datasets[0].data.push(event.duration / 1000);
  }

  function removeOldestData(chart: Chart) {
    displayedEventsRef.current.shift();
    chart.data.labels!.shift();
    chart.data.datasets[0].data.shift();
  }

  function setYScaleSuggestedMax(options: ChartOptions<"bar">, data: number[]) {
    if (options.scales?.y) {
      options.scales.y.suggestedMax = Math.max(...data) * 1.1;
    }
  }

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        const options: ChartOptions<"bar"> = {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 30,
            },
          },
          scales: {
            x: {
              display: !isSimplifiedMode,
              title: {
                display: false,
                text: "Time",
                color: textColor,
              },
              ticks: {
                color: textColor,
                callback: function (val, index) {
                  // This is necessary to correctly interpret multiline labels
                  return this.getLabelForValue(val as number);
                },
              },
              grid: {
                display: !isSimplifiedMode,
                color: gridColor,
              },
            },
            y: {
              display: !isSimplifiedMode,
              title: {
                display: !isSimplifiedMode,
                text: "Duration (s)",
                color: textColor,
              },
              ticks: {
                color: textColor,
              },
              grid: {
                display: !isSimplifiedMode,
                color: gridColor,
              },
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: `Recent ${currentPoseId} Events`,
              color: textColor,
            },
            datalabels: {
              color: textColor,
              font: {
                size: 18,
              },
              formatter: (value, context) => `${value.toFixed(1)}s`,
              anchor: "end",
              align: "top",
              offset: 0,
            },
          },
          animation: {
            duration: 800,
          },
          onClick: (event, elements, chart) => {
            if (isMobile) {
              if (elements.length === 0) {
                toggleMode();
              }
            } else {
              toggleMode();
            }
          },
        };

        if (!chartInstance.current) {
          // Initialize the chart with initial data
          const initialEvents = events.slice(0, maxEvents).reverse();

          const data = initialEvents.map((event) => event.duration / 1000);
          setYScaleSuggestedMax(options, data);

          // Initial chart creation
          chartInstance.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: [],
              datasets: [
                {
                  data: [],
                  backgroundColor: barColor,
                  borderColor: barBorderColor,
                  borderWidth: 1,
                },
              ],
            },
            options,
          });

          updateChartData();
        } else {
          // Check for new events
          const latestEventInChart =
            displayedEventsRef.current[displayedEventsRef.current.length - 1]
              ?.startAt;
          const newEvents = events.filter(
            (event) => event.startAt > latestEventInChart
          );

          newEvents.forEach((event) => {
            addData(chartInstance.current!, event);
            // initial update so that the add is animated correctly
            chartInstance.current!.update();
            if (
              chartInstance.current!.data.datasets[0].data.length > maxEvents
            ) {
              removeOldestData(chartInstance.current!);
            }
          });

          setYScaleSuggestedMax(
            options,
            chartInstance.current!.data.datasets[0].data as number[]
          );
          chartInstance.current.options = options;
        }

        chartInstance.current.update();
      }
    }
  }, [
    currentPoseId,
    events,
    maxEvents,
    barColor,
    barBorderColor,
    textColor,
    gridColor,
    isSimplifiedMode,
  ]);

  const updateChartData = () => {
    if (chartInstance.current) {
      // Clear existing data
      chartInstance.current.data.labels = [];
      chartInstance.current.data.datasets[0].data = [];
      displayedEventsRef.current = [];

      // Add new data
      const recentEvents = events.slice(0, maxEvents).reverse();
      recentEvents.forEach((event) => {
        addData(chartInstance.current!, event);
      });

      // Update y-axis scale
      setYScaleSuggestedMax(
        chartInstance.current.options as ChartOptions<"bar">,
        chartInstance.current.data.datasets[0].data as number[]
      );

      // Update the chart
      chartInstance.current.update();
    }
  };

  useEffect(() => {
    if (chartInstance.current) {
      updateChartData();
    }
  }, [maxEvents]);

  useEffect(() => {
    return () => {
      if (chartInstance.current) {
        console.log("Destroying chatInstance");
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [currentPoseId]);

  return (
    <Box width="100%" height="200px" {...props} ref={containerRef}>
      <canvas ref={chartRef} />
    </Box>
  );
};
