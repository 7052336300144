import { Avatar } from "@chakra-ui/react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../login/useAuthContext";
import "./AccountProfile.css"; // We'll create this file for custom styles

const AccountProfile: React.FC = () => {
  const { user } = useAuthContext();
  const history = useHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account" />
          </IonButtons>
          <IonTitle>Profile Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList inset={true}>
          <IonItem
            button
            detail
            onClick={() => history.push("/account/edit-photo-url")}
            className="profile-item"
          >
            <div className="label-value-container profile-photo-container">
              <IonLabel>
                Profile Photo
                <p>Tap to change</p>
              </IonLabel>
              <Avatar
                size="lg"
                marginBottom={2}
                src={
                  user?.photoURL ||
                  "https://ionicframework.com/docs/img/demos/avatar.svg"
                }
              />
            </div>
          </IonItem>

          <IonItem
            button
            detail={true}
            onClick={() => history.push("/account/edit-display-name")}
            className="profile-item"
          >
            <div className="label-value-container">
              <IonLabel>Display Name</IonLabel>
              <h4>{user?.displayName || "Not set"}</h4>
            </div>
          </IonItem>

          <IonItem
            button
            detail={true}
            onClick={() => history.push("/account/edit-email")}
            className="profile-item"
          >
            <div className="label-value-container">
              <IonLabel>Email</IonLabel>
              <h4>{user?.email || "Not set"}</h4>
            </div>
          </IonItem>

          <IonItem
            button
            detail={true}
            onClick={() => history.push("/account/edit-password")}
            className="profile-item"
          >
            <div className="label-value-container">
              <IonLabel>Password</IonLabel>
              <h4>Tap to change</h4>
            </div>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default AccountProfile;
