import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useMemo, useState } from "react";
import { useAvailablePoses } from "../../hooks/useAvailablePoses";
import poses from "../generated/poses.json";
import { playPoseStateTransitionTones } from "../pose-detection/playPoseStateTransitionTones";
import { PosePicker } from "../PosePicker";
import VideoDevicePicker, {
  SourceType,
} from "../source-picker/VideoDevicePicker";
import useSpeechQueue from "../tts/useSpeechQueue";
import { PoseIds } from "../types";
import AudioVolumeControls from "./AudioVolumeControls";
import { SettingsContext } from "./SettingsContext";

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SettingsModal: React.FC<SettingsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    detectingPoseId,
    setDetectingPoseId,
    poseModelComplexity,
    setPoseModelComplexity,
    isModelDelegateCPU,
    setIsModelDelegateCPU,
    isPerformanceControlsVisible,
    setIsPerformanceControlsVisible,
    isLargeFPS,
    setIsLargeFPS,
    videoDeviceId,
    setVideoDeviceId,
    automaticMirrorVideo,
    setAutomaticMirrorVideo,
    mirrorVideo,
    setMirrorVideo,
    isCameraBackFacing,
    setIsCameraBackFacing,
    resetAllSettings,
    onlyShowHandstandPoses,
    setOnlyShowHandstandPoses,
    isCurrentPoseAggregateSummaryVisible,
    setIsCurrentPoseAggregateSummaryVisible,
    isCurrentPoseHistoryVisible,
    setIsCurrentPoseHistoryVisible,
    overrideFrameAspectRatio,
    setOverrideFrameAspectRatio,
    overrideSourceTypeIcon,
    setOverrideSourceTypeIcon,
  } = useContext(SettingsContext);

  const availablePoses = useAvailablePoses();

  const poseDescription = useMemo(() => {
    // Find the pose description based on the detectingPoseId
    const pose = poses.find((p) => p.poseId === detectingPoseId);
    return pose ? pose.description : "";
  }, [detectingPoseId]);

  const [isVolumeTestRunning, setIsVolumeTestRunning] = useState(false);
  const { queueUtterance } = useSpeechQueue();

  const handleVideoDeviceChange = (
    deviceId: string | undefined,
    type: SourceType,
    isBackFacing?: boolean
  ) => {
    setVideoDeviceId(deviceId);
    if (isBackFacing !== undefined) {
      setIsCameraBackFacing(isBackFacing);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <Flex justifyContent="space-between">
                <FormLabel htmlFor="only-handstand-poses" mb="0">
                  Only Show Handstand Poses
                </FormLabel>
                <Switch
                  id="only-handstand-poses"
                  isChecked={onlyShowHandstandPoses}
                  onChange={(e) => setOnlyShowHandstandPoses(e.target.checked)}
                />
              </Flex>
              <FormHelperText>
                Disable this option to show a variety of poses.
              </FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Pose to Detect</FormLabel>
              <PosePicker
                poseValue={detectingPoseId}
                onPoseChange={(poseId) => setDetectingPoseId(poseId as PoseIds)}
                availablePoses={availablePoses}
              />
              <FormHelperText>{poseDescription}</FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Video Device</FormLabel>
              <VideoDevicePicker
                forceSelect={true}
                videoDeviceId={videoDeviceId}
                setVideoDeviceId={handleVideoDeviceChange}
              />
            </FormControl>

            <FormControl>
              <Flex justifyContent="space-between">
                <FormLabel htmlFor="automatic-mirror-video" mb="0">
                  Automatic Video Mirroring
                </FormLabel>
                <Switch
                  id="automatic-mirror-video"
                  isChecked={automaticMirrorVideo}
                  onChange={(e) => setAutomaticMirrorVideo(e.target.checked)}
                />
              </Flex>
              <FormHelperText>
                Automatically mirror video based on camera type (front/back
                facing)
              </FormHelperText>
            </FormControl>

            <FormControl>
              <Flex justifyContent="space-between">
                <FormLabel htmlFor="mirror-video" mb="0">
                  Mirror Video
                </FormLabel>
                <Switch
                  id="mirror-video"
                  isChecked={mirrorVideo}
                  onChange={(e) => setMirrorVideo(e.target.checked)}
                  isDisabled={automaticMirrorVideo}
                />
              </Flex>
              <FormHelperText>
                Manually control video mirroring (disabled when automatic is on)
              </FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Volume</FormLabel>
              <Flex flexGrow={1} width="100%">
                <AudioVolumeControls forceOpen={true} width="100%" />
                <Button
                  isLoading={isVolumeTestRunning}
                  onClick={() => {
                    setIsVolumeTestRunning(true);
                    playPoseStateTransitionTones(false, false);
                    window.setTimeout(async () => {
                      await queueUtterance({
                        text: "Announcements sound like this",
                      });
                      setIsVolumeTestRunning(false);
                    }, 500);
                  }}
                >
                  Test Volume
                </Button>
              </Flex>
              <FormHelperText>
                Determines the audio volume for sound effects and speech.
              </FormHelperText>
            </FormControl>

            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel htmlFor="aggregate-summary-visible" mb="0">
                Show Pose Aggregate Summary
              </FormLabel>
              <Switch
                id="aggregate-summary-visible"
                isChecked={isCurrentPoseAggregateSummaryVisible}
                onChange={(e) =>
                  setIsCurrentPoseAggregateSummaryVisible(e.target.checked)
                }
              />
            </FormControl>

            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel htmlFor="pose-history-visible" mb="0">
                Show Pose History
              </FormLabel>
              <Switch
                id="pose-history-visible"
                isChecked={isCurrentPoseHistoryVisible}
                onChange={(e) =>
                  setIsCurrentPoseHistoryVisible(e.target.checked)
                }
              />
            </FormControl>

            <Accordion allowToggle width="100%">
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontWeight="bold">
                    Advanced Options
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <VStack spacing={4}>
                    <FormControl>
                      <FormLabel htmlFor="override-frame-aspect-ratio">
                        Override Frame Aspect Ratio
                      </FormLabel>
                      <Select
                        id="override-frame-aspect-ratio"
                        value={overrideFrameAspectRatio}
                        onChange={(e) =>
                          setOverrideFrameAspectRatio(Number(e.target.value))
                        }
                      >
                        <option value={0}>No override</option>
                        <option value={3 / 4}>Portrait Full (3:4)</option>
                        <option value={9 / 16}>Portrait Wide (9:16)</option>
                        <option value={4 / 3}>Landscape Full (4:3)</option>
                        <option value={16 / 9}>Landscape Wide (16:9)</option>
                      </Select>
                    </FormControl>

                    <FormControl
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormLabel htmlFor="override-source-type-icon" mb="0">
                        Override Source Type Icon
                      </FormLabel>
                      <Switch
                        id="override-source-type-icon"
                        isChecked={overrideSourceTypeIcon}
                        onChange={(e) =>
                          setOverrideSourceTypeIcon(e.target.checked)
                        }
                      />
                    </FormControl>

                    <FormControl
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormLabel htmlFor="performance-controls-visible" mb="0">
                        Performance Controls Visible
                      </FormLabel>
                      <Switch
                        id="performance-controls-visible"
                        isChecked={isPerformanceControlsVisible}
                        onChange={(e) =>
                          setIsPerformanceControlsVisible(e.target.checked)
                        }
                      />
                    </FormControl>

                    <FormControl
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormLabel htmlFor="large-fps" mb="0">
                        Large FPS
                      </FormLabel>
                      <Switch
                        id="large-fps"
                        isChecked={isLargeFPS}
                        onChange={(e) => setIsLargeFPS(e.target.checked)}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Pose Model Complexity</FormLabel>
                      <Select
                        value={poseModelComplexity}
                        onChange={(e) =>
                          setPoseModelComplexity(
                            Number(e.target.value) as 0 | 1 | 2
                          )
                        }
                      >
                        <option value={0}>Lite</option>
                        <option value={1}>Full</option>
                        <option value={2}>Heavy</option>
                      </Select>
                    </FormControl>

                    <FormControl
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormLabel htmlFor="model-delegate-cpu" mb="0">
                        Use CPU for Pose Model
                      </FormLabel>
                      <Switch
                        id="model-delegate-cpu"
                        isChecked={isModelDelegateCPU}
                        onChange={(e) =>
                          setIsModelDelegateCPU(e.target.checked)
                        }
                      />
                    </FormControl>
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              resetAllSettings();
              onClose();
            }}
          >
            Reset
          </Button>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
