import { GuidanceStyle } from "../pose-detection/detection-guidance/GuidanceStyle";
import { PoseModelComplexity } from "../pose-detection/poseModelAssetMap";
import { PoseIds } from "../types";

export const defaultSettings = {
  detectingPoseId: PoseIds.Handstand,
  poseModelComplexity: 1 as PoseModelComplexity,
  isModelDelegateCPU: false,
  isLargeFPS: false,
  videoDeviceId: undefined as string | undefined,
  isPerformanceControlsVisible: false,
  onlyShowHandstandPoses: true,
  audioVolume: 1,
  isCurrentPoseAggregateSummaryVisible: false,
  isCurrentPoseHistoryVisible: true,
  poseDetectionGuidanceControlsExpanded: false,
  isPoseDetectionGuidanceEnabled: true,
  poseDetectionGuidanceInterval: 5000,
  poseDetectionGuidanceExponentialBackoffEnabled: true,
  guidanceStyle: GuidanceStyle.Friendly,
  detectionGuidanceMutedUntil: 0,
  detectionGuidanceControlsVisible: true,
  dontShowWaitlistModal: false,
  signInPromptSilencedUntil: 0,
  overrideFrameAspectRatio: 0,
  overrideSourceTypeIcon: false,
  automaticMirrorVideo: true,
  mirrorVideo: undefined as boolean | undefined,
  isCameraBackFacing: false,
};

export type Settings = typeof defaultSettings;
