// src/components/settings/EditPassword.tsx
import { Flex } from "@chakra-ui/react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { FirebaseError } from "firebase/app";
import { updatePassword, User } from "firebase/auth";
import React, { useState } from "react";
import { useHistory } from "react-router";
import ButtonToolbar from "../common/ButtonToolbar";
import { PasswordInput } from "../login/PasswordInput";
import { useAuthContext } from "../login/useAuthContext";

const EditPassword: React.FC = () => {
  const { user, signOut } = useAuthContext();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [requiresReauth, setRequiresReauth] = useState(false);
  const [present] = useIonToast();
  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;
    if (newPassword !== confirmPassword) {
      present({
        message: "Passwords do not match",
        duration: 3000,
        color: "danger",
      });
      return;
    }

    setIsLoading(true);
    try {
      await updatePassword(user as User, newPassword);
      present({
        message: "Password updated successfully",
        duration: 3000,
        color: "success",
      });
      setRequiresReauth(false);
      history.goBack();
    } catch (error) {
      const firebaseError = error as FirebaseError;
      if (firebaseError.code?.includes("requires-recent-login")) {
        setRequiresReauth(true);
        present({
          message: "Please reauthenticate to update password",
          duration: 3000,
          color: "warning",
        });
      } else {
        present({
          message: `Error updating password: ${firebaseError.message}`,
          duration: 3000,
          color: "danger",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleReauthenticate = async () => {
    await signOut();
    history.push("/login", { from: "/account/edit-password" });
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account/profile" />
          </IonButtons>
          <IonTitle>Edit Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap={6}>
            <PasswordInput
              value={newPassword}
              onChange={setNewPassword}
              label="New Password"
              showValidation={true}
            />
            <PasswordInput
              value={confirmPassword}
              onChange={setConfirmPassword}
              label="Confirm New Password"
              showValidation={false}
              customError={
                newPassword !== confirmPassword
                  ? "Passwords do not match"
                  : undefined
              }
            />
          </Flex>
          <ButtonToolbar>
            <IonButton slot="end" color="light" onClick={handleCancel}>
              Cancel
            </IonButton>
            {requiresReauth ? (
              <IonButton
                slot="end"
                strong={true}
                onClick={handleReauthenticate}
              >
                Sign In Again
              </IonButton>
            ) : (
              <IonButton
                slot="end"
                strong={true}
                type="submit"
                disabled={
                  !newPassword || newPassword !== confirmPassword || isLoading
                }
              >
                Update Password
              </IonButton>
            )}
          </ButtonToolbar>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default EditPassword;
