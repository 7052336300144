import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { format, formatDistanceToNow } from "date-fns";
import { FaAndroid, FaApple, FaGlobe } from "react-icons/fa";
import { useState, useEffect } from "react";
import packageJson from "../../package.json";

export const AppBuildDetails: React.FC = () => {
  const [version, setVersion] = useState<string>("");
  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    const getAppInfo = async () => {
      if (isNative) {
        const info = await App.getInfo();
        const nativeVersion = info.version;
        const packageVersion = packageJson.version;

        if (nativeVersion !== packageVersion) {
          setVersion(`${nativeVersion} (package: ${packageVersion})`);
        } else {
          setVersion(nativeVersion);
        }
      } else {
        // For web, use package.json
        setVersion(packageJson.version);
      }
    };

    getAppInfo();
  }, [isNative]);

  const getWebAddress = () => {
    const { protocol, hostname, port } = window.location;
    const scheme = protocol.slice(0, -1);
    const standardPorts: { [key: string]: string } = {
      http: "80",
      https: "443",
    };
    const showPort = port && port !== standardPorts[scheme];
    return `${scheme}://${hostname}${showPort ? ":" + port : ""}`;
  };

  const getPlatformInfo = () => {
    if (!isNative) {
      return { name: getWebAddress(), icon: FaGlobe };
    }
    const platform = Capacitor.getPlatform();
    switch (platform) {
      case "ios":
        return { name: "iOS", icon: FaApple };
      case "android":
        return { name: "Android", icon: FaAndroid };
      default:
        return { name: platform, icon: FaGlobe };
    }
  };

  const { name: platformOrAddress, icon } = getPlatformInfo();

  return (
    <>
      <Text>Version {version}</Text>
      <Text>
        Built{" "}
        <b>
          {formatDistanceToNow(
            new Date(Number(import.meta.env.VITE_BUILD_TIME) * 1000)
          )}
        </b>{" "}
        ago
      </Text>
      <Text>
        {format(
          new Date(Number(import.meta.env.VITE_BUILD_TIME) * 1000),
          "PPpp"
        )}
      </Text>
      <Flex alignItems="center" gap={2}>
        <Icon as={icon} />
        <Text wordBreak="break-all">{platformOrAddress}</Text>
      </Flex>
    </>
  );
};
