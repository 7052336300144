import {
  Criterion,
  CriterionJointPair,
  CriterionLandmarkPair,
  CriterionLandmarkPairs,
} from "../../generated/PoseDefinition";
import { PoseDetectionFeedbackItem } from "../pose-detection-feedback";
import { GuidanceStyle } from "./GuidanceStyle";
import { GuidanceTemplateType } from "./GuidanceTemplateType";
import { guidanceTemplates } from "./guidanceTemplates";

/**
 * Identifies the reason for generating a pose detection guidance. For example, for a "bendKnees"
 * criterion, we also need to know the template type (Visibility or JointsBent).
 * When combined with a guidance style, a guidance reason provides sufficient information
 * to pick a guidance template.
 */
export class GuidanceReason {
  constructor(
    public feedbackKey: string,
    public templateType: GuidanceTemplateType
  ) {}

  equals(other: GuidanceReason | undefined): boolean {
    return (
      this.feedbackKey === other?.feedbackKey &&
      this.templateType === other?.templateType
    );
  }

  toString(): string {
    return `${this.feedbackKey}:${this.templateType})`;
  }
}

/**
 * Return the singular form of the plural noun.
 * For example, if pluralNoun is "knees", return "knee".
 * If the singular form cannot be determined, return jointPair as is.
 * @param pluralNoun The noun to get the singular form of
 * @returns The singular form of the noun
 */
function singularize(pluralNoun: string) {
  if (pluralNoun === "indices") return "index";
  if (pluralNoun === "feet") return "foot";
  if (pluralNoun.endsWith("s")) {
    return pluralNoun.slice(0, -1);
  }
  return pluralNoun;
}

function getNounsListPhrase(nouns: string[]) {
  return nouns.length > 1
    ? nouns.slice(0, -1).join(", ") + " and " + nouns[nouns.length - 1]
    : nouns[0];
}

function getSidedPartPhrase(side: string | undefined, pair: string) {
  const landmarkPair = singularize(pair);
  const sideText = side
    ? "your " + side
    : startsWithVowel(landmarkPair)
    ? "an"
    : "a";
  const sidedPartPhrase = `${sideText} ${landmarkPair}`;
  return sidedPartPhrase;
}

/**
 * Identifies the reason for generating a pose detection guidance based on the first unmet feedback item.
 * @param firstUnmetItem - The first unmet feedback item
 * @returns The guidance reason
 */
export function feedbackItemsToGuidanceReason(
  firstUnmetItem: PoseDetectionFeedbackItem
) {
  const templateType = getTemplateType(firstUnmetItem);
  return new GuidanceReason(firstUnmetItem.criterion.feedbackKey, templateType);
}

/**
 * Generates guidance text based on the first unmet feedback item and the specified guidance style.
 * @param firstUnmetItem - The first unmet feedback item
 * @param guidanceStyle - The guidance style
 * @returns An object containing the unmet criteria guidance text and the guidance reason
 */
export function feedbackItemToGuidance(
  firstUnmetItem: PoseDetectionFeedbackItem,
  guidanceStyle: GuidanceStyle
) {
  if (firstUnmetItem.isMet) {
    // no guidance, no reason
    return { unmetCriteriaGuidance: undefined, guidanceReason: undefined };
  }

  const templateType = getTemplateType(firstUnmetItem);
  const templates = guidanceTemplates[guidanceStyle]?.[templateType] || [];
  const template = templates[Math.floor(Math.random() * templates.length)];

  if (!template) {
    throw new Error(
      `No template found for feedback item, templateType ${templateType}, guidanceStyle ${guidanceStyle}`
    );
  }

  const criterion = firstUnmetItem.criterion.criterion;
  const landmarksLackingVisibility = getLandmarksNeedingVisibility(criterion);
  const landmarksPhrase = getNounsListPhrase(landmarksLackingVisibility);
  const jointPair = (criterion as CriterionJointPair).jointPair;
  const landmarkPair = (criterion as CriterionLandmarkPair).landmarkPair;
  const sidedPartPhrase =
    jointPair || landmarkPair
      ? getSidedPartPhrase(
          firstUnmetItem.criterion.side,
          jointPair || landmarkPair
        )
      : "";
  let pair1 = "";
  let pair2 = "";
  if (
    criterion.type === "LandmarksAbove" ||
    criterion.type === "LandmarksAboveLowest" ||
    criterion.type === "LandmarksStacked" ||
    criterion.type === "LandmarksBelowHighest" ||
    criterion.type === "LandmarksSameHeight" ||
    criterion.type === "LandmarksTouching"
  ) {
    [pair1, pair2] = (criterion as CriterionLandmarkPairs).landmarkPairs;
  }
  const aboveText = firstUnmetItem.value > 0 ? "higher " : "";
  const singularPair2 = singularize(pair2);

  const unmetCriteriaGuidance = template
    .replace("{landmarksPhrase}", landmarksPhrase)
    .replace("{jointPair}", jointPair)
    .replace("{landmarkPair}", landmarkPair)
    .replace("{sidedPartPhrase}", sidedPartPhrase)
    .replace("{pair1}", pair1)
    .replace("{pair2}", pair2)
    .replace("{aboveText}", aboveText)
    .replace("{singularPair2}", singularPair2);
  return {
    unmetCriteriaGuidance,
    guidanceReason: new GuidanceReason(
      firstUnmetItem.criterion.feedbackKey,
      templateType
    ),
  };
}

function getTemplateType(
  feedbackItem: PoseDetectionFeedbackItem
): GuidanceTemplateType {
  if (feedbackItem.visibility < feedbackItem.visibilityThreshold) {
    return GuidanceTemplateType.Visibility;
  }
  return feedbackItem.criterion.type as GuidanceTemplateType;
}

const startsWithVowel = (str: string) => /^[aeiou]/i.test(str);
function getLandmarksNeedingVisibility(criterion: Criterion): string[] {
  switch (criterion.type) {
    case "LandmarksTogether":
    case "LandmarksApart":
    case "MassCenteredOn":
    case "LandmarkPairSameHeight":
    case "OneLandmarkHigher":
    case "OneLandmarkInFront":
      return [(criterion as CriterionLandmarkPair).landmarkPair];
    case "LandmarksAbove":
    case "LandmarksAboveLowest":
    case "LandmarksStacked":
    case "LandmarksBelowHighest":
    case "LandmarksSameHeight":
    case "LandmarksTouching":
      return (criterion as CriterionLandmarkPairs).landmarkPairs;
    case "JointsBent":
    case "JointsStraight":
    case "OneJointBent":
    case "OneJointStraight":
      return [(criterion as CriterionJointPair).jointPair];
    default:
      console.warn(
        "Criterion type not supported in getLandmarksNeedingVisibility",
        criterion
      );
      return [];
  }
}
