import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Skeleton,
  Spacer,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { FirebaseError } from "firebase/app";
import { verifyBeforeUpdateEmail } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { MdEmail, MdRefresh } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { getFirebaseAuth } from "../components/login/getFirebaseAuth";
import { useAuthContext } from "../components/login/useAuthContext";

const VerifyEmail: React.FC = () => {
  const toast = useToast();
  const authContext = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  const router = useIonRouter();
  const location = useLocation<{ verificationEmailSent: boolean }>();
  const [verificationEmailSent, setVerificationEmailSent] = useState(
    location.state?.verificationEmailSent || false
  );

  const [countdown, setCountdown] = useState(5);

  const [loadingCountdown, setLoadingCountdown] = useState(5);
  const [isInitialLoading, setIsInitialLoading] = useState(!authContext.user);

  console.log("VerifyEmail", {
    user: authContext.user,
    verificationEmailSent,
    isLoading,
    countdown,
    loadingCountdown,
    isInitialLoading,
  });
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isInitialLoading) {
      if (!authContext.user) {
        timer = setInterval(() => {
          setLoadingCountdown((prevCount) => {
            if (prevCount === 1) {
              clearInterval(timer);
              setIsInitialLoading(false);
              setLoadingCountdown(5); // Reset for potential reload countdown
            }
            return prevCount - 1;
          });
        }, 1000);
      } else {
        setIsInitialLoading(false);
      }
    } else if (!authContext.user) {
      timer = setInterval(() => {
        setLoadingCountdown((prevCount) => {
          if (prevCount === 1) {
            clearInterval(timer);
            router.push("/", "forward", "replace");
            window.location.reload();
          }
          return prevCount - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isInitialLoading, authContext.user]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (authContext.user?.emailVerified) {
      timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount === 1) {
            clearInterval(timer);
            handleContinue();
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [authContext.user?.emailVerified]);

  const handleSendVerificationEmail = async () => {
    setIsLoading(true);
    try {
      await FirebaseAuthentication.sendEmailVerification();
      setVerificationEmailSent(true);
      toast({
        title: "Verification email sent",
        description: "Please check your inbox and complete verification.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error sending verification email",
        description: (error as Error).message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const [showLoginAgain, setShowLoginAgain] = useState(false);

  const handleUpdateEmail = async () => {
    if (!authContext.user) {
      throw new Error("User is not authenticated");
    }
    if (!newEmail) {
      toast({
        title: "Email is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await verifyBeforeUpdateEmail(authContext.user, newEmail);
      toast({
        title: "Verification email sent",
        description:
          "Please check your email to verify and complete the update.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      const errorCode = (error as FirebaseError).code;
      if (errorCode?.includes("requires-recent-login")) {
        setShowLoginAgain(true);
        toast({
          title: "Recent login required",
          description: "Please log in again to update your email.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error updating email",
          description: (error as Error).message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };

  const handleLoginAgain = async () => {
    await authContext.signOut();
    router.push("/login", "forward", "replace");
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleContinue = () => {
    router.push("/", "forward", "replace");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Verify Email Address</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Box padding={[3, 5]}>
          {isInitialLoading ? (
            <>
              <Skeleton height={20} />
              <Text>Loading...</Text>
            </>
          ) : authContext.user ? (
            <VStack spacing={4}>
              {!authContext.user.email ? (
                <>
                  <Text>
                    You don't have an associated email address. Please link
                    another identity provider or add an email address:
                  </Text>
                  <IonRouterLink routerLink="/account/link-accounts">
                    <Button colorScheme="blue">Go to Link Accounts</Button>
                  </IonRouterLink>
                  <Flex align="center" width="100%" my={4}>
                    <Box flex={1} height="1px" bg="gray.300" />
                    <Text px={3} color="gray.500" fontWeight="medium">
                      or
                    </Text>
                    <Box flex={1} height="1px" bg="gray.300" />
                  </Flex>
                  <FormControl variant="floating">
                    <Input
                      placeholder=" "
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                    <FormLabel>Enter new email</FormLabel>
                  </FormControl>
                  {showLoginAgain ? (
                    <Button
                      width="100%"
                      onClick={handleLoginAgain}
                      colorScheme="blue"
                    >
                      Sign In Again
                    </Button>
                  ) : (
                    <Button
                      width="100%"
                      onClick={handleUpdateEmail}
                      isLoading={isLoading}
                    >
                      Add Email
                    </Button>
                  )}
                </>
              ) : !authContext.user.emailVerified ? (
                <>
                  <Text>
                    {verificationEmailSent
                      ? `A verification email has been sent to your inbox (${authContext.user.email}). Please complete the verification process.`
                      : `Your email address (${authContext.user.email}) is not verified. Please verify your email.`}
                  </Text>
                  <ButtonGroup spacing={2} mt={4}>
                    <Button
                      onClick={handleRefresh}
                      isLoading={isLoading}
                      colorScheme={verificationEmailSent ? "blue" : undefined}
                      leftIcon={<MdRefresh />}
                    >
                      Refresh
                    </Button>
                    <Button
                      onClick={handleSendVerificationEmail}
                      isLoading={isLoading}
                      colorScheme={verificationEmailSent ? undefined : "blue"}
                      leftIcon={<MdEmail />}
                    >
                      Re-send Verification Email
                    </Button>
                  </ButtonGroup>
                </>
              ) : (
                <>
                  <Text>Your email is verified!</Text>
                  <Text>Redirecting in {countdown} seconds...</Text>
                  <Button onClick={handleContinue} isLoading={isLoading}>
                    Continue Now
                  </Button>
                </>
              )}
              <Spacer />
              <Text color="chakra-subtle-text">
                Wrong account? Need to start over?
              </Text>
              <Button
                onClick={async () => {
                  setIsLoading(true);
                  await authContext.signOut();
                  router.push("/", "forward", "replace");
                }}
                colorScheme="red"
                size="sm"
              >
                Sign Out
              </Button>
            </VStack>
          ) : (
            <>
              <Skeleton height={20} />
              <Text>Reloading in {loadingCountdown} seconds...</Text>
            </>
          )}
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default VerifyEmail;
