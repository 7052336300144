import {
  DarkMode,
  Flex,
  FlexProps,
  Spacer,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useTimeoutEffect } from "react-timing-hooks";
import { TimeoutCreator } from "react-timing-hooks/dist/timeout/types";
import { ControlsExpander } from "../../common/ControlsExpander";
import { SettingsContext } from "../../settings/SettingsContext";
import {
  SpeechUtteranceRequestContainer,
  useSpeechFacade,
} from "../../tts/useSpeechFacade";
import { PoseDetectionFeedback } from "../pose-detection-feedback";
import DetectionGuidanceConfiguration from "./DetectionGuidanceConfiguration";
import DetectionGuidanceMuteControl from "./DetectionGuidanceMuteControl";
import { GuidanceState } from "./DetectionGuidanceStateVisualization";
import { GuidanceContext } from "./GuidanceContext";
import { GuidanceStyle } from "./GuidanceStyle";
import {
  GuidanceReason,
  feedbackItemToGuidance,
  feedbackItemsToGuidanceReason,
} from "./feedbackItemToGuidance";
import { useDetectionGuidanceMuteInterval } from "./useDetectionGuidanceMuteInterval";

const GUIDANCE_PAUSE_DURATION = 10000;

type PoseDetectionGuidanceControlsProps = {
  feedbackRef: React.MutableRefObject<PoseDetectionFeedback | null>;
  poseId?: string;
  movementPatternId?: string;
  checkpointPoseId?: string;
  latestCompletionAt?: number;
  voice?: string;
} & Partial<FlexProps>;

const PoseDetectionGuidanceControls: React.FC<
  PoseDetectionGuidanceControlsProps
> = ({
  feedbackRef,
  poseId,
  movementPatternId,
  checkpointPoseId,
  latestCompletionAt,
  voice,
  ...props
}) => {
  const {
    isPoseDetectionGuidanceEnabled,
    setIsPoseDetectionGuidanceEnabled,
    poseDetectionGuidanceInterval,
    poseDetectionGuidanceExponentialBackoffEnabled,
    guidanceStyle,
    detectionGuidanceControlsVisible,
  } = useContext(SettingsContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const mainRef = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: mainRef, handler: () => onClose() });

  const effectiveGuidanceStyle = useMemo(() => {
    // verify that the value is in the enum, and if not use Friendly
    if (Object.values(GuidanceStyle).includes(guidanceStyle)) {
      return guidanceStyle;
    }
    return GuidanceStyle.Friendly;
  }, [guidanceStyle]);

  const [guidanceState, setGuidanceState] = useState<GuidanceState>("ready");
  // also keep a ref to the guidanceState, for use in any timeout callback
  const guidanceStateRef = useRef<GuidanceState>(guidanceState);
  const { prepareUtterance, prepareAndQueueUtterance, cancelUtteranceRequest } =
    useSpeechFacade(
      {
        type: "detection-guidance",
      },
      voice
    );
  const utteranceRequestContainerRef = useRef<
    SpeechUtteranceRequestContainer | undefined
  >();
  const currentGuidanceReasonRef = useRef<GuidanceReason | undefined>();
  const pendingUtteranceRequestContainerRef = useRef<
    SpeechUtteranceRequestContainer | undefined
  >();
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const location = useLocation();
  const guidancePausedUntilRef = useRef<number | undefined>();
  const isMuteIntervalActive = useDetectionGuidanceMuteInterval();

  const currentDetectionGuidanceEnabled = useMemo(() => {
    if (!isPoseDetectionGuidanceEnabled) {
      return false;
    }

    if (isMuteIntervalActive) {
      return false;
    }

    // First, determine if we are running in a known context based on location
    let guidanceContext;
    const movementAdventureRegex = /^\/games\/movement-adventure/;
    const challengeRegex = /^\/challenge\/[^/]+\/play$/;
    const workoutRegex = /^\/workout\/[^/]+\/[^/]+$/;

    if (movementAdventureRegex.test(location.pathname)) {
      guidanceContext = GuidanceContext.MovementAdventure;
    } else if (challengeRegex.test(location.pathname)) {
      guidanceContext = GuidanceContext.Challenge;
    } else if (workoutRegex.test(location.pathname)) {
      guidanceContext = GuidanceContext.Workout;
    }

    return true;
  }, [
    isPoseDetectionGuidanceEnabled,
    isMuteIntervalActive,
    poseId,
    movementPatternId,
  ]);

  // force periodic re-evaluation of the
  const [checkFeedbackCounter, setCheckFeedbackCounter] = useState(0);

  useEffect(() => {
    if (!currentDetectionGuidanceEnabled) return;

    const interval = setInterval(() => {
      setCheckFeedbackCounter((prev) => prev + 1);
    }, 100);

    return () => clearInterval(interval);
  }, [currentDetectionGuidanceEnabled]);

  useEffect(() => {
    if (!currentDetectionGuidanceEnabled) {
      lastGuidanceTraceRef.current = undefined;
      setEffectiveGuidanceInterval(poseDetectionGuidanceInterval);
    }
  }, [currentDetectionGuidanceEnabled]);

  // The effectiveGuidanceInterval is based on poseDetectionGuidanceInterval but with exponential backoff applied (if enabled)
  const [effectiveGuidanceInterval, setEffectiveGuidanceInterval] = useState(
    poseDetectionGuidanceInterval
  );
  const lastGuidanceTraceRef = useRef<string | undefined>();
  const guidanceIntervalStartAtRef = useRef<number | undefined>();

  const determineGuidanceTrace = useCallback(
    (guidanceReason?: GuidanceReason) => {
      return `${poseId}-${movementPatternId}-${checkpointPoseId}-${guidanceReason?.toString()}`;
    },
    [poseId, movementPatternId, checkpointPoseId]
  );

  const updateGuidanceInterval = useCallback(
    (guidanceReason?: GuidanceReason) => {
      let newGuidanceInterval: number;
      if (poseDetectionGuidanceExponentialBackoffEnabled) {
        const currentGuidanceTrace = determineGuidanceTrace(guidanceReason);
        if (
          guidanceReason &&
          currentGuidanceTrace === lastGuidanceTraceRef.current
        ) {
          newGuidanceInterval = effectiveGuidanceInterval * 2;
        } else {
          newGuidanceInterval = poseDetectionGuidanceInterval;
          lastGuidanceTraceRef.current = currentGuidanceTrace;
        }
      } else {
        newGuidanceInterval = poseDetectionGuidanceInterval;
      }
      setEffectiveGuidanceInterval(newGuidanceInterval);
    },
    [
      poseDetectionGuidanceExponentialBackoffEnabled,
      determineGuidanceTrace,
      effectiveGuidanceInterval,
      poseDetectionGuidanceInterval,
      poseId,
      movementPatternId,
      checkpointPoseId,
    ]
  );

  useEffect(() => {
    // reset the guidance interval (from exponential backoff) when the pose changes
    updateGuidanceInterval();
  }, [
    poseId,
    movementPatternId,
    checkpointPoseId,
    poseDetectionGuidanceInterval,
  ]);

  // update the guidanceStateRef from the guidanceState
  useEffect(() => {
    guidanceStateRef.current = guidanceState;
  }, [guidanceState]);

  const lastPoseSpokenRef = useRef<string | undefined>();

  const createUtteranceRequestContainer = useCallback(
    (unmetCriteriaGuidance: string, guidanceReason?: GuidanceReason) => {
      const pose = movementPatternId ? checkpointPoseId : poseId;
      let guidanceText: string;
      if (pose !== lastPoseSpokenRef.current) {
        guidanceText = `${pose}: ${unmetCriteriaGuidance}`;
        lastPoseSpokenRef.current = pose;
      } else {
        guidanceText = unmetCriteriaGuidance;
      }

      if (!poseId && !checkpointPoseId) {
        console.warn("no pose or checkpoint pose", {
          guidanceText,
          poseId,
          checkpointPoseId,
        });
        return;
      }
      const utteranceRequestContainer: SpeechUtteranceRequestContainer = {
        unmetCriteriaGuidance,
        guidanceReason,
        text: guidanceText,
        contextProps: {
          poseId: poseId || checkpointPoseId,
          templateType: guidanceReason?.templateType,
          feedbackKey: guidanceReason?.feedbackKey,
        },
        guidanceStyle: effectiveGuidanceStyle,
      };
      return utteranceRequestContainer;
    },
    [movementPatternId, checkpointPoseId, poseId, effectiveGuidanceStyle]
  );

  const utteranceRequestContainerFromFeedback = useCallback(
    (pendingRequestContainer?: SpeechUtteranceRequestContainer) => {
      const firstUnmetItem = Object.values(feedbackRef.current || {}).find(
        (item) => !item.isMet
      );

      // no unmet items, no utterance
      if (!firstUnmetItem) {
        return;
      }

      const guidanceReason = feedbackItemsToGuidanceReason(firstUnmetItem);

      if (pendingRequestContainer) {
        // if the pending request matches, use it and avoid preparing a new one
        if (
          pendingRequestContainer.guidanceReason?.equals(guidanceReason) &&
          pendingRequestContainer.guidanceStyle === effectiveGuidanceStyle
        ) {
          return pendingRequestContainer;
        }
      }

      const { unmetCriteriaGuidance } = feedbackItemToGuidance(
        firstUnmetItem,
        effectiveGuidanceStyle
      );
      if (!unmetCriteriaGuidance) {
        return;
      }
      const utteranceRequestContainer = createUtteranceRequestContainer(
        unmetCriteriaGuidance,
        guidanceReason
      );
      return utteranceRequestContainer;
    },
    [effectiveGuidanceStyle, createUtteranceRequestContainer]
  );

  /**
   * Update state and pass through the utteranceRequestContainer to be prepared
   * and queued for speaking.
   */
  const speakGuidance = useCallback(
    (utteranceRequestContainer?: SpeechUtteranceRequestContainer) => {
      if (!utteranceRequestContainer?.text) {
        return;
      }

      updateGuidanceInterval(utteranceRequestContainer.guidanceReason);
      setGuidanceState("preparing");
      currentGuidanceReasonRef.current =
        utteranceRequestContainer.guidanceReason;
      utteranceRequestContainerRef.current = utteranceRequestContainer;
      prepareAndQueueUtterance(utteranceRequestContainer).then(() => {
        setGuidanceState("ready");
      });

      if (utteranceRequestContainer.preparationPromise) {
        utteranceRequestContainer.preparationPromise.then(() => {
          setGuidanceState("speaking");
        });
      }
    },
    [prepareAndQueueUtterance, setGuidanceState, updateGuidanceInterval]
  );

  const speakGuidanceRef = useRef(speakGuidance);
  useEffect(() => {
    speakGuidanceRef.current = speakGuidance;
  }, [speakGuidance]);

  /**
   * Timeout callback to initiate guidance when the timeout elapses. Used recursively
   * to wait for additional time in the case of exponential backoff.
   * We need to check if the appropriate guidance has changed at the moment
   * when the guidance would have been spoken without backoff, then decide
   * to either say it now (if the guidance is the same) or delay and say it
   * (or something different) in the future at the appropriate time.
   */
  const handleGuidanceTimeout = useCallback(
    (timeout: TimeoutCreator) => {
      // make sure we are still warming
      if (guidanceStateRef.current === "warming") {
        // Check to make sure we aren't paused due to a completion
        if (guidancePausedUntilRef.current) {
          if (Date.now() < guidancePausedUntilRef.current) {
            // wait the interval again (recursively)
            startGuidanceTimeoutRef.current(timeout);
            return;
          }
        }

        // Determine if sufficient time has elapsed (exponential backoff is in effect)
        const elapsed = Date.now() - (guidanceIntervalStartAtRef.current || 0);

        // If we have a pending utterance and the current unmet criteria guidance reason
        // is the same, we should used it instead of starting a new request
        const utteranceRequestContainer = utteranceRequestContainerFromFeedback(
          pendingUtteranceRequestContainerRef.current
        );
        if (elapsed >= effectiveGuidanceInterval) {
          speakGuidanceRef.current(utteranceRequestContainer);
        } else {
          // if not, only speak guidance if it is different; otherwise, wait the interval time again (exponential backoff)
          const currentGuidanceTrace = determineGuidanceTrace(
            utteranceRequestContainer?.guidanceReason
          );
          if (currentGuidanceTrace !== lastGuidanceTraceRef.current) {
            speakGuidanceRef.current(utteranceRequestContainer);
          } else {
            // wait the interval again (recursively)
            startGuidanceTimeoutRef.current(timeout);
          }
        }
      }
    },
    [
      effectiveGuidanceInterval,
      utteranceRequestContainerFromFeedback,
      determineGuidanceTrace,
    ]
  );

  const handleGuidanceTimeoutRef = useRef(handleGuidanceTimeout);
  useEffect(() => {
    handleGuidanceTimeoutRef.current = handleGuidanceTimeout;
  }, [handleGuidanceTimeout]);

  /**
   * Start a timeout for the guidance interval.
   */
  const startGuidanceTimeout = useCallback(
    (timeout: TimeoutCreator) => {
      timerRef.current = timeout(() => {
        // use a ref to call the callback so that we aren't using stale closures
        handleGuidanceTimeoutRef.current(timeout);
      }, poseDetectionGuidanceInterval);
    },
    [poseDetectionGuidanceInterval]
  );

  const startGuidanceTimeoutRef = useRef(startGuidanceTimeout);
  useEffect(() => {
    startGuidanceTimeoutRef.current = startGuidanceTimeout;
  }, [startGuidanceTimeout]);

  function cancelCurrentUtterance() {
    if (utteranceRequestContainerRef.current) {
      cancelUtteranceRequest(utteranceRequestContainerRef.current);
      utteranceRequestContainerRef.current = undefined;
    }
  }

  const previousPoseDetectionGuidanceIntervalRef = useRef(0);

  useTimeoutEffect(
    (timeout, clearAll) => {
      if (!isPoseDetectionGuidanceEnabled || !currentDetectionGuidanceEnabled) {
        setGuidanceState("disabled");
        clearAll();
        cancelCurrentUtterance();
        return;
      } else {
        if (guidanceState === "disabled") {
          setGuidanceState("ready");
        }
      }

      const intervalChanged =
        previousPoseDetectionGuidanceIntervalRef.current !==
        poseDetectionGuidanceInterval;
      previousPoseDetectionGuidanceIntervalRef.current =
        poseDetectionGuidanceInterval;

      // ready and unmet, start warming
      if (feedbackRef.current && guidanceState === "ready") {
        if (
          Object.entries(feedbackRef.current).some(([_, item]) => !item.isMet)
        ) {
          setGuidanceState("warming");
          guidanceIntervalStartAtRef.current = Date.now();
          clearAll();
          startGuidanceTimeoutRef.current(timeout);

          // as a performance optimization, we try to prepare the utterance before it is needed
          const utteranceRequestContainer =
            utteranceRequestContainerFromFeedback();
          pendingUtteranceRequestContainerRef.current =
            utteranceRequestContainer;
          if (utteranceRequestContainer) {
            prepareUtterance(utteranceRequestContainer);
          }
        }
      } else if (guidanceState === "warming") {
        // if we are warming and the feedback is met, stop warming
        if (
          feedbackRef.current &&
          Object.entries(feedbackRef.current).every(([_, item]) => item.isMet)
        ) {
          clearAll();
          setGuidanceState("ready");
          lastGuidanceTraceRef.current = undefined;
          setEffectiveGuidanceInterval(poseDetectionGuidanceInterval);
        } else if (intervalChanged) {
          // if the interval changed, stop warming and start again
          clearAll();
          setGuidanceState("ready");
        }
      }
    },
    [
      isPoseDetectionGuidanceEnabled,
      currentDetectionGuidanceEnabled,
      checkFeedbackCounter,
      guidanceState,
      utteranceRequestContainerFromFeedback,
      prepareUtterance,
      poseDetectionGuidanceInterval,
    ]
  );

  // Prevent or interrupt the current/queued guidance utterance if the feedback is met or guidance changes (visibility changes)
  useEffect(() => {
    // If there is no current guidance, do nothing
    if (!utteranceRequestContainerRef.current) {
      return;
    }

    const feedbackItem = currentGuidanceReasonRef.current
      ? feedbackRef.current?.[currentGuidanceReasonRef.current.feedbackKey]
      : undefined;

    if (!feedbackItem) {
      // failed to find matching feedback item, stop the utterance
      cancelCurrentUtterance();
      return;
    }

    const { guidanceReason } = feedbackItemToGuidance(
      feedbackItem,
      effectiveGuidanceStyle
    );
    if (!currentGuidanceReasonRef.current?.equals(guidanceReason)) {
      // guidance has changed, stop the utterance
      cancelCurrentUtterance();
    }
  }, [checkFeedbackCounter]);

  useEffect(() => {
    if (latestCompletionAt) {
      // an objective was completed; temporarily increase the interval
      setEffectiveGuidanceInterval((v) => {
        return v + GUIDANCE_PAUSE_DURATION;
      });
      guidancePausedUntilRef.current =
        latestCompletionAt + GUIDANCE_PAUSE_DURATION;
    }
  }, [latestCompletionAt]);

  // console.log("PoseDetectionGuidanceControls", { guidanceState });
  return detectionGuidanceControlsVisible ? (
    <DarkMode>
      <Flex
        ref={mainRef}
        backgroundColor="rgba(0,0,0,0.4)"
        color="white"
        borderRadius={5}
        width="fit-content"
        flexWrap="wrap"
        justifyContent="right"
        {...props}
      >
        <DetectionGuidanceMuteControl
          expanded={isOpen}
          guidanceState={guidanceState}
          effectiveGuidanceInterval={effectiveGuidanceInterval}
          {...(isOpen
            ? {}
            : {
                onClick: () => onOpen(),
              })}
        />
        <Spacer />
        <ControlsExpander expanded={isOpen}>
          <DetectionGuidanceConfiguration
            poseId={poseId}
            movementPatternId={movementPatternId}
          />
        </ControlsExpander>
      </Flex>
    </DarkMode>
  ) : null;
};

export default PoseDetectionGuidanceControls;
