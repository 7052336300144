import { Capacitor } from "@capacitor/core";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { CameraPermissionInstructions } from "./CameraPermissionInstructions";

export const CameraStartErrorControls: React.FC<{
  cameraStartError?: string;
}> = ({ cameraStartError }) =>
  cameraStartError ? (
    <Alert
      backgroundColor="rgba(0,0,0,0.4)"
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      left="50%"
      transform="translateX(-50%)"
      width="80%"
      maxWidth="400px"
      boxShadow="lg"
      borderRadius="md"
      p={4}
    >
      <Flex gap={3} alignItems="center">
        <AlertIcon boxSize="24px" mr={0} />
        <AlertTitle mt={2} mb={1} fontSize="md">
          {cameraStartError === "Permission denied"
            ? "Camera Permission Denied"
            : "Camera Error"}
        </AlertTitle>
      </Flex>
      <AlertDescription maxWidth="sm" fontSize="sm">
        <Flex direction="column" gap={3}>
          {cameraStartError === "Permission denied" ? (
            <>
              <Text>
                Camera access is denied. You need to grant permission to use the
                camera.
              </Text>
              <CameraPermissionInstructions />
            </>
          ) : (
            <>
              <Text>
                Accessing the camera is not working. Reloading the app may solve
                the problem.
                {Capacitor.isNativePlatform() && (
                  <>
                    {" "}
                    If not, you may need to <strong>
                      quit and re-open
                    </strong>{" "}
                    the app to get the camera working again.
                  </>
                )}
              </Text>
              <Text color="chakra-subtle-text">{cameraStartError}</Text>
            </>
          )}
        </Flex>
      </AlertDescription>
      <Box mt={3}>
        <Button
          colorScheme={
            cameraStartError === "Permission denied" ? undefined : "red"
          }
          size="sm"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </Button>
      </Box>
    </Alert>
  ) : null;
