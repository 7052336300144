import { ReactNode, useSyncExternalStore } from "react";
import { Redirect } from "react-router-dom";
import { useAuthContext } from "../components/login/useAuthContext";

type Props = { children?: ReactNode };

export const RequireVerifiedEmailRoute = ({ children }: Props) => {
  const authContext = useAuthContext();
  if (authContext.user && !authContext.user.emailVerified) {
    console.log("redirecting to verify email", authContext.user.email);
    return <Redirect to="/verify-email" />;
  }
  return <>{children}</>;
};
