import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { Capacitor } from "@capacitor/core";
import { NativeAudio } from "@scottjgilroy/native-audio";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SettingsContext } from "../settings/SettingsContext";

/**
 * Wraps the content for an utterance, either as text or an audio asset ID
 */
export type SpeechUtteranceWrapper = {
  /**
   * The unique identifier for the audio asset, if using pre-recorded audio
   */
  id?: string;
  /**
   * The text content of the utterance, if using text-to-speech
   */
  text?: string;
};

/**
 * Contains an utterance wrapper that can be queued and later resolved (when ended or cancelled) or cancelled.
 */
type QueuedSpeechUtterance = {
  utterance: SpeechUtteranceWrapper;
  resolve?: (done: boolean) => void;
  cancel?: () => void;
};

type SpeechQueueContextType = {
  utteranceQueue: React.MutableRefObject<QueuedSpeechUtterance[]>;
  currentUtteranceRef: React.MutableRefObject<QueuedSpeechUtterance | null>;
  isSpeaking: boolean;
  setIsSpeaking: React.Dispatch<React.SetStateAction<boolean>>;
  sourceRef: React.MutableRefObject<AudioBufferSourceNode | null>;
};

export const SpeechQueueContext = createContext<
  SpeechQueueContextType | undefined
>(undefined);

export const SpeechQueueProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const utteranceQueueRef = useRef<QueuedSpeechUtterance[]>([]);
  const currentUtteranceRef = useRef<QueuedSpeechUtterance | null>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const sourceRef = useRef<AudioBufferSourceNode | null>(null);

  return (
    <SpeechQueueContext.Provider
      value={{
        utteranceQueue: utteranceQueueRef,
        currentUtteranceRef,
        isSpeaking,
        setIsSpeaking,
        sourceRef,
      }}
    >
      {children}
    </SpeechQueueContext.Provider>
  );
};

/**
 * Type representing the callbacks and functions returned by the `useSpeechQueue` hook.
 */
type UseSpeechQueueResult = {
  /**
   * Speaks an utterance immediately, clearing the current utterance queue.
   * @param utterance - The utterance to speak
   */
  speak: (utterance: SpeechUtteranceWrapper) => void;

  /**
   * Queues an utterance to be spoken after other utterances in the queue.
   * @param utterance - The utterance to queue
   * @returns A promise which resolves to true if the utterance has been spoken or false if it is cancelled
   */
  queueUtterance: (utterance: SpeechUtteranceWrapper) => Promise<boolean>;

  /**
   * Clears the current utterance queue and stops the current utterance.
   */
  clearUtteranceQueue: () => void;

  /**
   * Cancels a specific utterance, removing it from the queue or stopping it if it's currently being spoken.
   * @param utterance - The utterance to cancel
   */
  cancelUtterance: (utterance: SpeechUtteranceWrapper) => void;
};

const useSpeechQueue = (): UseSpeechQueueResult => {
  const { audioVolume } = useContext(SettingsContext);
  const context = useContext(SpeechQueueContext);
  if (!context) {
    throw new Error("useSpeechQueue must be used within a SpeechQueueProvider");
  }

  const {
    utteranceQueue,
    currentUtteranceRef,
    isSpeaking,
    setIsSpeaking,
    sourceRef,
  } = context;
  const isSpeakingRef = useRef(isSpeaking);

  useEffect(() => {
    isSpeakingRef.current = isSpeaking;
  }, [isSpeaking]);

  const isNative = Capacitor.isNativePlatform();

  const speak = (utterance: SpeechUtteranceWrapper) => {
    clearUtteranceQueue();
    playUtterance({ utterance });
  };

  const queueUtterance = (
    utterance: SpeechUtteranceWrapper
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      utteranceQueue.current.push({ utterance, resolve });
      if (!isSpeakingRef.current) {
        // isSpeaking might not be updated in the current frame yet, so we call
        // stopCurrentUtterance() to ensure we don't have multiple concurrent utterances
        stopCurrentUtterance();
        playNextUtterance();
      }
    });
  };

  const clearUtteranceQueue = () => {
    utteranceQueue.current = [];
    stopCurrentUtterance();
  };

  const playNextUtterance = () => {
    if (utteranceQueue.current.length === 0) {
      setIsSpeaking(false);
      return;
    }
    const utterance = utteranceQueue.current[0];
    utteranceQueue.current = utteranceQueue.current.slice(1);
    playUtterance(utterance);
  };

  const playUtterance = async (queuedUtterance: QueuedSpeechUtterance) => {
    currentUtteranceRef.current = queuedUtterance;
    const utterance = queuedUtterance.utterance;
    setIsSpeaking(true);

    if (utterance.id) {
      try {
        // Set up the listener before playing
        const listener = await NativeAudio.addListener("complete", (event) => {
          console.log("NativeAudio complete event:", event);
          // if (event.assetId === utterance.id) {
          queuedUtterance.resolve?.(true);
          playNextUtterance();
          listener.remove();
          // }
        });

        console.log("NativeAudio.play", { utterance });
        await NativeAudio.play({ assetId: utterance.id });

        queuedUtterance.cancel = async () => {
          console.log("utterance cancel() calling NativeAudio.stop()", {
            utterance,
          });
          await NativeAudio.stop({ assetId: utterance.id as string });
          listener.remove();
          queuedUtterance.resolve?.(false);
        };
      } catch (error) {
        console.error("Error playing audio:", error);
        queuedUtterance.resolve?.(false);
      }
    } else if (utterance.text) {
      if (isNative) {
        try {
          await TextToSpeech.speak({
            text: utterance.text,
            category: "playback",
            volume: audioVolume,
          });
          queuedUtterance.resolve?.(true);
          playNextUtterance();
        } catch (error) {
          console.error("Error in native TTS:", error);
          queuedUtterance.resolve?.(false);
        }

        queuedUtterance.cancel = async () => {
          await TextToSpeech.stop();
          queuedUtterance.resolve?.(false);
        };
      } else {
        const synth = window.speechSynthesis;
        const synthUtterance = new SpeechSynthesisUtterance(utterance.text);
        synthUtterance.volume = audioVolume;

        // Note that the watchdog timer seems to prevent garbage collection of the
        // utterance (and failure of onend) in Safari, plus it is a sort of backup
        // if the end or error events do not fire for whatever reason.
        const watchdogTimer = setTimeout(() => {
          console.warn(
            "Watchdog timeout after speak instead of end or error event",
            utterance.text
          );
          queuedUtterance.resolve?.(false);
        }, 15000);

        synthUtterance.onend = () => {
          clearTimeout(watchdogTimer);
          queuedUtterance.resolve?.(true);
          playNextUtterance();
        };
        synthUtterance.onerror = () => {
          clearTimeout(watchdogTimer);
          queuedUtterance.resolve?.(false);
        };

        queuedUtterance.cancel = () => {
          synth.cancel();
          clearTimeout(watchdogTimer);
          queuedUtterance.resolve?.(true);
        };

        synth.speak(synthUtterance);
      }
    }
  };

  const stopCurrentUtterance = async () => {
    if (isNative) {
      try {
        await TextToSpeech.stop();
      } catch (error) {
        console.error("Error stopping native TTS:", error);
      }
    } else {
      try {
        if (window && window.speechSynthesis) {
          window.speechSynthesis.cancel();
        } else {
          console.warn("Speech synthesis is not available on this platform.");
        }
      } catch (error) {
        console.error("Error cancelling speech synthesis:", error);
      }
    }

    if (sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current = null;
    }

    setIsSpeaking(false);
  };

  const cancelUtterance = (utterance: SpeechUtteranceWrapper) => {
    const index = utteranceQueue.current.findIndex(
      (queuedUtterance) => queuedUtterance.utterance === utterance
    );
    if (index >= 0) {
      // remove it from the queue
      utteranceQueue.current.splice(index, 1);
    }

    if (currentUtteranceRef.current?.utterance === utterance) {
      currentUtteranceRef.current.cancel?.();
      setIsSpeaking(false);
    }
  };

  return { speak, queueUtterance, clearUtteranceQueue, cancelUtterance };
};

export default useSpeechQueue;
