import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, Input, VStack, Text } from "@chakra-ui/react";
import {
  getAuth,
  PasswordValidationStatus,
  validatePassword,
} from "firebase/auth";
import { FormErrorMessage } from "@chakra-ui/react";

interface PasswordInputProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  showValidation?: boolean;
  customError?: string;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  value,
  onChange,
  label = "Password",
  showValidation = true,
  customError,
}) => {
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [passwordStatus, setPasswordStatus] =
    useState<PasswordValidationStatus>();
  const auth = getAuth();

  useEffect(() => {
    const validatePasswordPolicy = async () => {
      if (value) {
        try {
          const status = await validatePassword(auth, value);
          setPasswordErrors([]);
          setPasswordStatus(status);
        } catch (error: any) {
          setPasswordErrors(error.errors || []);
          setPasswordStatus(undefined);
        }
      } else {
        setPasswordErrors([]);
        setPasswordStatus(undefined);
      }
    };

    validatePasswordPolicy();
  }, [value, auth]);

  const renderPasswordPolicyStatus = () => {
    if (
      !passwordStatus ||
      !passwordStatus.passwordPolicy ||
      !passwordStatus.passwordPolicy.customStrengthOptions
    )
      return null;

    const { customStrengthOptions } = passwordStatus.passwordPolicy;

    const strengthOptions = [
      {
        key: "minPasswordLength",
        label: "Minimum length",
        statusKey: "meetsMinPasswordLength",
      },
      {
        key: "maxPasswordLength",
        label: "Maximum length",
        statusKey: "meetsMaxPasswordLength",
      },
      {
        key: "containsLowercaseLetter",
        label: "Contains lowercase letter",
        statusKey: "containsLowercaseLetter",
      },
      {
        key: "containsUppercaseLetter",
        label: "Contains uppercase letter",
        statusKey: "containsUppercaseLetter",
      },
      {
        key: "containsNumericCharacter",
        label: "Contains numeric character",
        statusKey: "containsNumericCharacter",
      },
      {
        key: "containsNonAlphanumericCharacter",
        label: "Contains non-alphanumeric character",
        statusKey: "containsNonAlphanumericCharacter",
      },
    ];

    return (
      <VStack align="start" spacing={1} mt={2} mb={2}>
        <Text fontSize="sm" fontWeight="bold">
          Password requirements:
        </Text>
        {strengthOptions.map(({ key, label, statusKey }) => {
          if (key in customStrengthOptions) {
            const statusValue =
              passwordStatus[statusKey as keyof typeof passwordStatus];
            const constraintValue =
              customStrengthOptions[key as keyof typeof customStrengthOptions];
            if (typeof constraintValue === "boolean") {
              return (
                <Text
                  key={key}
                  fontSize="xs"
                  color={statusValue ? "green.500" : "red.500"}
                >
                  {statusValue ? "✓" : "✗"} {label}
                </Text>
              );
            } else if (typeof constraintValue === "number") {
              return (
                <Text
                  key={key}
                  fontSize="xs"
                  color={statusValue ? "green.500" : "red.500"}
                >
                  {statusValue ? "✓" : "✗"} {label}: {constraintValue}
                </Text>
              );
            }
          }
          return null;
        })}
      </VStack>
    );
  };

  return (
    <FormControl
      variant="floating"
      isInvalid={!!customError || !!passwordErrors.length}
    >
      <Input
        placeholder=" "
        type="password"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <FormLabel>{label}</FormLabel>
      {showValidation && renderPasswordPolicyStatus()}
      {passwordErrors.map((error, index) => (
        <Text key={index} color="red.500" fontSize="xs">
          {error}
        </Text>
      ))}
      {customError && <FormErrorMessage>{customError}</FormErrorMessage>}
    </FormControl>
  );
};
