import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import {
  FaChartLine,
  FaComments,
  FaListUl,
  FaStopwatch,
  FaVolumeUp,
} from "react-icons/fa";
import { useTimeoutEffect } from "react-timing-hooks";

export const IntroductionToHandstandTimer = () => {
  const [isTipOpen, setIsTipOpen] = useState(false);
  const [isTipNeeded, setIsTipNeeded] = useState(true);

  useTimeoutEffect(
    (timeout, clearAllTimers) => {
      // after 5 seconds, show the tip if it is needed (no expansions yet)
      if (isTipNeeded) {
        timeout(() => {
          if (isTipNeeded) {
            setIsTipOpen(true);
          }
        }, 3000);
      } else {
        clearAllTimers();
      }
    },
    [isTipNeeded]
  );
  const accordionIconRef = useRef<HTMLButtonElement>(null);

  return (
    <Flex direction="column" gap={3}>
      <Text>
        This app is designed to make it quick and easy to detect and record time
        in various poses, with a special focus on handstands.
      </Text>

      <Popover
        isOpen={isTipOpen}
        placement="top"
        initialFocusRef={accordionIconRef}
      >
        <Accordion
          allowMultiple={true}
          onClick={() => {
            setIsTipNeeded(false);
            setIsTipOpen(false);
          }}
        >
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex alignItems="center">
                  <Icon as={FaStopwatch} mr={2} />
                  <Text fontWeight="bold">Pose Detection and Timing</Text>
                </Flex>
              </Box>
              <PopoverAnchor>
                <Box>
                  <AccordionIcon />
                </Box>
              </PopoverAnchor>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>
                Handstand Timer uses advanced pose detection to automatically
                start and stop timing when you enter or exit a pose. It supports
                a wide range of poses, including:
              </Text>
              <UnorderedList mt={2}>
                <ListItem>Handstands and handstand variations</ListItem>
                <ListItem>
                  Calisthenics poses (e.g., tuck planche, horse)
                </ListItem>
                <ListItem>Yoga poses (e.g., crow, tree)</ListItem>
                <ListItem>General strength poses (e.g., plank, squat)</ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex alignItems="center">
                  <Icon as={FaVolumeUp} mr={2} />
                  <b>Audio Feedback</b>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <UnorderedList spacing={3}>
                <ListItem>
                  <Text>
                    <b>Start Tones and Metronome Ticks</b> help you know you are
                    in the pose and how much time has passed.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <b>Detection Guidance</b> will direct you to adjust your
                    position if a pose is not being detected.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <b>Duration Announcements</b> such as "5 seconds" will let
                    you know immediately upon completion of a pose what the
                    detected duration was.
                  </Text>
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex alignItems="center">
                  <Icon as={FaChartLine} mr={2} />
                  <Text fontWeight="bold">Record and Review</Text>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>Every pose you perform is recorded, allowing you to:</Text>
              <UnorderedList mt={2}>
                <ListItem>View the duration of each pose attempt</ListItem>
                <ListItem>Track your progress over time</ListItem>
                <ListItem>
                  Analyze your performance with detailed statistics
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex alignItems="center">
                  <Icon as={FaListUl} mr={2} />
                  <Text fontWeight="bold">Customization and Feedback</Text>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>
                We're constantly working to improve Handstand Timer and add more
                poses. Your feedback is crucial in this process:
              </Text>
              <UnorderedList mt={2}>
                <ListItem>
                  Suggest new poses you'd like to see supported
                </ListItem>
                <ListItem>Report any issues with pose detection</ListItem>
                <ListItem>Share your ideas for new features</ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex alignItems="center">
                  <Icon as={FaComments} mr={2} />
                  <Text fontWeight="bold">Coming Soon: Move Journey</Text>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>
                We're excited to announce our upcoming app, Move Journey! While
                Handstand Timer focuses on quick and easy pose timing, Move
                Journey will offer:
              </Text>
              <UnorderedList mt={2}>
                <ListItem>Comprehensive workout planning and tracking</ListItem>
                <ListItem>
                  Social features to connect with other movers
                </ListItem>
                <ListItem>Personalized challenges and goals</ListItem>
                <ListItem>In-depth progress analysis and insights</ListItem>
              </UnorderedList>
              <Text mt={2}>
                Stay tuned for the release of Move Journey, your all-in-one
                movement companion!
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <PopoverContent
          bg="var(--chakra-subtle-bg)"
          width="xsm"
          onClick={() => setIsTipOpen(false)}
        >
          <PopoverArrow bg="var(--chakra-subtle-bg)" />
          <PopoverBody>Expand to learn more</PopoverBody>
        </PopoverContent>
      </Popover>

      <Text mt={3}>
        Start using Handstand Timer today to elevate your handstand practice and
        track your progress in various movement disciplines!
      </Text>
    </Flex>
  );
};
