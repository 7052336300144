import {
  Firestore,
  FirestoreDataConverter,
  QueryConstraint,
  collection,
  doc,
  getFirestore,
  query,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  TextToSpeechUtterance,
  TextToSpeechUtteranceRequest,
  getUtteranceId,
} from "../../components/tts/TextToSpeech";

const textToSpeechUtteranceConverter: FirestoreDataConverter<TextToSpeechUtterance> =
  {
    fromFirestore: (snapshot) => {
      const data = snapshot.data() as TextToSpeechUtterance;
      data.id = snapshot.id;
      return data;
    },
    toFirestore: (textToSpeechUtterance) => {
      const { id, ...textToSpeechUtteranceWithoutId } = textToSpeechUtterance;
      return textToSpeechUtteranceWithoutId;
    },
  };

export class TextToSpeechUtterancesDao {
  firestore: Firestore;
  constructor() {
    this.firestore = getFirestore();
  }

  getTextToSpeechUtterancesCollection() {
    return collection(
      this.firestore,
      "elevenLabsTextToSpeechUtterances"
    ).withConverter(textToSpeechUtteranceConverter);
  }

  async saveTextToSpeechUtterance(
    textToSpeechUtterance: TextToSpeechUtteranceRequest
  ): Promise<string> {
    const textToSpeechUtterancesCollection =
      this.getTextToSpeechUtterancesCollection();
    const utteranceId = getUtteranceId(textToSpeechUtterance);

    const docRef = doc(textToSpeechUtterancesCollection, utteranceId);
    await setDoc(
      docRef,
      { ...textToSpeechUtterance, hash: utteranceId },
      { merge: true }
    );

    return utteranceId;
  }

  async getAudioDownloadUrl(
    utterance: TextToSpeechUtterance
  ): Promise<string | undefined> {
    if (utterance && utterance.audioPath) {
      const storage = getStorage();
      const audioRef = ref(storage, utterance.audioPath);
      return await getDownloadURL(audioRef);
    }
    return undefined;
  }
}

export function useTextToSpeechUtterances(
  ...constraints: QueryConstraint[]
): [TextToSpeechUtterance[] | undefined, boolean, Error | undefined] {
  const q = query(
    new TextToSpeechUtterancesDao().getTextToSpeechUtterancesCollection(),
    ...constraints
  );
  const [textToSpeechUtterances, loading, error] =
    useCollectionData<TextToSpeechUtterance>(q);
  if (error) {
    console.error(error);
  }
  return [textToSpeechUtterances, loading, error];
}
