import { Avatar, Box, Flex } from "@chakra-ui/react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  flag,
  logIn,
  logOut,
  person,
  personAdd,
  trashBin,
} from "ionicons/icons";
import React from "react";
import { AppBuildDetails } from "../AppBuildDetails";
import { useAuthContext } from "../login/useAuthContext";

const AccountSettings: React.FC = () => {
  const { user, signOut } = useAuthContext();
  const router = useIonRouter();

  const handleSignOut = async () => {
    await signOut();
    router.push("/login", "root", "replace");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {user ? (
          <>
            <Flex padding={3} alignItems="center">
              <Avatar
                name={user.displayName || undefined}
                src={user.photoURL || undefined}
                marginInlineEnd={6}
              />
              <Flex direction="column">
                {user.displayName ? (
                  <IonLabel>
                    <strong>{user.displayName}</strong>
                  </IonLabel>
                ) : null}
                <IonLabel color="medium">
                  {user.email || user.phoneNumber}
                </IonLabel>
              </Flex>
            </Flex>

            <IonList>
              <IonItem button routerLink="/account/link-accounts">
                <IonIcon icon={personAdd} slot="start" />
                <IonLabel>Add sign in methods (link accounts)</IonLabel>
              </IonItem>
              <IonItem button routerLink="/account/profile">
                <IonIcon icon={person} slot="start" />
                <IonLabel>Profile</IonLabel>
              </IonItem>
              <IonItem button routerLink="/account/delete">
                <IonIcon icon={trashBin} slot="start" />
                <IonLabel>Delete account</IonLabel>
              </IonItem>
              <IonItem button routerLink="/onboarding/1">
                <IonIcon icon={flag} slot="start" />
                <IonLabel>Revisit the Getting Started Guide</IonLabel>
              </IonItem>
              <IonItem>
                <IonButton
                  expand="block"
                  onClick={handleSignOut}
                  color="danger"
                >
                  <IonIcon icon={logOut} slot="start" />
                  Sign Out
                </IonButton>
              </IonItem>
            </IonList>
          </>
        ) : (
          <IonList>
            <IonItem button routerLink="/onboarding/1">
              <IonIcon icon={flag} slot="start" />
              <IonLabel>Revisit the Getting Started Guide</IonLabel>
            </IonItem>
            <IonItem>
              <IonButton expand="block" routerLink="/login">
                <IonIcon icon={logIn} slot="start" />
                Sign In
              </IonButton>
            </IonItem>
          </IonList>
        )}
        <Box margin={5} mt={8} fontSize={"sm"}>
          <AppBuildDetails />
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default AccountSettings;
