import {
  VolumeButtons,
  VolumeButtonsOptions,
  VolumeButtonsResult,
} from "@capacitor-community/volume-buttons";
import { Capacitor } from "@capacitor/core";
import {
  Box,
  BoxProps,
  Icon,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useDisclosure,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import { Volumes } from "@ottimis/capacitor-volumes";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { MdVolumeOff, MdVolumeUp } from "react-icons/md";
import { ControlsExpander } from "../common/ControlsExpander";
import { SettingsContext } from "./SettingsContext";

type Props = {
  forceOpen?: boolean;
} & BoxProps;

const AudioVolumeControls: React.FC<Props> = ({ forceOpen, ...props }) => {
  const { audioVolume, setAudioVolume } = useContext(SettingsContext);
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: forceOpen,
  });
  const mainRef = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: mainRef, handler: () => !forceOpen && onClose() });
  const [systemVolume, setSystemVolume] = useState<number | null>(null);
  const toast = useToast();
  const [currentToastId, setCurrentToastId] = useState<
    string | number | undefined
  >();

  const prevStateRef = useRef({
    systemVolumeZero: false,
    isAudioOff: audioVolume === 0,
  });

  const checkSystemVolume = useCallback(async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        const { value } = await Volumes.getVolumeLevel();
        setSystemVolume(value);
        const isVolumeZero = value === 0;
        const currentAudioOff = audioVolume === 0;
        const prevState = prevStateRef.current;

        if (
          isVolumeZero !== prevState.systemVolumeZero ||
          currentAudioOff !== prevState.isAudioOff
        ) {
          let warningMessage = "";
          if (isVolumeZero && currentAudioOff) {
            warningMessage =
              "Your system volume is 0 and the app volume is set to 0.";
          } else if (isVolumeZero) {
            warningMessage = "Your system volume is 0.";
          } else if (currentAudioOff) {
            warningMessage = "The app volume is set to 0.";
          }

          if (warningMessage && isOpen) {
            if (currentToastId) {
              toast.close(currentToastId);
            }

            const newToastId = toast({
              title: "Audio Warning",
              description: warningMessage,
              status: "warning",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });

            setCurrentToastId(newToastId);
          } else if (!isOpen && currentToastId) {
            toast.close(currentToastId);
            setCurrentToastId(undefined);
          }
        }

        prevStateRef.current = {
          systemVolumeZero: isVolumeZero,
          isAudioOff: currentAudioOff,
        };
      }
    } catch (error) {
      console.error("Failed to check system volume:", error);
    }
  }, [audioVolume, toast, currentToastId, isOpen]);

  useEffect(() => {
    checkSystemVolume();
  }, [checkSystemVolume]);

  useEffect(() => {
    if (isOpen) {
      checkSystemVolume();
    }
  }, [isOpen, checkSystemVolume]);

  const toggleExpand = () => {
    isOpen ? onClose() : onOpen();
  };

  const handleVolumeChange = (value: number) => {
    setAudioVolume(value);
  };

  const isAudioOff = audioVolume === 0;

  useEffect(() => {
    let volumeWatcher: any;

    const setupVolumeWatcher = async () => {
      if (Capacitor.isNativePlatform()) {
        const options: VolumeButtonsOptions = {
          suppressVolumeIndicator: false,
        };
        const callback = (result: VolumeButtonsResult, err?: any) => {
          if (err) {
            console.error("Error watching volume:", err);
            return;
          }
          console.log("Volume changed:", result);
          checkSystemVolume();
        };

        volumeWatcher = await VolumeButtons.watchVolume(options, callback);
      }
    };

    setupVolumeWatcher();

    return () => {
      if (volumeWatcher) {
        VolumeButtons.clearWatch();
      }
    };
  }, []);

  const toggleMute = useCallback(() => {
    if (isAudioOff) {
      setAudioVolume(1);
    } else {
      setAudioVolume(0);
    }
  }, [isAudioOff, setAudioVolume]);

  return (
    <Box ref={mainRef} display="flex" alignItems="top" {...props}>
      <Box position="relative">
        <IconButton
          borderRadius="full"
          variant="ghost"
          color="white"
          aria-label={isAudioOff ? "Turn audio on" : "Turn audio off"}
          icon={<Icon as={isAudioOff ? MdVolumeOff : MdVolumeUp} boxSize={8} />}
          onClick={() => (isOpen ? toggleMute() : toggleExpand())}
        />
        {(systemVolume === 0 || isAudioOff) && !isOpen && (
          <Icon
            as={FaExclamationTriangle}
            color="yellow.500"
            position="absolute"
            top="-1"
            right="-1"
            boxSize={4}
          />
        )}
      </Box>
      <ControlsExpander expanded={isOpen}>
        {isOpen && (
          <Box
            m={2}
            mr={4}
            minWidth="60px"
            maxWidth="180px"
            flexShrink={1}
            width="100%"
          >
            <Slider
              width={forceOpen ? "100%" : "180px"}
              aria-label="Audio volume control"
              value={audioVolume}
              min={0}
              max={1}
              step={0.01}
              onChange={handleVolumeChange}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>

            {isOpen && (systemVolume === 0 || isAudioOff) && (
              <Box color="yellow.500" mt={2}>
                <Icon as={FaExclamationTriangle} mr={2} />
                {systemVolume === 0 && isAudioOff
                  ? "System and app volume are both 0"
                  : systemVolume === 0
                  ? "System volume is 0"
                  : "App volume is 0"}
              </Box>
            )}
          </Box>
        )}
      </ControlsExpander>
    </Box>
  );
};

export default AudioVolumeControls;
