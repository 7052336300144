import { DrawingUtils, PoseLandmarkerResult } from "@mediapipe/tasks-vision";
import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { ControlOptions } from "../control-options";
import { drawPoseLandmarks } from "./draw-pose-landmarks";
import { drawStickFigure } from "./drawStickFigure";
import { PoseDetectionFeedback } from "./pose-detection-feedback";
import { calculateCenterOfMass } from "./pose-detection-feedback-utils";
import { regionOfInterestVisible } from "./PoseDetector";

export type InputImage =
  | HTMLImageElement
  | HTMLVideoElement
  | HTMLCanvasElement;

export type Rectangle = { width: number; height: number };
export type PositionedRectangle = Rectangle & {
  x: number;
  y: number;
};

/**
 * Returns a function that renders the pose detection results to a canvas.
 * The render function Renders the pose landmarks and the segmentation mask if available.
 *
 * @returns A function that renders the pose pose landmarks, augmented with information from pose detection feedback and segmentation mask (if enabled).
 */
export const usePoseDetectionRenderer = ({
  controlOptions,
  connectorThickness,
  videoSourceType,
  backgroundImage,
  landmarkRenderStyle,
}: {
  controlOptions: ControlOptions;
  connectorThickness: number | undefined;
  videoSourceType: string | undefined;
  backgroundImage: HTMLImageElement | undefined;
  landmarkRenderStyle: string | undefined;
}) => {
  const canvasElementRef = useRef<HTMLCanvasElement>();
  const canvasCtxRef = useRef<CanvasRenderingContext2D>();
  const resultsRef = useRef<PoseLandmarkerResult>();

  useLayoutEffect(() => {
    const newCanvasElement = document.getElementsByClassName(
      "output_canvas"
    )[0] as HTMLCanvasElement;
    canvasElementRef.current = newCanvasElement;

    canvasCtxRef.current = newCanvasElement.getContext(
      "2d"
    ) as CanvasRenderingContext2D;
    // console.log("canvasElementRef.current", {
    //   newCanvasElement,
    //   context2d: canvasCtxRef.current,
    // });
  }, []);

  useEffect(() => {
    if (connectorThickness && videoSourceType !== "webcam") {
      if (
        controlOptions.showLandmarks &&
        resultsRef.current &&
        canvasElementRef.current &&
        canvasCtxRef.current
      ) {
        canvasCtxRef.current.reset();

        if (
          controlOptions.landmarksRenderStyle === "stick-figure" &&
          resultsRef.current?.landmarks[0]
        ) {
          // Use rectangles for each limb and a white circle for the head, scaled by the ears
          drawStickFigure(
            canvasElementRef.current,
            resultsRef.current,
            canvasCtxRef.current,
            undefined,
            connectorThickness
          );
        } else {
          drawPoseLandmarks(
            canvasCtxRef.current,
            resultsRef.current.landmarks[0]
          );
        }
      }
    }
  }, [connectorThickness]);

  return useCallback(
    async (
      results: PoseLandmarkerResult,
      poseDetectionFeedback: PoseDetectionFeedback | undefined,
      activeJointLandmarkIndexes: number[],
      image: InputImage,
      selfieMode: boolean,
      regionOfInterest: PositionedRectangle
    ) => {
      resultsRef.current = results;

      // console.log("** rendering results", {
      //   results,
      //   canvasCtx: canvasCtxRef.current,
      //   canvasElement: canvasElementRef.current,
      // });
      if (canvasCtxRef.current && canvasElementRef.current) {
        // Draw the overlays.
        canvasCtxRef.current.save();
        canvasCtxRef.current.clearRect(
          0,
          0,
          canvasElementRef.current.width,
          canvasElementRef.current.height
        );

        // Flip the canvas if selfieMode is true
        if (selfieMode) {
          canvasCtxRef.current.scale(-1, 1);
          canvasCtxRef.current.translate(-canvasElementRef.current.width, 0);
        }

        if (results.segmentationMasks?.[0]) {
          if (
            backgroundImage &&
            backgroundImage.complete &&
            backgroundImage.naturalHeight !== 0
          ) {
            canvasCtxRef.current.globalCompositeOperation = "source-over";
            canvasCtxRef.current.drawImage(
              results.segmentationMasks?.[0],
              0,
              0,
              canvasElementRef.current.width,
              canvasElementRef.current.height
            );

            // TODO: pass in the input image for use here
            // Only overwrite missing pixels.
            // canvasCtxRef.current.globalCompositeOperation = "source-in";
            // canvasCtxRef.current.drawImage(
            //   results.image,
            //   0,
            //   0,
            //   canvasElementRef.current.width,
            //   canvasElementRef.current.height
            // );

            canvasCtxRef.current.globalCompositeOperation = "destination-over";
            drawBackgroundImage();

            canvasCtxRef.current.globalCompositeOperation = "source-over";
          } else {
            canvasCtxRef.current.drawImage(
              results.segmentationMasks?.[0],
              0,
              0,
              canvasElementRef.current.width,
              canvasElementRef.current.height
            );
            // Only overwrite existing pixels.
            if (
              controlOptions.effect === "mask" ||
              controlOptions.effect === "both"
            ) {
              canvasCtxRef.current.globalCompositeOperation = "source-in";
              // This can be a color or a texture or whatever...
              canvasCtxRef.current.fillStyle = "#00FF007F";
              canvasCtxRef.current.fillRect(
                0,
                0,
                canvasElementRef.current.width,
                canvasElementRef.current.height
              );
            } else {
              canvasCtxRef.current.globalCompositeOperation = "source-out";
              canvasCtxRef.current.fillStyle = "#0000FF7F";
              canvasCtxRef.current.fillRect(
                0,
                0,
                canvasElementRef.current.width,
                canvasElementRef.current.height
              );
            }
            // Only overwrite missing pixels.
            canvasCtxRef.current.globalCompositeOperation = "destination-atop";
            // TODO: pass in the input image for use here
            // canvasCtxRef.current.drawImage(
            //   results.image,
            //   0,
            //   0,
            //   canvasElementRef.current.width,
            //   canvasElementRef.current.height
            // );
            canvasCtxRef.current.globalCompositeOperation = "source-over";
          }
        } else if (
          backgroundImage &&
          backgroundImage.complete &&
          backgroundImage.naturalHeight !== 0
        ) {
          drawBackgroundImage();
        } else {
          canvasCtxRef.current.drawImage(
            image,
            0,
            0,
            canvasElementRef.current.width,
            canvasElementRef.current.height
          );
        }
        if (results.landmarks?.[0] && !results.segmentationMasks?.[0]) {
          if (controlOptions.showLandmarks) {
            if (landmarkRenderStyle === "stick-figure") {
              // Create a new canvas element to draw the stick figure on
              const stickFigureCanvas = document.createElement("canvas");
              stickFigureCanvas.width = canvasElementRef.current.width;
              stickFigureCanvas.height = canvasElementRef.current.height;
              const stickFigureCanvasCtx = stickFigureCanvas.getContext("2d");
              if (stickFigureCanvasCtx) {
                drawStickFigure(
                  canvasElementRef.current,
                  results,
                  stickFigureCanvasCtx,
                  undefined,
                  controlOptions.connectorThickness
                );
                canvasCtxRef.current.globalAlpha = 0.5;
                canvasCtxRef.current.drawImage(
                  stickFigureCanvas,
                  0,
                  0,
                  canvasElementRef.current.width,
                  canvasElementRef.current.height
                );
              }
            } else if (controlOptions.landmarksRenderStyle === "stick-figure") {
              drawStickFigure(
                canvasElementRef.current,
                results,
                canvasCtxRef.current,
                undefined,
                controlOptions.connectorThickness
              );
            } else {
              await drawPoseLandmarks(
                canvasCtxRef.current,
                results.landmarks[0]
              );
            }
          }
          if (
            controlOptions.showObservedLandmarks &&
            landmarkRenderStyle !== "stick-figure"
          ) {
            const drawingUtils = new DrawingUtils(canvasCtxRef.current);
            drawingUtils.drawLandmarks(
              [calculateCenterOfMass(results.landmarks[0])],
              {
                color: "red",
                fillColor: "black",
              }
            );
            if (poseDetectionFeedback) {
              for (const feedbackItem of Object.values(poseDetectionFeedback)) {
                if (feedbackItem.activeLandmarkIndexes) {
                  drawingUtils.drawLandmarks(
                    feedbackItem.activeLandmarkIndexes.map(
                      (index) => results.landmarks[0][index]
                    ),
                    {
                      color: "yellow",
                      fillColor: "yellow",
                    }
                  );
                }
              }
            }
            drawingUtils.drawLandmarks(
              Object.values(activeJointLandmarkIndexes).map(
                (index) => results.landmarks[0][index]
              ),
              { color: "white", fillColor: "blue" }
            );
          }
        }
        canvasCtxRef.current.restore();

        const ctx = canvasCtxRef.current;
        const width = canvasElementRef.current.width;
        const height = canvasElementRef.current.height;

        // Create a mask for areas outside the region of interest
        ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Semi-transparent black

        const maskMethod = (
          x: number,
          y: number,
          width: number,
          height: number
        ) => {
          if (regionOfInterestVisible) {
            ctx.fillRect(x, y, width, height);
          } else {
            ctx.clearRect(x, y, width, height);
          }
        };

        // Top
        maskMethod(0, 0, width, regionOfInterest.y);
        // Bottom
        maskMethod(
          0,
          regionOfInterest.y + regionOfInterest.height,
          width,
          height - (regionOfInterest.y + regionOfInterest.height)
        );
        // Left
        maskMethod(
          0,
          regionOfInterest.y,
          regionOfInterest.x,
          regionOfInterest.height
        );
        // Right
        maskMethod(
          regionOfInterest.x + regionOfInterest.width,
          regionOfInterest.y,
          width - (regionOfInterest.x + regionOfInterest.width),
          regionOfInterest.height
        );
      }

      function drawBackgroundImage() {
        if (
          canvasCtxRef.current &&
          canvasElementRef.current &&
          backgroundImage &&
          backgroundImage.complete &&
          backgroundImage.naturalHeight !== 0
        ) {
          // console.log("** rendering backgroundImage", backgroundImage);
          // Calculate the image's aspect ratio
          const imageAspectRatio =
            backgroundImage.width / backgroundImage.height;

          // Calculate the canvas's aspect ratio
          const canvasAspectRatio =
            canvasElementRef.current.width / canvasElementRef.current.height;

          let drawStartX, drawStartY, drawWidth, drawHeight;

          // If the image's aspect ratio is less than the canvas's aspect ratio
          // then the image will fit perfectly in the canvas in terms of height
          if (imageAspectRatio < canvasAspectRatio) {
            drawHeight = canvasElementRef.current.height;
            drawWidth = drawHeight * imageAspectRatio;
            drawStartX = (canvasElementRef.current.width - drawWidth) / 2; // center the image horizontally
            drawStartY = 0;
          } else {
            // Otherwise, the image will fit perfectly in the canvas in terms of width
            drawWidth = canvasElementRef.current.width;
            drawHeight = drawWidth / imageAspectRatio;
            drawStartX = 0;
            drawStartY = (canvasElementRef.current.height - drawHeight) / 2; // center the image vertically
          }

          canvasCtxRef.current.drawImage(
            backgroundImage,
            drawStartX,
            drawStartY,
            drawWidth,
            drawHeight
          );
        }
      }
    },
    [controlOptions, backgroundImage, backgroundImage?.src, landmarkRenderStyle]
  );
};
