import { useRef, useCallback } from "react";

export type FrameData = {
  frameTime: number;
  frameComponents: Record<string, number>;
};

export function useFrameData() {
  const frameDataRef = useRef<FrameData>({ frameTime: 0, frameComponents: {} });
  const callbackRef = useRef<() => void>(() => {});

  const setFrameData = useCallback((newFrameData: FrameData) => {
    frameDataRef.current = newFrameData;
    callbackRef.current();
  }, []);

  const getFrameData = useCallback(() => frameDataRef.current, []);

  return { setFrameData, getFrameData };
}
