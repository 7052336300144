import { Capacitor } from "@capacitor/core";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Button, Link, Text, VStack } from "@chakra-ui/react";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";
import {
  isAndroid,
  isChrome,
  isDesktop,
  isIOS,
  isIPad13,
  isMacOs,
  isSafari,
} from "react-device-detect";

export const CameraPermissionInstructions: React.FC = () => {
  const isNative = Capacitor.isNativePlatform();

  const openNativeSettings = async () => {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  if (isNative) {
    return (
      <VStack spacing={4} align="stretch">
        <Text>
          To enable camera permissions for this app, tap the button below to
          open app settings and then enable the camera permission.
        </Text>
        <Button colorScheme="green" onClick={openNativeSettings}>
          Open App Settings
        </Button>
      </VStack>
    );
  }

  return (
    <>
      {isChrome && isDesktop && (
        <Link
          href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop"
          isExternal
        >
          Instructions for Chrome Desktop <ExternalLinkIcon />
        </Link>
      )}
      {isChrome && isAndroid && (
        <Link
          href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DAndroid&oco=1"
          isExternal
        >
          Instructions for Chrome Android <ExternalLinkIcon />
        </Link>
      )}
      {isChrome && (isIOS || isIPad13) && (
        <Link
          href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DiOS&oco=1"
          isExternal
        >
          Instructions for Chrome iOS <ExternalLinkIcon />
        </Link>
      )}
      {isSafari && isMacOs && (
        <Link
          href="https://support.apple.com/en-au/guide/safari/ibrwe2159f50/mac"
          isExternal
        >
          Instructions for Safari on macOS <ExternalLinkIcon />
        </Link>
      )}
      {isSafari && (isIOS || isIPad13) && (
        <Link
          href="https://www.macrumors.com/how-to/access-website-settings-in-safari/"
          isExternal
        >
          Instructions for Safari on iOS <ExternalLinkIcon />
        </Link>
      )}
    </>
  );
};
