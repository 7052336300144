export enum GuidanceTemplateType {
  Visibility = "Visibility",
  JointsBent = "JointsBent",
  JointsStraight = "JointsStraight",
  OneJointBent = "OneJointBent",
  OneJointStraight = "OneJointStraight",
  LandmarksApart = "LandmarksApart",
  LandmarksTogether = "LandmarksTogether",
  LandmarkPairSameHeight = "LandmarkPairSameHeight",
  MassCenteredOn = "MassCenteredOn",
  OneLandmarkHigher = "OneLandmarkHigher",
  OneLandmarkInFront = "OneLandmarkInFront",
  LandmarksAbove = "LandmarksAbove",
  LandmarksAboveLowest = "LandmarksAboveLowest",
  LandmarksStacked = "LandmarksStacked",
  LandmarksBelowHighest = "LandmarksBelowHighest",
  LandmarksSameHeight = "LandmarksSameHeight",
  LandmarksTouching = "LandmarksTouching",
}
