import {
  FirebaseAuthentication,
  ProviderId,
} from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { FaApple, FaGoogle } from "react-icons/fa";
import { MdEmail, MdPhone } from "react-icons/md";
import { useLocation } from "react-router";
import { PasswordInput } from "../login/PasswordInput";
import { useAuthContext } from "../login/useAuthContext";
import AccountIdentityProviders from "./AccountIdentityProviders";

const LinkAccounts: React.FC = () => {
  const auth = getAuth();
  const { user } = useAuthContext();
  const location = useLocation<{ from: { pathname: string } }>();
  const from = location.state?.from?.pathname || "/account";
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const recaptchaContainerRef = React.useRef<HTMLDivElement>(null);
  const { colorMode } = useColorMode();

  const validatePhoneNumber = (number: string) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(value);

    if (value && !validatePhoneNumber(value)) {
      setPhoneError(
        "Please enter a valid phone number starting with + and country code"
      );
    } else {
      setPhoneError(null);
    }
  };

  useEffect(() => {
    const setupListeners = async () => {
      await FirebaseAuthentication.addListener(
        "phoneVerificationCompleted",
        ({ credential }) => {
          console.log("Phone verification completed:", credential);
          toast({
            title: "Phone verification completed",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      );

      await FirebaseAuthentication.addListener(
        "phoneVerificationFailed",
        ({ message }) => {
          console.error("Phone verification failed:", message);
          setPhoneError(message);
          setIsLoading(false);
        }
      );

      await FirebaseAuthentication.addListener(
        "phoneCodeSent",
        ({ verificationId }) => {
          console.log("Phone code sent:", verificationId);
          setVerificationId(verificationId);
          setCodeSent(true);
          setPhoneError(null);
          setIsLoading(false);
        }
      );
    };

    setupListeners();

    return () => {
      FirebaseAuthentication.removeAllListeners();
    };
  }, []);

  const handleLinkProvider = async (provider: string) => {
    setIsLoading(true);
    try {
      let result;
      switch (provider) {
        case "google.com":
          result = await FirebaseAuthentication.linkWithGoogle();
          toastLinkSuccess();
          break;
        case "apple.com":
          result = await FirebaseAuthentication.linkWithApple();
          toastLinkSuccess();
          break;
        case "password":
          result = await FirebaseAuthentication.linkWithEmailAndPassword({
            email,
            password,
          });
          toastLinkSuccess();
          break;
        case "phone":
          if (!codeSent) {
            if (recaptchaContainerRef.current) {
              await FirebaseAuthentication.linkWithPhoneNumber({
                phoneNumber,
                ...(Capacitor.isNativePlatform()
                  ? {}
                  : {
                      recaptchaVerifier: new RecaptchaVerifier(
                        auth,
                        "recaptcha-container",
                        {
                          theme: colorMode,
                        }
                      ),
                    }),
              });
            }
          } else {
            await FirebaseAuthentication.confirmVerificationCode({
              verificationId,
              verificationCode,
            });
            toast({
              title: "Successfully linked with phone number",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
          break;
      }
    } catch (error) {
      toast({
        title: `Error linking with ${provider}`,
        description: (error as Error).message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);

    function toastLinkSuccess() {
      toast({
        title: `Successfully linked with ${provider}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUnlink = async (providerId: ProviderId) => {
    setIsLoading(true);
    try {
      await FirebaseAuthentication.unlink({ providerId });
      toast({
        title: `Successfully unlinked ${providerId}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Error unlinking ${providerId}`,
        description: (error as Error).message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={from} />
          </IonButtons>
          <IonTitle>Link Accounts</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Box padding={[3, 5]}>
          <VStack spacing={4} align="stretch">
            <Text fontSize="xl" fontWeight="bold">
              Current Providers
            </Text>
            <AccountIdentityProviders
              providerData={user?.providerData || []}
              isUnlinkVisible={true}
              onUnlink={handleUnlink}
            />

            <Text fontSize="xl" fontWeight="bold" mt={4}>
              Link New Providers
            </Text>

            <Button
              leftIcon={<FaGoogle />}
              onClick={() => handleLinkProvider("google.com")}
              isLoading={isLoading}
              width="100%"
            >
              Link with Google
            </Button>

            <Button
              leftIcon={<FaApple />}
              onClick={() => handleLinkProvider("apple.com")}
              isLoading={isLoading}
              width="100%"
            >
              Link with Apple
            </Button>

            <form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                handleLinkProvider("password");
              }}
            >
              <Flex direction="column" gap={4}>
                <FormControl variant="floating">
                  <Input
                    placeholder=" "
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <FormLabel>Email</FormLabel>
                </FormControl>

                <PasswordInput
                  value={password}
                  onChange={setPassword}
                  label="Password"
                  showValidation={true}
                />
                <PasswordInput
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  label="Confirm Password"
                  showValidation={false}
                  customError={
                    password !== confirmPassword
                      ? "Passwords do not match"
                      : undefined
                  }
                />
                <Button
                  type="submit"
                  leftIcon={<MdEmail />}
                  isLoading={isLoading}
                  width="100%"
                  isDisabled={!email || !password}
                >
                  Link with Email/Password
                </Button>
              </Flex>
            </form>

            <form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                handleLinkProvider("phone");
              }}
            >
              <Flex direction="column" gap={4}>
                <FormControl variant="floating" isInvalid={!!phoneError}>
                  <Input
                    placeholder=" "
                    type="tel"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                  />
                  <FormLabel>Phone Number</FormLabel>
                  {phoneError && (
                    <FormErrorMessage>{phoneError}</FormErrorMessage>
                  )}
                  <FormHelperText>
                    Start with + followed by country code and number
                  </FormHelperText>
                </FormControl>
                {codeSent && (
                  <FormControl variant="floating">
                    <Input
                      placeholder=" "
                      type="number"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <FormLabel>Verification Code</FormLabel>
                  </FormControl>
                )}
                <ButtonGroup width="100%">
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    width="100%"
                    leftIcon={<MdPhone />}
                    isDisabled={!!phoneError || !phoneNumber}
                  >
                    {codeSent ? "Verify Code" : "Link with Phone Number"}
                  </Button>
                  {codeSent && (
                    <Button
                      onClick={() => {
                        setCodeSent(false);
                        setVerificationId("");
                        setVerificationCode("");
                      }}
                    >
                      Reset
                    </Button>
                  )}
                </ButtonGroup>
                <div id="recaptcha-container" ref={recaptchaContainerRef} />
              </Flex>
            </form>
          </VStack>
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default LinkAccounts;
