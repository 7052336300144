import { Text, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { forwardRef } from "react";

interface PoseDurationDisplayProps {
  isInPoseState: boolean;
}

export const PoseDurationDisplay = forwardRef<
  HTMLSpanElement,
  PoseDurationDisplayProps
>(({ isInPoseState }, ref) => {
  const textColor = useColorModeValue("gray.800", "white");
  const shadowColor = useColorModeValue("white", "black");

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: isInPoseState ? 1 : 0 }}
      transition={{ duration: 0.5 }}
    >
      <Text
        pointerEvents="none"
        fontSize={["6em", "10em", "14em"]}
        width="100%"
        textAlign="center"
        color={textColor}
        textShadow={`1px 1px 0 ${shadowColor}, -1px -1px 0 ${shadowColor}, 1px -1px 0 ${shadowColor}, -1px 1px 0 ${shadowColor}, 0px 1px 0 ${shadowColor}, 0px -1px 0 ${shadowColor}, -1px 0px 0 ${shadowColor}, 1px 0px 0 ${shadowColor}`}
      >
        <span ref={ref}>0.0 s</span>
      </Text>
    </motion.div>
  );
});
