export enum PoseIds {
  AllFours = "all fours",
  AtLeastOneHandUp = "at least one hand up",
  Bear = "bear",
  Boat = "boat",
  Butterfly = "butterfly",
  CactusArms = "cactus arms",
  ChairHandstandCompressionAlternatingLegs = "chair handstand compression alternating legs",
  ChildsPose = "child's pose",
  Corpse = "corpse",
  CouchStretch = "couch stretch",
  Crow = "crow",
  DeepSquatWithArmsRaised = "deep squat with arms raised",
  DeepSquat = "deep squat",
  Dolphin = "dolphin",
  DownwardDog = "downward dog",
  Eagle = "eagle",
  EasyPose = "easy pose",
  ExtendedChildsPose = "extended child's pose",
  FeetElevated = "feet elevated",
  ForwardFold = "forward fold",
  FrontSplits = "front splits",
  FullHandstand = "full handstand",
  GoddessWithCactusArms = "goddess with cactus arms",
  HalfHandstandCompressionWithFootOnTable = "half handstand compression with foot on table",
  HalfPikeHandstandWithFootOnTable = "half pike handstand with foot on table",
  HalfStraddleHandstandWithFootOnTable = "half straddle handstand with foot on table",
  HalfTuckHandstand = "half tuck handstand",
  HandsAtHeartCenter = "hands at heart center",
  HandsDown = "hands down",
  HandsOnEars = "hands on ears",
  HandsOnGround = "hands on ground",
  HandsOnHips = "hands on hips",
  HandsOnKnees = "hands on knees",
  HandsOnShoulders = "hands on shoulders",
  HandsOnToes = "hands on toes",
  HandsUp = "hands up",
  HandstandAtTheWall = "handstand at the wall",
  HandstandChestToWall = "handstand chest to wall",
  HandstandKickUpPrep = "handstand kick up prep",
  HandstandPrep = "handstand prep",
  Handstand = "handstand",
  HappyBaby = "happy baby",
  HeadLevel = "head level",
  HighV = "high v",
  Horse = "horse",
  KneePlank = "knee plank",
  KneelingWithArmsRaised = "kneeling with arms raised",
  LayingOnBackWithStraightLegsSlightlyRaised = "laying on back with straight legs slightly raised",
  LayingWithStraightLegsLiftedTo90Degrees = "laying with straight legs lifted to 90 degrees",
  LowDownwardDogPushUp = "low downward dog push-up",
  LowKneePushUp = "low knee push-up",
  LowPushUp = "low push-up",
  LungeLeft = "lunge left",
  LungeRight = "lunge right",
  ModifiedLowPushUp = "modified low push-up",
  ModifiedPushUp = "modified push-up",
  Mountain = "mountain",
  NarrowDownwardDog = "narrow downward dog",
  PancakeOnTheWall = "pancake on the wall",
  PartialRangeSquatWithArmsRaised = "partial range squat with arms raised",
  PartialRangeSquat = "partial range squat",
  PikeHandstandLeftHandToShoulderWithFeetOnChair = "pike handstand left hand to shoulder with feet on chair",
  PikeHandstandRightHandToShoulderWithFeetOnChair = "pike handstand right hand to shoulder with feet on chair",
  PikeHandstandWithBentKneesAndFeetOnWall = "pike handstand with bent knees and feet on wall",
  PikeHandstandWithFeetOnChair = "pike handstand with feet on chair",
  PikeHandstandWithFeetOnTable = "pike handstand with feet on table",
  PlankWithFeetElevated = "plank with feet elevated",
  PlankWithHipsHigh = "plank with hips high",
  PlankWithLeftHandToShoulder = "plank with left hand to shoulder",
  PlankWithLeftKneeToChest = "plank with left knee to chest",
  PlankWithRightHandToShoulder = "plank with right hand to shoulder",
  PlankWithRightKneeToChest = "plank with right knee to chest",
  Plank = "plank",
  SingleLegStance = "single leg stance",
  SissySquat = "sissy squat",
  Sphinx = "sphinx",
  SquatWithArmsRaised = "squat with arms raised",
  Squat = "squat",
  StandWithArmsRaised = "stand with arms raised",
  StandWithLeftKneeRaised = "stand with left knee raised",
  StandWithRightKneeRaised = "stand with right knee raised",
  Stand = "stand",
  StandingCrabWalkLeft = "standing crab walk left",
  StandingCrabWalkRight = "standing crab walk right",
  StandingForwardFold = "standing forward fold",
  StandingShoulderOpenerWithHandsOnTable = "standing shoulder opener with hands on table",
  StraddleForwardFold = "straddle forward fold",
  StraddleHandstand = "straddle handstand",
  TPose = "t-pose",
  TakeAKnee = "take a knee",
  ThreeBlocksChestExercise = "three blocks chest exercise",
  Tree = "tree",
  TuckHandstand = "tuck handstand",
  TuckPlanche = "tuck planche",
  UpwardDog = "upward dog",
  Warrior1 = "warrior 1",
  Warrior2 = "warrior 2",
  Wheel = "wheel",
}
