import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";
import {
  Auth,
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
} from "firebase/auth";
import { usePersistentStorageValue } from "../settings/usePersistentStorageValue";
import { useColorMode } from "@chakra-ui/react";

export const useFirebaseAuthProvider = (auth: Auth | null) => {
  const { colorMode } = useColorMode();
  const [expectLoggedIn, setExpectLoggedIn] = usePersistentStorageValue(
    "expectLoggedIn",
    false
  );
  const [hasRegistered, setHasRegistered] = usePersistentStorageValue(
    "hasRegistered",
    false
  );

  const handleLoginSuccess = () => {
    // Remember that we have signed in
    setExpectLoggedIn(true);
    setHasRegistered(true);
  };

  const signInWithApple = async () => {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple({
      skipNativeAuth: true,
    });
    // 2. Sign in on the web layer using the id token and nonce
    const provider = new OAuthProvider("apple.com");
    const credential = provider.credential({
      idToken: result.credential?.idToken,
      rawNonce: result.credential?.nonce,
    });
    const auth = getAuth();
    await signInWithCredential(auth, credential);
    handleLoginSuccess();
    return result.user;
  };

  const signInWithGoogle = async () => {
    console.log("signInWithGoogle start");
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle({
      // mode: "redirect",
    });
    console.log("result from signInWithGoogle", result);
    // 2. Sign in on the web layer using the id token
    const credential = GoogleAuthProvider.credential(
      result.credential?.idToken
    );
    const auth = getAuth();
    await signInWithCredential(auth, credential);
    handleLoginSuccess();
    return result.user;
  };

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithEmailAndPassword({
      email,
      password,
    });

    // 2. Sign in on the web layer using the email and password
    const credential = EmailAuthProvider.credential(email, password);
    const auth = getAuth();
    await signInWithCredential(auth, credential);
    handleLoginSuccess();
    return result.user;
  };

  const signInWithPhoneNumber = async (
    phoneNumber: string,
    recaptchaContainerOrId: HTMLElement | string
  ) => {
    await new Promise<void>(async (resolve) => {
      const auth = getAuth();
      await FirebaseAuthentication.addListener(
        "phoneCodeSent",
        async (event) => {
          // 2. Let the user enter the SMS code
          const verificationCode = window.prompt(
            "Please enter the verification code that was sent to your mobile device."
          );

          if (!verificationCode) {
            throw new Error("Verification code is required");
          }

          // 3. Sign in on the web layer using the verification ID and verification code.
          const credential = PhoneAuthProvider.credential(
            event.verificationId,
            verificationCode
          );
          await signInWithCredential(auth, credential);
          resolve();
        }
      );
      // 1. Start phone number verification
      await FirebaseAuthentication.signInWithPhoneNumber({
        phoneNumber,
        timeout: 0, // Disable SMS auto-retrieval
        ...(Capacitor.isNativePlatform()
          ? {}
          : {
              recaptchaVerifier: new RecaptchaVerifier(
                auth,
                recaptchaContainerOrId,
                {
                  theme: colorMode,
                }
              ),
            }),
      });
    });
    handleLoginSuccess();
  };

  const confirmVerificationCode = async (verificationCode: string) => {
    const result = await FirebaseAuthentication.confirmVerificationCode({
      verificationId: "",
      verificationCode,
    });
    handleLoginSuccess();
    return result.user;
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
      email,
      password,
    });
    await FirebaseAuthentication.sendEmailVerification();
    const credential = EmailAuthProvider.credential(email, password);
    const auth = getAuth();
    await signInWithCredential(auth, credential);
    handleLoginSuccess();
    console.log("createUserWithEmailAndPassword result", result);
    return result.user;
  };

  const applyActionCode = async (code: string) => {
    await FirebaseAuthentication.applyActionCode({ oobCode: code });
  };

  const confirmPasswordReset = async (code: string, newPassword: string) => {
    await FirebaseAuthentication.confirmPasswordReset({
      oobCode: code,
      newPassword,
    });
  };

  return {
    isAuthenticated: !!auth?.currentUser,
    user: auth?.currentUser,
    async signOut() {
      await auth?.signOut();
      await FirebaseAuthentication.signOut();
      setExpectLoggedIn(false);
    },
    signInWithGoogle,
    signInWithApple,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    confirmVerificationCode,
    createUserWithEmailAndPassword,
    applyActionCode,
    confirmPasswordReset,
    expectLoggedIn,
    hasRegistered,
  };
};
