import { NormalizedLandmark } from "@mediapipe/tasks-vision";
import { ControlOptions } from "../control-options";
import { getPoseEvaluationFunction } from "../generated/PoseDefinitionLoader";
import { JointAngle } from "./pose-utils";
import { PoseDetectionFeedback } from "./pose-detection-feedback";

export function detectInPose(
  isInPose: boolean,
  controlOptions: ControlOptions,
  poseId: string,
  poseLandmarks: NormalizedLandmark[],
  jointAnglesMap: { [key: number]: JointAngle },
  poseDetectionFeedback: PoseDetectionFeedback
): boolean {
  const detector = getPoseEvaluationFunction(poseId);
  return detector(
    isInPose,
    poseLandmarks,
    jointAnglesMap,
    poseDetectionFeedback,
    controlOptions.landmarkPoseVisibilityThreshold,
    controlOptions
  );
}
