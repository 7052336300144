import { DrawingUtils, NormalizedLandmark } from "@mediapipe/tasks-vision";
import {
  POSE_CONNECTIONS,
  POSE_LANDMARKS_LEFT,
  POSE_LANDMARKS_NEUTRAL,
  POSE_LANDMARKS_RIGHT,
} from "./landmarks";

export function drawPoseLandmarks(
  targetCanvasCtx: CanvasRenderingContext2D,
  poseLandmarks: NormalizedLandmark[]
) {
  const drawingUtils = new DrawingUtils(targetCanvasCtx);
  drawingUtils.drawConnectors(poseLandmarks, POSE_CONNECTIONS, {
    // visibilityMin: 0.65,
    color: "white",
  });
  drawingUtils.drawLandmarks(
    Object.values(POSE_LANDMARKS_LEFT).map((index) => poseLandmarks[index]),
    { color: "white", fillColor: "rgb(255,138,0)" }
  );
  drawingUtils.drawLandmarks(
    Object.values(POSE_LANDMARKS_RIGHT).map((index) => poseLandmarks[index]),
    { color: "white", fillColor: "rgb(0,217,231)" }
  );
  drawingUtils.drawLandmarks(
    Object.values(POSE_LANDMARKS_NEUTRAL).map((index) => poseLandmarks[index]),
    { color: "white", fillColor: "white" }
  );
}
