import {
  FilesetResolver,
  PoseLandmarker,
  PoseLandmarkerOptions,
  PoseLandmarkerResult,
} from "@mediapipe/tasks-vision";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { InputImage } from "./usePoseDetectionRenderer";

export const SLOW_LOADING_DURATION = 5000;
export const TIMEOUT_LOADING_DURATION = 15000;

const DEBUG_SIMULATE_INITIALIZE_DELAY = 0;
const DEBUG_SIMULATE_INITIALIZE_ERROR = false;

export function useInitializePoseModel(
  poseLandmarkerRef: MutableRefObject<PoseLandmarker | undefined>,
  controlOptions: PoseLandmarkerOptions,
  onModelInitialized?: (poseLandmarker: PoseLandmarker) => void
): {
  poseLandmarkerError: Error | undefined;
  poseLandmarkerInitializationSlow: boolean | undefined;
  poseLandmarkerInitializationTimeout: boolean | undefined;
} {
  const [poseLandmarkerError, setPoseLandmarkerError] = useState<Error>();
  const [
    poseLandmarkerInitializationSlow,
    setPoseLandmarkerInitializationSlow,
  ] = useState(false);
  const [
    poseLandmarkerInitializationTimeout,
    setPoseLandmarkerInitializationTimeout,
  ] = useState(false);
  const slowTimerIdRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutTimerIdRef = useRef<NodeJS.Timeout | null>(null);

  const initializePoseModel = useCallback(async () => {
    // console.log("creating a new Pose model", poseLandmarkerRef.current);
    // const poseConfig: PoseConfig = {
    //   locateFile: (file) => {
    //     return `${config.mediapipePoseBasePath}/${file}`;
    //   },
    // };

    try {
      console.log("[LM-m01] Loading vision model");
      const vision = await FilesetResolver.forVisionTasks(
        // "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.14/wasm"
        "/mediapipe-pose"
      );
      const poseLandmarker = await PoseLandmarker.createFromOptions(
        vision,
        controlOptions
      );
      console.log("==[ PoseLandmarker created");

      if (DEBUG_SIMULATE_INITIALIZE_DELAY) {
        // add an artificial delay of 3 seconds
        await new Promise((resolve) =>
          setTimeout(resolve, DEBUG_SIMULATE_INITIALIZE_DELAY)
        );
      }
      poseLandmarkerRef.current = poseLandmarker;
      if (slowTimerIdRef.current) {
        clearTimeout(slowTimerIdRef.current);
        slowTimerIdRef.current = null;
      }
      console.log("[LM-m02] Finished loading vision model");
      if (timeoutTimerIdRef.current) {
        clearTimeout(timeoutTimerIdRef.current);
        timeoutTimerIdRef.current = null;
      }
      if (DEBUG_SIMULATE_INITIALIZE_ERROR) {
        throw new Error("fake error during pose model initialization");
      }
      onModelInitialized ? onModelInitialized(poseLandmarker) : null;
    } catch (error) {
      console.error("Error initializing pose model:", error);
      setPoseLandmarkerError(error as Error);
    }

    // Set a timeout and if poseLandmakerRef.current is not set (initialization did not
    // complete) and there was no error, then setPoseLandmarkerInitializationSlow(true)
    slowTimerIdRef.current = setTimeout(() => {
      if (!poseLandmarkerRef.current && !poseLandmarkerError) {
        setPoseLandmarkerInitializationSlow(true);
      }
    }, SLOW_LOADING_DURATION);

    // Set a longer timeout and if poseLandmakerRef.current is still not set (initialization did not
    // complete), then setPoseLandmarkerInitializationTimeout(true) to show an alert dialog
    timeoutTimerIdRef.current = setTimeout(() => {
      if (!poseLandmarkerRef.current && !poseLandmarkerError) {
        setPoseLandmarkerInitializationTimeout(true);
      }
    }, TIMEOUT_LOADING_DURATION);
  }, []);

  useEffect(() => {
    initializePoseModel();
  }, []);

  useEffect(() => {
    // Release the camera and destroy the model when the component unmounts
    return () => {
      if (poseLandmarkerRef.current) {
        poseLandmarkerRef.current.close();
        poseLandmarkerRef.current = undefined;
        console.log("==] PoseLandmarker closed");
      }
    };
  }, []);

  useEffect(() => {
    if (poseLandmarkerRef.current) {
      console.log("!! Applying options to PoseLandmarker");
      const { canvas, ...otherOptions } = controlOptions;
      poseLandmarkerRef.current.setOptions(otherOptions);
    }
  }, [controlOptions]);

  return {
    poseLandmarkerError,
    poseLandmarkerInitializationSlow,
    poseLandmarkerInitializationTimeout,
  };
}
