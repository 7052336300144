import { IonToolbar } from "@ionic/react";
import React from "react";
import "./ButtonToolbar.css";

interface ButtonToolbarProps {
  children: React.ReactNode;
}

const ButtonToolbar: React.FC<ButtonToolbarProps> = ({ children }) => {
  return (
    <IonToolbar className="button-toolbar" style={{ "--background": "none" }}>
      <div className="button-container">{children}</div>
    </IonToolbar>
  );
};

export default ButtonToolbar;
