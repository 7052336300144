import { useContext, useEffect } from "react";
import { SettingsContext } from "../settings/SettingsContext";

function lerp(a: number, b: number, t: number) {
  return a + (b - a) * t;
}

const SCALE_MIN = 0.2;

export type InstrumentWithVolume = { volume: { value: number } };
export function useVolumeAwareSynth(targetSynth: InstrumentWithVolume) {
  const { audioVolume } = useContext(SettingsContext);

  useEffect(() => {
    function applyVolumeToSynth(targetSynth: InstrumentWithVolume) {
      // use a pow4 curve as an approximation of human perception of volume
      const volumePow4 = lerp(SCALE_MIN, 1, audioVolume) ** 4;

      // empirically, 15 * log10(v) seems better than 20 * log10(v)
      const volumeDecibels = 15 * Math.log10(volumePow4);

      targetSynth.volume.value = audioVolume === 0 ? -Infinity : volumeDecibels;
    }

    applyVolumeToSynth(targetSynth);
  }, [audioVolume, targetSynth]);
}
