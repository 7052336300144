import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { updateProfile } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ButtonToolbar from "../common/ButtonToolbar";
import { useAuthContext } from "../login/useAuthContext";

const EditPhotoUrl: React.FC = () => {
  const { user, setUser } = useAuthContext();
  const [photoUrl, setPhotoUrl] = useState(user?.photoURL || "");
  const [originalPhotoUrl, setOriginalPhotoUrl] = useState(
    user?.photoURL || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [present] = useIonToast();
  const history = useHistory();

  useEffect(() => {
    if (user?.photoURL) {
      setPhotoUrl(user.photoURL);
      setOriginalPhotoUrl(user.photoURL);
    }
  }, [user]);

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setIsLoading(true);
    try {
      await updateProfile(user, { photoURL: photoUrl });
      await user.reload();
      setUser(user);
      present({
        message: "Profile photo updated successfully",
        duration: 3000,
        color: "success",
      });
      history.goBack();
    } catch (error) {
      present({
        message: "Error updating profile photo",
        duration: 3000,
        color: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const isChanged = photoUrl !== originalPhotoUrl;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account/profile" />
          </IonButtons>
          <IonTitle>Edit Profile Photo</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form onSubmit={handleSave}>
          <IonItem>
            <IonAvatar slot="start">
              <img
                src={
                  photoUrl ||
                  "https://ionicframework.com/docs/img/demos/avatar.svg"
                }
                alt="Profile"
              />
            </IonAvatar>
            <IonLabel position="stacked">Photo URL</IonLabel>
            <IonInput
              value={photoUrl}
              onIonInput={(e) => setPhotoUrl(e.detail.value || "")}
              placeholder="Enter photo URL"
            />
          </IonItem>
          <ButtonToolbar>
            <IonButton
              slot="end"
              color="light"
              onClick={handleCancel}
              type="button"
            >
              Cancel
            </IonButton>
            <IonButton
              slot="end"
              strong={true}
              disabled={!isChanged || isLoading}
              type="submit"
            >
              Save
            </IonButton>
          </ButtonToolbar>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default EditPhotoUrl;
