import { User as NativeUser } from "@capacitor-firebase/authentication";
import { User } from "firebase/auth";
import React from "react";

export interface AuthContextType {
  loading: boolean;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  expectLoggedIn: boolean;
  hasRegistered: boolean;
  signOut: () => Promise<void>;
  signInWithApple: () => Promise<NativeUser | null>;
  signInWithGoogle: () => Promise<NativeUser | null>;
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<NativeUser | null>;
  signInWithPhoneNumber: (
    phoneNumber: string,
    recaptchaContainerOrId: HTMLElement | string
  ) => Promise<void>;
  confirmVerificationCode: (
    verificationCode: string
  ) => Promise<NativeUser | null>;
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<NativeUser | null>;
  applyActionCode: (code: string) => Promise<void>;
  confirmPasswordReset: (code: string, newPassword: string) => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextType>(null!);
