import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { PickerWithCustom } from "../../common/PickerWithCustom";
import { SettingsContext } from "../../settings/SettingsContext";
import DetectionGuidanceMuteControl from "./DetectionGuidanceMuteControl";
import { GuidanceStyle } from "./GuidanceStyle";
import { GuidanceTemplateType } from "./GuidanceTemplateType";
import { guidanceTemplates } from "./guidanceTemplates";

interface DetectionGuidanceConfigurationModalProps {
  poseId?: string;
  movementPatternId?: string;
  isOpen: boolean;
  onClose: () => void;
}

/**
 * DetectionGuidanceConfigurationModal component allows users to configure pose
 * detection guidance settings.
 * @param props - The component props
 * @param [props.poseId] - The current pose ID
 * @param [props.movementPatternId] - The current movement pattern ID
 * @param props.isOpen - Indicates if the modal is open
 * @param props.onClose - Callback function to close the modal
 * @returns The rendered DetectionGuidanceConfigurationModal component
 */
const DetectionGuidanceConfigurationModal: React.FC<
  DetectionGuidanceConfigurationModalProps
> = ({ poseId, movementPatternId, isOpen, onClose }) => {
  const {
    // isPoseGuidanceEnabled,
    // setIsPoseGuidanceEnabled,
    // isMovementPatternGuidanceEnabled,
    // setIsMovementPatternGuidanceEnabled,
    poseDetectionGuidanceInterval,
    setPoseDetectionGuidanceInterval,
    poseDetectionGuidanceExponentialBackoffEnabled,
    setPoseDetectionGuidanceExponentialBackoffEnabled,
    // isContextualDetectionGuidanceEnabled,
    // setIsContextualDetectionGuidanceEnabled,
    guidanceStyle,
    setGuidanceStyle,
  } = useContext(SettingsContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box color="var(--chakra-colors-whiteAlpha-900)">
          <ModalHeader>Pose Detection Guidance Configuration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Flex>
                <DetectionGuidanceMuteControl expanded={true} />
              </Flex>
              <FormControl>
                <FormLabel>Guidance Style</FormLabel>
                <Select
                  value={guidanceStyle}
                  onChange={(e) =>
                    setGuidanceStyle(e.target.value as GuidanceStyle)
                  }
                >
                  {Object.values(GuidanceStyle).map((style) => (
                    <option key={style} value={style}>
                      {style.charAt(0).toUpperCase() + style.slice(1)}
                    </option>
                  ))}
                </Select>

                <FormHelperText display="flex" gap={2}>
                  <Text>Example:</Text>
                  <Text fontStyle="italic">
                    {guidanceTemplates[guidanceStyle]?.[
                      GuidanceTemplateType.Visibility
                    ][0].replace("{landmarksPhrase}", "knees")}
                  </Text>
                </FormHelperText>
              </FormControl>{" "}
              <FormControl>
                <FormLabel>Guidance Interval (seconds)</FormLabel>
                <PickerWithCustom
                  standardValues={[1, 3, 5, 10, 15].map((value) => ({
                    value,
                    label: value.toString(),
                  }))}
                  initialValue={poseDetectionGuidanceInterval / 1000}
                  initialCustomValue={30}
                  onValueChange={(value) => {
                    setPoseDetectionGuidanceInterval(value * 1000);
                  }}
                  unit={""}
                  convert={Number}
                />
              </FormControl>
              <FormControl
                paddingLeft={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormLabel>Backoff when repeating</FormLabel>
                <Switch
                  isChecked={poseDetectionGuidanceExponentialBackoffEnabled}
                  onChange={(e) =>
                    setPoseDetectionGuidanceExponentialBackoffEnabled(
                      e.target.checked
                    )
                  }
                />
              </FormControl>
            </Stack>
          </ModalBody>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default DetectionGuidanceConfigurationModal;
