import {
  Alert,
  AlertIcon,
  Box,
  BoxProps,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Progress,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { getDefaultAggregationTimeRanges } from "../../services/firestore/PoseEventsDao";

interface CurrentPoseAggregateSummaryProps {
  aggregateDurations:
    | {
        range0: number;
        range1: number;
        range2: number;
      }
    | undefined;
  aggregateLoading: boolean;
  aggregateError: Error | null;
}

const formatDuration = (milliseconds: number) => {
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
};

export const CurrentPoseAggregateSummary: React.FC<
  CurrentPoseAggregateSummaryProps & BoxProps
> = ({ aggregateDurations, aggregateLoading, aggregateError, ...props }) => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const popoverBgColor = useColorModeValue("white", "gray.800");

  if (aggregateLoading) {
    return (
      <Box bg={bgColor} p={3} borderRadius="md" width="100%" {...props}>
        <Skeleton height="20px" mb={2} />
        <Skeleton height="16px" />
      </Box>
    );
  }

  if (aggregateError) {
    return (
      <Alert status="error" borderRadius="md" {...props}>
        <AlertIcon />
        Error loading aggregate data: {aggregateError.message}
      </Alert>
    );
  }

  const maxDuration = aggregateDurations
    ? Math.max(
        aggregateDurations.range0,
        aggregateDurations.range1,
        aggregateDurations.range2
      )
    : 0;

  const timeRanges = getDefaultAggregationTimeRanges();

  const formatDate = (date: Date, includeDay = false) => {
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    if (includeDay) {
      options.weekday = "short";
    }

    return date.toLocaleDateString("en-US", options);
  };

  const ranges = aggregateDurations
    ? [
        {
          label: "Today",
          value: aggregateDurations.range0,
          color: "blue",
          details: formatDate(timeRanges[0].startDate),
        },
        {
          label: "This Week",
          value: aggregateDurations.range1,
          color: "green",
          details: `${formatDate(
            timeRanges[1].startDate,
            true
          )} through ${formatDate(
            new Date(timeRanges[1].endDate.getTime() - 86400000),
            true
          )}`,
        },
        {
          label: "This Month",
          value: aggregateDurations.range2,
          color: "purple",
          details: `${formatDate(timeRanges[2].startDate)} through ${formatDate(
            new Date(timeRanges[2].endDate.getTime() - 86400000)
          )}`,
        },
      ]
    : [];

  return (
    <Box bg={bgColor} p={3} borderRadius="md" width="100%" {...props}>
      <HStack spacing={4} align="stretch">
        {ranges.map((range, index) => (
          <Flex key={index} direction="column" flex={1}>
            <Popover trigger="hover" placement="top">
              <PopoverTrigger>
                <Text
                  fontWeight="bold"
                  color={textColor}
                  fontSize="sm"
                  cursor="pointer"
                >
                  {range.label}
                </Text>
              </PopoverTrigger>
              <PopoverContent bg={popoverBgColor} borderColor={bgColor}>
                <PopoverCloseButton />
                <PopoverHeader>{range.label}</PopoverHeader>
                <PopoverBody>
                  <Text fontSize="sm">{range.details}</Text>
                  <Text fontSize="sm" mt={1}>
                    Total Duration:{" "}
                    <strong>{formatDuration(range.value)}</strong>
                  </Text>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Progress
              value={(range.value / maxDuration) * 100}
              size="sm"
              colorScheme={range.color}
              mb={1}
            />
            <Text color={textColor} fontSize="xs">
              {formatDuration(range.value)}
            </Text>
          </Flex>
        ))}
      </HStack>
    </Box>
  );
};
