import { IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { FaCog } from "react-icons/fa";
import DetectionGuidanceConfigurationModal from "./DetectionGuidanceConfigurationModal";

/**
 * DetectionGuidanceConfiguration component renders a button to open the detection
 * guidance configuration modal.
 * @param props - The component props
 * @param [props.poseId] - The current pose ID
 * @param [props.movementPatternId] - The current movement pattern ID
 * @returns The rendered DetectionGuidanceConfiguration component
 */
const DetectionGuidanceConfiguration = ({
  poseId,
  movementPatternId,
}: {
  poseId?: string;
  movementPatternId?: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <IconButton
        variant="ghost"
        aria-label="Configure Pose Detection Guidance"
        icon={<FaCog />}
        onClick={() => setIsModalOpen(true)}
      />
      <DetectionGuidanceConfigurationModal
        poseId={poseId}
        movementPatternId={movementPatternId}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default DetectionGuidanceConfiguration;
