import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  IconButton,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { limit, orderBy } from "firebase/firestore";
import React, {
  MouseEvent as ReactMouseEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FiRefreshCw } from "react-icons/fi";
import { MdPlayArrow } from "react-icons/md";
import { useAuthContext } from "../components/login/useAuthContext";
import { PoseEventSummary } from "../components/PoseEventSummary"; // Assume this component exists
import { SettingsContext } from "../components/settings/SettingsContext";
import { PoseIds } from "../components/types";
import { useAvailablePoses } from "../hooks/useAvailablePoses";
import {
  useAggregateDurations,
  useAllPoseEvents,
} from "../services/firestore/PoseEventsDao";
import { getRecentPoseEvents } from "../services/poseEventService";
import "./Home.css";
import { SettingsIcon } from "@chakra-ui/icons";
import { SettingsModal } from "../components/settings/SettingsModal";

const Home: React.FC = () => {
  const authContext = useAuthContext();
  const user = authContext.user;
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [localPoseEvents, setLocalPoseEvents] = useState<any[]>([]);
  const [poseEvents, poseEventsLoading, poseEventsError] = useAllPoseEvents(
    authContext.user?.uid,
    orderBy("startAt", "desc"),
    limit(5)
  );
  const recentPoseIds = useMemo(() => {
    // get a list of the unique pose ids from poseEvents
    return Array.from(
      new Set(poseEvents?.map((poseEvent) => poseEvent.poseId))
    );
  }, [poseEvents]);
  const {
    aggregateDurations,
    loading: aggregateLoading,
    error: aggregateError,
  } = useAggregateDurations(authContext.user?.uid, recentPoseIds);

  const availablePoses = useAvailablePoses();

  const [suggestedPoseId, setSuggestedPoseId] = useState<string>(
    PoseIds.Handstand
  );
  const { detectingPoseId, setDetectingPoseId } = useContext(SettingsContext);
  const router = useIonRouter();

  useEffect(() => {
    const fetchPoseEvents = async () => {
      const events = await getRecentPoseEvents(5); // Get 5 most recent events
      setLocalPoseEvents(events);
    };
    if (!user) fetchPoseEvents();
  }, [user]);

  const effectivePoseEvents = useMemo(() => {
    return user ? poseEvents : localPoseEvents;
  }, [user, poseEvents, localPoseEvents]);

  useEffect(() => {
    // Pick a pose that is not one of the recent poses to suggest
    const suggestedPoses = availablePoses.filter(
      (pose) => !effectivePoseEvents?.some((event) => event.poseId === pose)
    );
    const randomPose =
      suggestedPoses[Math.floor(Math.random() * suggestedPoses.length)];
    setSuggestedPoseId(randomPose);
  }, [availablePoses, effectivePoseEvents, detectingPoseId]);

  function detectPose(pose: PoseIds) {
    setDetectingPoseId(pose);
    router.push("/pose");
  }

  function handleNewPoseDetection(
    event: ReactMouseEvent<HTMLButtonElement>
  ): void {
    detectPose(suggestedPoseId as PoseIds);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Handstand Timer</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Handstand Timer</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Flex direction="column" m={5} gap={5}>
          <VStack spacing={4} align="stretch">
            {poseEventsLoading ? (
              <>
                <Skeleton height="10" />
                <Skeleton height="5" />
                <Skeleton height="80" />
              </>
            ) : effectivePoseEvents && effectivePoseEvents.length > 0 ? (
              <>
                <Heading size="lg">Welcome back!</Heading>
                <PoseEventSummary
                  events={effectivePoseEvents}
                  onDetectPose={detectPose}
                  aggregateDurations={aggregateDurations}
                  aggregateLoading={aggregateLoading}
                  aggregateError={aggregateError}
                />
                <Accordion allowToggle>
                  <AccordionItem>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Try a different pose (random)?
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel
                      display="flex"
                      flexDirection="column"
                      pb={4}
                      gap={3}
                    >
                      <Text>
                        How about a <strong>{suggestedPoseId}</strong>?
                      </Text>
                      <ButtonGroup>
                        <Button
                          leftIcon={<Icon as={SettingsIcon} />}
                          onClick={() => setIsSettingsOpen(true)}
                        >
                          Settings
                        </Button>
                        <Button
                          leftIcon={<FiRefreshCw />}
                          onClick={() => {
                            const newPose = availablePoses.filter(
                              (pose) => pose !== suggestedPoseId
                            )[
                              Math.floor(
                                Math.random() * (availablePoses.length - 1)
                              )
                            ];
                            setSuggestedPoseId(newPose);
                          }}
                        >
                          Switch
                        </Button>
                        <Button
                          leftIcon={<MdPlayArrow />}
                          onClick={handleNewPoseDetection}
                          colorScheme="blue"
                        >
                          Detect
                        </Button>
                        <SettingsModal
                          isOpen={isSettingsOpen}
                          onClose={() => setIsSettingsOpen(false)}
                        />
                      </ButtonGroup>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </>
            ) : (
              <>
                <Heading size="lg">Welcome to Handstand Timer!</Heading>
                <Text>
                  This app helps you detect and time your handstands using your
                  device's camera.
                </Text>
                <Text>
                  It looks like you haven't recorded any poses yet. Let's get
                  started!
                </Text>
                <IonRouterLink routerLink="/onboarding/1">
                  <Button colorScheme="green">Getting Started Guide</Button>
                </IonRouterLink>
              </>
            )}
          </VStack>
        </Flex>
      </IonContent>
    </IonPage>
  );
};

export default Home;
