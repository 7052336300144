import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../login/useAuthContext";
import EditEmailForm from "./EditEmailForm";

const EditEmail: React.FC = () => {
  const { user, signOut } = useAuthContext();
  const history = useHistory();

  const handleReauthenticate = async () => {
    await signOut();
    history.push("/login", { from: "/account/edit-email" });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSuccess = (updatedEmail: string) => {
    history.replace("/subscribe", {
      isVerificationPending: true,
      updatedEmail,
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account/profile" />
          </IonButtons>
          <IonTitle>Edit Email</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <EditEmailForm
          user={user}
          onCancel={handleCancel}
          onReauthenticate={handleReauthenticate}
          onSuccess={handleSuccess}
        />
      </IonContent>
    </IonPage>
  );
};

export default EditEmail;
