import { useMemo } from "react";

const lowercaseWords = new Set([
  "a",
  "an",
  "the",
  "and",
  "but",
  "or",
  "for",
  "nor",
  "on",
  "at",
  "to",
  "from",
  "by",
  "in",
  "of",
  "with",
]);

const hyphenatedPrefixes = ["mid", "anti", "super"];

function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

function shouldCapitalize(
  word: string,
  index: number,
  totalWords: number
): boolean {
  if (index === 0 || index === totalWords - 1) return true;
  if (!lowercaseWords.has(word.toLowerCase())) return true;
  return false;
}

export function useTitleCase(phrase: string) {
  return useMemo(() => {
    const words = phrase.toLowerCase().split(/\s+/);
    const totalWords = words.length;

    return words
      .map((word, index) => {
        if (word.includes("-")) {
          const [prefix, ...rest] = word.split("-");
          if (hyphenatedPrefixes.includes(prefix)) {
            return capitalize(prefix) + "-" + rest.join("-");
          }
          return word.split("-").map(capitalize).join("-");
        }

        if (shouldCapitalize(word, index, totalWords)) {
          return capitalize(word);
        }

        return word;
      })
      .join(" ");
  }, [phrase]);
}
