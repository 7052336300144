import { IonIcon } from "@ionic/react";
import React, { useMemo } from "react";
import { getPoseDefinition } from "../generated/PoseDefinitionLoader";
import { Pose, poses } from "../types";

interface IonIconProps extends React.ComponentProps<typeof IonIcon> {}

type PoseIconProps = {
  pose: Pose;
} & Omit<IonIconProps, "src" | "icon">;

const PoseIcon: React.FC<PoseIconProps> = ({ pose, ...ionIconProps }) => {
  // load the pose from the pose definition loader
  const poseDefinition = useMemo(() => {
    if (!pose || !poses.includes(pose)) {
      return null;
    }
    return getPoseDefinition(pose);
  }, [pose]);

  const icon = useMemo(
    () => poseDefinition?.icons?.find((icon) => icon.type === "image/svg+xml"),
    [poseDefinition]
  );

  if (!icon) {
    return null;
  }

  return <IonIcon src={icon.src} title={pose} {...ionIconProps} />;
};

export default PoseIcon;
