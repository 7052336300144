import { KeepAwake } from "@capacitor-community/keep-awake";
import { Camera } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import {
  Box,
  BoxProps,
  ColorModeProvider,
  Flex,
  forwardRef,
  Spacer,
  Text,
  useMergeRefs,
} from "@chakra-ui/react";
import {
  NormalizedLandmark,
  PoseLandmarker,
  PoseLandmarkerOptions,
  PoseLandmarkerResult,
} from "@mediapipe/tasks-vision";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useActiveTab } from "../../contexts/ActiveTabContext";
import { PoseEventsDao } from "../../services/firestore/PoseEventsDao";
import { PoseEvent } from "../../services/pose-events/PoseEvent";
import { addPoseEvent } from "../../services/poseEventService";
import { ControlOptions } from "../control-options";
import FPSStats, { FPSStatsProps } from "../fps/FPSStats";
import { useFrameData } from "../fps/useFrameData";
import { PoseIds } from "../generated/PoseIds";
import SignInModal from "../login/SignInModal";
import { useAuthContext } from "../login/useAuthContext";
import AudioVolumeControls from "../settings/AudioVolumeControls";
import { SettingsContext } from "../settings/SettingsContext";
import { MediaFile } from "../source-picker/MediaPicker";
import SourcePicker from "../source-picker/SourcePicker";
import { SourceType } from "../source-picker/VideoDevicePicker";
import { kickSynth } from "../tones/tone-synthesizers";
import useSpeechQueue from "../tts/useSpeechQueue";
import VideoScrubber from "../VideoScrubber";
import { CameraStartErrorControls } from "./CameraStartErrorControls";
import { detectInPose } from "./detect-in-pose";
import PoseDetectionGuidanceControls from "./detection-guidance/PoseDetectionGuidanceControls";
import { ModelLoadingControls } from "./ModelLoadingControls";
import { playModelReadyTones } from "./playModelReadyTones";
import { playPoseStateTransitionTones } from "./playPoseStateTransitionTones";
import { PoseDetectionFeedback } from "./pose-detection-feedback";
import { PoseEvaluation, PoseFrame, PoseScoring } from "./pose-scoring";
import { JointAngle } from "./pose-utils";
import { PoseLandmarkerErrorAlertDialog } from "./PoseLandmarkerErrorAlertDialog";
import { poseModelAssetMap } from "./poseModelAssetMap";
import { useInitializePoseModel } from "./useInitializePoseModel";
import {
  InputImage,
  PositionedRectangle,
  Rectangle,
  usePoseDetectionRenderer,
} from "./usePoseDetectionRenderer";

const autoStartCamera = true;
const skipLandmarker = false;
export const regionOfInterestVisible = false;

export const MIN_POSE_DURATION_TO_SAVE = 1000;
const MIN_POSE_DURATION_TO_ANNOUNCE = 3000;

const formatPixels = (v: number) => `${v.toFixed(0)}`;

type PoseDetectorProps = {
  onLoadingStatusChange?: (loadingStatus: string) => void;
  setPoseStartAt?: (poseStartAt: number) => void;
  setIsInPoseState?: (isInPose: boolean) => void;
  setCurrentPoseDuration?: (currentPoseDuration: number) => void;
  hideChrome?: boolean;
  detectingPoseId: PoseIds;
  latestCompletionAt?: number;
  voice?: string;
  fillWidth?: boolean;
} & BoxProps;

export const PoseDetector = forwardRef<PoseDetectorProps, "div">(
  (
    {
      onLoadingStatusChange,
      setPoseStartAt,
      setIsInPoseState,
      setCurrentPoseDuration,
      hideChrome,
      detectingPoseId,
      latestCompletionAt,
      voice,
      fillWidth = false,
      ...props
    },
    ref
  ) => {
    const { user } = useAuthContext();
    const mainRef = useRef<HTMLDivElement>();
    const mergedRefs = useMergeRefs(ref, mainRef);
    const [containerSize, setContainerSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const [isInitialSizeSet, setIsInitialSizeSet] = useState(false);
    // The pose detector canvas size is updated based on the source frames
    // (in the control panel) coming in from the selected video source
    const canvasWidthRef = useRef(1280);
    const canvasHeightRef = useRef(720);
    const frameWidthRef = useRef(1280);
    const frameHeightRef = useRef(720);

    const canvasCtxRef = useRef<CanvasRenderingContext2D | null>(null);

    const [regionOfInterest, setRegionOfInterest] =
      useState<PositionedRectangle>({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      });
    const regionOfInterestRef = useRef<PositionedRectangle>(regionOfInterest);

    const poseLandmarkerRef = useRef<PoseLandmarker>();
    const [isUsingCamera, setIsUsingCamera] = useState(true);
    const [selectedMedia, setSelectedMedia] = useState<MediaFile | null>(null);

    const {
      poseModelComplexity,
      isModelDelegateCPU,
      videoDeviceId,
      setVideoDeviceId,
      isPerformanceControlsVisible,
      isLargeFPS,
      setIsLargeFPS,
      signInPromptSilencedUntil,
      overrideFrameAspectRatio,
      automaticMirrorVideo,
      mirrorVideo,
      setMirrorVideo,
      isCameraBackFacing,
      setIsCameraBackFacing,
    } = useContext(SettingsContext);

    const [showSignInModal, setShowSignInModal] = useState(false);

    const toggleFPSSize = () => {
      setIsLargeFPS(!isLargeFPS);
    };

    const largeFPSStyle: Partial<FPSStatsProps> = {
      fontSize: "18px",
      lineHeight: "20px",
      height: "auto",
      graphHeight: 80,
      barWidth: [2, 3],
    };

    const videoSourceTypeRef = useRef<SourceType>("webcam");

    const timestampRef = useRef(0);

    const [poseEvaluation, setPoseEvaluation] = useState<PoseEvaluation>();

    const { queueUtterance } = useSpeechQueue();

    const [isAwake, setIsAwake] = useState(false);
    const { activeTab } = useActiveTab();
    const isActiveTabRef = useRef(false);
    const isPoseDetectionActiveRef = useRef(false);

    useEffect(() => {
      // Close the pose detection model on component unmount
      if (poseLandmarkerRef.current) {
        poseLandmarkerRef.current.close();
        poseLandmarkerRef.current = undefined;
      }
    }, []);

    const keepAwake = async () => {
      await KeepAwake.keepAwake();
    };

    const allowSleep = async () => {
      await KeepAwake.allowSleep();
    };

    const isSupported = async () => {
      const result = await KeepAwake.isSupported();
      return result.isSupported;
    };

    const isKeptAwake = async () => {
      const result = await KeepAwake.isKeptAwake();
      return result.isKeptAwake;
    };

    useEffect(() => {
      const newCanvasElement = document.getElementsByClassName(
        "output_canvas"
      )[0] as HTMLCanvasElement;
      if (newCanvasElement) {
        canvasCtxRef.current = newCanvasElement.getContext("2d");
      }
    }, []);

    useEffect(() => {
      if (!mainRef.current) return;
      console.log("useEffect ResizeObserver");

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          console.log("ResizeObserver setContainerSize", {
            width,
            height,
            canvasWidth: canvasWidthRef.current,
            canvasHeight: canvasHeightRef.current,
          });
          setContainerSize({ width, height });
          if (!isInitialSizeSet) {
            setIsInitialSizeSet(true);
          }
        }
      });

      resizeObserver.observe(mainRef.current);

      // Trigger an initial size calculation
      setContainerSize({
        width: mainRef.current.offsetWidth,
        height: mainRef.current.offsetHeight,
      });
      setIsInitialSizeSet(true);

      return () => {
        resizeObserver.disconnect();
      };
    }, []);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const streamRef = useRef<MediaStream>();
    const [loadingStatus, setLoadingStatus] = useState<string>();
    const loadingStatusRef = useRef(loadingStatus);

    const updateLoadingStatus = useCallback(
      (loadingStatus: string) => {
        loadingStatusRef.current = loadingStatus;
        setLoadingStatus(loadingStatus);
        if (onLoadingStatusChange) {
          onLoadingStatusChange(loadingStatus);
        }
      },
      [setLoadingStatus, onLoadingStatusChange]
    );
    useEffect(() => {
      updateLoadingStatus("loading");
    }, []);

    const framePoseDetectionFeedbackRef = useRef<PoseDetectionFeedback | null>(
      null
    );

    // depending on the mode, track the landmarks which are actively being evaluated
    const activeJointLandmarkIndexesRef = useRef<number[]>([]);

    const currentFrameRef = useRef<PoseFrame>({
      score: 0,
      confidence: 0,
      pairs: {},
    });
    const [currentFrame, setCurrentFrame] = useState<PoseFrame>({
      score: 0,
      confidence: 0,
      pairs: {},
    });
    const framesRef = useRef<PoseFrame[]>([]);

    /**
     * Ref for "is in pose", useful for callbacks and behavior that needs immediate reference to changes
     */
    const isInPoseRef = useRef(false);

    /**
     * State for "is in pose", useful for updating child components
     */
    const [isInPose, setIsInPose] = useState(false);

    const controlOptions: PoseLandmarkerOptions & ControlOptions = useMemo(
      () => ({
        volume: 1,
        showLandmarks: true,
        landmarkPoseVisibilityThreshold: 0.25,
        baseOptions: {
          modelAssetPath: poseModelAssetMap[poseModelComplexity],
          delegate: isModelDelegateCPU ? "CPU" : "GPU",
        },
        runningMode: "VIDEO",
        numPoses: 1,
        canvas: canvasRef.current || undefined,
      }),
      [poseModelComplexity, isModelDelegateCPU]
    );
    const poseScoring = new PoseScoring(controlOptions);

    const timerRef = useRef<number | null>(null);
    const speechTimerRef = useRef<number | null>(null);
    const detectedPoseIdRef = useRef<string>("");
    const detectingStaticPoseRef = useRef(false);
    const poseStartAtRef = useRef(Date.now());
    const [poseDuration, setPoseDuration] = useState<number>(0);

    const poseEventDao = new PoseEventsDao();

    function updateScoresForJoints(
      poseLandmarks: NormalizedLandmark[],
      timestamp: number
    ) {
      const jointAnglesMap: { [key: number]: JointAngle } = {};
      currentFrameRef.current = poseScoring.getFrameScoreForPoseLandmarks(
        poseLandmarks,
        timestamp,
        jointAnglesMap
      );
      return jointAnglesMap;
    }

    const getCurrentPoseId = useCallback(() => {
      return isInPoseRef.current ? detectedPoseIdRef.current : detectingPoseId;
    }, [detectingPoseId]);

    /**
     * Determine the pose state based on the current pose landmarks
     */
    function updatePoseState(
      poseId: string,
      poseLandmarks: NormalizedLandmark[],
      jointAnglesMap: { [key: number]: JointAngle }
    ) {
      const poseDetectionFeedback: PoseDetectionFeedback = {};
      const newIsInPose = detectInPose(
        isInPoseRef.current,
        controlOptions,
        poseId,
        poseLandmarks,
        jointAnglesMap,
        poseDetectionFeedback
      );

      framePoseDetectionFeedbackRef.current = poseDetectionFeedback;
      return { newIsInPose, poseDetectionFeedback };
    }

    function startMetronome() {
      timerRef.current = window.setInterval(() => {
        try {
          kickSynth.triggerAttackRelease("D2", "8n");
        } catch (error) {
          console.error("Error triggering synth:", error);
        }
      }, 1000);
    }

    function stopMetronome() {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    }

    function evaluateAndSavePose(
      frameTimestamp: number,
      announcePoseDuration = true
    ) {
      const poseEvaluation = poseScoring.evaluateFrames(framesRef.current);
      const endAt = frameTimestamp;
      let poseDuration = endAt - poseStartAtRef.current;
      setPoseDuration(poseDuration);
      const poseDurationSeconds = Math.floor(poseDuration / 1000);

      // based on the videoSourceType, only use pose events from a live camera (ignore video and image sources)
      if (videoSourceTypeRef.current === "webcam") {
        const poseEvent: PoseEvent = {
          poseId: detectedPoseIdRef.current,
          startAt: poseStartAtRef.current,
          endAt,
          duration: poseDuration,
          ownerId: user?.uid,
          evaluation: poseEvaluation,
        };
        if (poseDuration > MIN_POSE_DURATION_TO_SAVE) {
          // don't block rendering on saving the pose event
          (async () => {
            // console.log("saving pose event", poseEvent);
            // logEvent(analytics, "pose_event_end", {
            //   pose_id: poseEvent.poseId,
            //   duration: poseEvent.duration,
            // });
            await addPoseEvent(poseEvent);
            if (user) {
              await poseEventDao.savePoseEvent(poseEvent);
            } else {
              const now = Date.now();
              if (now > signInPromptSilencedUntil && !hideChrome) {
                setShowSignInModal(true);
              }
            }
          })();
        }
      }
      if (
        announcePoseDuration &&
        poseDuration > MIN_POSE_DURATION_TO_ANNOUNCE
      ) {
        speechTimerRef.current = window.setTimeout(() => {
          silentAudioRef.current && silentAudioRef.current.play();

          queueUtterance({ text: `${poseDurationSeconds} seconds` });
        }, 500);
      }
    }

    /**
     * Handle the transition between being in a pose and not being in a pose.
     * Play tones, start/stop metronome, save the pose event, and speak the duration.
     */
    function handlePoseTransition(
      newIsInPose: boolean,
      poseId: string,
      frameTimestamp: number
    ) {
      if (isInPoseRef.current !== newIsInPose) {
        isInPoseRef.current = newIsInPose;
        detectingStaticPoseRef.current = newIsInPose;
        if (setIsInPoseState) {
          setIsInPoseState(isInPoseRef.current);
        }
        setIsInPose(isInPoseRef.current);

        silentAudioRef.current && silentAudioRef.current.play();
        playPoseStateTransitionTones(isInPoseRef.current, false);
        if (isInPoseRef.current) {
          setPoseDuration(0);
          detectedPoseIdRef.current = poseId;
          poseStartAtRef.current = frameTimestamp;
          setPoseStartAt && setPoseStartAt(poseStartAtRef.current);
          startMetronome();
        } else {
          stopMetronome();
          evaluateAndSavePose(frameTimestamp);
        }
      }
    }

    /**
     * Process landmarks for the current frame
     */
    const processPoseLandmarks = useCallback(
      (poseLandmarks: NormalizedLandmark[]) => {
        // For the meaning of the indexes in keypoints, see:
        // https://github.com/tensorflow/tfjs-models/blob/master/pose-detection/README.md#blazepose-keypoints-used-in-mediapipe-blazepose

        const currentPoseId = getCurrentPoseId();
        activeJointLandmarkIndexesRef.current = [];

        if (
          currentPoseId &&
          Object.values(PoseIds).includes(currentPoseId as PoseIds)
        ) {
          const frameTimestamp = Date.now();
          const jointAnglesMap = updateScoresForJoints(
            poseLandmarks,
            frameTimestamp
          );

          const {
            newIsInPose,
          }: {
            newIsInPose: boolean;
          } = updatePoseState(currentPoseId, poseLandmarks, jointAnglesMap);
          handlePoseTransition(newIsInPose, currentPoseId, frameTimestamp);
          if (isInPoseRef.current) {
            framesRef.current.push(currentFrameRef.current);
            if (setCurrentPoseDuration) {
              setCurrentPoseDuration(Date.now() - poseStartAtRef.current);
            }
          }
          return undefined;
        }
      },
      [getCurrentPoseId, handlePoseTransition]
    );

    const resultsRef = useRef<PoseLandmarkerResult>();

    const renderCanvas = usePoseDetectionRenderer({
      controlOptions,
      connectorThickness: 1,
      videoSourceType: videoSourceTypeRef.current,
      backgroundImage: undefined,
      landmarkRenderStyle: "default",
    });

    const simulateTabChange = useCallback(() => {
      console.log("Simulating tab change");
      isActiveTabRef.current = false;
      handleVisibilityChangeRef.current();

      setTimeout(() => {
        isActiveTabRef.current = true;
        handleVisibilityChangeRef.current();
      }, 100); // Simulate a brief tab switch
    }, []);

    const onResults = useCallback(
      (results: PoseLandmarkerResult, image: InputImage) => {
        // Hide the spinner
        if (loadingStatusRef.current !== "loaded") {
          console.log("[LM-10] onResults called; loading complete");
          updateLoadingStatus("loaded");

          if (controlOptions.loadingCompleteSoundsEnabled) {
            playModelReadyTones();
          }

          setTimeout(simulateTabChange, 500);
        } else {
          // console.log("onResults", { results, image });
        }

        resultsRef.current = results;

        let poseDetectionFeedback: PoseDetectionFeedback | undefined;
        poseDetectionFeedback = processPoseLandmarks(results.worldLandmarks[0]);
        renderCanvas(
          results,
          poseDetectionFeedback,
          activeJointLandmarkIndexesRef.current,
          image,
          videoSourceTypeRef.current === "webcam" ? !!mirrorVideo : false,
          regionOfInterestRef.current
        );
      },
      [
        renderCanvas,
        processPoseLandmarks,
        updateLoadingStatus,
        controlOptions,
        detectingPoseId,
      ]
    );

    const {
      poseLandmarkerError,
      poseLandmarkerInitializationSlow,
      poseLandmarkerInitializationTimeout,
    } = useInitializePoseModel(poseLandmarkerRef, controlOptions, () => {
      if (!autoStartCamera) {
        updateLoadingStatus("loaded");
      }
    });

    useEffect(() => {
      return () => {
        // final cleanup when the component is unmounted
        timerRef.current && clearInterval(timerRef.current);
        timerRef.current = null;
        speechTimerRef.current && clearTimeout(speechTimerRef.current);
        speechTimerRef.current = null;
      };
    }, []);

    const onSourceChanged = useCallback(
      (id: string | undefined, type: SourceType, isBackFacing?: boolean) => {
        setVideoDeviceId(id);
        if (isBackFacing !== undefined) {
          setIsCameraBackFacing(isBackFacing);
        }
        videoSourceTypeRef.current = type;

        if (poseLandmarkerRef.current) {
          // reset because the model gives better results when reset between
          // source changes
          // poseLandmarkerRef.current.reset();
        }
      },
      []
    );

    const stopCamera = useCallback(() => {
      const stream =
        (videoRef.current?.srcObject as MediaStream) || streamRef.current;
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        streamRef.current = undefined;
        if (videoRef.current) {
          videoRef.current.srcObject = null;
        }
      }
    }, []);

    const updateRunningMode = useCallback((isVideo: boolean) => {
      if (isVideo) {
        poseLandmarkerRef.current?.setOptions({
          runningMode: "VIDEO",
        });
      } else {
        poseLandmarkerRef.current?.setOptions({
          runningMode: "IMAGE",
        });
      }
    }, []);

    const handleMediaSelected = (file: MediaFile | null) => {
      if (file) {
        setIsUsingCamera(false);
        stopCamera();
        cancelPredictWebcam();

        setSelectedMedia(file);
        const isVideo = file.mimeType.startsWith("video/");
        updateRunningMode(isVideo);
        videoSourceTypeRef.current = isVideo ? "video" : "image";
      } else {
        console.log("handleMediaSelected");
        handleCloseMedia();
      }
    };

    const handleCloseMedia = () => {
      console.log("handleCloseMedia");
      cancelPredictWebcam();
      setSelectedMedia(null);
      updateRunningMode(true);
      videoSourceTypeRef.current = "webcam";
      setIsUsingCamera(true);
      setupCamera();
    };

    const resizeCanvasForContent = useCallback(
      (size: Rectangle) => {
        if (size.width <= 0 || size.height <= 0) {
          return;
        }

        const componentWidth = containerSize.width || window.innerWidth;
        const componentHeight = containerSize.height || window.innerHeight;
        const sourceAspect = size.width / size.height;

        // Calculate region of interest (cropped area)
        let croppedWidth = size.width;
        let croppedHeight = size.height;
        if (overrideFrameAspectRatio) {
          if (overrideFrameAspectRatio > sourceAspect) {
            croppedHeight = size.width / overrideFrameAspectRatio;
          } else {
            croppedWidth = size.height * overrideFrameAspectRatio;
          }
        }

        // Calculate canvas size to fill component
        const croppedAspect = croppedWidth / croppedHeight;
        let canvasWidth, canvasHeight;
        if (fillWidth || croppedAspect > componentWidth / componentHeight) {
          canvasWidth = (componentWidth * size.width) / croppedWidth;
          canvasHeight = canvasWidth / sourceAspect;
        } else {
          canvasHeight = (componentHeight * size.height) / croppedHeight;
          canvasWidth = canvasHeight * sourceAspect;
        }

        // Calculate the region of interest relative to the canvas
        const roiLeft =
          (((size.width - croppedWidth) / 2) * canvasWidth) / size.width;
        const roiTop =
          (((size.height - croppedHeight) / 2) * canvasHeight) / size.height;

        // Calculate positioning
        const leftOffset = (componentWidth - canvasWidth) / 2;
        const topOffset = -roiTop;

        canvasWidthRef.current = canvasWidth;
        canvasHeightRef.current = canvasHeight;
        frameWidthRef.current = size.width;
        frameHeightRef.current = size.height;

        if (canvasRef.current) {
          canvasRef.current.width = canvasWidth;
          canvasRef.current.height = canvasHeight;
          canvasRef.current.style.position = "absolute";
          canvasRef.current.style.left = `${leftOffset}px`;
          canvasRef.current.style.top = `${topOffset}px`;
        }

        const regionOfInterest = {
          x: roiLeft,
          y: roiTop,
          width: (croppedWidth * canvasWidth) / size.width,
          height: (croppedHeight * canvasHeight) / size.height,
        };
        regionOfInterestRef.current = regionOfInterest;
        setRegionOfInterest(regionOfInterest);
        console.log("resizeCanvasForContent", {
          size,
          componentSize: { width: componentWidth, height: componentHeight },
          croppedSize: { width: croppedWidth, height: croppedHeight },
          canvasSize: { width: canvasWidth, height: canvasHeight },
          offset: { left: leftOffset, top: topOffset },
          overrideFrameAspectRatio,
          sourceAspect,
          croppedAspect,
          regionOfInterest: regionOfInterest,
        });

        return {
          width: canvasWidth,
          height: canvasHeight,
          regionOfInterest: regionOfInterest,
        };
      },
      [containerSize, overrideFrameAspectRatio, fillWidth]
    );

    useEffect(() => {
      // Implement the automaticMirrorVideo behavior
      if (automaticMirrorVideo) {
        console.log("Setting mirroring", videoDeviceId);
        // Mirror video for front-facing camera (not back-facing)
        setMirrorVideo(!isCameraBackFacing);
      }
    }, [videoDeviceId, automaticMirrorVideo, isCameraBackFacing]);

    const inputRef = useRef<InputImage | null>(null);
    const onFrame = useCallback(
      async (input: InputImage | null, size: Rectangle) => {
        if (
          frameWidthRef.current !== size.width ||
          frameHeightRef.current !== size.height
        ) {
          console.log("[LM-08] onFrame for new dimensions", {
            size,
            containerSize,
          });
          resizeCanvasForContent(size);
        }

        inputRef.current = input;

        if (!input || size.width <= 0 || size.height <= 0) {
          console.warn("unable to draw to canvas", {
            size,
            canvas: canvasRef.current,
            canvasCtx: canvasCtxRef.current,
            canvasWidth: canvasWidthRef.current,
            canvasHeight: canvasHeightRef.current,
          });
          return;
        }

        if (poseLandmarkerRef.current && isPoseDetectionActiveRef.current) {
          const frameStart = performance.now();
          const frameDuration = timestampRef.current
            ? frameStart - timestampRef.current
            : 0;
          timestampRef.current = frameStart;

          let result;
          if (skipLandmarker) {
            result = { worldLandmarks: [], landmarks: [] };
          } else if (videoSourceTypeRef.current === "image") {
            result = await poseLandmarkerRef.current.detect(input);
          } else {
            // console.log("detectForVideo", { input, size, width, height });
            try {
              result = await poseLandmarkerRef.current.detectForVideo(
                input,
                timestampRef.current
              );
            } catch (e) {
              console.error("Detection failed", e);
              result = { worldLandmarks: [], landmarks: [] };
            }
          }

          const detectDuration = performance.now() - timestampRef.current;
          onResults(result, input);
          updateFrameData({
            detect: detectDuration,
            remainder: frameDuration - detectDuration,
          });
        } else if (canvasCtxRef.current) {
          // just render the frame
          if (mirrorVideo) {
            canvasCtxRef.current.save();
            canvasCtxRef.current.scale(-1, 1);
            canvasCtxRef.current.translate(-canvasWidthRef.current, 0);
          }

          canvasCtxRef.current.drawImage(
            input,
            0,
            0,
            canvasWidthRef.current,
            canvasHeightRef.current
          );

          if (mirrorVideo) {
            canvasCtxRef.current.restore();
          }
          // console.log("onFrame render without pose landmarker");
        }
      },
      [onResults, mirrorVideo, containerSize, resizeCanvasForContent]
    );
    const onFrameRef = useRef(onFrame);
    onFrameRef.current = onFrame;

    useLayoutEffect(() => {
      resizeCanvasForContent({
        width: frameWidthRef.current,
        height: frameHeightRef.current,
      });
      // if (inputRef.current) {
      onFrame(inputRef.current, {
        width: frameWidthRef.current,
        height: frameHeightRef.current,
      });
      // }
    }, [containerSize, resizeCanvasForContent]);

    const animationFrameIdRef = useRef<number | null>(null);
    const lastVideoTimeRef = useRef(-1);
    const predictWebcam = useCallback(() => {
      if (!videoRef.current || !isPoseDetectionActiveRef.current) {
        console.log("predictWebcam: skipping", {
          video: !!videoRef.current,
          isPoseDetectionActive: isPoseDetectionActiveRef.current,
          performance: performance.now(),
        });
        return;
      }

      if (lastVideoTimeRef.current === -1) {
        console.log("[LM-04] predictWebcam processing first frame");
      }
      // console.log("predictWebcam", {
      //   "video.currentTime": videoRef.current.currentTime,
      // });

      // only process the frame if the video has advanced
      if (lastVideoTimeRef.current !== videoRef.current.currentTime) {
        lastVideoTimeRef.current = videoRef.current.currentTime;

        videoRef.current.style.height = `${canvasHeightRef.current}px`;
        videoRef.current.style.width = `${canvasWidthRef.current}px`;

        onFrameRef.current(videoRef.current, {
          width: videoRef.current.videoWidth,
          height: videoRef.current.videoHeight,
        });
      }

      // Call this function again to keep predicting when the browser is ready.
      animationFrameIdRef.current = window.requestAnimationFrame(predictWebcam);
    }, []);

    const cancelPredictWebcam = useCallback(() => {
      if (animationFrameIdRef.current !== null) {
        window.cancelAnimationFrame(animationFrameIdRef.current);
        animationFrameIdRef.current = null;
      }
    }, []);

    useEffect(() => {
      return () => {
        cancelPredictWebcam();
      };
    }, [cancelPredictWebcam]);

    const handleVisibilityChange = useCallback(async () => {
      const supported = await isSupported();
      if (supported) {
        if (isActiveTabRef.current && !document.hidden) {
          isPoseDetectionActiveRef.current = true;
          await keepAwake();
          const awake = await isKeptAwake();
          setIsAwake(awake);
          console.log("handleVisibilityChange: awake", {
            awake,
            "document.hidden": document.hidden,
            video: videoRef.current,
            isPoseDetectionActive: isPoseDetectionActiveRef.current,
            isActiveTab: isActiveTabRef.current,
            performance: performance.now(),
          });
          // Resume pose detection
          if (videoRef.current) {
            cancelPredictWebcam();
            predictWebcam();
          }
        } else {
          // Stop pose detection
          isPoseDetectionActiveRef.current = false;
          timestampRef.current = 0;
          console.log("handleVisibilityChange: not awake", {
            "document.hidden": document.hidden,
            video: videoRef.current,
            isPoseDetectionActive: isPoseDetectionActiveRef.current,
            isActiveTab: isActiveTabRef.current,
            performance: performance.now(),
          });

          await allowSleep();
          setIsAwake(false);

          // End the pose
          const frameTimestamp = Date.now();
          handlePoseTransition(false, detectingPoseId, frameTimestamp);
          cancelPredictWebcam();
        }
      }
    }, [predictWebcam, detectingPoseId, cancelPredictWebcam]);

    // Restore sleeping when unmounted
    useEffect(() => {
      return () => {
        allowSleep();
      };
    }, []);

    const handleVisibilityChangeRef = useRef(handleVisibilityChange);
    handleVisibilityChangeRef.current = handleVisibilityChange;

    useEffect(() => {
      // const newIsActiveTab = activeTab === "/pose";
      const newIsActiveTab = true;
      isActiveTabRef.current = newIsActiveTab;
      console.log("[activeTab] useEffect", {
        newIsActiveTab: newIsActiveTab,
        activeTab,
      });

      const visibilityChangeLiestener = (e: Event) => {
        console.log("visibilitychange event", e);
        setTimeout(simulateTabChange, 500);
      };

      // listen for actual visibility changes (app minimized, etc.)
      document.addEventListener("visibilitychange", visibilityChangeLiestener);

      handleVisibilityChangeRef.current();

      return () => {
        document.removeEventListener(
          "visibilitychange",
          visibilityChangeLiestener
        );
      };
    }, [activeTab]);

    const { setFrameData, getFrameData } = useFrameData();

    const updateFrameData = useCallback(
      (frameComponents: Record<string, number>) => {
        const now = Date.now();
        setFrameData({
          frameTime: now,
          frameComponents,
        });
      },
      [setFrameData]
    );

    const [cameraStartError, setCameraStartError] = useState<string>();

    const setupCamera = useCallback(async () => {
      console.log("setupCamera", { cameraStartError, videoDeviceId });
      setCameraStartError(undefined);
      const constraints: MediaStreamConstraints = {
        video: {
          deviceId: videoDeviceId,
        },
      };

      try {
        if (Capacitor.isNativePlatform()) {
          const permission = await Camera.requestPermissions({
            permissions: ["camera"],
          });
          if (permission.camera !== "granted") {
            console.warn("Camera permission not granted");
            setCameraStartError("Permission denied");
            return;
          }
        }

        console.log("[LM-02] getUserMedia start", {
          constraints,
          videoElement: videoRef.current,
        });
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        console.log("[LM-05] getUserMedia complete", {
          stream,
          videoElement: videoRef.current,
          readyState: videoRef.current?.readyState,
          networkState: videoRef.current?.networkState,
          error: videoRef.current?.error,
        });

        if (videoRef.current) {
          // Still keep the loadeddata event listener as a fallback
          videoRef.current.addEventListener("loadeddata", () => {
            console.log("[LM-06] video loaded (loadeddata event)");
            playVideo();
          });

          videoRef.current.srcObject = stream;
          streamRef.current = stream;

          // Check video readiness in an interval
          const checkVideoReady = setInterval(() => {
            if (videoRef.current && videoRef.current.readyState >= 3) {
              console.log("[LM-06] video ready (checked by interval)");
              clearInterval(checkVideoReady);
              playVideo();
            } else {
              console.log("checkVideoReady", {
                readyState: videoRef.current?.readyState,
              });
            }
          }, 100);

          // Clear the interval after a certain timeout to avoid infinite checking
          let checkingTimeout = setTimeout(() => {
            console.log("checkingTimeout", {
              readyState: videoRef.current?.readyState,
            });
            setCameraStartError("Video failed to load within the timeout.");
            clearInterval(checkVideoReady);
          }, 10000);

          const playVideo = () => {
            videoRef.current
              ?.play()
              .then(() => {
                console.log("[LM-06] video played successfully");
                clearTimeout(checkingTimeout);
                setCameraStartError(undefined);
                if (isPoseDetectionActiveRef.current) {
                  predictWebcam();
                  setTimeout(simulateTabChange, 500);
                }
              })
              .catch((error) => {
                console.error("Error playing video:", error);
                setCameraStartError(error.message);
                setTimeout(playVideo, 1000);
              });
          };
        } else {
          console.warn("video not started, videoRef.current is null");
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
        setCameraStartError(
          (error as Error).message || "Failed to access camera"
        );
      }
    }, [videoDeviceId, predictWebcam, simulateTabChange]);

    useEffect(() => {
      if (autoStartCamera) {
        setupCamera();
      }

      return () => {
        const stream =
          (videoRef.current?.srcObject as MediaStream) || streamRef.current;
        console.log(
          "stopping camera",
          videoRef.current,
          videoRef.current?.srcObject,
          streamRef.current
        );
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
          streamRef.current = undefined;
          if (videoRef.current) {
            videoRef.current.srcObject = null;
          }
        }
      };
    }, [setupCamera]);

    console.log("** render PoseDetector", {
      cameraStartError,
      loadingStatus,
      poseLandmarkerError,
    });

    const silentAudioRef = useRef<HTMLAudioElement>(null);

    return (
      <ColorModeProvider value="dark">
        <Box
          ref={mergedRefs}
          width={fillWidth ? "100%" : "auto"}
          height={fillWidth ? canvasHeightRef.current : "auto"}
          position="relative"
          color="var(--chakra-colors-chakra-body-text)"
          {...props}
        >
          <Box
            position="absolute"
            width="100%"
            height="100%"
            maxHeight="100%"
            backgroundColor="transparent"
          >
            <audio ref={silentAudioRef} id="slientAudio">
              <source src="/sounds/silence.mp3" type="audio/mp3"></source>
            </audio>
            {isUsingCamera && (
              <video
                ref={videoRef}
                id="webcam"
                style={{
                  width: `${canvasWidthRef.current}px`,
                  height: `${canvasHeightRef.current}px`,
                  position: "absolute",
                  visibility: "hidden",
                }}
                autoPlay
                playsInline
              ></video>
            )}

            <canvas
              ref={canvasRef}
              className="output_canvas"
              style={{
                left: `calc((100% - ${canvasWidthRef.current}px)/2)`,
                background: "transparent",
              }}
              width={canvasWidthRef.current}
              height={canvasHeightRef.current}
              hidden={!isInitialSizeSet}
            ></canvas>

            {selectedMedia && (
              <VideoScrubber
                media={selectedMedia}
                onClose={handleCloseMedia}
                onFrame={(input, dimensions) => onFrame(input, dimensions)}
                zIndex={2}
                position="absolute"
                bottom={0}
                left={0}
                right={0}
              />
            )}
            <Flex
              position="absolute"
              top={0}
              left={`calc((100% - ${regionOfInterest.width}px)/2)`}
              padding={3}
              height="100%"
              width={`${regionOfInterest.width}px`}
              alignItems="end"
              flexDirection="column"
              gap={3}
              border={regionOfInterestVisible ? "1px solid red" : "none"}
            >
              <SourcePicker
                videoDeviceId={videoDeviceId}
                setVideoDeviceId={onSourceChanged}
                selectedMedia={selectedMedia}
                onMediaSelected={handleMediaSelected}
                backgroundColor="rgba(0,0,0,0.4)"
                borderRadius="full"
              />
              <PoseDetectionGuidanceControls
                feedbackRef={framePoseDetectionFeedbackRef}
                poseId={detectingPoseId}
                latestCompletionAt={latestCompletionAt}
                voice={voice}
                borderRadius={20}
              />
              <AudioVolumeControls
                backgroundColor="rgba(0,0,0,0.4)"
                borderRadius={20}
              />

              <Spacer />
              {hideChrome || !isPerformanceControlsVisible ? null : (
                <FPSStats
                  getFrameData={getFrameData}
                  visualizeBreakdown={isLargeFPS}
                  {...(isLargeFPS ? largeFPSStyle : {})}
                  pointerEvents="all"
                  onClick={toggleFPSSize}
                  style={{ cursor: "pointer" }}
                  backgroundColor="rgba(0,0,0,0.4)"
                />
              )}
              {hideChrome || !isPerformanceControlsVisible ? null : (
                <Box backgroundColor="rgba(0,0,0,0.4)">
                  <Text position="relative" size="sm" padding={1}>
                    {`${formatPixels(frameWidthRef.current)}x${formatPixels(
                      frameHeightRef.current
                    )} : ${formatPixels(canvasWidthRef.current)}x${formatPixels(
                      canvasHeightRef.current
                    )}`}
                  </Text>
                </Box>
              )}
            </Flex>
            <Flex direction="column" gap={3} padding={3}>
              <ModelLoadingControls
                loadingStatus={loadingStatus}
                poseLandmarkerError={poseLandmarkerError}
                poseLandmarkerInitializationSlow={
                  poseLandmarkerInitializationSlow
                }
              />
              <CameraStartErrorControls cameraStartError={cameraStartError} />
            </Flex>
            <PoseLandmarkerErrorAlertDialog
              isLoaded={loadingStatus === "loaded"}
              error={poseLandmarkerError}
              timedOut={poseLandmarkerInitializationTimeout}
              onRetry={() => {
                window.location.reload();
              }}
            />
            <SignInModal
              isOpen={showSignInModal}
              onClose={() => setShowSignInModal(false)}
            />
          </Box>
        </Box>
      </ColorModeProvider>
    );
  }
);
PoseDetector.displayName = "PoseDetector";
