import { Flex, useTheme } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import Select, {
  GroupBase,
  OnChangeValue,
  Props,
  SelectInstance,
  SingleValue,
} from "react-select";
import { useColorModeAwareSelectStyles } from "./useColorModeAwareSelectStyles";
import PoseIcon from "./pose-detection/PoseIcon";
import { Pose, poses as allPoses } from "./types";

type PoseOption = {
  value: Pose;
  label: Pose;
};

export function PosePicker<
  Option extends PoseOption = PoseOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  poseValue,
  includeNone,
  onPoseChange,
  availablePoses,
  ...props
}: {
  poseValue: Pose | undefined;
  includeNone?: boolean;
  onPoseChange: (pose: Pose | undefined) => void;
  availablePoses?: Pose[];
} & Props<PoseOption, IsMulti, Group>) {
  const theme = useTheme();
  const colorModeAwareSelectStyles = useColorModeAwareSelectStyles<
    PoseOption,
    IsMulti,
    Group
  >();

  const options: PoseOption[] = useMemo(() => {
    const posesToUse = availablePoses || allPoses;
    const posesOptions = posesToUse.map((pose) => ({
      value: pose,
      label: pose,
    }));
    if (includeNone) {
      return [{ value: "(none)", label: "(none)" }, ...posesOptions];
    }
    return posesOptions;
  }, [availablePoses, includeNone]);

  let selectRef = useRef<SelectInstance<PoseOption, IsMulti, Group> | null>(
    null
  );

  const scrollMenuIntoView = useCallback(() => {
    selectRef.current?.menuListRef?.scrollIntoView({ behavior: "smooth" });
    // ideally both are in view, but ensure that the main control is in view
    selectRef.current?.controlRef?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const timerIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", scrollMenuIntoView);
    };
  }, []);

  return (
    <Select<PoseOption, IsMulti, Group>
      ref={selectRef}
      onMenuOpen={() => {
        window.addEventListener("resize", scrollMenuIntoView);
        scrollMenuIntoView();
        timerIdRef.current = setTimeout(() => {
          window.removeEventListener("resize", scrollMenuIntoView);
        }, 500);
      }}
      value={options.find((option) => option.value === poseValue)}
      onChange={(option: OnChangeValue<PoseOption, IsMulti>) => {
        const singleValue = option as SingleValue<PoseOption>;
        console.log("PosePicker Select onChange", singleValue?.value);
        onPoseChange(
          singleValue?.value === "(none)" ? undefined : singleValue?.value
        );
      }}
      options={options}
      formatOptionLabel={({
        value,
        label,
      }: {
        value: string;
        label: string;
      }) => (
        <Flex gap={3} alignItems="center">
          <PoseIcon pose={value} boxSize={8} />
          {label}
        </Flex>
      )}
      isSearchable
      styles={colorModeAwareSelectStyles}
      {...props}
    />
  );
}
