import { EditIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import { getFunctions } from "firebase/functions";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../login/useAuthContext";
import EditEmailForm from "../settings/EditEmailForm";
import { SettingsContext } from "../settings/SettingsContext";
import { useSubscriptions } from "./SubscriptionContext";
import { waitlistTags } from "./waitlistTags";

interface WaitlistModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WaitlistModal: React.FC<WaitlistModalProps> = ({ isOpen, onClose }) => {
  const {
    pendingTags,
    isLoading,
    setIsLoading,
    handlePendingTagToggle,
    applyPendingChanges,
  } = useSubscriptions();
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [isVerificationPending, setIsVerificationPending] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState<string>();
  const { user, signOut } = useAuthContext();
  const toast = useToast();
  const bgColor = useColorModeValue("white", "gray.800");
  const iconColor = useColorModeValue("gray.600", "gray.400");

  const getSelectedTagNames = () => {
    return pendingTags
      .map((tagId) =>
        waitlistTags
          .find((tag) => tag.id === tagId)
          ?.name.replace(" Waitlist", "")
      )
      .filter(Boolean);
  };

  const handleSubscribe = async () => {
    if (!user) return;

    setIsLoading(true);
    try {
      await applyPendingChanges();
      onClose();
    } catch (error) {
      console.error("Error subscribing to waitlist:", error);
      toast({
        title: "Error subscribing to waitlist",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { dontShowWaitlistModal, setDontShowWaitlistModal } =
    useContext(SettingsContext);
  const handleClose = () => {
    if (dontShowAgain) {
      // Save user preference to not show the modal again
      setDontShowWaitlistModal(true);
    }
    onClose();
  };

  const history = useHistory();
  const handleReauthenticate = async () => {
    await signOut();
    // You may need to handle this differently in the modal context
    toast({
      title: "Please sign in again to update your email",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onClose();
    history.push("/login", { from: "/account/edit-email" });
  };

  const [verificationLatChecked, setVerificationLastChecked] = useState(0);
  const [isReloadingUser, setIsReloadingUser] = useState(false);

  const checkVerificationStatus = useCallback(async () => {
    if (isVerificationPending) {
      setIsReloadingUser(true);
      try {
        await user?.reload();
        setVerificationLastChecked(Date.now());
        if (user?.email === updatedEmail) {
          setUpdatedEmail(undefined);
          setIsVerificationPending(false);
          setShowEmailForm(false);
          toast({
            title: "Email verified successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        if (
          error instanceof FirebaseError &&
          error.code === "auth/user-token-expired"
        ) {
          await signOut();
          toast({
            title: "Session expired",
            description: "Please sign in again to continue.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          onClose();
          // Redirect to login page
          history.push("/login", { from: "/account/edit-email" });
        } else {
          // Handle other errors
          console.error("Error reloading user:", error);
          toast({
            title: "Error verifying email",
            description: "Please try again later.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } finally {
        setIsReloadingUser(false);
      }
    }
  }, [isVerificationPending, user, toast]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkVerificationStatus();
      }
    };

    const handleAppResume = () => {
      checkVerificationStatus();
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Capacitor.addListener("resume", handleAppResume);

    let interval: NodeJS.Timeout;
    if (isVerificationPending) {
      interval = setInterval(checkVerificationStatus, 5000);
    }

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      // Capacitor.removeAllListeners();
      if (interval) clearInterval(interval);
    };
  }, [isVerificationPending, checkVerificationStatus, verificationLatChecked]);

  const handleEditEmailSuccess = (email: string) => {
    setUpdatedEmail(email);
    setIsVerificationPending(true);
    setShowEmailForm(false);
    toast({
      title: "Verification email sent",
      description: "Please check your email to verify your new address",
      status: "info",
      duration: 5000,
      isClosable: true,
    });
  };

  const verificationColor = useColorModeValue("orange.700", "orange.100");

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Join the Move Journey Waitlist</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Flex gap={3}>
              <Box position="relative" boxSize="14" flexShrink={0}>
                <Image
                  src="/icons/move_journey_logo_icon_x48.png"
                  alt="Logo"
                  borderRadius="full"
                  srcSet="/icons/move_journey_logo_icon_x96.png 2x, /icons/move_journey_logo_icon_x144.png 3x"
                />
                <Box
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bottom="0"
                  right="0"
                  bg={bgColor}
                  borderRadius="full"
                  p="1"
                  boxShadow="md"
                >
                  <Icon as={EmailIcon} boxSize="5" color={iconColor} />
                </Box>
              </Box>

              <Text>
                Would you like to join our waitlist to be the first to hear
                about the release of the Move Journey app?
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Text fontWeight="bold">
                You'll be subscribed to updates for the{" "}
                {getSelectedTagNames().length > 0 ? (
                  <>
                    {getSelectedTagNames().map((name, index, array) => (
                      <Fragment key={name}>
                        {index === 1 && index === array.length - 1 && " and "}
                        {index > 1 && index === array.length - 1 && ", and "}
                        {index > 0 && index < array.length - 1 && ", "}
                        {name}
                      </Fragment>
                    ))}
                    {" version"}
                    {getSelectedTagNames().length > 1 ? "s" : ""}
                  </>
                ) : (
                  "selected platforms"
                )}
                .
              </Text>
              <IconButton
                aria-label="Edit subscriptions"
                icon={<EditIcon />}
                size="sm"
                ml={2}
                onClick={() => setShowAllTags(!showAllTags)}
              />
            </Flex>
            <Collapse in={showAllTags} animateOpacity>
              <Card maxWidth="600px" marginInline={[3, 5]} marginBlock={1}>
                <VStack spacing={2} align="stretch" p={4}>
                  {waitlistTags.map((tag) => (
                    <Flex key={tag.id} alignItems="center">
                      <HStack spacing={2}>
                        <Icon as={tag.icon} boxSize={5} />
                        <Text>{tag.name}</Text>
                      </HStack>
                      <Switch
                        ml="auto"
                        isChecked={pendingTags.includes(tag.id)}
                        onChange={() => handlePendingTagToggle(tag.id)}
                        isDisabled={isLoading}
                      />
                    </Flex>
                  ))}
                </VStack>
              </Card>
            </Collapse>
            {showEmailForm ? null : (
              <Flex alignItems="center" justifyContent="space-between">
                <Text>Email: {user?.email || "Not set"}</Text>
                <Button
                  size="sm"
                  onClick={() => setShowEmailForm(!showEmailForm)}
                >
                  Edit Email
                </Button>
              </Flex>
            )}
            {isVerificationPending ? (
              <HStack>
                <Text fontSize="sm" color={verificationColor}>
                  Verification of <strong>{updatedEmail}</strong> pending
                </Text>
                <Button
                  size="sm"
                  onClick={checkVerificationStatus}
                  isLoading={isReloadingUser}
                >
                  Check now
                </Button>
              </HStack>
            ) : null}
            <Collapse in={showEmailForm} animateOpacity>
              <Box>
                <EditEmailForm
                  user={user}
                  onCancel={() => setShowEmailForm(false)}
                  onReauthenticate={handleReauthenticate}
                  onSuccess={handleEditEmailSuccess}
                />
              </Box>
            </Collapse>
            <Checkbox
              isChecked={dontShowAgain}
              onChange={(e) => setDontShowAgain(e.target.checked)}
            >
              Don't show this again
            </Checkbox>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleClose}>
            No, thanks
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubscribe}
            isLoading={isLoading}
            isDisabled={isVerificationPending}
          >
            Subscribe
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WaitlistModal;
