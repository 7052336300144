import { Capacitor } from "@capacitor/core";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Animation,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  createAnimation,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { limit, orderBy } from "firebase/firestore";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IoIosSettings } from "react-icons/io";
import { MdSettings } from "react-icons/md";
import { useAuthContext } from "../components/login/useAuthContext";
import { PoseDetector } from "../components/pose-detection/PoseDetector";
import { PoseDurationDisplay } from "../components/pose-detection/PoseDurationDisplay";
import { usePoseDuration } from "../components/pose-detection/usePoseDuration";
import { useTitleCase } from "../components/pose-detection/useTitleCase";
import { CurrentPoseAggregateSummary } from "../components/pose-events/CurrentPoseAggregateSummary";
import { CurrentPoseHistory } from "../components/pose-events/CurrentPoseHistory";
import { SettingsContext } from "../components/settings/SettingsContext";
import { SettingsModal } from "../components/settings/SettingsModal";
import {
  useAggregateDurations,
  usePoseEvents,
} from "../services/firestore/PoseEventsDao";
import "./PosePage.css";

const PosePage: React.FC = () => {
  const authContext = useAuthContext();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isInPoseState, setIsInPoseState] = useState<boolean>(false);
  const mainToolbarRef = useRef<HTMLIonToolbarElement | null>(null);
  const mainAnimation = useRef<Animation | null>(null);
  const platform = Capacitor.getPlatform();
  const SettingsIcon = platform === "ios" ? IoIosSettings : MdSettings;
  const {
    detectingPoseId,
    isCurrentPoseAggregateSummaryVisible,
    isCurrentPoseHistoryVisible,
  } = useContext(SettingsContext);
  const poseTitle = useTitleCase(detectingPoseId);
  const poseStateBackgroundColor = useColorModeValue("#6dddff", "#0054e9");
  const { durationRef, updateDuration } = usePoseDuration();

  const textColor = useColorModeValue("gray.800", "white");
  const shadowColor = useColorModeValue("white", "black");

  const [poseEvents, poseEventsLoading, poseEventsError] = usePoseEvents(
    authContext.user?.uid,
    detectingPoseId,
    orderBy("startAt", "desc"),
    limit(15)
  );
  const [triggerRecalculation, setTriggerRecalculation] = useState(0);
  useEffect(() => {
    setTriggerRecalculation((prev) => prev + 1);
  }, [poseEvents]);

  const memoizedUserId = useMemo(
    () => authContext.user?.uid,
    [authContext.user?.uid]
  );

  const memoizedPoseIds = useMemo(
    () => (isCurrentPoseAggregateSummaryVisible ? [detectingPoseId] : []),
    [detectingPoseId, isCurrentPoseAggregateSummaryVisible]
  );

  const {
    aggregateDurations,
    loading: aggregateLoading,
    error: aggregateError,
  } = useAggregateDurations(
    memoizedUserId,
    memoizedPoseIds,
    triggerRecalculation
  );

  useLayoutEffect(() => {
    const createToolbarAnimation = (
      toolbarRef: React.RefObject<HTMLIonToolbarElement>,
      animationRef: React.MutableRefObject<Animation | null>
    ) => {
      if (toolbarRef.current?.shadowRoot) {
        const updateAnimation = () => {
          const innerElement = toolbarRef.current?.shadowRoot?.querySelector(
            ".toolbar-background"
          ) as HTMLElement | null;

          if (innerElement) {
            // Destroy previous animation if it exists
            if (animationRef.current) {
              animationRef.current.destroy();
            }

            animationRef.current = createAnimation()
              .addElement(innerElement)
              .duration(300)
              .fromTo(
                "background",
                "var(--ion-toolbar-background)",
                poseStateBackgroundColor
              );
            return true;
          }
          return false;
        };

        // Check if the element already exists
        if (updateAnimation()) {
          return;
        }

        // If the element doesn't exist, set up the MutationObserver
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === "childList") {
              if (updateAnimation()) {
                observer.disconnect();
              }
            }
          });
        });

        observer.observe(toolbarRef.current.shadowRoot, {
          childList: true,
          subtree: true,
        });

        return () => observer.disconnect();
      }
    };

    // Clean up previous animations
    if (mainAnimation.current) {
      mainAnimation.current.destroy();
    }

    createToolbarAnimation(mainToolbarRef, mainAnimation);

    // Clean up function
    return () => {
      if (mainAnimation.current) {
        mainAnimation.current.destroy();
      }
    };
  }, [mainToolbarRef, poseStateBackgroundColor]);
  useEffect(() => {
    const playAnimation = (
      animation: React.MutableRefObject<Animation | null>
    ) => {
      if (isInPoseState) {
        animation.current?.direction("normal").play();
      } else {
        animation.current?.direction("reverse").play();
      }
    };

    playAnimation(mainAnimation);
  }, [isInPoseState]);

  const [isViewActive, setIsViewActive] = useState(false);

  useIonViewDidEnter(() => {
    setIsViewActive(true);
  });

  useIonViewDidLeave(() => {
    setIsViewActive(false);
  });

  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const checkOrientation = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    checkOrientation();
    window.addEventListener("resize", checkOrientation);

    return () => window.removeEventListener("resize", checkOrientation);
  }, []);

  console.log("Render PosePage");
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar ref={mainToolbarRef}>
          <IonTitle>{poseTitle}</IonTitle>
          <IonButtons slot="end">
            <IconButton
              aria-label="Open Settings"
              icon={<Icon as={SettingsIcon} boxSize={8} />}
              onClick={() => setIsSettingsOpen(true)}
              marginInline={2}
              variant="ghost"
              borderRadius="full"
            />
            <SettingsModal
              isOpen={isSettingsOpen}
              onClose={() => setIsSettingsOpen(false)}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Flex
          direction={isLandscape ? "row" : "column"}
          height="100%"
          backgroundColor={
            isInPoseState ? poseStateBackgroundColor : "transparent"
          }
          transition="background-color 0.3s ease"
        >
          <Box
            position="relative"
            flexGrow={1}
            height="100%"
            width={isLandscape ? "70%" : "100%"}
          >
            {isViewActive ? (
              <PoseDetector
                id="pose-detector"
                detectingPoseId={detectingPoseId}
                setIsInPoseState={setIsInPoseState}
                setCurrentPoseDuration={updateDuration}
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                backgroundColor="transparent"
              />
            ) : null}
            {!isLandscape && (
              <Flex
                direction="column"
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                justifyContent="flex-end"
                padding={3}
              >
                <PoseDurationDisplay
                  ref={durationRef}
                  isInPoseState={isInPoseState}
                />
                {isCurrentPoseAggregateSummaryVisible ? (
                  <CurrentPoseAggregateSummary
                    aggregateDurations={aggregateDurations?.[detectingPoseId]}
                    aggregateLoading={aggregateLoading}
                    aggregateError={aggregateError}
                  />
                ) : null}
                {isCurrentPoseHistoryVisible &&
                poseEvents &&
                detectingPoseId ? (
                  <CurrentPoseHistory
                    currentPoseId={detectingPoseId}
                    events={poseEvents}
                  />
                ) : null}
              </Flex>
            )}
          </Box>
          {isLandscape && (
            <Flex
              direction="column"
              width="30%"
              height="100%"
              justifyContent="flex-end"
              padding={3}
            >
              <PoseDurationDisplay
                ref={durationRef}
                isInPoseState={isInPoseState}
              />
              {isCurrentPoseAggregateSummaryVisible ? (
                <CurrentPoseAggregateSummary
                  aggregateDurations={aggregateDurations?.[detectingPoseId]}
                  aggregateLoading={aggregateLoading}
                  aggregateError={aggregateError}
                />
              ) : null}
              {isCurrentPoseHistoryVisible && poseEvents && detectingPoseId ? (
                <CurrentPoseHistory
                  currentPoseId={detectingPoseId}
                  events={poseEvents}
                />
              ) : null}
            </Flex>
          )}
        </Flex>
      </IonContent>
    </IonPage>
  );
};
PosePage.displayName = "PosePage";
export default PosePage;
