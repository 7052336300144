import React from "react";
import { IonList, IonItem, IonIcon, IonLabel, IonButton } from "@ionic/react";
import {
  mailOutline,
  phonePortraitOutline,
  logoGoogle,
  logoFacebook,
  logoApple,
} from "ionicons/icons";
import { UserInfo } from "firebase/auth";
import { ProviderId } from "@capacitor-firebase/authentication";

interface AccountIdentityProvidersProps {
  providerData: UserInfo[];
  isUnlinkVisible?: boolean;
  onUnlink?: (providerId: ProviderId) => void;
}

const AccountIdentityProviders: React.FC<AccountIdentityProvidersProps> = ({
  providerData,
  isUnlinkVisible = false,
  onUnlink,
}) => {
  function getProviderIcon(providerId: string) {
    switch (providerId) {
      case "password":
        return mailOutline;
      case "phone":
        return phonePortraitOutline;
      case "google.com":
        return logoGoogle;
      case "apple.com":
        return logoApple;
      case "facebook.com":
        return logoFacebook;
      default:
        return mailOutline;
    }
  }

  function getProviderName(providerId: string): string {
    switch (providerId) {
      case "password":
        return "Email/Password";
      case "phone":
        return "Phone";
      case "google.com":
        return "Google";
      case "apple.com":
        return "Apple";
      case "facebook.com":
        return "Facebook";
      default:
        return providerId;
    }
  }

  function getProviderIdentifier(provider: UserInfo): string {
    if (provider.email) {
      return provider.email;
    } else if (provider.phoneNumber) {
      return provider.phoneNumber;
    } else if (provider.displayName) {
      return provider.displayName;
    } else {
      return "No identifier available";
    }
  }

  return (
    <IonList>
      {providerData.map((provider) => (
        <IonItem key={provider.providerId}>
          <IonIcon icon={getProviderIcon(provider.providerId)} slot="start" />
          <IonLabel>
            <h2>{getProviderName(provider.providerId)}</h2>
            <p>{getProviderIdentifier(provider)}</p>
          </IonLabel>
          {isUnlinkVisible && onUnlink && (
            <IonButton
              slot="end"
              onClick={() => onUnlink(provider.providerId as ProviderId)}
              disabled={providerData.length === 1}
            >
              Unlink
            </IonButton>
          )}
        </IonItem>
      ))}
    </IonList>
  );
};

export default AccountIdentityProviders;
