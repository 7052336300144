import React, { ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class DetailedErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error: error,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Add more context
    console.error("Error occurred in component:", {
      error: error,
      componentStack: errorInfo.componentStack,
      location: window.location.href,
      time: new Date().toISOString(),
    });

    // Add null check before accessing componentStack
    if (errorInfo.componentStack) {
      console.log(
        "Current component tree:",
        errorInfo.componentStack
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line.length > 0)
      );
    }

    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: "20px" }}>
          <h1>Something went wrong</h1>
          <details style={{ whiteSpace: "pre-wrap" }}>
            <summary>Error Details</summary>
            <p>
              <strong>Error:</strong>{" "}
              {this.state.error && this.state.error.toString()}
            </p>
            <p>
              <strong>Component Stack:</strong>
            </p>
            <pre>
              {this.state.errorInfo &&
                this.state.errorInfo.componentStack &&
                this.state.errorInfo.componentStack
                  .split("\n")
                  .map((line, i) => <div key={i}>{line}</div>)}
            </pre>
            <p>
              <strong>Location:</strong> {window.location.href}
            </p>
            <p>
              <strong>Time:</strong> {new Date().toISOString()}
            </p>
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default DetailedErrorBoundary;
