import { useCallback, useRef } from "react";

export const usePoseDuration = () => {
  const durationRef = useRef<HTMLSpanElement>(null);

  const updateDuration = useCallback((duration: number) => {
    if (durationRef.current) {
      durationRef.current.textContent = `${(duration / 1000).toFixed(1)} s`;
    }
  }, []);

  return { durationRef, updateDuration };
};
