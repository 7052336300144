import { DarkMode, Fade, Flex, Spinner, Text } from "@chakra-ui/react";
import { CameraPermissionPopover } from "./CameraPermissionPopover";
import { useCameraPermissionDetector } from "./useCameraPermissionDetector";

export const ModelLoadingControls: React.FC<{
  loadingStatus?: string;
  poseLandmarkerError?: Error;
  poseLandmarkerInitializationSlow?: boolean;
}> = ({
  loadingStatus,
  poseLandmarkerError,
  poseLandmarkerInitializationSlow,
}) => {
  const cameraPermission = useCameraPermissionDetector();

  console.log("render ModelLoadingControls", {
    cameraPermission,
    loadingStatus,
    poseLandmarkerError,
    poseLandmarkerInitializationSlow,
  });
  return (
    <Fade
      in={
        loadingStatus === "loading" &&
        !poseLandmarkerError &&
        cameraPermission !== "denied"
      }
      unmountOnExit
      transition={{ enter: { duration: 1 }, exit: { duration: 1 } }}
    >
      <Flex
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        alignItems="center"
        justifyContent="center"
        color="white"
        backgroundColor="rgba(0,0,0,0.4)"
      >
        <Flex direction="column" position="relative" gap={3}>
          <Flex
            position="relative"
            alignItems="center"
            justifyContent="center"
            height="120px"
          >
            <DarkMode>
              {cameraPermission !== "denied" && (
                <Spinner boxSize="160px" position="absolute" />
              )}
              <Flex
                direction="column"
                alignItems="center"
                position="absolute"
                borderRadius={5}
                p={1}
              >
                <Flex flexWrap="wrap" textAlign="center">
                  {cameraPermission === "denied" ||
                  cameraPermission === "prompt" ? (
                    <CameraPermissionPopover
                      cameraPermission={cameraPermission}
                    />
                  ) : (
                    <Text>
                      {loadingStatus === "loaded" ? "Loaded" : "Loading..."}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </DarkMode>
          </Flex>
          <Fade
            in={poseLandmarkerInitializationSlow}
            unmountOnExit
            transition={{ enter: { duration: 1 }, exit: { duration: 1 } }}
          >
            <Flex background="rgba(0,0,0,.4)" borderRadius={5} p={1}>
              <Text>Sorry, loading is taking longer than expected.</Text>
            </Flex>
          </Fade>
        </Flex>
      </Flex>
    </Fade>
  );
};
