import { useEffect, useRef, useState } from "react";
import { CriterionForEvaluator } from "./PoseDefinitionInterpreter";

export enum ThresholdOperator {
  GreaterThan = ">",
  LessThan = "<",
}
export type SideFeedbackItem = {
  /** Current value of the criteria. */
  value: number;
  /** Whether the criteria is currently met. */
  isMet: boolean;
  visibility: number;
};

export type PoseDetectionFeedbackItem = {
  /** The criterion agains which this feedback item was evaluated. */
  criterion: CriterionForEvaluator;
  /** Minimum possible value */
  min: number;
  /** Maximum possible value */
  max: number;
  /** Threshold at which the criteria will be considered met when not yet in the pose. */
  startThreshold: number;
  /** Threshold at which the criteria will no longer be considered met. Applicable while in the pose. */
  endThreshold: number;
  /** Operator to use when comparing the value to the threshold. */
  thresholdOperator: ThresholdOperator;
  /** Details for whether the criteria is currently met for left and right sides */
  sides?: {
    [key in "left" | "right"]: SideFeedbackItem;
  };
  /** Current value of the criteria. */
  value: number;
  /** Whether the criteria is currently met. */
  isMet: boolean;
  visibilityThreshold: number;
  visibility: number;
  /** Callback function to format the value for display */
  format: (value: number) => string;
  activeLandmarkIndexes?: number[];
};

/**
 * Feedback to convey the criteria for a pose to be considered valid.
 * Each key is a criteria, such as a joint angle or a contraint of one landmark
 * relative to another, and each value provides details about the citeria and
 * whether the criteria is currently met.
 */
export type PoseDetectionFeedback = {
  [key: string]: PoseDetectionFeedbackItem;
};

type PoseDetectionFeedbackProps = {
  feedback: PoseDetectionFeedback;
} & React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

const textYOffset = 15;

type PoseDetectionFeedbackBarProps = {
  min: number;
  max: number;
  startThreshold: number;
  endThreshold: number;
  value: number;
  isMet: boolean;
  visibilityThreshold: number;
  visibilityValue: number;
  format: (value: number) => string;
};

const drawFeedbackBar = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  {
    min,
    max,
    startThreshold,
    endThreshold,
    value,
    isMet,
    visibilityThreshold,
    visibilityValue,
    format,
  }: PoseDetectionFeedbackBarProps
) => {
  const percentage =
    Math.min(1, Math.max(0, (value - min) / (max - min))) * width;
  const startThresholdPercentage =
    ((startThreshold - min) / (max - min)) * width;
  const endThresholdPercentage = ((endThreshold - min) / (max - min)) * width;
  const visibilityThresholdPercentage = visibilityThreshold * width;
  const visibilityPercentage = visibilityValue * width;

  // use the visibilityValue to determine the opacity of the bar
  ctx.globalAlpha = visibilityValue;
  ctx.fillStyle = "blue";
  ctx.fillRect(x, y, endThresholdPercentage, height);

  ctx.fillStyle = isMet ? "#995555" : "gray";
  ctx.fillRect(x, y, percentage, height);
  // ctx.fillStyle = isMet ? "green" : "gray";
  // const tickWidth = 10;
  // ctx.fillRect(x + percentage - tickWidth / 2, y, tickWidth, height);

  ctx.lineWidth = isMet ? 1 : 3;
  ctx.strokeStyle = "black";
  ctx.strokeRect(
    x + endThresholdPercentage,
    y + 1,
    Math.abs(startThresholdPercentage - endThresholdPercentage),
    height - 2
  );

  ctx.fillStyle = visibilityValue >= visibilityThreshold ? "yellow" : "gray";
  ctx.fillRect(x, y + height - 3, visibilityPercentage, 3);

  ctx.globalAlpha = 1;
  ctx.fillStyle = "white";
  const valueFormatted = format(value);
  ctx.fillText(
    valueFormatted,
    x + width - ctx.measureText(valueFormatted).width,
    y + textYOffset
  );
};

type FeedbackItemProps = {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  width: number;
  height: number;
  key: string;
  item: PoseDetectionFeedbackItem;
};

/**
 * Draw a group of bars for a feedback item (generally one or three bars).
 */
const drawFeedbackItem = ({
  ctx,
  x,
  y,
  width,
  height,
  key,
  item,
}: FeedbackItemProps): number => {
  const leftPadding = 20; // Add some left padding
  drawFeedbackBar(ctx, x + leftPadding, y, width - leftPadding, height, {
    visibilityValue: item.visibility,
    ...item,
  });

  ctx.globalAlpha = 1;

  ctx.font = "bold 16px Arial";

  // Draw the key text
  ctx.fillStyle = item.isMet ? "#cccccc" : "white";
  ctx.fillText(key, x + leftPadding, y + textYOffset); // Adjust the x position

  // Draw the check icon if isMet is true, otherwise draw an "x" mark
  if (item.isMet) {
    ctx.fillText(
      "✔",
      x + leftPadding + ctx.measureText(key).width + 10,
      y + textYOffset
    );
  }

  let totalHeight = height;
  const barGap = 2;

  // If sides property exists, draw additional bars for each side
  if (item.sides) {
    let sideY = y + height + barGap; // Start position for side bars
    const sideHeight = 20; // Height of side bars

    for (const [sideKey, sideValue] of Object.entries(item.sides)) {
      drawFeedbackBar(
        ctx,
        x + leftPadding,
        sideY,
        width - leftPadding,
        sideHeight,
        {
          ...item,
          value: sideValue.value,
          isMet: sideValue.isMet,
          visibilityValue: sideValue.visibility,
        }
      );

      ctx.globalAlpha = 1;

      // Draw the side key text
      ctx.fillStyle = sideValue.isMet ? "#cccccc" : "white";
      const sideLabelText = sideKey.substring(0, 1).toUpperCase();
      ctx.fillText(sideLabelText, x + leftPadding, sideY + textYOffset); // Adjust the x position

      // Draw the check icon if side isMet is true, otherwise draw an "x" mark
      if (sideValue.isMet) {
        ctx.fillText(
          "✔",
          x + leftPadding + ctx.measureText(sideLabelText).width + 10,
          sideY + textYOffset
        );
      }

      sideY += sideHeight + barGap;
      totalHeight += sideHeight + barGap;
    }
  }

  // Draw the check icon if isMet is true, otherwise draw an "x" mark
  if (!item.isMet) {
    ctx.fillStyle = "white";
    ctx.fillRect(x + leftPadding - 6, y, 3, totalHeight);
    ctx.fillText("x", x, y + totalHeight / 2);
  }

  return totalHeight + 10;
};

/**
 * Component to render feedback items as a horizontal bar graph.
 * Useful when calibrating pose detection criteria.
 */
const PoseDetectionFeedbackComponent = ({
  feedback,
  ...props
}: PoseDetectionFeedbackProps) => {
  // console.log("PoseDetectionFeedbackComponent", feedback);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [contentHeight, setContentHeight] = useState(window.innerHeight);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Set the canvas dimensions to match the window's dimensions
    canvas.width = window.innerWidth;
    canvas.height = contentHeight;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    let y = 0;
    const barHeight = 20;
    const barWidth = canvas.width;

    for (const [key, value] of Object.entries(feedback)) {
      const itemHeight = drawFeedbackItem({
        ctx,
        x: 0,
        y,
        width: barWidth,
        height: barHeight,
        key,
        item: value,
      });

      y += itemHeight;
    }
    // canvas.height = y;
    setContentHeight(y);
  }, [feedback]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: props.width || "100%",
        height: `${contentHeight}px`,
        maxHeight: "100%",
      }}
      {...props}
    />
  );
};

export default PoseDetectionFeedbackComponent;
