import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Spinner,
  Switch,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IonItem, IonLabel, useIonRouter } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { useAuthContext } from "../login/useAuthContext";
import { useSubscriptions } from "./SubscriptionContext";
import { waitlistTags } from "./waitlistTags";
import { useState, useEffect, useCallback } from "react";
import { FirebaseError } from "firebase/app";

const SubscribeForm = () => {
  const { currentTags, isLoading, handleCurrentTagToggle } = useSubscriptions();
  const { user, signOut } = useAuthContext();
  const history = useHistory();
  const router = useIonRouter();
  const location = useLocation();

  const [isVerificationPending, setIsVerificationPending] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState<string>();
  const [isReloadingUser, setIsReloadingUser] = useState(false);

  useEffect(() => {
    const state = location.state as {
      isVerificationPending?: boolean;
      updatedEmail?: string;
    };
    if (state?.isVerificationPending) {
      setIsVerificationPending(true);
      setUpdatedEmail(state.updatedEmail);
    }
  }, [location]);

  const toast = useToast();

  const checkVerificationStatus = useCallback(async () => {
    if (isVerificationPending) {
      setIsReloadingUser(true);
      try {
        await user?.reload();
        if (user?.email === updatedEmail) {
          setUpdatedEmail(undefined);
          setIsVerificationPending(false);
          toast({
            title: "Email verified successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        if (
          error instanceof FirebaseError &&
          error.code === "auth/user-token-expired"
        ) {
          await signOut();
          toast({
            title: "Session expired",
            description: "Please sign in again to continue.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          // Redirect to login page
          history.push("/login", { from: "/subscribe" });
        } else {
          // Handle other errors
          console.error("Error reloading user:", error);
          toast({
            title: "Error verifying email",
            description: "Please try again later.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } finally {
        setIsReloadingUser(false);
      }
    }
  }, [isVerificationPending, user, updatedEmail, toast, history, signOut]);

  const verificationColor = useColorModeValue("orange.700", "orange.100");

  return (
    <Box m={5}>
      <Heading size="lg" mb={4}>
        Join the Move Journey Waitlist
      </Heading>
      <Flex direction="column" gap={3}>
        <Text>
          Join our waitlist to be the first to hear about the release of the
          Move Journey app and join our community of movers. Move Journey will
          help support your movement practice with challenges and workouts
          enabled through pose and movement detection. Capture and share photos
          from your practice automatically, and more!
        </Text>
        {user ? (
          <IonItem
            button
            detail={true}
            onClick={() => history.push("/account/edit-email")}
            className="profile-item"
          >
            <div className="label-value-container">
              <IonLabel>Email</IonLabel>
              <h4>{user?.email || "Not set"}</h4>
            </div>
          </IonItem>
        ) : null}
        {isVerificationPending ? (
          <HStack>
            <Text fontSize="sm" color={verificationColor}>
              Verification of <strong>{updatedEmail}</strong> pending
            </Text>
            <Button
              size="sm"
              onClick={checkVerificationStatus}
              isLoading={isReloadingUser}
            >
              Check now
            </Button>
          </HStack>
        ) : null}
        <Text mb={4}>
          Choose which updates you'd like to receive about Move Journey.
        </Text>
      </Flex>

      <Card maxWidth="600px" marginInline={[3, 5]}>
        <VStack spacing={4} align="stretch" p={4}>
          {user ? null : (
            <Button onClick={() => router.push("/login")} colorScheme="green">
              Sign In
            </Button>
          )}
          {waitlistTags.map((tag) => (
            <FormControl key={tag.id} display="flex" alignItems="center">
              <HStack spacing={2}>
                <Icon as={tag.icon} boxSize={5} />
                <FormLabel htmlFor={`tag-${tag.id}`} mb="0">
                  {tag.name}
                </FormLabel>
              </HStack>
              <HStack ml="auto" spacing={2}>
                {user && isLoading && <Spinner size="sm" />}
                <Switch
                  id={`tag-${tag.id}`}
                  isChecked={currentTags?.includes(tag.id)}
                  onChange={() => handleCurrentTagToggle(tag.id)}
                  isDisabled={isLoading || !user}
                />
              </HStack>
            </FormControl>
          ))}
        </VStack>
      </Card>
    </Box>
  );
};

export default SubscribeForm;
