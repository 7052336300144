import {
  Box,
  BoxProps,
  Flex,
  Icon,
  IconButton,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { MdCamera, MdImage, MdVideocam, MdVideoFile } from "react-icons/md";
import { ControlsExpander } from "../common/ControlsExpander";
import { SettingsContext } from "../settings/SettingsContext";
import MediaPicker, { MediaFile } from "./MediaPicker";
import VideoDevicePicker, { SourceType } from "./VideoDevicePicker";

type Props = {
  forceOpen?: boolean;
  videoDeviceId: string | undefined;
  setVideoDeviceId: (
    deviceId: string | undefined,
    type: SourceType,
    isBackFacing?: boolean
  ) => void;
  selectedMedia: MediaFile | null;
  onMediaSelected: (file: MediaFile | null) => void;
} & BoxProps;

const SourcePicker: React.FC<Props> = ({
  forceOpen,
  videoDeviceId,
  setVideoDeviceId,
  selectedMedia,
  onMediaSelected,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: forceOpen,
  });
  const mainRef = React.useRef<HTMLDivElement>(null);
  const { overrideSourceTypeIcon } = useContext(SettingsContext);

  const sourceType = useMemo(() => {
    if (overrideSourceTypeIcon) return "webcam";
    if (selectedMedia) {
      return selectedMedia.mimeType.startsWith("image/") ? "image" : "video";
    }
    return "webcam";
  }, [overrideSourceTypeIcon, selectedMedia]);

  useOutsideClick({
    ref: mainRef,
    handler: () => !forceOpen && onClose(),
  });

  const toggleExpand = () => {
    isOpen ? onClose() : onOpen();
  };

  const handleMediaSelected = (file: MediaFile) => {
    console.log("handleMediaSelected");
    onMediaSelected(file);
    !forceOpen && onClose();
  };

  const handleVideoDeviceChange = (
    deviceId: string | undefined,
    type: SourceType,
    isBackFacing?: boolean
  ) => {
    console.log("handleVideoDeviceChange");
    setVideoDeviceId(deviceId, type, isBackFacing);
    onMediaSelected(null);
    !forceOpen && onClose();
  };

  const sourceIcon = useMemo(() => {
    switch (sourceType) {
      case "webcam":
        return MdVideocam;
      case "video":
        return MdVideoFile;
      case "image":
        return MdImage;
      default:
        return MdCamera;
    }
  }, [sourceType]);

  console.log("render SourcePicker");
  return (
    <Box ref={mainRef} display="flex" alignItems="top" {...props}>
      <IconButton
        borderRadius="full"
        variant="ghost"
        color="white"
        aria-label="Select source"
        icon={<Icon as={sourceIcon} boxSize={8} />}
        onClick={toggleExpand}
      />
      <ControlsExpander expanded={isOpen}>
        <Flex>
          <VideoDevicePicker
            videoDeviceId={videoDeviceId}
            setVideoDeviceId={handleVideoDeviceChange}
          />
          <MediaPicker
            onMediaSelected={handleMediaSelected}
            onError={(error) => console.error("Media selection error:", error)}
          />
        </Flex>
      </ControlsExpander>
    </Box>
  );
};

export default SourcePicker;
