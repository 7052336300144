import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

const usePageTracking = () => {
  const analytics = getAnalytics();
  const location = useLocation();

  useEffect(() => {
    const pageViewParams = {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    };
    logEvent(analytics, "page_view", pageViewParams);
  }, [location]);
};

export default usePageTracking;
