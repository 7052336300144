export const POSE_LANDMARKS = {
  NOSE: 0,
  RIGHT_EYE_INNER: 4,
  RIGHT_EYE: 5,
  RIGHT_EYE_OUTER: 6,
  LEFT_EYE_INNER: 1,
  LEFT_EYE: 2,
  LEFT_EYE_OUTER: 3,
  RIGHT_EAR: 8,
  LEFT_EAR: 7,
  MOUTH_RIGHT: 10,
  MOUTH_LEFT: 9,
  RIGHT_SHOULDER: 12,
  LEFT_SHOULDER: 11,
  RIGHT_ELBOW: 14,
  LEFT_ELBOW: 13,
  RIGHT_WRIST: 16,
  LEFT_WRIST: 15,
  RIGHT_PINKY: 18,
  LEFT_PINKY: 17,
  RIGHT_INDEX: 20,
  LEFT_INDEX: 19,
  RIGHT_THUMB: 22,
  LEFT_THUMB: 21,
  RIGHT_HIP: 24,
  LEFT_HIP: 23,
  RIGHT_KNEE: 26,
  LEFT_KNEE: 25,
  RIGHT_ANKLE: 28,
  LEFT_ANKLE: 27,
  RIGHT_HEEL: 30,
  LEFT_HEEL: 29,
  RIGHT_FOOT_INDEX: 32,
  LEFT_FOOT_INDEX: 31,
};

export const POSE_LANDMARKS_LEFT = {
  LEFT_EYE_INNER: 1,
  LEFT_EYE: 2,
  LEFT_EYE_OUTER: 3,
  LEFT_EAR: 7,
  LEFT_MOUTH: 9,
  LEFT_SHOULDER: 11,
  LEFT_ELBOW: 13,
  LEFT_WRIST: 15,
  LEFT_PINKY: 17,
  LEFT_INDEX: 19,
  LEFT_THUMB: 21,
  LEFT_HIP: 23,
  LEFT_KNEE: 25,
  LEFT_ANKLE: 27,
  LEFT_HEEL: 29,
  LEFT_FOOT_INDEX: 31,
};

export const POSE_LANDMARKS_RIGHT = {
  RIGHT_EYE_INNER: 4,
  RIGHT_EYE: 5,
  RIGHT_EYE_OUTER: 6,
  RIGHT_EAR: 8,
  RIGHT_MOUTH: 10,
  RIGHT_SHOULDER: 12,
  RIGHT_ELBOW: 14,
  RIGHT_WRIST: 16,
  RIGHT_PINKY: 18,
  RIGHT_INDEX: 20,
  RIGHT_THUMB: 22,
  RIGHT_HIP: 24,
  RIGHT_KNEE: 26,
  RIGHT_ANKLE: 28,
  RIGHT_HEEL: 30,
  RIGHT_FOOT_INDEX: 32,
};

export const POSE_LANDMARKS_NEUTRAL = {
  NOSE: 0,
};

declare interface Connection {
  start: number;
  end: number;
}

export const POSE_CONNECTIONS: Connection[] = [
  { start: POSE_LANDMARKS.NOSE, end: POSE_LANDMARKS.LEFT_EYE_INNER },
  { start: POSE_LANDMARKS.LEFT_EYE_INNER, end: POSE_LANDMARKS.LEFT_EYE },
  { start: POSE_LANDMARKS.LEFT_EYE, end: POSE_LANDMARKS.LEFT_EYE_OUTER },
  { start: POSE_LANDMARKS.LEFT_EYE_OUTER, end: POSE_LANDMARKS.LEFT_EAR },
  { start: POSE_LANDMARKS.NOSE, end: POSE_LANDMARKS.RIGHT_EYE_INNER },
  { start: POSE_LANDMARKS.RIGHT_EYE_INNER, end: POSE_LANDMARKS.RIGHT_EYE },
  { start: POSE_LANDMARKS.RIGHT_EYE, end: POSE_LANDMARKS.RIGHT_EYE_OUTER },
  { start: POSE_LANDMARKS.RIGHT_EYE_OUTER, end: POSE_LANDMARKS.RIGHT_EAR },
  { start: POSE_LANDMARKS.MOUTH_LEFT, end: POSE_LANDMARKS.MOUTH_RIGHT },
  { start: POSE_LANDMARKS.LEFT_SHOULDER, end: POSE_LANDMARKS.RIGHT_SHOULDER },
  { start: POSE_LANDMARKS.LEFT_SHOULDER, end: POSE_LANDMARKS.LEFT_ELBOW },
  { start: POSE_LANDMARKS.LEFT_ELBOW, end: POSE_LANDMARKS.LEFT_WRIST },
  { start: POSE_LANDMARKS.LEFT_WRIST, end: POSE_LANDMARKS.LEFT_PINKY },
  { start: POSE_LANDMARKS.LEFT_WRIST, end: POSE_LANDMARKS.LEFT_INDEX },
  { start: POSE_LANDMARKS.LEFT_WRIST, end: POSE_LANDMARKS.LEFT_THUMB },
  { start: POSE_LANDMARKS.LEFT_PINKY, end: POSE_LANDMARKS.LEFT_INDEX },
  { start: POSE_LANDMARKS.RIGHT_SHOULDER, end: POSE_LANDMARKS.RIGHT_ELBOW },
  { start: POSE_LANDMARKS.RIGHT_ELBOW, end: POSE_LANDMARKS.RIGHT_WRIST },
  { start: POSE_LANDMARKS.RIGHT_WRIST, end: POSE_LANDMARKS.RIGHT_PINKY },
  { start: POSE_LANDMARKS.RIGHT_WRIST, end: POSE_LANDMARKS.RIGHT_INDEX },
  { start: POSE_LANDMARKS.RIGHT_WRIST, end: POSE_LANDMARKS.RIGHT_THUMB },
  { start: POSE_LANDMARKS.RIGHT_PINKY, end: POSE_LANDMARKS.RIGHT_INDEX },
  { start: POSE_LANDMARKS.LEFT_SHOULDER, end: POSE_LANDMARKS.LEFT_HIP },
  { start: POSE_LANDMARKS.RIGHT_SHOULDER, end: POSE_LANDMARKS.RIGHT_HIP },
  { start: POSE_LANDMARKS.LEFT_HIP, end: POSE_LANDMARKS.RIGHT_HIP },
  { start: POSE_LANDMARKS.LEFT_HIP, end: POSE_LANDMARKS.LEFT_KNEE },
  { start: POSE_LANDMARKS.RIGHT_HIP, end: POSE_LANDMARKS.RIGHT_KNEE },
  { start: POSE_LANDMARKS.LEFT_KNEE, end: POSE_LANDMARKS.LEFT_ANKLE },
  { start: POSE_LANDMARKS.RIGHT_KNEE, end: POSE_LANDMARKS.RIGHT_ANKLE },
  { start: POSE_LANDMARKS.LEFT_ANKLE, end: POSE_LANDMARKS.LEFT_HEEL },
  { start: POSE_LANDMARKS.RIGHT_ANKLE, end: POSE_LANDMARKS.RIGHT_HEEL },
  { start: POSE_LANDMARKS.LEFT_HEEL, end: POSE_LANDMARKS.LEFT_FOOT_INDEX },
  { start: POSE_LANDMARKS.RIGHT_HEEL, end: POSE_LANDMARKS.RIGHT_FOOT_INDEX },
  { start: POSE_LANDMARKS.LEFT_ANKLE, end: POSE_LANDMARKS.LEFT_FOOT_INDEX },
  { start: POSE_LANDMARKS.RIGHT_ANKLE, end: POSE_LANDMARKS.RIGHT_FOOT_INDEX },
];
