import {
  forwardRef,
  Image,
  ImageProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { getPoseDefinition } from "../generated/PoseDefinitionLoader";
import { Pose, poses } from "../types";

type PoseIconProps = {
  pose: Pose;
} & ImageProps;

const PoseIcon = forwardRef<PoseIconProps, "img">(({ pose, ...props }, ref) => {
  // load the pose from the pose definition loader
  const poseDefinition = useMemo(() => {
    if (!pose || !poses.includes(pose)) {
      return null;
    }
    return getPoseDefinition(pose);
  }, [pose]);
  const icon = useMemo(
    () => poseDefinition?.icons?.find((icon) => icon.type === "image/svg+xml"),
    [poseDefinition]
  );
  const iconColor = useColorModeValue("black", "white");

  // Apply a CSS filter to invert the colors if the theme is dark
  const filter = useMemo(() => {
    return iconColor === "white" ? "invert(1)" : "none";
  }, [iconColor]);

  if (!icon) {
    return null;
  }

  return (
    <Image
      ref={ref}
      src={icon.src}
      alt={`${pose} icon`}
      title={pose}
      filter={filter}
      height="40px"
      {...props}
    />
  );
});

export default PoseIcon;
