/// <reference types="vite-plugin-svgr/client" />
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonProgressBar,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { motion, useInView } from "framer-motion";
import { arrowDown } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTimeoutEffect } from "react-timing-hooks";
import { titleAndLabel } from "../components/common/titleAndLabel";
import CameraDemo from "../components/onboarding/CameraDemo";
import Collage from "../components/onboarding/Collage";
import { IntroductionToHandstandTimer } from "../components/onboarding/IntroductionToHandstandTimer";
import WomanPlacingPhone from "../images/woman-placing-phone.svg?react";
import { PosePicker } from "../components/PosePicker";
import { SettingsContext } from "../components/settings/SettingsContext";
import { PoseIds } from "../components/types";

interface StepProps {
  label: string;
  heading: string;
  content: JSX.Element;
  action: string;
}

const imageVariants = {
  hidden: { opacity: 0, scale: 1 },
  visible: { opacity: 1, scale: 1.2 },
};

const textVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const OnboardingUseApp: React.FC = () => {
  const { detectingPoseId, setDetectingPoseId } = useContext(SettingsContext);
  return (
    <Flex direction="column" gap={3}>
      <Text>
        Proceed to using Handstand Timer to detect the pose "{detectingPoseId}".
      </Text>
      <Text>Optionally, pick a different pose to detect.</Text>
      <FormControl>
        <PosePicker
          poseValue={detectingPoseId}
          onPoseChange={(poseId) => setDetectingPoseId(poseId as PoseIds)}
        />
      </FormControl>
    </Flex>
  );
};

const Onboarding = ({}: {}) => {
  const steps: StepProps[] = [
    {
      label: "Welcome",
      heading: "Welcome to Handstand Timer!",
      content: (
        <Flex direction="column" gap={3} alignItems="center">
          <Collage />
          <motion.div
            initial="hidden"
            animate="visible"
            variants={textVariants}
            transition={{ delay: 1, duration: 2 }}
          >
            <Text>
              Hi! I'm Scott, the creator of Handstand Timer. I am a software
              engineer turned solo entrepreneur, and I made this app to solve
              one specific problem: making it easier to measure and record the
              time you spend in a handstand. Handstand Timer is also great for
              timing various other poses and it takes direct inspiration from my
              own practice with handbalancing, calisthenics, and yoga. I'm
              excited to take the experience I have had as a software engineer
              at Fitbit and Google, and provide you with a tool that can help
              you in your movement practice!
            </Text>
          </motion.div>
        </Flex>
      ),
      action: "Continue",
    },
    {
      label: "Features",
      heading: "Introduction to Features",
      content: <IntroductionToHandstandTimer />,
      action: "Continue to How-To",
    },
    {
      label: "Camera How-To",
      heading: "How to Use the Camera Feature",
      content: (
        <Flex
          width="100%"
          borderRadius={5}
          overflow="hidden"
          direction="column"
          gap={3}
        >
          <WomanPlacingPhone width="100%" height="100%" />
          <UnorderedList spacing={3} ml={5}>
            <ListItem>
              <Text>
                <b>Step 1:</b> Find a space where you can prop up your device
                and then step back so the camera can see your whole body. If you
                don't have a good space for this, you may need to get creative
                or try again later.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <b>Step 2:</b> Prop your device against a wall (or use a stand)
                on a flat surface, such as the floor or table. If the surface is
                slippery, your may need to put something under the device to
                keep it propped at a good angle and prevent it from sliding.
              </Text>
            </ListItem>
            <ListItem>
              <Flex direction="column" gap={3}>
                <Text>
                  <b>Step 3:</b> Advance to the next step to activate the
                  camera. Note that for pose detection to work, you will need to
                  allow Handstand Timer to access your camera.
                </Text>
                <Alert status="info">
                  <AlertIcon />
                  Handstand Timer does not transmit your video or images. Pose
                  detection is performed locally on your device. We do not
                  capture or store your videos or images.
                </Alert>
              </Flex>
            </ListItem>
            <ListItem>
              <Text>
                <b>Step 4:</b> Position yourself in front of the device so that
                your entire body is visible.
              </Text>
            </ListItem>
          </UnorderedList>
        </Flex>
      ),
      action: "Activate Camera",
    },
    {
      label: "Camera Demo",
      heading: "Put a Hand Up!",
      content: <CameraDemo />,
      action: "Continue",
    },
    {
      label: "Use the App",
      heading: "Enjoy!",
      content: <OnboardingUseApp />,
      action: "Continue",
    },
  ];

  // "step" starts from 1, but the array index activeStep starts from 0
  const { step } = useParams<{ step: string }>();
  const [activeStep, setActiveStep] = useState(step ? Number(step) - 1 : 0);
  const activeStepText = steps[activeStep].label;
  const progress = ((activeStep + 1) / steps.length) * 100;
  const router = useIonRouter();

  const bottomRef = useRef<HTMLDivElement>(null);
  const bottomInView = useInView(bottomRef);
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    if (step) {
      setActiveStep(Number(step) - 1);
    }
    window.scrollTo(0, 0); // Scroll to top
  }, [step]);

  const handleNextStep = () => {
    ionContentRef.current?.scrollToTop(300); // 300ms animation duration, adjust as needed
    if (activeStep === steps.length - 1) {
      router.push("/pose");
    } else {
      router.push(`/onboarding/${activeStep + 2}`);
    }
  };

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [hasMusicPlayed, setHasMusicPlayed] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [isManuallyToggled, setIsManuallyToggled] = useState(false);

  useEffect(() => {
    if (audioRef.current && activeStep === 0 && !hasMusicPlayed) {
      audioRef.current.play().catch(() => {
        setIsPlaying(false);
        setIsOpen(false);
      });
      setHasMusicPlayed(true);
    }
  }, [activeStep, hasMusicPlayed]);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setIsManuallyToggled(true);
  };

  useTimeoutEffect(
    (timeout) => {
      if (!isManuallyToggled && isOpen) {
        timeout(() => {
          setIsOpen(false);
        }, 5000);
      }
    },
    [isOpen, isManuallyToggled]
  );

  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/pose" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton fill="outline" onClick={() => router.push("/pose")}>
              Skip
            </IonButton>
          </IonButtons>
          <IonTitle>
            Step {activeStep + 1}: <b>{activeStepText}</b>
          </IonTitle>
          <IonProgressBar value={progress / 100} />
        </IonToolbar>
      </IonHeader>
      <IonContent ref={ionContentRef} fullscreen={true}>
        <Flex direction="column" m={3} gap={1}>
          <Heading mt={0} size="md">
            {steps[activeStep].heading}
          </Heading>
          <Flex direction="column" flexGrow={1} gap={3} alignItems="center">
            <VStack
              alignItems="center"
              spacing={3}
              maxWidth="600px"
              width="100%"
            >
              {steps[activeStep].content}
            </VStack>
            <Box ref={bottomRef} height={1} />
            <Flex justifyContent="center">
              <Button colorScheme="blue" onClick={handleNextStep}>
                {steps[activeStep].action}
              </Button>
            </Flex>
          </Flex>
          <Fade in={!bottomInView}>
            <Flex
              position="fixed"
              left="0"
              bottom="20px"
              alignItems="center"
              justifyContent="center"
              width="100%"
              pointerEvents="none"
            >
              <Box
                position="relative"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => {
                  bottomRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
                pointerEvents={bottomInView ? "none" : "auto"}
              >
                <IconButton
                  {...titleAndLabel("Jump to bottom")}
                  rounded="full"
                  colorScheme="blue"
                  icon={<IonIcon icon={arrowDown} />}
                />
              </Box>
            </Flex>
          </Fade>
        </Flex>
      </IonContent>
    </>
  );
};
export default Onboarding;
