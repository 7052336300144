import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { updateProfile } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ButtonToolbar from "../common/ButtonToolbar";
import { useAuthContext } from "../login/useAuthContext";

const EditDisplayName: React.FC = () => {
  const { user, setUser } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName || "");
  const [originalDisplayName, setOriginalDisplayName] = useState(
    user?.displayName || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [present] = useIonToast();
  const history = useHistory();

  useEffect(() => {
    if (user?.displayName) {
      setDisplayName(user.displayName);
      setOriginalDisplayName(user.displayName);
    }
  }, [user]);

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setIsLoading(true);
    try {
      await updateProfile(user, { displayName });
      await user.reload();
      setUser(user);
      present({
        message: "Display name updated successfully",
        duration: 3000,
        color: "success",
      });
      history.goBack();
    } catch (error) {
      present({
        message: "Error updating display name",
        duration: 3000,
        color: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const isChanged = displayName !== originalDisplayName;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account/profile" />
          </IonButtons>
          <IonTitle>Edit Display Name</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form onSubmit={handleSave}>
          <IonItem>
            <IonLabel position="stacked">Display Name</IonLabel>
            <IonInput
              value={displayName}
              onIonInput={(e) => setDisplayName(e.detail.value || "")}
              placeholder="Enter display name"
            />
          </IonItem>
          <ButtonToolbar>
            <IonButton color="light" onClick={handleCancel} type="button">
              Cancel
            </IonButton>
            <IonButton
              strong={true}
              disabled={!isChanged || isLoading}
              type="submit"
            >
              Save
            </IonButton>
          </ButtonToolbar>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default EditDisplayName;
