import {
  Box,
  BoxProps,
  Flex,
  ResponsiveValue,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useReducer, useRef, useCallback } from "react";
import { FrameData } from "./useFrameData";

type FrameItem = {
  frameTime: number;
  frameComponents?: Record<string, number>;
};

/**
 * Represents the state of the FPS statistics.
 */
type FPSState = {
  /** Array of FPS values for recent frames */
  fps: number[];
  /** Number of frames processed in the current second */
  frames: number;
  /** Maximum FPS value observed */
  max: number;
  /** Current length of the fps array */
  len: number;
  /** Timestamp of the previous frame */
  prevTime: number;
  /** Average durations of frames within each interval (second) for recent intervals  */
  frameDurations: number[];
  /** Array of average frame component timings for recent intervals */
  frameComponentAverages: Record<string, number>[];
  /** Sum of component timings for the current second */
  componentSums?: Record<string, number>;
  /**  */
  frameItems: FrameItem[];
};

/**
 * Represents the actions that can be dispatched to update the FPS state.
 */
type FPSAction =
  | { type: "tick" }
  | {
      type: "frame";
      frameTime: number;
      frameComponents?: Record<string, number>;
    };

export type FPSStatsProps = {
  visualizeBreakdown?: boolean;
  graphHeight?: ResponsiveValue<number>;
  graphWidth?: ResponsiveValue<number>;
  barWidth?: ResponsiveValue<number>;
  getFrameData?: () => FrameData;
} & BoxProps;

/**
 * A component that displays real-time FPS (Frames Per Second) statistics.
 */
function FPSStats({
  visualizeBreakdown = false,
  graphHeight = 29,
  graphWidth = 70,
  barWidth = 1,
  getFrameData,
  ...props
}: FPSStatsProps) {
  const graphHeightValue =
    useBreakpointValue(
      typeof graphHeight === "object" ? graphHeight : { base: graphHeight }
    ) ?? 29;
  const graphWidthValue =
    useBreakpointValue(
      typeof graphWidth === "object" ? graphWidth : { base: graphWidth }
    ) ?? 70;
  const barWidthValue =
    useBreakpointValue(
      typeof barWidth === "object" ? barWidth : { base: barWidth }
    ) ?? 1;

  const [state, dispatch] = useReducer<React.Reducer<FPSState, FPSAction>>(
    (state, action) => {
      const currentTime =
        action.type === "frame" ? action.frameTime : Date.now();
      const newComponentSums = { ...state.componentSums };
      const newFrameItems: FrameItem[] =
        action.type === "frame"
          ? [
              ...state.frameItems.filter(
                (item) => item.frameTime > currentTime - 1000
              ),
              {
                frameTime: currentTime,
                frameComponents: action.frameComponents,
              },
            ]
          : [];

      if (action.type === "frame" && action.frameComponents) {
        // Add current frame components to sums
        Object.entries(action.frameComponents).forEach(([key, value]) => {
          newComponentSums[key] = (newComponentSums[key] || 0) + value;
        });
      }

      if (currentTime > state.prevTime + 1000) {
        const nextFPS = [
          ...new Array(
            Math.floor((currentTime - state.prevTime - 1000) / 1000)
          ).fill(0),
          Math.max(
            1,
            Math.round((state.frames * 1000) / (currentTime - state.prevTime))
          ),
        ];

        // Calculate average component values
        const averageComponents: Record<string, number> = {};
        Object.entries(newComponentSums).forEach(([key, sum]) => {
          averageComponents[key] = sum / state.frames;
        });
        // average frame duration for the last second
        const nextFrameDuration = (currentTime - state.prevTime) / state.frames;

        // console.log("tick", {
        //   fps: nextFPS[nextFPS.length - 1],
        //   currentTime,
        //   averageComponents,
        //   nextFrameDuration,
        // });
        return {
          max: Math.max(state.max, ...nextFPS),
          len: Math.min(state.len + nextFPS.length, graphWidthValue),
          fps: [...state.fps, ...nextFPS].slice(-graphWidthValue),
          frameDurations: [...state.frameDurations, nextFrameDuration].slice(
            -graphWidthValue
          ),
          frames: 1,
          prevTime: currentTime,
          frameComponentAverages: [
            ...state.frameComponentAverages,
            averageComponents,
          ].slice(-graphWidthValue),
          componentSums:
            action.type === "frame" ? action.frameComponents : undefined, // Reset sums for the new second
          frameItems: newFrameItems,
        };
      } else {
        if (currentTime === state.prevTime) {
          console.log("Duplicate frame detected");
          return state;
        }
        return {
          ...state,
          frames: state.frames + 1,
          componentSums: action.type === "frame" ? newComponentSums : undefined,
          frameItems: newFrameItems,
        };
      }
    },
    {
      len: 0,
      max: 0,
      frames: 0,
      prevTime: Date.now(),
      fps: [],
      frameDurations: [],
      frameComponentAverages: [],
      componentSums: {},
      frameItems: [],
    }
  );

  const requestRef = useRef<number>(0);

  const tick = useCallback(() => {
    if (getFrameData) {
      const { frameTime, frameComponents } = getFrameData();
      dispatch({ type: "frame", frameTime, frameComponents });
    } else {
      dispatch({ type: "tick" });
    }
  }, [getFrameData]);

  useEffect(() => {
    const animate = () => {
      tick();
      requestRef.current = requestAnimationFrame(animate);
    };
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [tick]);

  const { fps, max, len, frameItems, frameComponentAverages } = state;

  const renderHistoryGraph = useCallback(() => {
    if (getFrameData && frameComponentAverages.length > 0) {
      const componentKeys = Object.keys(frameComponentAverages[0]);
      const maxDuration = Math.max(
        ...frameComponentAverages.map((frame) =>
          Object.values(frame).reduce(
            (sum, duration) =>
              sum + (duration >= 0 && duration <= 1000 ? duration : 0),
            0
          )
        )
      );

      return frameComponentAverages.map((frame, i) => (
        <div
          key={`frame-${i}`}
          style={{
            position: "absolute",
            bottom: 0,
            right: `${(len - 1 - i) * barWidthValue}px`,
            width: barWidthValue,
            height: graphHeightValue,
          }}
        >
          {componentKeys.map((key, j) => {
            const height = (graphHeightValue * (frame[key] || 0)) / maxDuration;
            const prevHeight = componentKeys
              .slice(0, j)
              .reduce(
                (sum, k) =>
                  sum + (graphHeightValue * (frame[k] || 0)) / maxDuration,
                0
              );
            return (
              <div
                key={`component-${i}-${j}`}
                style={{
                  position: "absolute",
                  bottom: prevHeight,
                  height: `${height}px`,
                  width: barWidthValue,
                  background: `hsl(${
                    (j * 360) / componentKeys.length
                  }, 100%, 50%)`,
                }}
              />
            );
          })}
        </div>
      ));
    } else {
      return fps.map((frame, i) => (
        <div
          key={`fps-${i}`}
          style={{
            position: "absolute",
            bottom: 0,
            right: `${(len - 1 - i) * barWidthValue}px`,
            height: `${(graphHeightValue * frame) / max}px`,
            width: barWidthValue,
            background: "#00ffff",
          }}
        />
      ));
    }
  }, [
    fps,
    frameComponentAverages,
    graphHeightValue,
    graphWidthValue,
    barWidthValue,
  ]);

  const renderIntervalGraph = useCallback(() => {
    if (
      getFrameData &&
      frameItems.length > 0 &&
      frameItems[0]?.frameComponents
    ) {
      const componentKeys = Object.keys(frameItems[0]?.frameComponents);
      const maxDuration = Math.max(
        ...frameItems.map((frameItem) =>
          frameItem.frameComponents
            ? Object.values(frameItem.frameComponents).reduce(
                (sum, duration) =>
                  sum + (duration >= 0 && duration <= 1000 ? duration : 0),
                0
              )
            : 0
        )
      );

      return frameItems.map((frameItem, i) => (
        <div
          key={`frame-${i}`}
          style={{
            position: "absolute",
            bottom: 0,
            left: `${i * barWidthValue}px`,
            width: barWidthValue,
            height: graphHeightValue,
          }}
        >
          {componentKeys.map((key, j) => {
            if (!frameItem.frameComponents) return null;
            const height =
              (graphHeightValue * (frameItem.frameComponents[key] || 0)) /
              maxDuration;
            const prevHeight = componentKeys
              .slice(0, j)
              .reduce(
                (sum, k) =>
                  sum +
                  (graphHeightValue * (frameItem.frameComponents?.[k] || 0)) /
                    maxDuration,
                0
              );
            return (
              <div
                key={`component-${i}-${j}`}
                style={{
                  position: "absolute",
                  bottom: prevHeight,
                  height: `${height}px`,
                  width: barWidthValue,
                  background: `hsl(${
                    (j * 360) / componentKeys.length
                  }, 100%, 50%)`,
                }}
              />
            );
          })}
        </div>
      ));
    } else {
      return null;
    }
  }, [frameItems, graphHeightValue, graphWidthValue, barWidthValue]);

  return (
    <Box
      zIndex={999999}
      height="46px"
      width={`${
        graphWidthValue * barWidthValue +
        8 +
        (visualizeBreakdown ? 64 * barWidthValue : 0)
      }px`}
      padding={1}
      backgroundColor="#000"
      fontSize="9px"
      lineHeight="10px"
      fontFamily="Helvetica, Arial, sans-serif"
      fontWeight="bold"
      boxSizing="border-box"
      pointerEvents="none"
      {...props}
    >
      <Text>{fps[len - 1] || 0} FPS</Text>
      <Flex position="relative" height={`${graphHeightValue}px`}>
        <div
          style={{
            position: "absolute",
            height: graphHeightValue,
            width: graphWidthValue * barWidthValue,
            background: "#282844",
            boxSizing: "border-box",
            overflow: "hidden",
          }}
        >
          {renderHistoryGraph()}
        </div>
        {visualizeBreakdown ? (
          <>
            <Box
              background="yellow"
              position="absolute"
              top="-1px"
              left={`${graphWidthValue * barWidthValue}px`}
              width={barWidthValue}
              height={`${graphHeightValue + 2}px`}
            />
            <div
              style={{
                position: "absolute",
                left: (graphWidthValue + 4) * barWidthValue,
                height: graphHeightValue,
                width: frameItems.length * barWidthValue,
                background: "#333333",
                boxSizing: "border-box",
                overflow: "hidden",
              }}
            >
              {renderIntervalGraph()}
            </div>
          </>
        ) : null}
      </Flex>
    </Box>
  );
}

export default FPSStats;
