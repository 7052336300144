import React from "react";
import { Settings, defaultSettings } from "./settingsConfig";

type SetterFunctions = {
  [K in keyof Settings as `set${Capitalize<string & K>}`]: (
    value: Settings[K]
  ) => void;
};

export type SettingsContextType = Settings &
  SetterFunctions & {
    resetAllSettings: () => void;
  };

const createDefaultSetters = () => {
  const setters: Partial<SetterFunctions> = {};
  for (const key in defaultSettings) {
    setters[
      `set${
        key.charAt(0).toUpperCase() + key.slice(1)
      }` as keyof SetterFunctions
    ] = () => {};
  }
  return setters as SetterFunctions;
};

export const SettingsContext = React.createContext<SettingsContextType>({
  ...defaultSettings,
  ...createDefaultSetters(),
  resetAllSettings: () => {},
});
