import {
  Box,
  BoxProps,
  Fade,
  Flex,
  Icon,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdPause, MdPlayArrow } from "react-icons/md";
import CustomSlider from "./CustomSlider";

interface MediaFile {
  path?: string;
  blob?: Blob;
  mimeType: string;
}

type VideoScrubberProps = {
  media: MediaFile | null;
  onClose: () => void;
  onFrame: (
    input: HTMLVideoElement | HTMLImageElement,
    dimensions: { width: number; height: number }
  ) => void;
} & BoxProps;

const autoStart = true;
const loop = true;
const COOLDOWN_DURATION = 500;

const VideoScrubber: React.FC<VideoScrubberProps> = ({
  media,
  onClose,
  onFrame,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const isPlayingRef = useRef(isPlaying);
  isPlayingRef.current = isPlaying;
  const [duration, setDuration] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const isLoadedRef = useRef(isLoaded);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const currentTimeRef = useRef(0);
  const sliderRef = useRef<HTMLInputElement>(null);

  const isVideo = media?.mimeType.startsWith("video/");

  const [isVisible, setIsVisible] = useState(true);
  const isVisibleRef = useRef(isVisible);
  isVisibleRef.current = isVisible;
  const fadeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastVisibilityChangeRef = useRef<number>(0);

  const startFadeTimeout = () => {
    if (fadeTimeoutRef.current) {
      clearTimeout(fadeTimeoutRef.current);
    }
    fadeTimeoutRef.current = setTimeout(
      () => {
        setIsVisible(false);
      },
      isPlayingRef.current ? 1000 : 5000
    );
  };

  const isInCooldownPeriod = () => {
    return Date.now() - lastVisibilityChangeRef.current < COOLDOWN_DURATION;
  };

  const handleVisibilityChange = (newVisibility: boolean) => {
    if (isInCooldownPeriod()) return;

    setIsVisible(newVisibility);
    isVisibleRef.current = newVisibility; // Update ref immediately
    lastVisibilityChangeRef.current = Date.now();
    startFadeTimeout();
  };

  const handleMouseMove = () => {
    if (!isVisibleRef.current) {
      handleVisibilityChange(true);
    } else {
      startFadeTimeout();
    }
  };

  const handleClick = (e: MouseEvent) => {
    // Check if the click is on a control element
    const isControlClick = (e.target as HTMLElement).closest(
      'button, [role="slider"]'
    );
    console.log("handleClick", {
      isControlClick,
      "e.target": e.target,
      "isVisibleRef.current": isVisibleRef.current,
    });

    if (!isControlClick) {
      // Toggle visibility if it's not a control click
      handleVisibilityChange(!isVisibleRef.current);
    } else if (!isVisibleRef.current) {
      // For control clicks, ensure visibility if currently hidden
      handleVisibilityChange(true);
    } else {
      // If already visible, just restart the fade timeout
      startFadeTimeout();
    }
  };

  useEffect(() => {
    startFadeTimeout();

    // Add global event listeners
    document.addEventListener("click", handleClick);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      if (fadeTimeoutRef.current) {
        clearTimeout(fadeTimeoutRef.current);
      }
      // Remove global event listeners
      document.removeEventListener("click", handleClick);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isPlaying]);

  // Cancel any pending animation frames
  function cancelPendingAnimationFrame() {
    console.log("cancelPendingAnimationFrame");
    if (animationFrameRef.current !== null) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }
  }

  const forceLoadTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!media) return;

    // cleanup from previous media
    cancelPendingAnimationFrame();

    if (isVideo) {
      if (!videoRef.current) return;
      const video = videoRef.current;

      const setMediaSource = () => {
        console.log("setMediaSource", {
          media,
          "video.currentTime": video.currentTime,
        });
        if (media.path) {
          video.src = media.path;
        } else if (media.blob) {
          video.src = URL.createObjectURL(media.blob);
        }
      };

      setMediaSource();

      const updateDuration = () => setDuration(video.duration || 0);

      const handleLoadedMetadata = () => {
        console.log("loadedmetadata", { "video.src": video.src });
        const videoDimensions = {
          width: video.videoWidth,
          height: video.videoHeight,
        };
        setDimensions(videoDimensions);
        updateDuration();
      };

      const handleLoadedData = () => {
        console.log("loadeddata", { "video.src": video.src });
        if (
          !isLoadedRef.current &&
          video.videoWidth > 0 &&
          video.videoHeight > 0
        ) {
          forceLoadTimeoutRef.current &&
            clearTimeout(forceLoadTimeoutRef.current);
          setIsLoaded(true);
          if (autoStart) {
            console.log("loadeddata: autoStart");
            setIsPlaying(true);
          }
          onFrame(video, {
            width: video.videoWidth,
            height: video.videoHeight,
          });
        } else {
          console.log("loadeddata: no autoStart", {
            "isLoadedRef.current": isLoadedRef.current,
            "video.videoWidth": video.videoWidth,
            "video.videoHeight": video.videoHeight,
          });
        }
      };

      forceLoadTimeoutRef.current = setTimeout(() => {
        if (
          !isLoadedRef.current &&
          video.videoWidth > 0 &&
          video.videoHeight > 0
        ) {
          console.log("Forcing load state after timeout");
          handleVideoLoaded();
        }

        function handleVideoLoaded() {
          setIsLoaded(true);
          if (autoStart) {
            setIsPlaying(true);
          }
          onFrame(video, {
            width: video.videoWidth,
            height: video.videoHeight,
          });
        }
      }, 2000);

      video.addEventListener("loadedmetadata", handleLoadedMetadata);
      video.addEventListener("loadeddata", handleLoadedData);

      return () => {
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
        video.removeEventListener("loadeddata", handleLoadedData);
        if (media.blob) {
          URL.revokeObjectURL(video.src);
        }
        cancelPendingAnimationFrame();
        forceLoadTimeoutRef.current &&
          clearTimeout(forceLoadTimeoutRef.current);
      };
    } else {
      // Handle image
      if (!imageRef.current) return;
      const image = imageRef.current;

      const setImageSource = () => {
        if (media.path) {
          image.src = media.path;
        } else if (media.blob) {
          image.src = URL.createObjectURL(media.blob);
        }
      };

      setImageSource();

      const handleImageLoad = () => {
        if (!imageRef.current) return;
        const image = imageRef.current;
        setIsLoaded(true);
        setDimensions({
          width: image.naturalWidth,
          height: image.naturalHeight,
        });
        onFrame(image, {
          width: image.naturalWidth,
          height: image.naturalHeight,
        });
      };

      image.addEventListener("load", handleImageLoad);

      return () => {
        image.removeEventListener("load", handleImageLoad);
        if (media.blob) {
          URL.revokeObjectURL(image.src);
        }
        cancelPendingAnimationFrame();
      };
    }
  }, [media, isVideo]);

  const prevFrameTimeRef = useRef(0);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    if (!imageRef.current) return;
    const image = imageRef.current;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "c") {
        onFrame(image, {
          width: image.naturalWidth,
          height: image.naturalHeight,
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onFrame]);

  useEffect(() => {
    if (!isVideo || !isLoaded) return;

    if (!videoRef.current) return;

    const frameCallback = () => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        if (currentTime !== prevFrameTimeRef.current) {
          prevFrameTimeRef.current = currentTime;
          currentTimeRef.current = currentTime;
          if (sliderRef.current) {
            sliderRef.current.value = currentTime.toString();
            sliderRef.current.dispatchEvent(
              new Event("input", { bubbles: true })
            );
          }
          onFrame(videoRef.current, dimensions);
        }
      }
      if (isPlayingRef.current) {
        animationFrameRef.current = requestAnimationFrame(frameCallback);
      }
    };

    if (isPlaying) {
      console.log("calling .play()");
      videoRef.current.play();
      animationFrameRef.current = requestAnimationFrame(frameCallback);
    } else {
      videoRef.current.pause();
      cancelPendingAnimationFrame();
    }

    return cancelPendingAnimationFrame;
  }, [isPlaying, onFrame, isVideo, isLoaded, dimensions]);

  const togglePlayPause = () => {
    if (!videoRef.current) return;

    const newIsPlaying = !isPlaying;
    setIsPlaying(newIsPlaying);
    isPlayingRef.current = newIsPlaying;

    if (newIsPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
      cancelPendingAnimationFrame();
    }
  };

  const handleSliderChange = (value: number) => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = value;
    currentTimeRef.current = value;
    onFrame(videoRef.current, dimensions);
  };

  const handleClose = () => {
    console.log("handleClose");
    setIsPlaying(false);
    setIsLoaded(false);
    isPlayingRef.current = false;
    cancelPendingAnimationFrame();
    onClose();
  };

  useEffect(() => {
    return cancelPendingAnimationFrame;
  }, []);

  console.log("render VideoScrubber");
  return (
    <Fade in={isVisible}>
      <Box bg="rgba(0,0,0,0.5)" p={2} {...props}>
        <video
          key="video-player"
          ref={videoRef}
          style={{ display: "none" }}
          width="640px"
          height="480px"
          muted
          playsInline
          loop={loop}
        />
        <img
          key="image"
          ref={imageRef}
          style={{ display: "none" }}
          alt="Selected media"
        />
        <Flex align="center" direction="column">
          {!isLoaded && <Text color="white">Loading media...</Text>}
          <Flex align="center" width="100%">
            {isVideo && (
              <>
                <IconButton
                  aria-label={isPlaying ? "Pause" : "Play"}
                  icon={<Icon as={isPlaying ? MdPause : MdPlayArrow} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlayPause();
                  }}
                  size="sm"
                  mr={2}
                  isDisabled={!isLoaded}
                />
                <Box flex={1} mr={2}>
                  <CustomSlider
                    ref={sliderRef}
                    min={0}
                    max={duration}
                    onChange={handleSliderChange}
                    isDisabled={!isLoaded}
                  />
                </Box>
              </>
            )}
            <IconButton
              aria-label="Close"
              icon={<Icon as={MdClose} />}
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
              size="sm"
            />
          </Flex>
        </Flex>
      </Box>
    </Fade>
  );
};

export default React.memo(VideoScrubber);
