import {
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryConstraint,
  WithFieldValue,
  addDoc,
  collection,
  getAggregateFromServer,
  getDocs,
  getFirestore,
  orderBy,
  query,
  sum,
  where,
} from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { PoseEvent } from "../pose-events/PoseEvent";

export type { PoseEvent };
export type PoseEventData = Omit<PoseEvent, "id">;

function convertToFirestore<
  T extends PartialWithFieldValue<PoseEvent> | WithFieldValue<PoseEvent>
>(poseEvent: T, data: DocumentData) {
  // TODO: consider changing the type of startAt and endAt to Date
  // if (poseEvent.startAt && poseEvent.startAt instanceof Date) {
  //   data.startAt = poseEvent.startAt.getTime();
  // }
  // if (poseEvent.endAt && poseEvent.endAt instanceof Date) {
  //   data.endAt = poseEvent.endAt.getTime();
  // }
  return data;
}

const poseEventConverter: FirestoreDataConverter<PoseEvent> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data() as PoseEvent;
    // data.id = snapshot.id;
    // if (data.startAt) {
    //   data.startAt = new Date(data.startAt);
    // }
    // if (data.endAt) {
    //   data.endAt = new Date(data.endAt);
    // }
    return data;
  },
  toFirestore: (poseEvent) => {
    const { ...poseEventWithoutId } = poseEvent;
    return convertToFirestore(poseEvent, poseEventWithoutId);
  },
};

export class PoseEventsDao {
  firestore: Firestore;
  constructor() {
    this.firestore = getFirestore();
  }

  getPoseEventsCollection() {
    return collection(this.firestore, "poseEvents").withConverter(
      poseEventConverter
    );
  }

  async savePoseEvent(event: PoseEventData) {
    const poseEventsCollection = this.getPoseEventsCollection();
    await addDoc(poseEventsCollection, event);
  }

  async getPoseEvents(
    ownerId: string,
    poseId: string,
    ...constraints: QueryConstraint[]
  ) {
    const q = this.getQuery(ownerId, poseId, constraints);
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => doc.data());
  }

  getQuery(ownerId: string, poseId: string, constraints: QueryConstraint[]) {
    const poseEventsCollection = this.getPoseEventsCollection();
    return constraints.length
      ? query(
          poseEventsCollection,
          where("poseId", "==", poseId),
          where("ownerId", "==", ownerId),
          ...constraints
        )
      : query(
          poseEventsCollection,
          where("poseId", "==", poseId),
          where("ownerId", "==", ownerId),
          orderBy("startAt")
        );
  }

  getQueryAll(ownerId: string, constraints: QueryConstraint[]) {
    const poseEventsCollection = this.getPoseEventsCollection();
    return constraints.length
      ? query(
          poseEventsCollection,
          where("ownerId", "==", ownerId),
          ...constraints
        )
      : query(
          poseEventsCollection,
          where("ownerId", "==", ownerId),
          orderBy("startAt")
        );
  }

  async getAggregateDuration(
    ownerId: string,
    poseId: string,
    startDate: Date,
    endDate: Date
  ) {
    const poseEventsCollection = this.getPoseEventsCollection();
    const q = query(
      poseEventsCollection,
      where("ownerId", "==", ownerId),
      where("poseId", "==", poseId),
      where("startAt", ">=", startDate.getTime()),
      where("startAt", "<", endDate.getTime())
    );

    const snapshot = await getAggregateFromServer(q, {
      totalDuration: sum("duration"),
    });

    return snapshot.data().totalDuration;
  }

  async getAggregateDurations(
    ownerId: string,
    poseIds: string[],
    timeRanges: { startDate: Date; endDate: Date }[]
  ): Promise<Record<string, Record<string, number>>> {
    const results: Record<string, Record<string, number>> = {};

    for (const pose of poseIds) {
      results[pose] = {};
      for (let i = 0; i < timeRanges.length; i++) {
        const { startDate, endDate } = timeRanges[i];
        const duration = await this.getAggregateDuration(
          ownerId,
          pose,
          startDate,
          endDate
        );
        results[pose][`range${i}`] = duration;
      }
    }

    return results;
  }
}

export function usePoseEvents(
  ownerId?: string,
  poseId?: string,
  ...constraints: QueryConstraint[]
): [PoseEvent[] | undefined, boolean, Error | undefined] {
  if (!ownerId || !poseId) {
    useCollectionData<PoseEvent>();
    return [undefined, false, undefined];
  }
  const q = new PoseEventsDao().getQuery(ownerId, poseId, constraints);
  const [poseEvents, loading, error] = useCollectionData<PoseEvent>(q);
  if (error) {
    // ensure that the error is at least visible in the console
    console.error(error);
  }
  return [poseEvents, loading, error];
}

export function useAllPoseEvents(
  ownerId?: string,
  ...constraints: QueryConstraint[]
): [PoseEvent[] | undefined, boolean, Error | undefined] {
  if (!ownerId) {
    useCollectionData<PoseEvent>();
    return [undefined, false, undefined];
  }
  const q = new PoseEventsDao().getQueryAll(ownerId, constraints);
  const [poseEvents, loading, error] = useCollectionData<PoseEvent>(q);
  if (error) {
    // ensure that the error is at least visible in the console
    console.error(error);
  }
  return [poseEvents, loading, error];
}

export const getDefaultAggregationTimeRanges = () => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

  // Calculate the start of this week (Sunday)
  const thisWeekStart = new Date(today);
  thisWeekStart.setDate(today.getDate() - today.getDay());

  // Calculate the end of this week (next Sunday)
  const thisWeekEnd = new Date(thisWeekStart);
  thisWeekEnd.setDate(thisWeekStart.getDate() + 7);

  // Calculate the start of this month
  const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

  // Calculate the start of next month
  const nextMonthStart = new Date(today.getFullYear(), today.getMonth() + 1, 1);

  return [
    {
      startDate: today,
      endDate: tomorrow,
    },
    {
      startDate: thisWeekStart,
      endDate: thisWeekEnd,
    },
    {
      startDate: thisMonthStart,
      endDate: nextMonthStart,
    },
  ];
};

export function useAggregateDurations(
  ownerId?: string,
  poseIds?: string[],
  trigger: number = 1
): {
  aggregateDurations: Record<
    string,
    {
      range0: number;
      range1: number;
      range2: number;
    }
  > | null;
  loading: boolean;
  error: Error | null;
} {
  const [aggregateDurations, setAggregateDurations] = useState<Record<
    string,
    {
      range0: number;
      range1: number;
      range2: number;
    }
  > | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const timeRanges = useMemo(getDefaultAggregationTimeRanges, []);

  const fetchAggregateDurations = useCallback(async () => {
    if (!ownerId || !poseIds) {
      setLoading(false);
      return;
    }

    try {
      const dao = new PoseEventsDao();
      const durations = await dao.getAggregateDurations(
        ownerId,
        poseIds,
        timeRanges
      );
      setAggregateDurations(
        durations as Record<
          string,
          {
            range0: number;
            range1: number;
            range2: number;
          }
        >
      );
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
    }
  }, [ownerId, poseIds, timeRanges]);

  useEffect(() => {
    if (trigger) {
      fetchAggregateDurations();
    }
  }, [fetchAggregateDurations, trigger]);

  useEffect(() => {
    console.log("useAggregateDurations State changed:", {
      aggregateDurations,
      loading,
      error,
    });
  }, [aggregateDurations, loading, error]);

  return useMemo(
    () => ({
      aggregateDurations,
      loading,
      error,
    }),
    [aggregateDurations, loading, error]
  );
}
