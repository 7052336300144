import crc32 from "crc/crc32";

export interface TextToSpeechUtterance {
  id: string;
  text: string;
  context: {
    type: string;
    id?: string;
    subtype?: string;
    sceneIndex?: number;
  };
  voice?: string;
  hash: string;
  /**
   * Path to the storage object with the generated speech audio, such as gs://pose-challenges.appspot.com/elevenlabs-tts/216bf8b7.mp3
   */
  audioPath?: string;
  status?: string;
}

export type TextToSpeechUtteranceRequest = Pick<
  TextToSpeechUtterance,
  "text" | "voice" | "context"
>;

export function getUtteranceId(
  utterance: TextToSpeechUtteranceRequest
): string {
  const { text, voice } = utterance;
  return crc32(JSON.stringify({ text, voice })).toString(16);
}
